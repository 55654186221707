import React, { Fragment, useState } from "react"
import BlueButton from "../common/molecules/BlueButton";
import { CollapseItem } from './CollapseItem'

export const Collapse = ({
  faqs=[
    {
      id: '1',
      title: 'What is CBD?',
      desc: 'Cannabidiol (CBD) is one of more than 100 cannabinoids in the cannabis plant, known to possess anti-inflammatory, neuroprotective, and anti-anxiety properties. Because CBD is non-psychoactive, it will NOT make you high.'
    },
    {
      id: '2',
      title: 'What are cannabinoids?',
      desc: 'Cannabinoids are the key chemical compounds of the hemp and marijuana plants (both cannabis, just varietals). Although at least 113 active cannabinoids have been identified to-date, tetrahydrocannabinol (THC) is the only one that’s psychoactive. We extract our CBD from hemp, which makes our products 100% THC-Free.'
    },
    {
      id: '3',
      title: 'Will CBD make me high?',
      desc: 'Nope. CBD is completely non-psychoactive. If you’re looking to get high, please do not buy our products! You’ll be thoroughly disappointed (but feeling a little more chill about it).'
    },
    {
      id: '4',
      title: 'Are Not Pot products all-natural?',
      desc: 'Our CBD is extracted from organic USA hemp and most of our products are vegan. We are also proud to be 100% cruelty-free, meaning we only test our products on humans—the ones in our HQ to be exact! Our team is extremely passionate about animals and doesn’t condone animal testing in any way.'
    },
    {
      id: '5',
      title: 'Are Not Pot products third-party tested?',
      desc: <Fragment>Yes, all our products go through extensive third-party testing to ensure quality and consistency. For lab information on our products, you may go to the fine print section of each product page. <i>We love a babe that reads the fine print. Here’s 10% off. Code: <strong>URSOFINE</strong></i></Fragment>
    }
  ]
 }) => {
  const [isActive, setIsActive] = useState(false);
  const handleClick = () => setIsActive(!isActive);

  return (
    <Fragment>
      {faqs.map((faq, index) => (
        <CollapseItem
          faq={faq}
          key={index}
          handleClick={handleClick}
        />
      ))}

      <BlueButton
          m={{xs: "3.75rem auto 0", md: "3.75rem 0 2rem"}}
          textSize="header1"
          text="All faqs"
          w="15.875rem"
          className="small-font"
          onClick={e => {
            e.preventDefault()
            window.location.href = '/helpdesk/about-cbd'
          }}
        />
    </Fragment>
  )
}

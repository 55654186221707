import React, {useState} from "react"
import {graphql} from "gatsby"
import ls from "local-storage"
import {useMutation, useQuery} from "@apollo/client"

import {SEO} from "../components/common/SEO"
import Layout from "../components/common/Layout"
import GraphQLErrorList from "../components/common/GraphqQLErrorList"
import {saveCheckoutInLocalStorage} from "../services/cartService"
import {CHECKOUT_BY_ID, CHECKOUT_CREATE, TOGGLE_CART,} from "../graphql/queries"
import ModuleDealer from "../modules/ModuleDealer"

const Dealer = props => {
  //***************This checkoutData has to be in parent of all the instance of moduleContentWithPropductCards */
  const [cartItems, setCartItems] = useState(null);
  const [toggleCart] = useMutation(TOGGLE_CART, {
    onCompleted: () => {
      if (window && window.rdt){
        window.rdt('track', 'AddToCart', {
            "currency" : "USD",
            "itemCount": 1,
            "value": 100
        });
      }
    },
  })

  const {data: checkoutData, refetch: refechCheckoutData} = useQuery(
    CHECKOUT_BY_ID,
    {
      // skip: !ls("lastCheckoutID"),
      variables: {id: ls("lastCheckoutID")},
      onCompleted: result => {
        const {node = {}} = result
        const {orderStatusUrl = null} = node || {};
        if (orderStatusUrl) {
          ls.remove("lastCheckoutID");
          if (!ls.get('disable_notSpam')) {
            ls.set('disable_notSpam', true);
          }
          if (ls.get('np_code')) {
            ls.remove('np_code');
          }
        }
      },

    }
  )

  const [checkoutCreate] = useMutation(CHECKOUT_CREATE, {
    onCompleted: result => {
      //window.history.pushState("header", "Not Pot", "/#cart")
      toggleCart({variables: {cartDrawerVisible: true}})
      saveCheckoutInLocalStorage(result?.checkoutCreate?.checkout)
      refechCheckoutData({
        // skip: !ls("lastCheckoutID"),
        variables: {id: ls("lastCheckoutID")},
      })
    },
  })
  //********************************** */

  // useEmergence()
  const {data, errors} = props
  const PagePath = props?.location?.pathname || props.uri;
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors}/>
      </Layout>
    )
  }
  const {affiliate} = data
  const site = (data || {}).site
  const location = props.location

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }
  const pageTitle = affiliate.name
  const media = affiliate?.header?.image?.asset?.url || ''
  return (
    <Layout site={site}>
      <SEO
        title={pageTitle}
        media={media}
        url={PagePath ? PagePath : ''}
      />
      <ModuleDealer affiliate={affiliate} checkoutData={cartItems}/>
    </Layout>
  )
}

export default Dealer

export const query = graphql`
  query DealerTemplateQuery($id: String!, $slug: String!) {
    affiliate: sanityAffiliatePage(id: { eq: $id }) {
      name
      quote
      quoteSource
      zodiac
      header
      slug
      discountCoupon
      AffiliateProducts {
        description
        affiliateProduct {
          title
          desktopTitle
          mobileTitle
          price
          inventory_quantity
          productHandle
          variantTitle
          featuredImage {
            asset {
              path
              url
            }
          }
          carousel {
            assets {
              video {
                asset {
                  url
                }
              }
              image {
                asset {
                  url
                }
              }
            }
          }
        }
      }
      info {
        city
        state
      }
      social {
        twitter
        instagram
        youtube
        tiktok
        twitch
      }
      preferences {
        interest
        music
        movies
        books
        munchies
      }
      head {
        image {
          asset {
            path
            url
          }
        }
        alt
      }
      _createdAt
    }
    junipProduct(remote_handle: { eq: $slug }) {
      id
      rating_average
      rating_count
      remote_id
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SiteSettings
    }
  }
`

import React, {useState} from "react"
import {Button, Div, Text} from "../lib/atomize"
import {Element} from "react-scroll"
import RatingStar from "../components/reviews/RatingStar"
import ReviewListItem from "../components/reviews/ReviewListItem"
import WebsiteContainer from "../components/common/atoms/WebsiteContainer"

import useReviews from "../hooks/useReviews"

// const params = {
//   breakpoints: {
//     1024: {
//       slidesPerView: 3,
//       spaceBetween: 50,
//       slidesPerColumn: 2,
//       slidesPerGroup: 3,
//     },
//     768: {
//       slidesPerView: 2,
//       spaceBetween: 40,
//       slidesPerColumn: 2,
//       slidesPerGroup: 2,
//     },
//     640: {
//       slidesPerView: 1,
//       spaceBetween: 40,
//       slidesPerColumn: 1,
//       //   slidesPerGroup: 1,
//     },
//     320: {
//       slidesPerView: 1,
//       spaceBetween: 50,
//       slidesPerColumn: 3,
//     },
//   },
// }

const sortTypes = [
  {
    id: "most-recent",
    title: "Most Recent",
    sort: "created_at",
    order: "desc",
  },
  {
    id: "oldest",
    title: "Oldest",
    field: "created_at",
    order: "asc",
  },
  {
    id: "higest-rated",
    title: "Highest Rated",
    field: "rating",
    order: "desc",
  },
  {
    id: "lowest-rated",
    title: "Lowest Rated",
    field: "rating",
    order: "asc",
  },
]

export default function ModuleProductReviews({
                                               product,
                                               junipProduct,
                                               perPage,
                                             }) {
  const computedJunipProduct = junipProduct.aliased_product || junipProduct

  const [after, setAfter] = useState(null)
  const [before, setBefore] = useState(null)
  const [selectedSort, setSelectedSort] = useState(sortTypes[0])
  // const [swiper, setSwiper] = useState(null)
  const {data, loading, error, meta} = useReviews({
    productId: computedJunipProduct.remote_id,
    after,
    before,
    sort: selectedSort.field,
    order: selectedSort.order,
    perPage,
  })

  // useEffect(() => {

  // const onSwiperInit = swiper => {
  //   setSwiper(swiper)
  // }

  // const goNext = () => {
  //   if (swiper) {
  //     swiper.slideNext()
  //   }
  // }

  // const goPrev = () => {
  //   if (swiper) {
  //     swiper.slidePrev()
  //   }
  // }

  // if (loading) return <Loader />

  if (!junipProduct.rating_average) return false

  return (
    <Div
      id="reviews"
      className="bgblocks"
      data-bg="#EAF1F9"
      m={{
        t: {
          xs: "3.375rem",
          lg: "9.375rem"
        }
      }}
    >
      <WebsiteContainer>
        <Div textAlign="center" d="flex" flexDir="column" align="center">
          <Element
            name="review-element"
            id="review-element"
            style={{visibility: "hidden"}}
          ></Element>
          <Text fontFamily="secondary" textWeight="bold" textSize="display1">
            {junipProduct.rating_average.toFixed(2)}
          </Text>
          <RatingStar
            m={{y: "1.25rem"}}
            rating={junipProduct.rating_average.toFixed(2)}
            width="20px"
            height="20px"
          />
          <Text textSize="paragraph1">{junipProduct.rating_count} Reviews</Text>
          <Div
            d="flex"
            flexWrap="wrap"
            justify="center"
            maxW="20rem"
            m={{t: "1.25rem", b: "3.125rem", x: "auto"}}
          >
            {sortTypes.map((sortType, id) => {
              return (
                <Div key={sortType.id}>
                  <Button
                    rounded="20px"
                    shadow={selectedSort.id === sortType.id ? "2" : ""}
                    textColor={
                      selectedSort.id === sortType.id ? "white" : "blue"
                    }
                    bg={
                      selectedSort.id === sortType.id ? "blue" : "transparent"
                    }
                    // hoverBg={
                    //   !(selectedSort.id === sortType.id) && "yellowGreen"
                    // }
                    onClick={() => setSelectedSort(sortType)}
                    border="1px solid"
                    borderColor="blue"
                    m={{
                      b: "1.25rem",
                      r: id % 2 === 0 ? "10px" : "0",
                    }}
                    p="10px 1.25rem"
                  >
                    {sortType.title}
                  </Button>
                </Div>
              )
            })}
          </Div>

          {/* /*  PDP Reviews  * */}
          {/* {loading && <Loader />} */}
          <Div
            p={{t: "2rem"}}
            w="100%"
            border={{t: "1px solid"}}
            borderColor="lightBlue"
            className="pdp-reviews"
          >
            {/* <Swiper
              // getSwiper={swiper => onSwiperInit(swiper)}
              {...params}
            > */}
            {loading
              ? Array.from(Array(6).keys()).map(index => (
                <Div w="100%" key={index}>
                  <Div
                    p="7rem 1.25rem"
                    bg="yellowGreen"
                    shadow="2"
                    rounded="lg"
                    h="100%"
                  />
                </Div>
              ))
              : (data || []).map((review, id) => {
                return (
                  <Div key={review.id} className="slide-review" w="100%">
                    <ReviewListItem review={review}/>
                  </Div>
                )
              })}
          </Div>

          {/* </Swiper> */}
          <Div d="flex" justify="center" align="center" m={{t: "3.125rem"}}>
            <Button
              bg="transparent"
              border="1px solid"
              borderColor={
                meta && meta.page.before === null ? "#8e96ac" : "blue"
              }
              rounded="20px"
              textColor={meta && meta.page.before === null ? "#8e96ac" : "blue"}
              p="10px 1.25rem"
              onClick={() => {
                setBefore(meta.page.before)
                setAfter(null)
              }}
            >
              Prev
            </Button>
            <Text m="0 1.25rem">Displaying Reviews </Text>
            <Button
              onClick={() => {
                setAfter(meta.page.after)
                setBefore(null)
              }}
              bg="transparent"
              border="1px solid"
              borderColor={
                meta && meta.page.after === null ? "#8e96ac" : "blue"
              }
              rounded="20px"
              textColor={meta && meta.page.after === null ? "#8e96ac" : "blue"}
              p="10px 1.25rem"
            >
              Next
            </Button>
          </Div>
        </Div>
      </WebsiteContainer>
    </Div>
  )
}

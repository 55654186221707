import React from "react"
import { Div, Text } from "../../lib/atomize"

import StarRatings from 'react-star-ratings';

export default function RatingStar({ m, rating, width, height, starColor }) {
  return (
    <Div pos="relative" m={m}>

      <StarRatings
        rating={rating ? parseFloat(rating) : 0}
        numberOfStars={5}
        starDimension={width}
        starSpacing="0"
        starEmptyColor="#FFFFFF"
        starRatedColor= {starColor || "#283377"}
        svgIconViewBox="0 0 13 12"
        svgIconPath="M6.40742 0.208252L8.14357 3.7812L12.0257 4.35415L9.21657 7.1353L9.87972 11.0624L6.40742 9.20825L2.93513 11.0624L3.59828 7.1353L0.78913 4.35415L4.67128 3.7812L6.40742 0.208252Z"
      />
    </Div>
  )
}

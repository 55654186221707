import React from 'react'

export const trackProductPage = (product) => {
    if (process.env.NODE_ENV === "production") {
        window.freshpaint.removeEventProperty('productPageView')
        window.freshpaint.track(
            'productPageView',
            {
                productId: product.sanityId,
                content_name: product.title,
                content_type: 'product_group'
            }
        );
    }
}

export const trackAddToBag = (product, variant, count) => {
    const {sanityId = ''} = product || {}
    const {_id: variantId = '', title = '', price = ''} = variant || {}
    if (process.env.NODE_ENV === "production") {
        window.freshpaint.removeEventProperty('addToBag')
        window.freshpaint.track(
            'addToBag',
            {
                productId: sanityId || variantId,
                content_type: 'product_group',
                content_name: title,
                value: price,
                quantity: count,
                currency: 'USD',
            }
        );
    }
}

export const trackCheckout = (amount) => {
    if (process.env.NODE_ENV === "production") {
        window.freshpaint.removeEventProperty('checkout')
        window.freshpaint.track(
            'checkout',
            {
                value: amount,
                currency: 'USD',
            }
        );
    }
}

import React, {Fragment, useEffect, useState} from "react";
import BlueButton from "../common/molecules/BlueButton";
import {Div} from "../../lib/atomize";
import ModulePdpHero from "../../modules/ModulePdpHero";
import {Dropdown} from "../common/Dropdown";
import {Carousel} from "../common/Carousel";
import {getDefaultVariant} from "../../services/productService";
import { EasterEggItem } from "../easteregg/EasterEggItem";

export const Subscription = ({pageContent}) => {
  const [productList, setProductList] = useState([])
  const [selectedProduct, setSelectedProduct] = useState('')
  const [carousel, setCarousel] = useState('');
  useEffect(() => {
    if (pageContent && Array.isArray(pageContent)) {
      pageContent.map((content) => {
        if (content?._type === 'contentWithImageExtra') {
          const products = []
          if (content?.products) {
            content.products.map((product) => {
              let item = {...product?.productVariant}
              item.shortDescription = product.description
              if (item.hasSubscription) {
                products.push(item)
              }
            });
            setProductList(products)
            setCarousel(content?.carousel)
            if (products && products[0]) {
              setSelectedProduct(products[0])
            }
          }

        }
      })
    }
  }, [pageContent])

  const [selectedVariant, setSelectedVariant] = useState(
    getDefaultVariant({
      variantSlug: selectedProduct?.variantSlug,
      productList,
    })
  )
  return (

    <Div id="lp-membership" className="lp-membership bgblocks" data-bg="#FEE3E1">
      <ModulePdpHero
        setSelectedVariant={setSelectedVariant}
        selectedVariant={{
          _id: selectedProduct?._type === 'product' ? selectedProduct?.defaultVariant?._id : selectedProduct?._id,
          variantTitle: 'Build your ritual',
          carousel: carousel || {
            title: 'Chilltime',
            assets: []
          },
          hasSubscription: true,
          price: selectedProduct?._type === 'product' ? selectedProduct?.defaultVariant?.price : selectedProduct?.price,
          discountedPrice: selectedProduct?._type === 'product' ? selectedProduct?.defaultVariant?.discountedPrice : selectedProduct?.discountedPrice,
          inventory_quantity: selectedProduct?._type === 'product' ? selectedProduct?.defaultVariant?.inventory_quantity : selectedProduct?.inventory_quantity,
          hideOneTime: true
        }}
        product={{
          desktopTitle: 'Build your ritual',
          subscriptionType: "subscribe",
          price: selectedProduct?._type === 'product' ? selectedProduct?.defaultVariant?.price : selectedProduct?.price,
          discountedPrice: selectedProduct?._type === 'product' ? selectedProduct?.defaultVariant?.discountedPrice : selectedProduct?.discountedPrice,
        }}
        descriptionExtra={
          <Fragment>
            <Dropdown
              selected={selectedProduct}
              options={productList}
              onSelect={(selected) => {
                setSelectedProduct(selected)
              }}
            />
            <p className="product-desc">{selectedProduct.shortDescription} <a
              href={selectedProduct?.productHandle ? `/products/${selectedProduct?.customVariantSlug || selectedProduct?.productHandle}` : '#'}
              className="full-link">See full details</a>.</p>

          </Fragment>
        }
        ctaText="ADD TO SUBSCRIPTION"
      />

      <Carousel mode={process.env.GATSBY_SITE_MODE}/>

      <section className="faq">
        <div style={{position: "relative"}}>
          <h2>Still have questions?</h2>
        </div>
        <BlueButton
          m="3.75rem auto 2rem"
          textSize="header1"
          text="Read our faqs"
          w="15.875rem"
          className="small-font"
          onClick={e => {
            e.preventDefault()
            window.location.href = '/helpdesk/membership'
          }}
        />
      </section>

    </Div>
  )
}

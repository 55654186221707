import React, { useState } from 'react'
import { Div } from '../../lib/atomize'
import ReadMoreReact from 'read-more-react'

import CardHeader from '../common/CardHeader'
import ImageLazyLoad from '../common/molecules/ImageLazyLoad'
import TestimonialsModal from '../common/molecules/TestimonialsModal'
import trimText from 'read-more-react/dist/utils/trimText.js'

const ProductTestimonials = ({ quote }) => {
  const { product = '', subtitle = '', handle = '', testimonial = '', image = {} } = quote
  const [modal, showModal] = useState(false)
  const [showAll, setShowAll] = useState(false)

  return (
    <Div
      border="1px solid"
      borderColor="lightPink"
      shadow="1"
      rounded="lg"
      className="card-item"
    >
      <CardHeader
        heading={product}
        padding="0.625rem 0.625rem"
        className="card-item-header"
      />
      <Div bg="yellow" rounded={{ b: "lg" }} d="flex" flexDir="column">
        <Div
          p="0.5rem 1.25rem"
          textAlign="center"
          bg="#ffffff"
          className="card-item-sub-header"
        >
          <p>{handle}</p>
        </Div>
        <Div
          bg="gray"
          p="1.25rem"
          className="card-item-img-container"
          pos="relative"
          rounded={{ b: "lg" }}
        >
          {image &&
            <ImageLazyLoad
              bgImg={image?.asset?.url || ''}
              bgPos="center"
              bgSize="cover"
              p={{ b: "100%" }}
              transition
            >
              <img
                src={image?.asset?.url || ''}
                alt={handle}
                className="product-img"
              />
            </ImageLazyLoad>
          }
          <p className={`body-text${showAll ? ' full' : ''}`}>
            <span className="text">{testimonial}</span>
            <span className="read-more-button mobile" onClick={() => setTimeout(() => { setShowAll(true) }, 100)}>Read More</span>
            <span className="read-more-button" onClick={() => showModal(true)}>Read More</span>
          </p>
        </Div>
      </Div>
      <TestimonialsModal
        isOpen={modal}
        onClose={() => showModal(false)}
        image={image?.asset?.url}
        testimonial={testimonial}
        product={product}
        handle={handle}
      />
    </Div>
  )
}

export default ProductTestimonials;

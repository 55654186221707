import React from "react"
import BasePortableText from "@sanity/block-content-to-react"

import serializers from "./serializers"
import clientConfig from "../../../client-config"

const PortableText = ({ blocks, className }) => (
  <BasePortableText
    blocks={blocks}
    serializers={serializers}
    {...clientConfig.sanity}
    className={className}
    renderContainerOnSingleChild
  />
)

export default PortableText

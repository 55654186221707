import React, {useEffect} from "react"
import isMobile from 'ismobilejs';

export default function () {
  useEffect(() => {
    if (isMobile().any) {
      if (isMobile().android.phone) {
        window.location.href = 'sms:+14352604954?body=Join'
      }
      if (isMobile().apple.phone) {
        window.location.href = 'sms:+14352604954&body=Join'
      }

    } else {
      window.location.href = '/access-subscribe-page'
    }
  })
  return (

    <>
      Loading...
    </>
  )
}

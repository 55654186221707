import React, {useContext} from "react"
import {Anchor, Text} from "../../lib/atomize"
import {Link} from "gatsby"

import BlueButton from "./molecules/BlueButton"
import {AppContext} from "../../context";

const CTALink = props => {
  const {
    route,
    link,
    landingPageRoute,
    kind,
    title,
    redirectToMain = false,
    internal = false,
    ...rest
  } = props

  const [state, dispatch] = useContext(AppContext);
  const {utmParams = ''} = state

  let currentLink = route || link || "#"

  if (
    landingPageRoute &&
    landingPageRoute.slug &&
    landingPageRoute.slug.current
  ) {
    currentLink = landingPageRoute.slug.current
  }
  if (internal) {
    if (currentLink.charAt(0) !== '/') {
      currentLink = `/${currentLink}`
    }
  }
  if (kind === "button:blue") {
    return <BlueButton text={title} {...rest} route={route}/>
  }


  // External
  let externalLink = link
  if (link) {
    const regex = new RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi);
    let target = "_blank"
    if (regex.test(link)) {
      if (link.includes('notpot')) {
        //console.log(utmHelper.appendUTM(link,utmParams))
        if (utmParams) {
          externalLink = `${link}${utmParams}`.trim()
        }
        target = "_self"
      }
    }

    return (
      <Anchor
        href={externalLink}
        textSize="body"
        textColor="blue"
        d="block"
        style={{lineHeight: "14px"}}
        target={target}
        hoverTextColor="rgb(108, 151, 196) !important"
        {...rest}
      >
        {title}
      </Anchor>
    )
  }

  return (
    <Link to={currentLink}>
      <Text
        textSize="body"
        hoverTextColor="rgb(108, 151, 196) !important"
        textColor="blue"
        style={{lineHeight: "14px"}}
        {...rest}
      >
        {title}
      </Text>
    </Link>
  )
}

export default CTALink

import * as yup from "yup"

export const upadteProfileValidator = yup.object().shape({
  email: yup
    .string()
    .required("Please use a valid email address.")
    .min(1)
    .email("Please use a valid email address."),
  firstName: yup.string().required("Please enter first name").min(1),
  lastName: yup.string().required("Please enter last name.").min(1),
  phone: yup.string().required("Please use a valid phone number.").min(10),
})

/**
 * validator for update password form
 */

export const updatePasswordValidator = yup.object().shape({
  password: yup.string().required().min(6).max(255),
  password_confirmation: yup
    .string()
    .required()
    .min(6)
    .max(255)
    .oneOf([yup.ref("password"), null], "Passwords don't match")
    .required("Confirm Password is required"),
})

/**
 * validator for reset password form
 */
export const resetPasswordValidator = yup.object().shape({
  password: yup.string().required().min(6).max(255),
  password_confirmation: yup
    .string()
    .required()
    .min(6)
    .max(255)
    .oneOf([yup.ref("password"), null], "Passwords don't match")
    .required("Confirm Password is required"),
})

import React, { useState, useEffect } from "react"
import { get, capitalize } from "lodash"
import ls from "local-storage"
import axios from "axios"
import { Formik } from "formik"
import { useQuery, gql, useMutation } from "@apollo/client"
import { Div, Text, Input, Image, Button, Icon } from "../lib/atomize"

import { FETCH_CUSTOMER, CUSTOMER_UPDATE } from "../graphql/queries"
import { upadteProfileValidator } from "../validators"
import {
  firePostLogout,
  onProfileUpdateRequestError,
  onProfileUpdateRequestComplete,
} from "../services/authService"
import BlueButton from "../components/common/molecules/BlueButton"
import { formatPhoneNumber } from "../utilities/helpers"

const ModuleAccountInfo = ({ toggleTab }) => {
  let formikProps
  const {
    data: fetchCustomerData,
    loading: fetchCustomerLoading,
    refetch: refetchCustomer,
  } = useQuery(FETCH_CUSTOMER, {
    skip: !ls("accessToken"),
    variables: { accessToken: ls("accessToken") },
  })

  const [updateProfile] = useMutation(CUSTOMER_UPDATE, {
    onCompleted: result => {
      onProfileUpdateRequestComplete({
        response: result,
        formik: formikProps,
        refetchCustomer,
      })
    },
    onError: error => {
      onProfileUpdateRequestError({ error, formik: formikProps })
    },
  })

  /**
   * on submit form
   *
   * @param {*} values
   */
  const onSubmit = values => {
    if (values.phone) {
      values.phone = formatPhoneNumber(values.phone)
    }
    updateProfile({
      variables: {
        customerAccessToken: ls("accessToken"),
        customer: values,
      },
    })
  }
  //

  /**
   * on logout
   */
  const onClickLogoutBtn = e => {
    e.preventDefault()

    firePostLogout()
  }
  // useEffect(() => {
  //   fetchCustomers()
  // }, [])
  const customer = get(fetchCustomerData, "customer")

  if (fetchCustomerLoading)
    return (
      <Div d="flex" justify="center" align="center" h="50vh">
        <Icon name="Loading" color="blue" size="16px" />
      </Div>
    )

  return (
    <>
      <Div
        p={{
          y: { xs: "1.875rem", lg: "2.5rem" },
          x: { xs: "1.25rem", lg: "2.5rem" },
        }}
        bg="yellow"
        rounded="lg"
        shadow="4"
      >
        <Text
          textWeight="bold"
          textSize="display1"
          fontFamily="secondary"
          p={{ b: { xs: "1.875rem", lg: "2.5rem" } }}
          style={{ lineHeight: "32px" }}
          border={{ b: "1px solid" }}
          borderColor="borderColor"
          textAlign={{ xs: "center", lg: "left" }}
        >
          Your Account
        </Text>
        <Formik
          initialValues={{
            phone: customer?.phone || "",
            email: customer?.email || "",
            firstName: customer?.firstName || "",
            lastName: customer?.lastName || "",
          }}
          onSubmit={onSubmit}
          validationSchema={upadteProfileValidator}
        >
          {props => {
            const {
              values,
              status,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              submitForm,
            } = props

            formikProps = props

            return (
              <form onSubmit={handleSubmit}>
                {status && status.successMessage && (
                  <Text
                    textColor="success700"
                    m={{ b: "1rem" }}
                    opacity={status.successMessage ? "1" : "0"}
                    transition
                  >
                    {status.successMessage}
                  </Text>
                )}
                {status && status.errorMessage && (
                  <Text
                    textColor="danger700"
                    m={{ b: "1rem" }}
                    opacity={status.errorMessage ? "1" : "0"}
                    transition
                  >
                    {status.errorMessage}
                  </Text>
                )}
                <Div
                  m={{ t: "2.4rem" }}
                  // d="flex" flexWrap="wrap" maxW="70%"
                  className="account-card-input"
                >
                  <Div>
                    <Input
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      placeholderTextColor="navy"
                      shadow="2"
                      textColor="blue"
                      h="2.5rem"
                      border="1px solid"
                      borderColor="transparent"
                      borderColor={
                        touched.firstName
                          ? values.firstName
                            ? errors.firstName
                              ? "red"
                              : "oilGreen"
                            : errors.firstName
                              ? "red"
                              : "transparent"
                          : "transparent"
                      }
                      p="0 2.125rem"
                      prefix={
                        <Image
                          src="/name.svg"
                          w="14px"
                          h="14px"
                          pos="absolute"
                          top="50%"
                          left="0.75rem"
                          transform="translateY(-50%)"
                        />
                      }
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.firstName && (
                      <Text
                        textColor="error"
                        textSize="paragraph"
                        m={{ b: "0.1rem" }}
                      >
                        {touched.firstName && capitalize(errors.firstName)}
                      </Text>
                    )}
                  </Div>
                  <Div>
                    <Input
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      placeholderTextColor="navy"
                      shadow="2"
                      textColor="blue"
                      h="2.5rem"
                      border="1px solid"
                      borderColor="transparent"
                      borderColor={
                        touched.lastName
                          ? values.lastName
                            ? errors.lastName
                              ? "red"
                              : "oilGreen"
                            : errors.lastName
                              ? "red"
                              : "transparent"
                          : "transparent"
                      }
                      p="0 2.125rem"
                      prefix={
                        <Image
                          src="/name.svg"
                          w="14px"
                          h="14px"
                          pos="absolute"
                          top="50%"
                          left="0.75rem"
                          transform="translateY(-50%)"
                        />
                      }
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.lastName && (
                      <Text
                        textColor="error"
                        textSize="paragraph"
                        m={{ b: "0.1rem" }}
                      >
                        {touched.lastName && capitalize(errors.lastName)}
                      </Text>
                    )}
                  </Div>
                  <Div>
                    <Input
                      type="text"
                      name="email"
                      placeholder="Email"
                      placeholderTextColor="navy"
                      shadow="2"
                      textColor="blue"
                      h="2.5rem"
                      border="1px solid"
                      borderColor="transparent"
                      borderColor={
                        touched.email
                          ? values.email
                            ? errors.email
                              ? "red"
                              : "oilGreen"
                            : errors.email
                              ? "red"
                              : "transparent"
                          : "transparent"
                      }
                      p="0 2.125rem"
                      prefix={
                        <Image
                          src="/email.svg"
                          w="14px"
                          h="14px"
                          pos="absolute"
                          top="50%"
                          left="0.75rem"
                          transform="translateY(-50%)"
                          alt="Email"
                        />
                      }
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.email && (
                      <Text
                        textColor="error"
                        textSize="paragraph"
                        m={{ b: "0.1rem" }}
                      >
                        {touched.email && capitalize(errors.email)}
                      </Text>
                    )}
                  </Div>
                  <Div>
                    <Input
                      type="text"
                      name="phone"
                      placeholder="Phone Number"
                      placeholderTextColor="navy"
                      shadow="2"
                      textColor="blue"
                      h="2.5rem"
                      border="1px solid"
                      borderColor="transparent"
                      borderColor={
                        touched.phone
                          ? values.phone
                            ? errors.phone
                              ? "red"
                              : "oilGreen"
                            : errors.phone
                              ? "red"
                              : "transparent"
                          : "transparent"
                      }
                      p="0 2.125rem"
                      prefix={
                        <Image
                          src="/phone.svg"
                          w="14px"
                          h="14px"
                          pos="absolute"
                          top="50%"
                          left="0.75rem"
                          transform="translateY(-50%)"
                          alt="Phone"
                        />
                      }
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.phone && (
                      <Text
                        textColor="error"
                        textSize="paragraph"
                        m={{ b: "0.1rem" }}
                      >
                        {touched.phone && capitalize(errors.phone)}
                      </Text>
                    )}
                  </Div>

                  <BlueButton
                    type="submit"
                    m={{ t: { xs: "0.625rem", lg: "0" } }}
                    text={
                      isSubmitting ? (
                        <Icon name="Loading" color="white" size="16px" />
                      ) : (
                        "Update Info"
                      )
                    }
                    padding="0.5rem 1.25rem"
                    textSize="header1"
                    h="2.5rem"
                    w="100%"
                  />
                </Div>
              </form>
            )
          }}
        </Formik>
        <Div
          m={{ t: "2.5rem" }}
          d={{ xs: "block", md: "flex" }}
          maxW={{ md: "15.375rem" }}
        >
          <Button
            rounded="20px"
            cursor="pointer"
            bg="pink"
            shadow="2"
            p="13px 1.25rem"
            w={{ xs: "100%", md: "auto" }}
            m={{ r: { md: "10px" }, b: { xs: "10px", md: "0" } }}
            textTransform="uppercase"
            textColor="blue"
            textWeight="bold"
            fontFamily="secondary"
            onClick={() => toggleTab("update-password")}
          >
            Update Password
          </Button>
          <Button
            w={{ xs: "100%", md: "auto" }}
            rounded="20px"
            p="13px 1.25rem"
            cursor="pointer"
            bg="transparent"
            border="1px solid"
            borderColor="borderColor"
            //shadow="2"
            //m={{ r: "10px" }}
            textTransform="uppercase"
            textColor="blue"
            textWeight="bold"
            fontFamily="secondary"
            onClick={onClickLogoutBtn}
          >
            Sign out
          </Button>
        </Div>
      </Div>
    </>
  )
}

export default ModuleAccountInfo

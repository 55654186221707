import React from "react"
import { Div, Text, Image } from "../../../lib/atomize"

import SideDrawer from "../atoms/SideDrawer"
import ForgotPasswordForm from "../../auth/ForgotPasswordForm"

const ForgotPasswordModal = ({ isOpen, onClose }) => {
  return (
    <SideDrawer isOpen={isOpen} onClose={onClose}>
      <Div
        d="flex"
        justify="space-between"
        align="center"
        m={{ b: "2.5rem" }}
        p={{ b: "2.5rem" }}
        border={{ b: "1px solid" }}
        borderColor="yellowPink"
      >
        <Text
          textTransform="uppercase"
          textWeight="bold"
          fontFamily="secondary"
          textSize="display1"
        >
          Forgot Password
        </Text>
        <Div
          style={{ lineHeight: "0" }}
          bg="lightPink"
          shadow="2"
          hoverShadow="3"
          cursor="pointer"
          rounded="sm"
          onClick={onClose}
        >
          <Image src="/close.svg" h="1rem" w="1rem" p="10px" alt="Close" />
        </Div>
      </Div>

      <ForgotPasswordForm />
    </SideDrawer>
  )
}

export default ForgotPasswordModal

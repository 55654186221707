import React, {useContext, useEffect, useState} from "react"
import {Div} from "../lib/atomize"
import {graphql} from "gatsby"
import {useMutation} from "@apollo/client"
import {SEO} from "../components/common/SEO"
import Layout from "../components/common/Layout"
import GraphQLErrorList from "../components/common/GraphqQLErrorList"
import {TOGGLE_CART,} from "../graphql/queries"
import ModulePdpHero from "../modules/ModulePdpHero";
import {getProductModules} from "../services/productService";
import ls from "local-storage";
import {isEmpty} from "lodash";
import {freebieActions} from "../actions";
import {AppContext} from "../context";

// modules

const Bundle = props => {
  const [toggleCart] = useMutation(TOGGLE_CART, {
    onCompleted: result => {
      const {node = {}} = result
      const {orderStatusUrl = null} = node || {};
      if (orderStatusUrl) {
        ls.remove("lastCheckoutID");
        if (!ls.get('disable_notSpam')) {
          ls.set('disable_notSpam', true);
        }
        if (ls.get('np_code')) {
          ls.remove('np_code');
        }
      }
      if (window && window.rdt){
        window.rdt('track', 'AddToCart', {
            "currency" : "USD",
            "itemCount": 1,
            "value": 100
        });
      }
    },
  })
  const [bundlePrice, setBundlePrice] = useState(0)
  const [bundleQuantity, setBundleQuantity] = useState(undefined)
  const [bundleVariants, setBundleVariants] = useState([])
  const [state, dispatch] = useContext(AppContext);
  const PagePath = props?.location?.pathname || props.uri;

  const [selectedVariant, setSelectedVariant] = useState(
    {}
  )
  //*************************** */
  const {data, errors} = props
  useEffect(() => {
    const {site = {}} = data
    const {freebie = {}} = site

    if (!isEmpty(freebie)) {
      dispatch({
        type: freebieActions.SET_FREEBIE,
        payload: freebie,
      })
    }

  }, [PagePath, data])

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors}/>
      </Layout>
    )
  }
  const site = (data || {}).site
  const {junipProduct = {}, bundle = {}} = data

  const {
    og = {},
    id = 0,
    howToUse = {},
    supplementFactsFineprint = '',
    latestLabReportsFineprint = '',
    ingredients = {},
    subheader = '',
    volume = '',
    pax = 1,
    relatedVariants = [],
    product_name = '',
  } = bundle
  const {seo = {}} = bundle || {};
  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }
  const product = {...bundle, variants: bundleVariants,}
  const title = bundle?.name || 'Bundles'
  const modules = getProductModules({
    product
  })

  const formatItems = (items = []) => {
    let price = 0;
    const qtylist = [];
    const variants = [];
    const bundles = items.length ? items.map((item) => {
      const {variant = {}, name = '', displayTitle = '', pax = 1, slug = ''} = item
      const {price: variantPrice = 0, inventory_quantity = 0} = variant || {}
      const itemPrice = parseFloat(variantPrice) * (pax || 1)
      price += itemPrice;
      qtylist.push(inventory_quantity)
      if (variant) {
        variants.push(
          {
            ...variant,
            name: name || variant.name,
            variantTitle: displayTitle,
            deleted: false
          }
        )
        return {
          id: variant._id,
          name: displayTitle || variant.variantTitle,
          desc: variant.productDescription,
          image: variant?.featuredImage?.asset?.url || variant?.carousel?.assets[0]?.image?.asset?.url || '',
          price: parseFloat(variantPrice * pax),
          slug,
          pax
        }
      }

    }) : []
    if (bundlePrice === 0 && price !== 0) {
      setBundlePrice(price.toFixed(2))
    }
    if (qtylist.length && bundleQuantity === undefined) {
      setBundleVariants(variants)
      setBundleQuantity(qtylist.length ? Math.min(...qtylist) : 0)
    }
    return bundles
  }

  const seoTitle = seo?.seo_title || title;
  const seoDescription = seo?.meta_description || bundle?.description || '';
  const seoKeywords = seo?.focus_synonyms || [];
  const seoFocus = seo?.focus_keyword || "";
  const metaKeywords = [seoFocus].concat(seoKeywords);
  const socialTitle = og?.title || seoTitle;
  const socialDesc = og?.description || seoDescription;
  const carousel = bundle?.carousel || [];
  const items = bundle?.items && Array.isArray(bundle?.items) && formatItems(bundle.items) || [];

  const pageClass = (PagePath ? PagePath.replace(/\//g, '') : 'home')
  const isHalloween = (PagePath === '/bundles/halloween')
  const customVariants = []

  if (isEmpty(selectedVariant)) {
    setSelectedVariant({
      id: id,
      variantTitle: title,
      name: title,
      carousel: carousel || {
        title: 'Chilltime',
        assets: []
      },
      price: bundlePrice,
      inventory_quantity: bundleQuantity,
      hasSubscription: false,
      productDescription: bundle?.description || '',
      featuredImage: bundle.featuredImage || {}
    })
  }

  relatedVariants.map((relatedItem => {
    customVariants.push({
      _id: `/bundles/${relatedItem.slug}` === PagePath ? id : 'not-bundle',
      id: id,
      variantTitle: relatedItem.slug,
      name: relatedItem.customName,
      customName: relatedItem.customName,
      price: bundlePrice,
      inventory_quantity: bundleQuantity,
      hasSubscription: false,
      productDescription: bundle?.description || '',
      featuredImage: bundle.featuredImage || {},
      custom: true
    })
  }))
  return (
    <Layout
      site={site}
      pageClass={`page-wrapper page-${pageClass}`}
      changeColor={!isHalloween}
    >
      <SEO
        title={seoTitle}
        keywords={metaKeywords}
        site={site}
        url={PagePath ? PagePath : ''}
        socialTitle={socialTitle}
        socialDesc={socialDesc}
        headComponents={site.headComponents}
      />
      <Div>
        <Div
          p={{t: {xs: "1.875rem", lg: "2.5rem"}}}
          className="bundles-container"
        >
          <ModulePdpHero
            product={{
              desktopTitle: title,
              pdpTitle: product_name,
              price: '5',
              volume,
              variants: bundleVariants,
              supplementFactsFineprint,
              latestLabReportsFineprint,
              howToUse,
              ingredients,
              subheader
            }}
            selectedVariant={{
              _id: id,
              id: id,
              variantTitle: title,
              name: title,
              carousel: carousel || {
                title: 'Chilltime',
                assets: []
              },
              price: bundlePrice,
              inventory_quantity: bundleQuantity,
              hasSubscription: false,
              productDescription: bundle?.description || '',
              featuredImage: bundle.featuredImage || {},
              product_name
            }}
            bundles={items}
            originalPrice={bundle?.original_price || ''}
            customVariants={customVariants}
            setSelectedVariant={setSelectedVariant}
          />
          {modules}
        </Div>
      </Div>
    </Layout>
  )
}

export default Bundle

export const query = graphql`
  query BundleTemplateQuery($id: String!, $slug: String!) {
    bundle: sanityBundles(id: { eq: $id }) {
      id
      name
      product_name
      description:_rawDescription
      original_price
      subheader
      volume
      caption: _rawCaption
      seo {
        seo_title
        meta_description
        focus_keyword
        focus_synonyms
      }
      og{
        title
        description
      }
      carousel {
        title
        icon {
          asset {
            url
          }
        }
        assets {
          video {
            asset {
              url
            }
          }
          image {
            asset {
              url
            }
          }
          caption
          alt
        }
      }
      items {
        displayTitle
        pax
        slug{
          current
        }
        variant {
          _id
          id
          title
          price
          discountedPrice
          sku
          inventory_quantity
          variantTitle
          hasSubscription
          productDescription: _rawProductDescription
          icon {
            asset {
              url
            }
          }
          supplementFacts {
            asset {
              url
            }
          }
          latestCoas {
            asset {
              url
            }
          }
          featuredImage {
            asset {
              path
              url
            }
          }
          carousel {
            title
            icon {
              asset {
                url
              }
            }
            assets {
              video {
                asset {
                  url
                }
              }
              image {
                asset {
                  url
                }
              }
              caption
              alt
            }
          }
          seo {
            seo_title
            meta_description
            focus_keyword
            focus_synonyms
          }
          deleted
        }
      }
      featuredImage {
        asset {
          path
          url
        }
      }
      supplementFactsFineprint
      latestLabReportsFineprint
      howToUse: _rawHowToUse(resolveReferences: {maxDepth: 10})
      ingredients: _rawIngredients(resolveReferences: {maxDepth: 10})
      _rawContent(resolveReferences: { maxDepth: 20 })
      relatedVariants{
        customName
        slug
      }
    }

    junipProduct(remote_handle: { eq: $slug }) {
      id
      rating_average
      rating_count
      remote_id
    }

    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SiteSettings
    }
  }
`

import React, {Fragment} from "react"
import {Col, Div, Row} from "../../lib/atomize"
import FeaturesBlock from "../../components/home/FeaturesBlock"
import PortableText from "../common/PortableText"
import Slider from "react-slick";

export default function BundleFeatures(
  {
    title,
    body,
    list = []
  }) {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <Fragment
    >
      {title && <h1>{title}</h1>}
      {body && <PortableText blocks={body}/>}
      <Div>
        <Row className="features">
          {
            list.map((item, index, id) =>
              <Col
                key={`featuresList-${index}`}>
                <Div>
                  <FeaturesBlock {...item} index={index}/>
                </Div>
              </Col>
            )
          }
        </Row>
      </Div>
      <div className="features-carousel">
        <Slider {...settings}>
          {
            list.map((item, index, id) =>
              <div key={`featuresList-${index}`}>
                <FeaturesBlock {...item} index={index}/>
              </div>
            )
          }
        </Slider>
      </div>
    </Fragment>
  )
}

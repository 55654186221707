import React from 'react';
import Slider from "react-slick";
import { PRSliderItem } from './PRSliderItem';
import { Div, Input, Text, Icon, Image, Button } from "../../../lib/atomize"
import { PRLogoList } from './PRLogoList';

export const PRSlider = ({
  posts = []
}) => {

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          dots: true,
          arrows: false
        }
      }
    ]
  };


  return (
    <Div
      m={{ b: "40px" }}
      className="bgblocks"
      data-bg="#EAF1F9"
    >
      <section className="carousel pr">
        <div className="inner">
          <div className="slider-wrapper">
            <Slider {...settings}>
              {posts.map((post, index) => (
                <PRSliderItem
                  post={post}
                  key={index}
                />
              ))}
            </Slider>
          </div>

          <PRLogoList
            logos={[
              {
                _key: '1',
                src: '/pr/allure-logo.png'
              },
              {
                _key: '2',
                src: '/pr/daily-beast-logo.png'
              },
              {
                _key: '3',
                src: '/pr/forbes-logo.png'
              },
              {
                _key: '4',
                src: '/pr/refinery29-logo.png'
              }
            ]}
          />
        </div>
      </section>
    </Div>
  );
}

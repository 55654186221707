import React, { Component } from "react"
import { Transition } from "react-transition-group"
import { Div } from "../../../lib/atomize"
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock"
import Wave from "react-wavify"

/**
 * Duration for the transition
 */
const duration = 500

/**
 * Default Style for Backlayer
 */
const defaultbackLayerStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
}

/**
 * Trasition Styles for Backlayer
 */
const backLayerTransitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 0.5 },
  exiting: { opacity: 0 },
  exited: { opacity: 0, display: "none" },
}

/**
 * Default Style for SideDrawerContent
 */
const defaultSideDrawerContentStyle = {
  transition: `all ${duration}ms ease-in-out`,
  opacity: 0,
}

/**
 * Trasition Styles for SideDrawerContent
 */
const transitionSideDrawerContentStyles = {
  entering: { opacity: 0, transform: "translateX(100%)" },
  entered: { opacity: 1, transform: "translateX(0)" },
  exiting: { opacity: 0, transform: "translateX(100%)" },
  exited: { opacity: 0, transform: "translateX(100%)" },
}

/**
 * Trasition Styles for SVG
 */
const transitionSVG = {
  entering: {
    display: "block",
  },
  entered: {
    display: "block",
  },
  exiting: {
    display: "block",
  },
  exited: {
    display: "none",
  },
}

class SideDrawer extends Component {
  constructor(props) {
    super(props)

    this.disableBodyScroll = this.disableBodyScroll.bind(this)
    this.enableBodyScroll = this.enableBodyScroll.bind(this)
  }

  /**
   * Disable the body scroll when Opened
   */
  disableBodyScroll() {
    disableBodyScroll()
  }

  /**
   * Enable the body scroll when Closed
   */
  enableBodyScroll() {
    enableBodyScroll()
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks()
  }

  render() {
    const { children, isOpen, onClose, isCart, ...rest } = this.props
    const randomNumber = Math.random()

    return (
      <Transition
        in={isOpen}
        timeout={duration}
        onExiting={this.enableBodyScroll}
        onEntering={this.disableBodyScroll}
      >
        {state => (
          <>
            <Div
              style={{
                ...defaultbackLayerStyle,
                ...backLayerTransitionStyles[state],
              }}
              tabIndex="-1"
              pos="fixed"
              top="0"
              left="0"
              right="0"
              bottom="0"
              bg="white"
              zIndex="1000"
              cursor="pointer"
              onClick={onClose}
            />

            <Div
              style={{
                ...defaultSideDrawerContentStyle,
                ...transitionSideDrawerContentStyles[state],
              }}
              pos="fixed"
              top="0"
              right="0"
              bottom="0"
              d="flex"
              overflow="visible"
              bg="linear-gradient(180deg, #FDEEFF 0%, #EAF1F9 49.48%, #FFFCF0 100%)"
              zIndex="1001"
            >
              {/* Wave */}
              <Div
                pos="absolute"
                bottom="0"
                left="0"
                w="100vh"
                transform="translateX(10px)rotate(-90deg)"
                transformOrigin="0% 100%"
                style={{
                  pointerEvents: "none",
                  ...transitionSVG[state],
                }}
              >
                <Wave
                  fill={`url(#waveGradientSidebar${randomNumber})`}
                  // filter="url(#waveFilterSideBar)"
                  paused={!isOpen}
                  options={{
                    height: 84,
                    points: 3,
                    amplitude: 40,
                    speed: 0.25,
                  }}
                >
                  <defs>
                    <filter
                      id="waveFilterSideBar"
                      x="-100%"
                      y="-100%"
                      width="200%"
                      height="200%"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feOffset
                        result="offOut"
                        in="SourceGraphic"
                        dx="0"
                        dy="-10"
                      />
                      <feColorMatrix
                        result="matrixOut"
                        in="offOut"
                        type="matrix"
                        values="0.2 0 0 0 1 0 0.2 0 0 1 0 0 0.2 0 1 0 0 0 1 0"
                      />
                      <feGaussianBlur
                        result="blurOut"
                        in="matrixOut"
                        stdDeviation="16"
                      />
                      <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
                    </filter>
                    <linearGradient
                      id={`waveGradientSidebar${randomNumber}`}
                      x1="836"
                      y1="44"
                      x2="24"
                      y2="44"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#FDEEFF" />
                      <stop offset="0.4948" stopColor="#EAF1F9" />
                      <stop offset="1" stopColor="#FFFCF0" />
                    </linearGradient>
                  </defs>
                </Wave>
              </Div>
              <Div
                onClick={e => {
                  e.stopPropagation()
                }}
                {...rest}
                zIndex="1"
              >
                <Div
                  p={`2.5rem 1.5rem ${isCart ? "0rem" : "2.5rem"} 0`}
                  d="flex"
                  flexDir="column"
                  h="100%"
                  pos="relative"
                >
                  {children}
                </Div>
              </Div>
            </Div>
          </>
        )}
      </Transition>
    )
  }
}

SideDrawer.defaultProps = {
  pos: "relative",
  w: { xs: "100vw", sm: "375px" },
  p: "0 0 0 2.5rem",
  onClose: () => { },
}

export default SideDrawer

import {freebieActions} from "../actions";

export const initialFreebieState = {};
export const freebieReducer = (state, action) => {
  switch (action.type) {
    case freebieActions.SET_FREEBIE: {
      return action.payload
    }
    default:
      return state;
  }
};

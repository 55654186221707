import React from 'react';
import {Div, Image, Text} from "../../../lib/atomize";
import BlueButton from "../molecules/BlueButton";
import {SideDrawerMenuSection} from "./SideDrawerMenuSection";
import Wave from "react-wavify"

export const SideDrawerMenu = (
  {
    isOpen = false,
    setIsOpen = () => {},
    primaryMenu = [],
    secondaryMenu = [],
    toggleNotSpam = () => {}
  }
) => {
  const randomNumber = Math.random()

  return (
    <div className={isOpen ? "side-drawer active" : "side-drawer"}>
      <div className="mask" onClick={() => setIsOpen(!isOpen)} />
      <div className="content">
        <header>
          <Div
            d="flex"
            justify="space-between"
            align="center"
            m={{b: "2rem"}}
            p={{b: "2.5rem"}}
            border={{b: "1px solid"}}
            borderColor="yellowPink"
          >
            <Text
              textTransform="uppercase"
              textWeight="bold"
              fontFamily="secondary"
              textSize="display1"
            >
              menu
            </Text>
            <Div
              style={{lineHeight: "0"}}
              bg="lightPink"
              shadow="2"
              hoverShadow="3"
              cursor="pointer"
              rounded="sm"
              onClick={() => setIsOpen(!isOpen)}
            >
              <Image src="/close.svg" h="1rem" w="1rem" p="10px" alt="Close" />
            </Div>
          </Div>
        </header>
        {primaryMenu && primaryMenu.length ? <SideDrawerMenuSection items={primaryMenu} /> : ''}
        {secondaryMenu && secondaryMenu.length ? <SideDrawerMenuSection items={secondaryMenu} type="secondary" /> : ''}
        <BlueButton
          type="submit"
          text="Sign up for Not Spam"
          textSize="header1"
          h="3.125rem"
          onClick={() => {
            setIsOpen(!isOpen)
            toggleNotSpam()
          }}
        />
      
        <Div
          pos="absolute"
          top="0"
          left={isOpen ? "370px" : "0"}
          w="100vh"
          transform="translate(0, -160px) rotate(90deg)"
          transformOrigin="0% 100%"
        >
        <Wave
          fill={`url(#waveGradientSidebar${randomNumber})`}
          // filter="url(#waveFilterSideBar)"
          paused={!isOpen}
          options={{
            height: 84,
            points: 3,
            amplitude: 40,
            speed: 0.25,
          }}
        >
          <defs>
            <filter
              id="waveFilterSideBar"
              x="-100%"
              y="-100%"
              width="200%"
              height="200%"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feOffset
                result="offOut"
                in="SourceGraphic"
                dx="0"
                dy="-10"
              />
              <feColorMatrix
                result="matrixOut"
                in="offOut"
                type="matrix"
                values="0.2 0 0 0 1 0 0.2 0 0 1 0 0 0.2 0 1 0 0 0 1 0"
              />
              <feGaussianBlur
                result="blurOut"
                in="matrixOut"
                stdDeviation="16"
              />
              <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
            </filter>
            <linearGradient
              id={`waveGradientSidebar${randomNumber}`}
              x1="836"
              y1="44"
              x2="24"
              y2="44"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FFFCF0" />
              <stop offset="0.4948" stopColor="#EAF1F9" />
              <stop offset="1" stopColor="#FDEEFF" />
            </linearGradient>
          </defs>
        </Wave>
        </Div>
      </div>
    </div>
  )

}

import React, { useState, useEffect } from "react"
import { Div, Text, Row, Col, Button, Icon } from "../../lib/atomize"
import axios from "axios"
import { get } from "lodash"
import moment from "moment"

const BillingInfo = ({
  setActivePage,
  data,
  setActiveBilling,
  errors,
  formSubmitting,
}) => {
  return (
    <>
      <Div
        p={{
          y: { xs: "1.875rem", lg: "2.5rem" },
          x: { xs: "1.25rem", lg: "2.5rem" },
        }}
        bg="yellow"
        rounded="lg"
        shadow="4"
      >
        <Text
          textWeight="bold"
          textSize="display1"
          fontFamily="secondary"
          p={{ b: { xs: "1.875rem", lg: "2.5rem" } }}
          style={{ lineHeight: "32px" }}
          textAlign={{ xs: "center", lg: "left" }}
        >
          Billing Info
        </Text>
        <Div>
          {/* {[1, 2].map(id => {
            return ( */}
          <Div
            border={{ t: "1px solid" }}
            borderColor="borderColor"
            p={{ y: "2.5rem" }}
          >
            <Text textWeight="bold" m={{ b: "10px" }}>
              Card on file
            </Text>
            <Text m={{ b: "10px" }}>
              {data.card_brand} ending in {data.card_last4} Expires{" "}
              {data.card_exp_month}/{data.card_exp_year}
            </Text>
            <Text
              textDecor="underline"
              cursor="pointer"
              onClick={() => {
                setActivePage("cartinfo")
                setActiveBilling(data)
              }}
            >
              Update payment info
            </Text>
          </Div>
          <Div
            border={{ t: "1px solid" }}
            borderColor="borderColor"
            p={{ y: "2.5rem" }}
          >
            <Text textWeight="bold" m={{ b: "10px" }}>
              Billing Information
            </Text>
            <Text>
              {data.billing_address.first_name} {data.billing_address.last_name}
            </Text>
            <Text>{data.billing_address.address1}</Text>
            <Text>
              {data.billing_address.city}, {data.billing_address.province},{" "}
              {data.billing_address.zip}
            </Text>
            <Text>{data.billing_address.country}</Text>
            <Text
              m={{ t: "10px" }}
              textDecor="underline"
              cursor="pointer"
              onClick={() => {
                setActivePage("edit")
                setActiveBilling(data.billing_address)
              }}
            >
              Edit address
            </Text>
          </Div>
        </Div>
      </Div>
    </>
  )
}

export default BillingInfo

import React, {useEffect} from "react"
import {Helmet} from "react-helmet"

const getDefaultSEOData = () => {
  switch (process.env.GATSBY_SITE_MODE) {
    case "notpotbody":
      return {
        title: `Not Pot - World's Chillest CBD Topicals & Creams`,
        description: `We've created a new approach to wellness—one that's safe, effective, and serious fun. Meet the everyday essentials designed to grow with you.`,
        author: `@notpot`,
        image: 'https://cdn.shopify.com/s/files/1/2229/0673/files/OG-img.jpg?v=1605249136',
        keywords: ['CBD Products', 'CBD topicals', 'CBD creams', 'CBD store']
      }
      break;
    case'notpotgoogle':
      return {
        title: `Not Pot - World's Chillest Hemp Gummies`,
        description: `We've created a new approach to wellness—one that's safe, effective, and serious fun. Meet the everyday essentials designed to grow with you.`,
        author: `@notpot`,
        image: 'https://cdn.shopify.com/s/files/1/2229/0673/files/OG-img.jpg?v=1605249136',
        keywords: ['Hemp Products', 'Hemp topicals', 'Hemp creams', 'Hemp store']
      }
    default:
      return {
        title: `Not Pot - World's Chillest CBD Gummies & CBD Oil`,
        description: `We've created a new approach to wellness—one that's safe, effective, and serious fun. Meet the everyday essentials designed to grow with you.`,
        author: `@notpot`,
        image: 'https://cdn.shopify.com/s/files/1/2229/0673/files/OG-img.jpg?v=1605249136',
        keywords: ['CBD Products', 'CBD topicals', 'CBD creams', 'CBD store']

      }
  }

}

const getDomain = (mode) => {
  let domain = ''
  switch (mode) {
    case'notpotgoogle':
      domain = 'notpot.co'
      break;
    case'notpotbody':
      domain = 'notpotbody.com'
      break;
    default:
      domain = 'notpot.com'
      break;
  }
  return domain
}


export const SEO = ({title, description, keywords, media, url, socialTitle, socialDesc, productInfo}) => {
  const siteMetadata = getDefaultSEOData();

  let productionURLS = ['master', 'notpotbody', 'notpotgoogle', 'production']

  const noindexMeta = [
    {name: 'robots', content: 'noindex'},
    {
      name: "google-site-verification", content: "7MfJFsxBVui5UlEBExUFeMW9-Q6g9fPgoaxwzvbqaV0"
    }
  ]
  const SEOMODE = productionURLS.includes(process.env.GATSBY_SITE_MODE) ? 'production' : 'dev'
  const metaDescription = description || siteMetadata.description;
  const metatitle = (title ? title === siteMetadata.title ? title : `${title} | ${siteMetadata.title}` : siteMetadata.title);
  const metaImage = media || siteMetadata.image;
  const metaSocialTitle = socialTitle || metatitle;
  const metaSocialDesc = socialDesc || metaDescription;
  const metaKeywords = keywords || [];
  const siteAuthor = siteMetadata.author;
  const seoUrl = url ? `https://${getDomain(process.env.GATSBY_SITE_MODE)}/${url}` : `https://${getDomain(process.env.GATSBY_SITE_MODE)}/`;
  useEffect(() => {
    if (!process.env.GATSBY_SITE_MODE) {
      const path = window.location.pathname;
      window.location.href = `http://notpot.com${path}`
    }
  }, [])
  const productMeta = [
    {property: 'product:item_group_id', content: productInfo?.productGroupId, value: productInfo?.productGroupId},
    {property: 'product:retailer_item_id', content: productInfo?.productId, value: productInfo?.productId},
    {property: 'product:brand', content: 'Not Pot'},
    {property: 'product:availability', content: productInfo?.quantity ? 'in stock' : 'out of stock', value: 'in stock'},
    {property: 'product:condition', content: 'new', value: 'new'},
    {property: 'product:price:amount', content: productInfo?.price, value: productInfo?.price},
    {property: 'product:price:currency', content: 'USD'},
    {property: 'og:price:currency', content: 'USD'},
    {property: 'og:price:amount', content: productInfo?.price, value: productInfo?.price},
    {property: 'price', value: `${productInfo?.price} USD`,},
    {property: 'content_id', content: productInfo?.productId, value: productInfo?.productId},
    {property: 'id', content: productInfo?.productId, value: productInfo?.productId},
    {property: 'availability', content: 'in stock', value: 'in stock'},
    {property: 'google_product_category', content: 'Product', value: 'Product'}
  ];
  const metaData = [
    {name: 'robots', content: 'indexed'},
    {
      name: "google-site-verification",
      content: "7MfJFsxBVui5UlEBExUFeMW9-Q6g9fPgoaxwzvbqaV0",
    },
    {
      name: "description",
      content: metaDescription,
    },
    {
      property: "og:title",
      content: metaSocialTitle,
    },
    {
      property: "og:image",
      content: metaImage,
    },
    {
      property: "og:description",
      content: metaSocialDesc,
    },
    {
      property: "fb:app_id",
      content: "1119211085164333",
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      property: "og:url",
      content: seoUrl,
    },
    {
      name: "twitter:card",
      content: "summary",
    },
    {
      name: "twitter:creator",
      content: siteAuthor,
    },
    {
      name: "twitter:title",
      content: metaSocialTitle,
    },
    {
      name: "twitter:description",
      content: metaSocialDesc,
    },
    {
      name: "twitter:image",
      content: metaImage,
    },
    {
      property: "twitter:url",
      content: seoUrl,
    },
  ].concat(metaKeywords && metaKeywords.length > 0 ? {
    name: "keywords",
    content: metaKeywords.join(", "),
  } : [])
    .concat(productMeta);
  return (
    <Helmet
      title={metatitle}
      meta={SEOMODE === 'production' ? metaData : noindexMeta}
    >
      <link
        rel="canonical"
        href={seoUrl}
      />
    </Helmet>
  )
}

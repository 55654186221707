import React, {Fragment} from "react"
import {Div, Text} from "../../lib/atomize"
import ProductHeroRating from "./ProductHeroRating"
import ProductAddToBag from "./ProductAddToBag"

export const StickyAddToBag = (
  {
    product,
    selectedVariant,
    junipProduct,
    ctaText = '',
    count,
    changeCounterValue = {},
    checkbox,
    delivery,
    volume = '',
    bundles = [],
    originalPrice = '',
    toggleStickyCart = false,
  }
) => {

  return (
    <>
      <div className={toggleStickyCart ? "sticky-addtobag active" : "sticky-addtobag"}>
        {bundles.length ?
          <Text
            textSize="14px"
            textWeight="bold"
            fontFamily="primary"
            className="save"
          >
            Save on Bundles
          </Text>
          :
          <div className="rating">
            {junipProduct?.rating_count >= 1 ?

              <Div>
                <ProductHeroRating junipProduct={junipProduct} />
              </Div>

            :

              <Text
                textSize="14px"
                textWeight="bold"
                fontFamily="primary"
                className="save"
              >
                New product!
              </Text>
            }
          </div>
        }

        <div className="price">
          <Div d="flex">
            {selectedVariant?.hasSubscription && checkbox === "subscribe" && (
              <Fragment>
                <Text
                  textSize="14px"
                  textWeight="bold"
                  fontFamily="primary"
                  m={{r: "5px"}}
                  textDecor="line-through"
                  opacity="0.3"
                >
                  ${Number.isInteger(parseFloat(selectedVariant?.price)) ? parseFloat(selectedVariant?.price) : selectedVariant?.price}
                </Text>
              </Fragment>
            )}

            {originalPrice && <Text
              textSize="14px"
              textWeight="bold"
              fontFamily="primary"
              m={{r: "5px"}}
              textDecor="line-through"
              opacity="0.3"
            >
              ${originalPrice}
            </Text>}

            <Text
              textSize="14px"
              textWeight="bold"
              fontFamily="primary"
            >
              $
              {checkbox === "one-time"
                ? Number.isInteger(parseFloat(selectedVariant?.price)) ? parseFloat(selectedVariant?.price) : selectedVariant?.price
                : Number.isInteger(parseFloat(selectedVariant?.discountedPrice)) ? parseFloat(selectedVariant?.discountedPrice) : selectedVariant?.discountedPrice}
            </Text>

            {!bundles.length && checkbox === "one-time" && volume && (
              <Text
                textSize="14px"
                fontFamily="primary"
                className="volume"
                m={{l: "5px"}}
              >
                ({volume})
              </Text>
            )}
          </Div>
        </div>

        <div className="cta">
          {selectedVariant?.inventory_quantity > 0 && (
            <>
              <Div m={{t: "1rem"}}>
                <ProductAddToBag
                  product={product}
                  count={count}
                  changeCounterValue={value => changeCounterValue(value)}
                  variant={selectedVariant}
                  productType={checkbox}
                  frequency={delivery}
                  ctaText={ctaText}
                  bundles={bundles}
                />
              </Div>
            </>
          )}
        </div>
      </div>
    </>
  )
}


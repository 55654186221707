import React, { useEffect, useState, useRef } from "react"
import Lottie from "react-lottie"
import { Div } from "../../../lib/atomize"

export default function FloatingSticker({ file, ...rest }) {
  const currentContainer = useRef(null)
  const [size, setSize] = useState(0)

  const setNewSize = () => {
    setSize(currentContainer.current.clientWidth)
  }

  useEffect(() => {
    setNewSize()
    window.addEventListener("resize", setNewSize)
    return () => {
      window.removeEventListener("resize", setNewSize)
    }
  })

  return (
    <Div pos="absolute" ref={currentContainer} {...rest}>
      <Lottie
        options={{
          animationData: file,
          loop: true,
          autoplay: true,
        }}
        // height={size.height}
        width={size}
        isStopped={false}
        isPaused={false}
      />
    </Div>
  )
}

import React from "react"
import { Div, Image, Row, Col, Text, Anchor } from "../../../lib/atomize"
import Wave from "react-wavify"
import { Link } from "gatsby"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"
import { useLocation } from "react-use"

import CTALink from "../CTALink"
import FooterForm from "./FooterForm"
import WebsiteContainer from "../atoms/WebsiteContainer"
// import ImageLazyLoad from "../../common/molecules/ImageLazyLoad"
import "../../../assets/global.css"
import { EasterEggItem } from "../../../components/easteregg/EasterEggItem"
import { Card } from "../Card"

export default function Footer({ footerMenuItems, socialLinks = [], notSpamForm, customClass = '', waveColor = '#fdeeff' }) {
  const footerLinks = footerMenuItems.reduce((accumulator, currentValue) => {
    return accumulator.concat(currentValue.items)
  }, [])

  const location = useLocation()

  return (
    <>
      {/* Desktop wave */}
      <Div d={{ xs: "none", md: "block" }} m={{ b: "-8px" }}>
        <Wave
          fill={waveColor}
          filter="url(#waveFilterFooter)"
          paused={false}
          options={{
            height: 84,
            points: 8,
            amplitude: 40,
            speed: 0.25,
          }}
          className="wave"
        >
          <defs>
            <filter
              id="waveFilterFooter"
              x="-100%"
              y="-100%"
              width="200%"
              height="200%"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feOffset result="offOut" in="SourceGraphic" dx="0" dy="-10" />
              <feColorMatrix
                result="matrixOut"
                in="offOut"
                type="matrix"
                values="0.2 0 0 0 1 0 0.2 0 0 1 0 0 0.2 0 1 0 0 0 1 0"
              />
              <feGaussianBlur
                result="blurOut"
                in="matrixOut"
                stdDeviation="16"
              />
              <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
            </filter>
          </defs>
        </Wave>
      </Div>

      {/* Mobile Wave */}
      <Div d={{ xs: "block", md: "none" }} m={{ b: "-8px" }}>
        <Wave
          fill={waveColor}
          filter="url(#waveFilterFooterMobile)"
          paused={false}
          options={{
            height: 84,
            points: 3,
            amplitude: 40,
            speed: 0.25,
          }}
        >
          <defs>
            <filter
              id="waveFilterFooterMobile"
              x="-100%"
              y="-100%"
              width="200%"
              height="200%"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feOffset result="offOut" in="SourceGraphic" dx="0" dy="-10" />
              <feColorMatrix
                result="matrixOut"
                in="offOut"
                type="matrix"
                values="0.2 0 0 0 1 0 0.2 0 0 1 0 0 0.2 0 1 0 0 0 1 0"
              />
              <feGaussianBlur
                result="blurOut"
                in="matrixOut"
                stdDeviation="16"
              />
              <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
            </filter>
          </defs>
        </Wave>
      </Div>

      <WebsiteContainer style={{position: "relative", zIndex: "1"}} className="card-extra">
        <Card 
          customClass="footer"
          imgSrc="/oracle/wp-3.png"/>
      </WebsiteContainer>

      {/* Footer */}
      <Div id="footer" name="footer" bg="pink" pos="relative" className={`footer bgblocks ${customClass}`} data-bg="#fdeeff">
        
        <WebsiteContainer>
        
          <Div
            p={{ y: { xs: "3rem", lg: "6.25rem" } }}
          // m={{ t: { xs: "6.25rem", lg: "9.375rem" } }}
          >
            
            <Row>
              {notSpamForm &&
                <Col size={{ xs: "12", lg: "6" }}>
                  <Div p={{ lg: "0 3.5rem 0 1.5rem" }}>
                    <FooterForm
                      notSpamForm={
                        {
                          ...notSpamForm,
                          noPhone: true
                        }
                      }
                    />
                  </Div>
                </Col>
              }
              {socialLinks.length ?
                <Col size={{ xs: "12", lg: "6" }}>
                  <Div p={{ lg: "0 2.25rem 0 5rem" }}>

                    {process.env.GATSBY_SITE_MODE && process.env.GATSBY_SITE_MODE === 'production' ?
                      <Div
                        m={{ t: "2rem", l: { xs: "auto" }, r: { xs: "auto" } }}
                        d={{ xs: "flex", lg: "none" }}
                        p="5px 20px"
                        w="135px"
                        border="1px solid"
                        borderColor="#F8D4E4"
                        style={{
                          borderRadius: "20px",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                        data-acsb-custom-trigger="true"
                        className="accessibility"
                      >
                        <Image
                          src="/accessibility.svg"
                          h="16px"
                          w="16px"
                          m={{ t: "5px", b: "5px", r: "5px" }}
                          d="inline-block"
                          alt="Accessibility"
                        />
                        <span
                          style={{
                            fontSize: "14px",
                            display: "inline-block",
                            fontFamily: "JeanLuc",
                          }}
                        >
                          Accessibility
                        </span>
                      </Div>
                      : ''
                    }
                    {/* Desktop Links */}

                    <Div d={{ xs: "none", lg: "block" }}>
                      <Row>
                        {footerMenuItems.map((footerMenuItem, id) => {
                          return (
                            <Col
                              size={{ xs: "6", lg: "4" }}
                              key={`footer-menu-${id}`}
                            >
                              <Div>
                                <Text
                                  d={{ xs: "none", lg: "block" }}
                                  fontFamily="secondary"
                                  m={{ b: "1rem" }}
                                  textWeight="500"
                                  textSize="header"
                                  style={{ lineHeight: "16px" }}
                                  tag="h4"
                                >
                                  {footerMenuItem.title}
                                </Text>
                                {footerMenuItem.items.map((item, id) => {
                                  return (
                                    <Div
                                      m={{ b: "1rem" }}
                                      key={`footer-menu-item-${id}`}
                                    >
                                      <CTALink {...item} />
                                    </Div>
                                  )
                                })}
                              </Div>
                            </Col>
                          )
                        })}
                      </Row>
                    </Div>

                    {/* Mobile Links */}

                    <Div d={{ xs: "block", lg: "none" }} m={{ t: "2.5rem" }}>
                      <Row>
                        {footerLinks.map((link, id) => {
                          return (
                            <Col size={{ xs: "6" }} key={`mobile-links-${id}`}>
                              <Div m={{ b: "1rem" }} textAlign="center">
                                <CTALink {...link} />
                              </Div>
                            </Col>
                          )
                        })}
                      </Row>
                    </Div>
                    <Div
                      m={{ t: "2rem" }}
                      d="flex"
                      justify={{ xs: "center", lg: "left" }}
                      className="sn"
                    >
                      {socialLinks.map((socialLink, index, id) => {
                        return (
                          <Anchor
                            href={socialLink.link}
                            key={`social-link-${index}`}
                            title={socialLink.title}
                            target="_blank"
                          >
                            <Div
                              bg="lightPink"
                              shadow="2"
                              hoverShadow="3"
                              transition
                              rounded="sm"
                              m={{
                                r:
                                  index === socialLinks.length - 1
                                    ? "0"
                                    : "1.25rem",
                              }}
                              h="2.5rem"
                              w="2.5rem"
                              d="flex"
                              align="center"
                              justify="center"
                            >
                              {/* <Image
                                src={socialLink.icon.asset.url}
                                h="1rem"
                                w="1rem"
                              /> */}
                              <Div h="1rem" w="1rem">
                                {/* <ImageLazyLoad
                                  bgImg={socialLink.icon.asset.url}
                                  bgSize="contain"
                                  bgPos="center"
                                  bgRepeat="no-repeat"
                                  p={{ b: "100%" }}
                                  transition
                                /> */}
                                <LazyLoadImage
                                  src={socialLink.icon.asset.url}
                                  width="100%"
                                  effect="blur"
                                  alt={socialLink.title}
                                />
                              </Div>
                            </Div>
                          </Anchor>
                        )
                      })}
                    </Div>
                    {process.env.GATSBY_SITE_MODE && process.env.GATSBY_SITE_MODE === 'production' ?
                      <Div
                        m={{ t: { lg: "2rem" } }}
                        d={{ xs: "none", lg: "flex" }}
                        p="5px 20px"
                        w="135px"
                        border="1px solid"
                        borderColor="#F8D4E4"
                        style={{
                          borderRadius: "20px",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                        data-acsb-custom-trigger="true"
                        className="accessibility"
                      >
                        <Image
                          src="/accessibility.svg"
                          h="16px"
                          w="16px"
                          m={{ t: "5px", b: "5px", r: "5px" }}
                          d="inline-block"
                          alt="Accessibility"
                        />
                        <span
                          style={{
                            fontSize: "14px",
                            display: "inline-block",
                            fontFamily: "JeanLuc",
                          }}
                        >
                          Accessibility
                        </span>
                      </Div>
                      : ''
                    }
                    <Div
                      m={{ t: "3rem" }}
                      d="flex"
                      textSize="tiny"
                      justify={{ xs: "center", lg: "left" }}
                      flexWrap={{ xs: "wrap", lg: "nowrap" }}
                    >
                      {/* <Image
                        src="/np-icon.png"
                        h="2.5rem"
                        w="2.5rem"
                        m={{ r: { xs: "0", lg: "1.25rem" } }}
                      /> */}
                      <Div
                        h="2.5rem"
                        w="2.5rem"
                        minW="2.5rem"
                        m={{ r: { xs: "0", lg: "1.25rem" } }}
                        rounded="circle"
                        //style={{ overflow: "hidden" }}
                        pos="relative"
                      >
                        {/* <ImageLazyLoad
                          bgImg="/np-icon.png"
                          bgSize="cover"
                          bgPos="center"
                          p={{ b: "100%" }}
                          transition
                        /> */}
                        <LazyLoadImage
                          src="/np-icon.png"
                          width="100%"
                          effect="blur"
                          alt="Not Pot"
                        />
                      </Div>

                      <Div d="flex" flexDir="column">
                        <Div
                          d="flex"
                          align="center"
                          m={{ t: { xs: "0.625rem", lg: "0" } }}
                          order={{ xs: "2", lg: "1" }}
                          justify={{ xs: "center", lg: "left" }}
                        >
                          <Link to="/legal/privacy-policy">
                            <Text
                              textSize="tiny"
                              textColor="blue"
                              textWeight="bold"
                              textDecor="underline"
                              hoverTextColor="navy"
                            >
                              Privacy Policy
                            </Text>
                          </Link>
                          <Div
                            h="4px"
                            w="4px"
                            bg="blue"
                            rounded="circle"
                            m={{ x: "0.625rem" }}
                          ></Div>
                          <Anchor
                            textSize="tiny"
                            textColor="blue"
                            textWeight="bold"
                            textDecor="underline"
                            hoverTextColor="navy"
                            href="/legal/terms"
                          >
                            Terms &amp; Conditions
                          </Anchor>
                        </Div>
                        <Div
                          d="flex"
                          m={{ t: "0.625rem" }}
                          order={{ xs: "2", lg: "2" }}
                          textAlign={{ xs: "center", lg: "left" }}
                        >
                          These statements have not been evaluated by the Food and
                          Drug Administration. This product is not intended to
                          diagnose, treat, cure, or prevent any disease.
                        </Div>
                        <Div
                          d="block"
                          m={{ t: "0.625rem" }}
                          order={{ xs: "3", lg: "3" }}
                          textAlign={{ xs: "center", lg: "left" }}
                        >
                          Not Pot products are not to be used or sold to persons under the age of 18.
                        </Div>
                        <Div
                          d="flex"
                          m={{ t: "0.625rem" }}
                          order={{ xs: "1", lg: "3" }}
                          justify={{ xs: "center", lg: "left" }}
                        >
                          &copy; 2020 Blemish Inc. 340 S Lemon Ave #7499, Walnut, CA 91789
                        </Div>
                        <Div
                          d="flex"
                          m={{ t: "0.625rem" }}
                          order={{ xs: "4", lg: "4" }}
                          justify={{ xs: "center", lg: "left" }}
                        >
                          <img src="/visa.png" style={{ marginRight: 10, height: "15px" }} alt="Visa" />
                          <img src="/mastercard.png" style={{ marginRight: 10, height: "15px" }} alt="Mastercard" />
                          <img src="/Vector.svg" alt="Sezzle" />
                        </Div>
                      </Div>
                    </Div>
                  </Div>
                </Col> :

                <Col size={{ xs: "12" }}>
                  <Div
                    m={{ t: "3rem" }}
                    d="flex"
                    textSize="tiny"
                    justify={{ xs: "center", lg: "left" }}
                    flexWrap={{ xs: "wrap", lg: "nowrap" }}
                  >
                    {/* <Image
                        src="/np-icon.png"
                        h="2.5rem"
                        w="2.5rem"
                        m={{ r: { xs: "0", lg: "1.25rem" } }}
                      /> */}
                    <Div
                      h="2.5rem"
                      w="2.5rem"
                      minW="2.5rem"
                      m={{ r: { xs: "0", lg: "1.25rem" } }}
                      rounded="circle"
                      //style={{ overflow: "hidden" }}
                      pos="relative"
                    >
                      {/* <ImageLazyLoad
                          bgImg="/np-icon.png"
                          bgSize="cover"
                          bgPos="center"
                          p={{ b: "100%" }}
                          transition
                        /> */}
                      <LazyLoadImage
                        src="/np-icon.png"
                        width="100%"
                        effect="blur"
                        alt="Not Pot"
                      />
                    </Div>

                    <Div d="flex" flexDir="column">
                      <Div
                        d="flex"
                        align="center"
                        m={{ t: { xs: "0.625rem", lg: "0" } }}
                        order={{ xs: "2", lg: "1" }}
                        justify={{ xs: "center", lg: "left" }}
                      >
                        <Link to="/legal/privacy-policy">
                          <Text
                            textSize="tiny"
                            textColor="blue"
                            textWeight="bold"
                            textDecor="underline"
                            hoverTextColor="navy"
                          >
                            Privacy Policy
                          </Text>
                        </Link>
                        <Div
                          h="4px"
                          w="4px"
                          bg="blue"
                          rounded="circle"
                          m={{ x: "0.625rem" }}
                        ></Div>
                        <Anchor
                          textSize="tiny"
                          textColor="blue"
                          textWeight="bold"
                          textDecor="underline"
                          hoverTextColor="navy"
                          href="/legal/terms"
                        >
                          Terms &amp; Conditions
                        </Anchor>
                      </Div>
                      <Div
                        d="flex"
                        m={{ t: "0.625rem" }}
                        order={{ xs: "2", lg: "2" }}
                        textAlign={{ xs: "center", lg: "left" }}
                      >
                        These statements have not been evaluated by the Food and
                        Drug Administration. This product is not intended to
                        diagnose, treat, cure, or prevent any disease.
                      </Div>
                      <Div
                        d="block"
                        m={{ t: "0.625rem" }}
                        order={{ xs: "3", lg: "3" }}
                        textAlign={{ xs: "center", lg: "left" }}
                      >
                        Not Pot products are not to be used or sold to persons under the age of 18.
                      </Div>
                      <Div
                        d="flex"
                        m={{ t: "0.625rem" }}
                        order={{ xs: "1", lg: "3" }}
                        justify={{ xs: "center", lg: "left" }}
                      >
                        &copy; 2020 Blemish Inc. 340 S Lemon Ave #7499, Walnut, CA 91789
                      </Div>
                    </Div>
                  </Div>
                </Col>
              }
            </Row>
          </Div>
        </WebsiteContainer>
      </Div>
    </>
  )
}

import React, { useState } from "react"
import axios from "axios"
import { Div, Text, Image } from "../../lib/atomize"
import moment from "moment"
import ls from "local-storage"
import RatingStar from "./RatingStar"
import ShowMoreText from "react-show-more-text"
import { decodeHTML } from "../../utilities/helpers"
import { voteReview } from "../../services/reviewService"
// import PdpModal from "../common/molecules/PdpModal"

const ReviewListItem = ({ review: item }) => {
  const [review, setReview] = useState(item)
  // const [modal, showModal] = useState(false)
  const [image, variantImage] = useState(null)

  const executeOnClick = isExpanded => {
    // console.log(isExpanded)
  }

  // const openModal = data => {
  //   showModal(true)
  //    variantImage(data)
  // }

  return (
    <>
      {/* <PdpModal
        isOpen={modal}
        onClose={() => showModal(false)}
        variantImage={image}
      /> */}
      <Div p="1.25rem" bg="yellowGreen" shadow="2" rounded="lg" h="100%">
        <Div d="flex" justify="space-between" align="center">
          <Text textSize="header" textWeight="bold" tag="h4">
            {review.customer.first_name} {review.customer.last_name}
          </Text>
          <RatingStar rating={review.rating} height="12px" width="12px" />
        </Div>
        <Div d="flex" justify="space-between" m={{ y: "10px" }}>
          <div className="date">
            <Image
              src="/check2x.png"
              h="32px"
              w="32px"
              alt="Icon"
            />
            <p>{moment(review.created_at).format("MMMM Do YYYY")}</p>
          </div>
          <Div d="flex">
            <Div
              d="flex"
              align="center"
              m={{ r: "1rem" }}
              onClick={() => {
                const upvotes = ls("upReviews") ? ls("upReviews") : []
                const downvotes = ls("downReviews") ? ls("downReviews") : []
                const isUpvoted = upvotes.includes(review.id)
                const isDownvoted = downvotes.includes(review.id)

                if (isUpvoted) return

                voteReview({
                  review,
                  type: "up",
                  alreadyVoted: isUpvoted,
                  setReview,
                })
              }}
            >
              <Div
                border="1px solid"
                borderColor="blue"
                // bg={isUpvoted ? "blue" : "yellow"}
                rounded="circle"
                h="1rem"
                w="1rem"
                d="flex"
                align="center"
                justify="center"
                m={{ r: "5px" }}
              >
                <Image src="/up-arrow.svg" h="8px" w="6px" cursor="pointer" alt="Icon" />
              </Div>

              <Text>{review.up_vote_count}</Text>
            </Div>
            <Div
              d="flex"
              align="center"
              onClick={() => {
                const upvotes = ls("upReviews") ? ls("upReviews") : []
                const downvotes = ls("downReviews") ? ls("downReviews") : []
                const isUpvoted = upvotes.includes(review.id)
                const isDownvoted = downvotes.includes(review.id)

                if (isDownvoted) return

                voteReview({
                  review,
                  type: "down",
                  alreadyVoted: isDownvoted,
                  setReview,
                })
              }}
            >
              <Div
                border="1px solid"
                borderColor="blue"
                // bg={isDownvoted ? "blue" : "yellow"}
                rounded="circle"
                h="1rem"
                w="1rem"
                d="flex"
                align="center"
                justify="center"
                m={{ r: "5px" }}
              >
                <Image src="/down-arrow.svg" h="8px" w="6px" cursor="pointer" alt="Icon" />
              </Div>

              <Text>{review.down_vote_count}</Text>
            </Div>
          </Div>
        </Div>

        <Div overflow="auto" textAlign="left">
          <Text
            m={{ b: "10px" }}
            textWeight="bold"
            dangerouslySetInnerHTML={{ __html: decodeHTML(review.title) }}
            tag="h3"
          >
            {/* {review.title} */}
          </Text>
          <Div className="show-more-text">
            <ShowMoreText
              lines={8}
              more="Show more"
              less="Show less"
              anchorClass=""
              onClick={executeOnClick}
              expanded={false}
            //width={280}
            >
              <Text
                dangerouslySetInnerHTML={{ __html: decodeHTML(review.body) }}
              ></Text>
            </ShowMoreText>
          </Div>
        </Div>
        {/* <Div m={{ t: "10px" }} textAlign="left">
          Reviews Images
          {review.photo_urls.map(image => {
            return (
              <Image
                src={image.thumbnail}
                h="3.75rem"
                w="3.75rem"
                rounded="lg"
                m={{ r: "10px" }}
                onClick={() => openModal(image.large)}
                cursor="pointer"
              />
            )
          })}
        </Div> */}
      </Div>
    </>
  )
}

export default ReviewListItem

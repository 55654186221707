import React from "react"
import { Div, Text } from "../../lib/atomize"
import moment from "moment"
import CardHeader from "../common/CardHeader"
import ImageLazyLoad from "../common/molecules/ImageLazyLoad"

const PolaroidCard = ({
  affiliate: { name, quote, quoteSource, zodiac, info, head, _createdAt },
}) => (
  <Div
    rounded="lg"
    border="1px solid"
    borderColor="lightPink"
    shadow="1"
    m={{ b: "40px" }}
    className="bgblocks"
    data-bg="#FDEEFF"
  >
    <CardHeader
      image="/dealer-profile.svg"
      heading="Profile"
      padding="0.625rem 0.625rem"
    />
    <Div bg="yellow" rounded={{ b: "lg" }} d="flex" flexDir="column" p="30px">
      <Text
        textSize="display1"
        textTransform="uppercase"
        fontFamily="secondary"
        m={{ b: "30px" }}
      >
        Hi, I'M {name || ""}
      </Text>

      <Div d="flex" flexDir={{ lg: "row", xs: "column" }} className="dealer-profile">
        <Div
          transform="rotate(-2deg)"
          d="flex"
          flexDir="column"
          m={{ r: { lg: "30px" }, b: { lg: "0px", xs: "30px" } }}
          className="polaroid-container"
        >
          <Div
            border="1px solid"
            borderColor="#F8D4E4"
            bg="#FFFFFF"
            shadow="7"
            p={{ x: "20px", t: "20px", b: "15px" }}
            className="profile-wrapper"
          >
            <ImageLazyLoad
              bgImg={head?.image?.asset?.url}
              bgPos="center"
              bgSize="cover"
              p={{ b: "100%" }}
              transition
            />
            <Text
              textSize="title1"
              textTransform="uppercase"
              fontFamily="tertiary"
              m={{ t: "14px" }}
              style={{ alignSelf: "center" }}
              textAlign="center"
            >
              {head?.alt || name}
            </Text>
          </Div>
        </Div>
        <Div d="flex" flexDir="column" fontFamily="primary" textSize="tiny">
          <Text textSize="header1" textWeight="bold" m={{ b: "10px" }}>
            &ldquo;{quote}&rdquo;
          </Text>
          {quoteSource && (
            <Text textSize="header" textWeight="bold" m={{ b: "20px" }}>
              &#8211;&nbsp;{quoteSource}
            </Text>
          )}
          {zodiac && (
            <Div d="flex" align="center">
              <Div h="20px" minW="20px" m={{ r: "6px" }}>
                <img
                  src={`/${zodiac.toLowerCase()}-apple.png`}
                  alt="smiley"
                  width="20px"
                />
              </Div>
              <Text>{zodiac}</Text>
            </Div>
          )}
          {info?.city && info?.state && (
            <Text>
              {info.city},&nbsp;{info.state}
            </Text>
          )}
          <Text m={{ t: "20px" }}>
            Dealer Since: {moment(_createdAt).format("MM/DD/YY")}
          </Text>
        </Div>
      </Div>
    </Div>
  </Div>
)

export default PolaroidCard

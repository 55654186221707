import React from "react"
import { Div, Text } from "../../lib/atomize"

import CardHeader from "../common/CardHeader"

const DealerDetails = ({ preferences, createdAt }) => {
  const details = [
    { key: "Books", value: preferences.books },
    { key: "Movies", value: preferences.movies },
    { key: "Munchies", value: preferences.munchies },
    { key: "Hobbies", value: preferences.interest },
    { key: "Music", value: preferences.music },
  ]

  const shouldShowDetails = () => {
    const keys = Object.keys(preferences)
    return keys.some(key => preferences[key] !== null)
  }

  if (!shouldShowDetails()) {
    return null
  }

  return (
    <Div
      rounded="lg"
      border="1px solid"
      borderColor="lightPink"
      shadow="1"
      m={{ b: "40px" }}
      className="bgblocks"
      data-bg="#FFE3E1"
    >
      <CardHeader
        image="/dealer-details.svg"
        heading="My Faves"
        padding="0.625rem 0.625rem"
      />
      <Div
        bg="yellow"
        rounded={{ b: "lg" }}
        d="flex"
        flexDir="column"
        p="30px"
        color="blue"
        justify="flex-start"
      >
        {details.map(
          (detail, index) =>
            detail.value && (
              <Div d="flex" align="flex-start" m={{ b: "8px" }} key={index}>
                <Text
                  rounded="sm"
                  p={{ x: "10px", y: "5px" }}
                  m={{ r: "10px" }}
                  bg="pink"
                  minW="98px"
                  textWeight="bold"
                  className="dealer-detail-key"
                >
                  {detail.key}
                </Text>
                <Text
                  rounded="sm"
                  p={{ x: "10px", y: "5px" }}
                  bg="bg2"
                  flexGrow="1"
                >
                  {detail.value}
                </Text>
              </Div>
            )
        )}
      </Div>
    </Div>
  )
}

export default DealerDetails

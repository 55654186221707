import React from 'react';
import {Div} from "../../../lib/atomize"

export const SocialPostSliderItem = ({post}) => {
  const {
    image = {
      asset: {
        url: ''
      }
    },
    avatar = {
      asset: {
        url: ''
      }
    },
    name = '',
    handle = '',
    _key = ''
  } = post;

  return (
    <div className="item" key={_key}>
      <div className="content">
        <div className="image">
          {image &&
          <img
            src={image?.asset?.url}
            // height="300px"
            width="250px"
            effect="blur"
          />
          }
        </div>
        <div className="details">
          <Div d="flex" align="center">
            <Div m={{r: "1rem"}}>
              {avatar && <img
                src={avatar?.asset?.url}
                width="40px"
                height="40px"
                effect="blur"
                style={{
                  borderRadius: "50%",
                }}
              />
              }
            </Div>
            <Div>
              <h4>{name}</h4>
              <p>{handle}</p>
            </Div>
          </Div>
        </div>
      </div>
    </div>
  );
}

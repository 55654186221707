import React from "react";
import {graphql} from "gatsby";

const removeCheckoutData = ({ data }) => {
  const site = (data || {}).site

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <></>
    )
}
export default removeCheckoutData

export const query = graphql`
  query removeCheckoutDataQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SiteSettings
    }
  }
`

import React from "react"
import {Col, Div, Icon, Input, Row, Text, Image} from "../../lib/atomize";

import SideDrawer from "../../components/common/atoms/SideDrawer";
import ApplyWholesaleForm from "./ApplyWholesaleForm";

const FormModal = ({ isOpen, onClose }) => {
  return (
    <div className="wholesale-form-modal">
      <SideDrawer 
        isOpen={isOpen} 
        onClose={onClose} 
        w={{ xs: "100vw", sm: "450px" }}
      >
        <header>
          <Div
            d="flex"
            justify="space-between"
            align="center"
            p={{ b: "2.5rem" }}
          >
            <Text
              textTransform="uppercase"
              textWeight="bold"
              fontFamily="secondary"
              textSize="header1"
            >
              wholesale application
            </Text>
            <Div
              style={{ lineHeight: "0" }}
              bg="lightPink"
              shadow="2"
              hoverShadow="3"
              cursor="pointer"
              rounded="sm"
              onClick={onClose}
            >
              <Image src="/close.svg" h="1rem" w="1rem" p="10px" alt="Close" />
            </Div>
          </Div>
        </header>

        <ApplyWholesaleForm />
      </SideDrawer>
    </div>
  )
}

export default FormModal;

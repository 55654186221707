import React, { useContext, useEffect, useRef, useState, Fragment } from "react"
import { get, isEmpty } from "lodash"
import queryString from "query-string"
import { Div, StyleReset, ThemeProvider, Image } from "lib/atomize"
import { useLocation } from "react-use"
import theme from "../Theme"
import Header from "./organisms/Header"
import Footer from "./organisms/Footer"
import { NotSpamModal } from "./organisms/NotSpamModal"
import { AppContext } from "context";
import { utmActions } from "actions"
import "../../assets/scss/app.scss"
import { discountHelper } from '../../helpers/discountHelper'
import { EasterEgg } from "../../components/easteregg/EasterEgg"
import { EasterEggItem } from "../easteregg/EasterEggItem"

export default function Layout
  (
    {
      children,
      site,
      path = '',
      pageClass = '',
      redirectToMain = false,
      changeColor = true
    }
  ) {
  const location = useLocation()
  const navMenuItems = get(site, "navMenu.items", [])
  const footerMenuItems = get(site, "footerNavMenu", [])
  const socialLinks = get(site, "socialLinks", [])
  const notSpamForm = get(site, "notSpamForm", [])
  const callout = get(site, "callout", '')
  const [enableToggle, setEnableToggle] = useState(false)
  const [state, dispatch] = useContext(AppContext);
  const { utmParams } = state
  /* Notes: [
   * add url list here to disallow notspam modal
   * ]
   */
  const notAllowed = ['/birthday-sale']

  const isNotpot = process.env.GATSBY_SITE_MODE === 'production';
  const isNotpotBody = process.env.GATSBY_SITE_MODE === 'notpotbody';
  const isNotpotGoogle = process.env.GATSBY_SITE_MODE === 'notpotgoogle';

  const scrollBgChange = () => {
    const body = document.body
    const header = document.getElementById('header')
    const bgBlocks = document.querySelectorAll(".bgblocks")
    // var scroll = window.scrollY + window.innerHeight / 3
    const offset = window.innerHeight / 3

    bgBlocks.forEach(section => {
      const dimentions = section.getBoundingClientRect()

      if (
        dimentions.top <= offset &&
        dimentions.top + dimentions.height > offset
      ) {
        body.style.backgroundColor = section.dataset.bg
        header.style.backgroundColor = section.dataset.bg

        return null
      }
    })
  }

  useEffect(() => {
    if (changeColor) {
      window.addEventListener("scroll", scrollBgChange)
      document.body.classList.remove('halloween')
    } else {
      document.body.classList.add('halloween')
      window.removeEventListener("scroll", scrollBgChange)
    }

    return () => {
      document.body.classList.remove('halloween')
      window.removeEventListener("scroll", scrollBgChange)
    }
  }, [children])

  useEffect(() => {
    const { search = '' } = location
    const utm_params = queryString.parse(search)
    if (!isEmpty(utm_params)) {
      dispatch({
        type: utmActions.SET_UTM_PARAMS,
        payload: search,
      })
    }
  }, []);

  useEffect(() => {
    if (utmParams) {
      let params = new URLSearchParams(utmParams);
      if (params && params.has('np_code')) {
        discountHelper.saveDiscount(params.get('np_code'));
      } else if (params && !params.has('np_code')) {
        discountHelper.clearDiscount();
      }
    }
  }, [utmParams])

  const disallowNotSpam = () => !notAllowed.includes(location.pathname);

  const paths = location.pathname === '/products/banana-og-hemp-body-creme' || location.pathname === '/products/banana-og-hemp-body-creme/fresh-banana' || location.pathname === '/products/banana-og-hemp-body-creme/fragrance-free';

  const notSpamRef = useRef();

  const toggleNotSpam = () => {
    setEnableToggle(true)
    notSpamRef.current.toggle()
  }

  return (
    <ThemeProvider theme={theme}>
      <StyleReset />
      <Div
        className={pageClass}
        // bg="bg2"
        // style={{ backgroundAttachment: "fixed" }}
        textColor="blue"
        pos="relative"
      >
        <Header
          {...site}
          navMenuItems={navMenuItems}
          redirectToMain={redirectToMain}
          callout={callout}
          toggleNotSpam={toggleNotSpam}
          waveColor={changeColor ? '#fdeeff' : '#593376'}
        />
        <Div
          id="main">
          {children}
        </Div>

        {/* <Fragment>
          <EasterEggItem
            posClass = 'lt'
            image = '/home-easter-egg1.png'
            link = '/products/je68c'
          />
          <EasterEggItem
            posClass = 'rt'
            image = '/home-easter-egg2.png'
            link = '/products/ao0zs'
          />
        </Fragment> */}

        {(!isNotpot || location.pathname === "/" || enableToggle) &&
          <NotSpamModal
            ref={notSpamRef}
            setEnableToggle={setEnableToggle}
            notSpamForm={
              {
                ...notSpamForm,
                noPhone: true
              }
            }
          />
        }

        {path === '/bedtime-story' || path === '/birthday-sale' ?
          <Footer footerMenuItems={footerMenuItems} customClass='lp-single-footer' /> :
          <Footer footerMenuItems={footerMenuItems} socialLinks={socialLinks} notSpamForm={notSpamForm} waveColor={changeColor ? '#fdeeff' : '#EBC0C1'} />
        }
      </Div>
    </ThemeProvider>
  )
}

Layout.defaultProps = {
  navMenuItems: [],
  footerMenuItems: [],
  socialLinks: [],
}

import React from 'react';

export const CallOutSliderItem = ({alert}) => {
  const {
    _key = '',
    icon = '',
    text = '',
    link = ''
  } = alert
  
  return (
    <div className="item" key={_key}>
      <div className="content" 
        onClick={()=>{
          if(link){
            window.location.href = link;
          }
        }}
        style={{cursor: link ? "pointer" : "default"}}
      >
        <div className="image">
          <img
            src={typeof icon === 'object' ? icon.asset.url : icon}
            width="15px"
            height="15px"
            effect="blur"
          />
        </div>
        <p>{text}</p>
      </div>
    </div>
  );
}

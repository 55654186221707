import React, {Fragment, useEffect, useState} from "react"
import _, {isEmpty, takeWhile} from "lodash"
import {Collapse, Div, Icon, Image, Input, Label, Text} from "../../lib/atomize"
import {useLocation} from "@reach/router"
import * as yup from "yup"
import {Formik} from "formik"
import {Link} from "gatsby"

import ProductVariants from "./ProductVariants"
import ProductAddToBag from "./ProductAddToBag"
import ProductHeroRating from "./ProductHeroRating"
import PortableText from "../../components/common/PortableText"
import BlueButton from "../common/molecules/BlueButton"
import BundleFeatures from '../bundles/BundleFeatures';
// import Dropdown from "../common/BlueButton"
import {RateTag} from "../common/molecules/RateTag"

import {Bundles} from "../../components/bundles"
import {StickyAddToBag} from "./StickyAddToBag"
import axios from "axios";

const deliveryOptions = [15, 30, 45, 60]

const ProductDescription = (
  {
    product,
    selectedVariant,
    setSelectedVariant,
    setSelectedSlide,
    junipProduct,
    slug,
    subscriptionType = "one-time",
    productExtra = "",
    ctaText = '',
    bundles = [],
    originalPrice = '',
    enableAddToBundle = false,
    addBundleItem = () => {
    },
    onClose = () => {
    },
    customVariants = []
  }
) => {

  const location = useLocation()
  const {variants = [], volume = ''} = product
  const [count, setCount] = useState(1)
  const [dropdown, showDropdown] = useState("")
  const [delivery, setSelectedDelivery] = useState(deliveryOptions[1])
  const [checkbox, setCheckbox] = useState(subscriptionType)
  const shouldRenderProductVariants = !bundles.length
  let formikProps
  const [toggleStickyCart, setToggleStickyCart] = useState(false);
  const {listId = '', hasSubscription = false, original_price = '', features = {}} = selectedVariant

  const initialValues = {
    email: "",
  }

  const addprice = value => {
    const discountPrice = setSelectedVariant.price - value
  }

  const onSubmit = values => {
    const {setSubmitting, setStatus, resetForm} = formikProps

    const klaviyoPromise = listId ?
      axios.post(
        `/api/klaviyo`,
        {
          email: values.email,
          listId
        }
      ) :
      axios.post(`/api/instock`, {
        email: values.email,
        variant: selectedVariant._id,
        product: product._id,
      })

    Promise.all([klaviyoPromise])
      .then(responses => {
        setSubmitting(false)
        resetForm(initialValues)
        setStatus({
          successMessage:
            "Thanks! We’ll let you know when this is back in stock.",
          errorMessage: "",
        })
      })
      .catch(error => {
        setSubmitting(false)
        setStatus({
          errorMessage: "Something went wrong. Please try again later.",
          successMessage: "",
        })
      })
  }

  const validator = yup.object().shape({
    email: yup
      .string()
      .required("Please use a valid email address.")
      .min(1)
      .email("Please use a valid email address."),
  })

  const changeCounterValue = value => {
    if (value === "plus") {
      setCount(prevCount => prevCount + 1)
    } else {
      if (count === 1) {
        return null
      }
      setCount(prevCount => prevCount - 1)
    }
  }

  useEffect(() => {
    showDropdown(false)
  }, [checkbox])

  const normalItems = variants.filter(variant => !variant.deleted)

  const {
    klaviyoListId = '',
    inventory_quantity = 0
  } = selectedVariant

  const getPrice = (price) => {
    if (Number.isInteger(parseFloat(price))) {
      return parseFloat(price)
    }
    return parseFloat(price).toFixed(2)
  }

  return (
    <>
      <Div maxW={{xs: "100%", lg: "21rem"}} className={customVariants && customVariants.length ? "pdp related-bundles" : "pdp"}>
        <Div border={{b: "1px solid"}} borderColor="borderColor">
          <Text
            textWeight="bold"
            textTransform="uppercase"
            textSize="display1"
            style={{lineHeight: "32px"}}
            fontFamily="secondary"
            tag="h2"
            m={{b: "1rem"}}
          >
            {product?.pdpTitle || product?.desktopTitle || product?.title}
          </Text>
          {productExtra}
          {junipProduct?.rating_count >= 1 && (
            <Div m={{t: "1rem"}}>
              <ProductHeroRating junipProduct={junipProduct}/>
            </Div>
          )}

          <RateTag/>
          {product?.subheader &&
          <p className="subheader">
            <strong>{product.subheader}</strong>
          </p>
          }
          {customVariants && customVariants.length ? <Div m={{t: "1.875rem"}} d="flex" className="btn-group">
            <ProductVariants
              slug={slug}
              product={product}
              variants={customVariants}
              selectedVariant={selectedVariant}
              setSelectedSlide={setSelectedSlide}
              setSelectedVariant={setSelectedVariant}
              type="bundles"
              customSlug={slug}
            />
          </Div> : ''

          }
          {shouldRenderProductVariants && normalItems.length !== 1 && product?.variants?.length > 1 && (
            <Div m={{t: "1.875rem"}} d="flex" className="hello">
              <ProductVariants
                slug={slug}
                product={product}
                variants={product?.variants}
                selectedVariant={selectedVariant}
                setSelectedSlide={setSelectedSlide}
                setSelectedVariant={setSelectedVariant}
              />
            </Div>
          )}
          {selectedVariant?.productDescription && (
            <Div m={{t: "1.875rem", b: "2.5rem"}} className="prod-desc">
              <PortableText blocks={selectedVariant?.productDescription}/>
            </Div>
          )}

          {bundles.length ? <Bundles bundles={bundles}/> : ''}

        </Div>

        {/* //Price */}
        {!enableAddToBundle &&
        <Div m={{t: "2.5rem"}}>

          <Div d="flex" flexDir="column">
            <Div d="flex">
              {(original_price ||
                originalPrice ||
                (hasSubscription && checkbox === 'subscribe')) &&
              <Text
                textSize="button"
                textWeight="bold"
                fontFamily="secondary"
                m={{r: "10px"}}
                textDecor="line-through"
                opacity="0.3"
              >
                ${
                original_price ||
                originalPrice ||
                (hasSubscription && checkbox === 'subscribe') && getPrice(selectedVariant.price || 0) || ''
              }
              </Text>
              }

              <Text textSize="button" textWeight="bold" fontFamily="secondary" d="flex">

                $
                {
                  hasSubscription && checkbox === 'subscribe' ?
                    getPrice(selectedVariant.discountedPrice || (selectedVariant.price && parseFloat(selectedVariant.price) - 4.2)) :
                    getPrice(selectedVariant.price || 0)

                }
              </Text>
            </Div>

            {checkbox === "one-time" && selectedVariant?.inventory_quantity > 0 && (
              <Div
                m={{t: "5px"}}
                fontFamily="primary"
                d="flex"
                align="center"
                className="sezzle"
              >
                <Text textSize="body" textWeight="500">
                  Or 4 interest-free payments of{" "}
                  <span style={{fontWeight: "bold"}}>
                    ${(selectedVariant?.price / 4).toFixed(2)}
                  </span>{" "}
                  with
                </Text>
                <img src="/Vector.svg" style={{marginLeft: 4}} alt="Sezzle"/>
              </Div>
            )}
          </Div>

          {/* //subscription type select */}

          <Div m={{t: "1.875rem"}}>
            {selectedVariant?.hasSubscription && (
              <>
                {selectedVariant?.inventory_quantity > 0 && (
                  <>
                    {!selectedVariant?.hideOneTime &&
                    <Fragment>
                      <Label
                        textSize="body"
                        textWeight="bold"
                        style={{lineHeight: "16px"}}
                        align="center"
                        m={{b: "10px"}}
                        onClick={() => setCheckbox("one-time")}
                      >
                        <input
                          type="radio"
                          className="apply-radio"
                          checked={checkbox === "one-time" ? "checked" : ""}
                          name="count"
                        />
                        Just one time
                      </Label>

                      <Label
                        bgColor="white"
                        onClick={() => setCheckbox("subscribe")}
                      >
                        <input
                          type="radio"
                          className="apply-radio"
                          checked={checkbox === "subscribe" ? "checked" : ""}
                          name="subscribe"
                        />
                        <Div>
                          <Text
                            textColor="blacklight"
                            textWeight="bold"
                            textSize="body"
                            style={{lineHeight: "16px"}}
                          >
                            Subscribe & save
                          </Text>
                          {/* <Text textSize="paragraph1" m={{ t: "5px" }}>
                              Skip, adjust, or cancel anytime.
                            </Text> */}
                          <Div d="flex" align="center" m={{t: "10px"}}>
                            <img src="/pdp-tick.svg" alt="Icon"/>
                            <Text textSize="paragraph1" m={{l: "5px"}}>
                              Save 10% per item
                            </Text>
                          </Div>

                          <Div d="flex" align="center" m={{t: "5px"}}>
                            <img src="/pdp-tick.svg" alt="Icon"/>
                            <Text textSize="paragraph1" m={{l: "5px"}}>
                              Free shipping for subscribers
                            </Text>
                          </Div>
                          <Div d="flex" align="center" m={{t: "5px"}}>
                            <img src="/pdp-tick.svg" alt="Icon"/>
                            <Text textSize="paragraph1" m={{l: "5px"}}>
                              Early access to new products
                            </Text>
                          </Div>

                        </Div>
                      </Label>
                    </Fragment>
                    }

                    {checkbox === "subscribe" && (
                      <Div>
                        <Div
                          maxW="12.5rem"
                          pos="relative"
                          //bg="transparent"
                          focusBg="white"
                          focusBorderColor="blue"
                          p="10px 15px"
                          className={
                            dropdown
                              ? "dropdown rounded-dropdown"
                              : "dropdown rounded-dropdown-closed"
                          }
                          m={{t: "10px"}}
                          border="1px solid"
                          borderColor="blue"
                          textColor="blue"
                          onClick={() => showDropdown(!dropdown)}
                        >
                          {" "}
                          <Image
                            src="/reload.svg"
                            h=".5rem"
                            w=".5rem"
                            m={{r: "10px"}}
                          />
                          Every {delivery} days
                          <Image
                            pos="absolute"
                            src="/toggle-arrow.svg"
                            h=".5rem"
                            w=".5rem"
                            right="15px"
                            top="50%"
                            transform={`rotateZ(${dropdown ? "-180" : "0"
                            }deg)
                        translateY(${dropdown ? "50%" : "-50% "}) `}
                            transition
                            //{`translateY(-50%)`}
                          />
                        </Div>
                        <Collapse
                          isOpen={dropdown}
                          onClick={() => showDropdown(!dropdown)}
                          maxW="12.5rem"
                          className="dropdown-items"
                        >
                          <Div
                            bg="white"
                            //pos="absolute"
                            w="100%"
                            top="-11px"
                            p="10px 13px"
                            maxH="10rem"
                            overflow="auto"
                            border={{
                              t: "0",
                              x: "1px solid",
                              b: "1px solid",
                            }}
                            borderColor="blue"
                            rounded={{b: "20px"}}
                          >
                            <Div h="1px" bg="blue" m={{b: "5px"}}/>
                            {deliveryOptions.map((name, index) => (
                              <Text
                                bg="transparent"
                                d="block"
                                p={{y: "0.25rem"}}
                                cursor="pointer"
                                onClick={() => {
                                  setSelectedDelivery(name)
                                  showDropdown(false)
                                  addprice("4.20")
                                }}
                              >
                                Every {name} days
                              </Text>
                            ))}
                          </Div>
                        </Collapse>
                      </Div>
                    )}
                  </>
                )}
              </>
            )}

            <Div m={{t: "2.5rem"}}>
              {klaviyoListId || inventory_quantity <= 0 ?
                <Fragment>
                  <Text m={{b: "10px"}} textSize="paragraph1" p={{b: "1rem"}}>
                  <span style={{color: "#e74f3c", fontWeight: "bold"}}>
                    Out of stock
                  </span>{" "}
                    - notify me when it’s available
                  </Text>
                  <Formik
                    initialValues={{
                      email: "",
                    }}
                    onSubmit={onSubmit}
                    validationSchema={validator}
                  >
                    {props => {
                      const {
                        values,
                        status,
                        touched,
                        errors,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        submitForm,
                      } = props
                      formikProps = props
                      {
                        /* console.log(status) */
                      }
                      return (
                        <form onSubmit={handleSubmit}>
                          {status && status.successMessage && (
                            <Text
                              textColor="success700"
                              m={{b: "1rem"}}
                              opacity={status.successMessage ? "1" : "0"}
                              transition
                            >
                              {status.successMessage}
                            </Text>
                          )}
                          {status && status.errorMessage && (
                            <Text
                              textColor="danger700"
                              m={{b: "1rem"}}
                              opacity={status.errorMessage ? "1" : "0"}
                              transition
                            >
                              {status.errorMessage}
                            </Text>
                          )}
                          <Div
                            d={
                              (status && status.successMessage) ||
                              (status && status.errorMessage)
                                ? "none"
                                : "flex"
                            }
                          >
                            <Div flexGrow="2">
                              <Input
                                type="email"
                                name="email"
                                placeholder="Email"
                                placeholderTextColor="navy"
                                shadow="2"
                                textColor="blue"
                                h="3.125rem"
                                border="1px solid"
                                borderColor={
                                  touched.email
                                    ? values.email
                                      ? errors.email
                                        ? "red"
                                        : "oilGreen"
                                      : errors.email
                                        ? "red"
                                        : "transparent"
                                    : "transparent"
                                }
                                m={{r: "10px"}}
                                p="0 2.125rem"
                                prefix={
                                  <Image
                                    src="/email.svg"
                                    w="14px"
                                    h="10px"
                                    pos="absolute"
                                    top="50%"
                                    left="0.75rem"
                                    transform="translateY(-50%)"
                                    alt="Email"
                                  />
                                }
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <Text
                                textColor="error"
                                textSize="paragraph"
                                m={{t: ".5rem"}}
                              >
                                {touched.email && _.capitalize(errors.email)}
                              </Text>
                            </Div>
                            <BlueButton
                              text={
                                isSubmitting ? (
                                  <Icon
                                    name="Loading"
                                    color="white"
                                    size="16px"
                                  />
                                ) : (
                                  "Sign Up"
                                )
                              }
                              type="submit"
                              padding="0.5rem 1.4rem"
                              textSize="header1"
                              h="3.125rem"
                            />
                          </Div>
                        </form>
                      )
                    }}
                  </Formik>
                </Fragment>
                :

                <ProductAddToBag
                  product={product}
                  count={count}
                  changeCounterValue={value => changeCounterValue(value)}
                  variant={selectedVariant}
                  productType={checkbox}
                  frequency={delivery}
                  ctaText={ctaText}
                  bundles={bundles}
                  toggleStickyCart={toggleStickyCart}
                  setToggleStickyCart={setToggleStickyCart}
                />
              }
            </Div>

            <Div
              d="flex"
              m={{
                t:
                  selectedVariant?.inventory_quantity > 0
                    ? "1.25rem"
                    : "0.75rem",
              }}
            >
              <Image src="/dove.svg" h="30px" w="30px" p={{r: "10px"}} alt="Icon"/>

              <Text textSize="paragraph" className="bail">
                We use profits to help pay for someone’s bail every month
                through our{" "}
                <Link to="/mission">
                  <Text
                    tag="span"
                    textColor="blue"
                    hoverTextColor="navy"
                    cursor="pointer"
                    style={{
                      textDecoration: "underline",
                      fontWeight: "bold",
                    }}
                  >
                    Not Pot Bail Fund.
                  </Text>
                </Link>
              </Text>

              <StickyAddToBag
                product={product}
                selectedVariant={selectedVariant}
                junipProduct={junipProduct}
                ctaText={ctaText}
                count={count}
                changeCounterValue={changeCounterValue}
                checkbox={checkbox}
                delivery={delivery}
                volume="200 ML"
                bundles={bundles}
                volume={volume}
                originalPrice={originalPrice}
                toggleStickyCart={toggleStickyCart}
              />
            </Div>
          </Div>
        </Div>
        }
        {enableAddToBundle &&
        <Fragment>
          {
            !isEmpty(features) &&
            <BundleFeatures
              {...features}
            />
          }
          <BlueButton
            text="ADD TO BUNDLE"
            type="button"
            padding="0.5rem 1.4rem"
            m="1rem 0 0"
            textSize="header1"
            h="3.125rem"
            w="100%"
            onClick={(e) => {
              e.preventDefault()
              addBundleItem(selectedVariant)
              onClose()
            }}
          />
        </Fragment>
        }

      </Div>
    </>
  )
}

export default ProductDescription

import React, {useState} from "react";
import {Collapse, Div, Image, Text} from "../../lib/atomize";

export const Dropdown = ({
                           options = [], selected = '', onSelect = () => {
  }
                         }) => {

  const [dropdown, showDropdown] = useState("");

  return (
    <Div>
      <Div
        maxW="12.5rem"
        pos="relative"
        //bg="transparent"
        focusBg="white"
        focusBorderColor="blue"
        p="10px 15px"
        className={
          dropdown
            ? "dropdown rounded-dropdown"
            : "dropdown rounded-dropdown-closed"
        }
        m={{t: "10px"}}
        border="1px solid"
        borderColor="blue"
        textColor="blue"
        onClick={() => showDropdown(!dropdown)}
      >
        {selected?.desktopTitle || selected?.variantTitle}
        <Image
          pos="absolute"
          src="/toggle-arrow.svg"
          h=".5rem"
          w=".5rem"
          right="15px"
          top="50%"
          transform={`rotateZ(${dropdown ? "-180" : "0"
          }deg)
                        translateY(${dropdown ? "50%" : "-50% "}) `}
          transition
          //{`translateY(-50%)`}
        />
      </Div>
      <Collapse
        isOpen={dropdown}
        onClick={() => showDropdown(!dropdown)}
        maxW="12.5rem"
        className="dropdown-items"
      >
        <Div
          bg="white"
          //pos="absolute"
          w="100%"
          top="-11px"
          p="10px 13px"
          maxH="10rem"
          overflow="auto"
          border={{
            t: "0",
            x: "1px solid",
            b: "1px solid",
          }}
          borderColor="blue"
          rounded={{b: "20px"}}
        >
          <Div h="1px" bg="blue" m={{b: "5px"}}></Div>
          <Text
            bg="transparent"
            d="block"
            p={{y: "0.25rem"}}
            cursor="pointer"
            onClick={() => {
              showDropdown(false)
            }}
          >
            {options.map((product, index) => (
              <Text
                bg="transparent"
                d="block"
                p={{y: "0.25rem"}}
                cursor="pointer"
                onClick={() => {
                  onSelect(product)
                }}
              >
                {product?.desktopTitle || product?.variantTitle}
              </Text>
            ))}
          </Text>
        </Div>
      </Collapse>
    </Div>
  )
}

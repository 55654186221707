import React from "react"
import { Div, Text, Row, Col } from "../lib/atomize"
import PortableText from "../components/common/PortableText"
import FeaturesBlock from "../components/home/FeaturesBlock"
import WebsiteContainer from "../components/common/atoms/WebsiteContainer"

export default function ModuleContentWithFeatures({ title, body, list }) {
  return (
    <Div
      p={{ t: { xs: "6.25rem", lg: "9.375rem" } }}
      // data-emergence="hidden"
      data-bg="#EAF1F9"
      className="home-features bgblocks"
    >
      <WebsiteContainer>
        <Row className="features-header-wrapper">
          <Col size={{ xs: "12", lg: "8" }} offset={{ xs: "0", lg: "2" }} className="features-header">
            <Div textAlign="center">
              <Text
                textSize="display1"
                textTransform="uppercase"
                fontFamily="secondary"
                tag="h2"
              >
                {title}
              </Text>
              <Div
                m={{ t: "1.25rem", x: { xs: "0", lg: "2rem" } }}
                className="description"
              >
                <PortableText blocks={body} />
              </Div>
            </Div>
          </Col>
        </Row>
        {list &&
          list.length &&
          (list.length === 4 ? (
            <Row>
              <Col size={{ xs: "12", lg: "8" }} offset={{ xs: "0", lg: "2" }}>
                <Div>
                  <Div m={{ t: { xs: "-0.5rem", lg: "2rem" } }}>
                    <Row>
                      {list &&
                        list.length &&
                        list.map((item, index, id) => {
                          return (
                            <Col
                              size={{ xs: "12", lg: "6" }}
                              key={`featuresList${index}`}
                            >
                              <Div
                                m={{
                                  t: "3rem",
                                  l: {
                                    xs: "0",
                                    lg: index % 2 === 0 ? "0" : "2rem",
                                  },
                                  r: {
                                    xs: "0",
                                    lg: index % 2 === 0 ? "2rem" : "0",
                                  },
                                }}
                              >
                                <FeaturesBlock {...item} index={index} />
                              </Div>
                            </Col>
                          )
                        })}
                    </Row>
                  </Div>
                </Div>
              </Col>
            </Row>
          ) : (
            <Div m={{ t: { xs: "-0.5rem", lg: "2rem" } }}>
              <Row justify="center">
                {list &&
                  list.length &&
                  list.map((item, index, id) => {
                    return (
                      <Col
                        size={{ xs: "12", lg: "4" }}
                        key={`featuresList${index}`}
                      >
                        <Div
                          m={{
                            t: "3rem",
                            l: {
                              xs: "0",
                              lg: "1rem",
                            },
                            r: {
                              xs: "0",
                              lg: "1rem",
                            },
                          }}
                        >
                          <FeaturesBlock {...item} index={index} />
                        </Div>
                      </Col>
                    )
                  })}
              </Row>
            </Div>
          ))}
        {/*  */}
      </WebsiteContainer>
    </Div>
  )
}

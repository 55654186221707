import React, {Fragment, useState} from 'react'
import {first} from 'lodash'
import {Col, Div, Icon, Row, Text} from "../../lib/atomize"
import WebsiteContainer from "../../components/common/atoms/WebsiteContainer"
import BlueButton from "../../components/common/molecules/BlueButton"
import CardHeader from "../../components/common/CardHeader"
import VideoOrImage from "../common/molecules/VideoOrImage"
import {Progress} from "../../components/valentines/Progress"
import {Collapse} from "../blackfriday/Collapse"
import {Link} from "gatsby"
import ProductAddToBag from "../pdp/ProductAddToBag"
import Social from '../../components/wholesale/Social'

const Start = (
  {
    setActiveQuestion = () => {
    }
  }
) => {

  return (
    <>
      <Div
        className="bgblocks"
        data-bg="#FDEEFF"
      >
        <WebsiteContainer>
          <Row>
            <Col
              size={{xs: "12", lg: "5", xl: "4"}}
            >
              <Div h="100%" align="center" d="flex" className="modulehomeHero">
                <Div
                  d="flex"
                  flexDir="column"
                  align={{xs: "center", lg: "flex-start"}}
                  textAlign={{xs: "center", lg: "left"}}
                >
                  <Div className="hero-title">
                    <h2><p>MEET YOUR NEW BEST bud</p></h2>
                  </Div>
                  <Div className="hero-description">
                    <p>If you need help finding the right CBD product for you, this quiz is at your service! Plus, you
                      get a special code. *wink*</p>
                  </Div>
                  <BlueButton
                    m={{xs: "2rem auto", md: "2rem 0 0"}}
                    text='Take the cbd quiz'
                    padding="0.5rem 1.25rem"
                    textSize="header1"
                    h="3rem"
                    w="100%"
                    maxW="254px"
                    onClick={() => {
                      setActiveQuestion('match', 10)
                    }}
                  />
                </Div>
              </Div>
            </Col>
            <Col
              size={{xs: "12", lg: "6", xl: "7"}}
              offset={{xs: "0", lg: "1"}}
            >
              <Div
                shadow="1"
                border="1px solid"
                borderColor="yellowPink"
                rounded="lg"
              >
                <>
                  <CardHeader
                    heading="QUIZTIME"
                    image="https://cdn.sanity.io/images/bkrzj7um/production/874afd4d0959e1ba52cd8a62bc0a936ce5a75989-17x16.svg"
                  />
                  <Div p="0.5rem" bg="white" textAlign="center">

                    <Text
                      style={{lineHeight: "12px"}}
                    >
                      best_buds.jpg
                    </Text>
                  </Div>
                  <VideoOrImage
                    bgPos="center"
                    bgSize="cover"
                    file="https://cdn.sanity.io/images/bkrzj7um/production/d83c18690da58aa4b46247eff36c16bdaa93836e-601x477.png"
                  />
                </>
              </Div>
            </Col>
          </Row>
        </WebsiteContainer>
      </Div>
    </>
  )
}

const BackButton = (
  {
    goBack = () => {
    }
  }
) => {
  return (
    <Text className="back" textAlign="center" onClick={() => {
      goBack('back')
    }}>
      <Icon name="LeftArrow" color="#283377" size="20px"/> GO BACK
    </Text>
  )
}

const OptionList = (
  {
    setActiveQuestion = () => {
    },
    question = '',
    options = [],
    percent = 20,
  }) => {
  return (
    <WebsiteContainer>
      <Row>
        <Col
          size={{xs: "12"}}
        >
          <Div d="flex" justify="center" className="option-list">
            {
              options.map((
                  {
                    text = '',
                    value = '',
                    icon = ''
                  },
                  key
                ) =>
                  <button onClick={() => {
                    setActiveQuestion(value, percent)
                  }} key={`OptionList-${key}`}
                  >
                    <img src={icon} alt={icon}/>
                    <span>{text}</span>
                  </button>
              )
            }
          </Div>
          {/* <BackButton goBack={setActiveQuestion}/> */}
        </Col>
      </Row>
    </WebsiteContainer>
  )
}

const OptionListWithCard = (
  {
    setActiveQuestion = () => {
    },
    question = '',
    options = [],
    percent = 50
  }) => {
  return (
    <>
      <h3 className="question">{question}</h3>
      <Row
        className="card-items-wrapper"
      >
        {
          options.map(
            ({
               text = '',
               value = '',
               icon = '',
               buttonText = '',
               heading = ''
             }, key) =>
              <Col
                size={{
                  xs: "6",
                  lg: "4",
                }}
                key={`OptionListWithCard-${key}`}
                onClick={() => {
                  setActiveQuestion(value, percent)
                }}
              >
                <Div
                  shadow="1"
                  border="1px solid"
                  borderColor="yellowPink"
                  rounded="lg"
                  className="card-item"
                >
                  <>
                    <CardHeader
                      heading={heading}
                    />
                    <Div p="0.5rem" bg="white" textAlign="center" className="card-item-sub-header">

                      <Text>
                        {text}
                      </Text>
                    </Div>
                    <VideoOrImage
                      bgPos="center"
                      bgSize="cover"
                      file={icon}
                    />
                    <Div p="0.5rem" bg="yellow" textAlign="center" rounded={{b: "lg"}}>
                      <BlueButton
                        m={{xs: "20px auto"}}
                        text={buttonText}
                        padding="0.5rem 1.25rem"
                        textSize="header1"
                        h="3rem"
                        w="100%"
                        maxW="200px"
                        onClick={() => {
                          setActiveQuestion(value, percent)
                        }}
                      />
                    </Div>
                  </>
                </Div>
              </Col>
          )
        }
      </Row>

      {/* <BackButton goBack={setActiveQuestion}/> */}
    </>
  )
}

const SelectedResult = (
  {
    selectedVariant = {},
    realSocialMediaPosts = {}
  }
) => {
  const [count, setCount] = useState(1)
  const {title = '', carousel = {}, variantTitle = '', _id = '', klaviyoListId = ''} = selectedVariant;
  const {assets = [], title: carouselTitle = '',} = carousel;
  const {image = {}, caption = ''} = first(assets) || {}

  const changeCounterValue = value => {
    if (value === "plus") {
      setCount(prevCount => prevCount + 1)
    } else {
      if (count === 1) {
        return null
      }
      setCount(prevCount => prevCount - 1)
    }
  }


  const productLinks = {
    "37694676107437": {slug: 'icy-gel', title: 'CBD ICY GEL'},
    "33245868097595": {slug: 'vegan-cbd-gummies/original', title: 'CBD ORIGINAL GUMMIES'},
    "42074835321085": {slug: 'oat-puff-cbd-eczema-creme'},
    "39465651306669": {slug: 'banana-og-hemp-body-creme/fresh-banana', title: 'BANANA OG HEMP BODY CRÈME'},
    "40528601219245": {slug: 'cbd-glow-oil'},
    "31068684255291": {slug: 'vegan-cbd-gummies/sleep', title: 'CBD SLEEP GUMMIES'},
    "37885099081901": {slug: 'cbd-bath-soak/sleep'},
    "37844410302637": {slug: 'cbd-bath-soak/muscle'},
    "40528813523117": {
      slug: 'pet-parents-duo',
      type: 'bundles',
      asset: 'https://cdn.sanity.io/images/bkrzj7um/production/5e4651369724cf9f43e724225d3d1ea407c3c827-1158x1158.jpg',
      title: 'PET PARENTS DUO'
    }
  }

  const {type = 'products', asset = ''} = productLinks[_id]

  return (
    <>
      <Div
        className="bgblocks"
        data-bg="#FDEEFF"
      >
        <WebsiteContainer>
          <Row>
            <Col
              size={{xs: "12", lg: "6", xl: "5"}}
            >
              <Div h="100%" align="center" d="flex" className="modulehomeHero">
                <Div
                  d="flex"
                  flexDir="column"
                  align={{xs: "center", lg: "flex-start"}}
                  textAlign={{xs: "center", lg: "left"}}
                >
                  <Div className="hero-description" m={{b: "1rem"}}>
                    <p>Here's the perfect CBD product for {type === 'products' ? 'you!' : 'you and your pet!'}</p>
                  </Div>
                  <Div className="hero-title">
                    <h2>
                      <p>{productLinks[_id]?.title || `${title} ${variantTitle === 'Default' ? '' : variantTitle}`} </p>
                    </h2>
                  </Div>
                  <Div className="hero-description">
                    <p>Chill out with your new best bud, and get $4.20 off your stash with
                      code <strong>CBD-BUD</strong> at checkout.</p>
                  </Div>
                  {/* {klaviyoListId ?

                    <BlueButton
                      m={{xs: "2rem auto", md: "2rem 0 0"}}
                      text='Waitlist'
                      padding="0.5rem 1.25rem"
                      textSize="header1"
                      h="3rem"
                      w="100%"
                      maxW="254px"
                      onClick={() => window.location.href = `/${type}/${productLinks[_id]?.slug || ''}`}
                    /> :

                    <Div m={{t: "1rem"}} className="valentines-addtobag">
                      {type === 'product' &&
                        <ProductAddToBag
                          count={count}
                          changeCounterValue={value => changeCounterValue(value)}
                          variant={selectedVariant}
                          productType={"one-time"}
                        />
                      }
                    </Div>
                  } */}
                  <BlueButton
                    m={{xs: "2rem auto", md: "2rem 0 0"}}
                    text='View Product'
                    padding="0.5rem 1.25rem"
                    textSize="header1"
                    h="3rem"
                    w="100%"
                    maxW="254px"
                    onClick={() => window.location.href = `/${type}/${productLinks[_id]?.slug || ''}`}
                  />
                </Div>
              </Div>
            </Col>
            <Col
              size={{xs: "12", lg: "6", xl: "6"}}
              offset={{xs: "0", lg: "1"}}
            >
              <Div
                shadow="1"
                border="1px solid"
                borderColor="yellowPink"
                rounded="lg"
              >
                <>
                  <CardHeader
                    heading={carouselTitle || ''}
                  />
                  <Div p="0.5rem" bg="white" textAlign="center">

                    <Text
                      style={{lineHeight: "12px"}}
                    >
                      {caption}
                    </Text>
                  </Div>
                  <VideoOrImage
                    bgPos="center"
                    bgSize="cover"
                    file={asset || image?.asset?.url || 'https://cdn.sanity.io/files/bkrzj7um/production/a100402f78540792c56d2f1951ba62200544247a.jpg'}
                  />
                </>
              </Div>
            </Col>
          </Row>
          <Row>
            <div className="bf-logo-list">
              <div className="logo">
                <img src="/pr/black-friday/forbes-logo.png"/>
              </div>
              <div className="logo">
                <img src="/pr/black-friday/daily-beast-logo.png"/>
              </div>
              <div className="logo">
                <img src="/pr/black-friday/refinery29-logo.png"/>
              </div>
              <div className="logo">
                <img src="/pr/black-friday/allure-logo.png"/>
              </div>
            </div>
          </Row>
          <Row>
            <Row className="bf-faq">
              <Col
                size={{xs: "12", lg: "6", xl: "7"}}
                p={{xs: "0 8px", lg: "0 0 0 8px", xl: "0"}}
              >
                <Div
                  shadow="1"
                  m={{t: {xs: "2.5rem", lg: "0"}}}
                  border="1px solid"
                  borderColor="yellowPink"
                  rounded="lg"
                >
                  <CardHeader
                    heading="ChillTime"
                    image="https://cdn.sanity.io/images/bkrzj7um/production/874afd4d0959e1ba52cd8a62bc0a936ce5a75989-17x16.svg"
                  />
                  <Div p="0.5rem" bg="white" textAlign="center">

                    <Text
                      style={{lineHeight: "12px"}}
                    >
                      ask_me_anything.jpg
                    </Text>
                  </Div>
                  <VideoOrImage
                    bgPos="center"
                    bgSize="cover"
                    file="https://cdn.sanity.io/files/bkrzj7um/production/a100402f78540792c56d2f1951ba62200544247a.jpg"
                  />
                </Div>
              </Col>
              <Col
                size={{xs: "12", lg: "5", xl: "4"}}
                offset={{xs: "0", lg: "1"}}
              >
                <Text
                  textTransform="uppercase"
                  m={{t: {xs: "2rem"}}}
                  p={{b: "1.25rem"}}
                  textWeight="bold"
                  textSize="display1"
                  fontFamily="secondary"
                  textAlign={{xs: "center", lg: "left"}}
                  tag="h3"
                >FAQ</Text>
                <Collapse
                  faqs={[
                    {
                      id: '1',
                      title: 'What is CBD?',
                      desc: 'Cannabidiol (CBD) is one of more than 100 cannabinoids in the cannabis plant, known to possess anti-inflammatory, neuroprotective, and anti-anxiety properties. Because CBD is non-psychoactive, it will NOT make you high.'
                    },
                    {
                      id: '2',
                      title: 'What are cannabinoids?',
                      desc: 'Cannabinoids are the key chemical compounds of the hemp and marijuana plants (both cannabis, just varietals). Although at least 113 active cannabinoids have been identified to-date, tetrahydrocannabinol (THC) is the only one that’s psychoactive. We extract our CBD from hemp, which makes our products 100% THC-Free.'
                    },
                    {
                      id: '3',
                      title: 'Will CBD make me high?',
                      desc: 'Nope. CBD is completely non-psychoactive. If you’re looking to get high, please do not buy our products! You’ll be thoroughly disappointed (but feeling a little more chill about it).'
                    },
                    {
                      id: '4',
                      title: 'Are Not Pot products all-natural?',
                      desc: 'Our CBD is extracted from organic USA hemp and most of our products are vegan. We are also proud to be 100% cruelty-free, meaning we only test our products on humans—the ones in our HQ to be exact! Our team is extremely passionate about animals and doesn’t condone animal testing in any way.'
                    },
                    {
                      id: '5',
                      title: 'Are Not Pot products third-party tested?',
                      desc: <Fragment>Yes, all our products go through extensive third-party testing to ensure quality
                        and consistency. For lab information on our products, you may go to the fine print section of
                        each product page.</Fragment>
                    }
                  ]}
                />
              </Col>
            </Row>
          </Row>

          <Social
            {
              ...realSocialMediaPosts
            }
          />
        </WebsiteContainer>
      </Div>
    </>
  )
}
const QuizQuestion = (
  {
    selectedProduct = {},
    setActiveQuestion = () => {
    },
    goNext = () => {
    },
    completeResult = () => {
    },
    questions = [],
    realSocialMediaPosts = {},
    activeQuestion = {},
    percent = 0,
    questionData = [],
    selectedQuestionIndex = 0,
    selectedVariant = {}
  }
) => {
  return (
    <div className="quiz-wrapper">
      {percent ?
        <header>
          <h2>{questionData[selectedQuestionIndex] && questionData[selectedQuestionIndex].question || 'no question'}</h2>
          <Progress percent={percent}/>
        </header> : ''
      }
      {
        {
          'start': <Start setActiveQuestion={goNext}/>,
          'match': <OptionList
            options={questionData[selectedQuestionIndex] && questionData[selectedQuestionIndex].options || []}
          />,
          'selectPath': <OptionList
            options={[
              {
                text: 'A relaxing trip with daily massages, unli margarita, and yoga by the beach',
                value: `sleep`,
                buttonText: 'POP A GUMMY',
                icon: 'https://cdn.sanity.io/images/bkrzj7um/production/7e8a13def720811936c62ef18b71229b11d69ce3-80x80.png'
              },
              {
                text: 'A cozy stay up in the mountains where you\'re surrounded by chirping birds and fresh waterfalls',
                value: `sleep`,
                buttonText: 'POP A GUMMY',
                icon: 'https://cdn.sanity.io/images/bkrzj7um/production/5898077f8558187713366ae92206a12ee15f6ea7-121x121.png'
              },
              {
                text: 'An exciting adventure with surfing, kayaking, snorkeling, and other recreational activities',
                value: `sleep`,
                buttonText: 'POP A GUMMY',
                icon: 'https://cdn.sanity.io/images/bkrzj7um/production/bac113d0a4d2899fd9661865e287bb42cc7a2568-120x120.png'
              },
              {
                text: 'A pawfect vacation with your best fur-iend in a pet-friendly villa',
                value: `sleep`,
                buttonText: 'POP A GUMMY',
                icon: 'https://cdn.sanity.io/images/bkrzj7um/production/bbe5b036f279252249a0ee4f4d9d5cb546633b9f-80x80.png'
              },
              {
                text: 'An overnight hike in a beautiful campsite with a nice overlooking view',
                value: `sleep`,
                buttonText: 'POP A GUMMY',
                icon: 'https://cdn.sanity.io/images/bkrzj7um/production/481bd40594345deed5cd4e7f6d55b63dbb99b4e4-250x250.png'
              },
            ]}
          />,
          'matchProduct': <>

          </>,
          'resultsPage': <SelectedResult selectedVariant={selectedVariant}
                                         realSocialMediaPosts={realSocialMediaPosts}/>
        }[activeQuestion]
      }
    </div>
  )
}
export const Quiz = (
  {
    variantOptions = [],
    checkoutData = {},
    realSocialMediaPosts = {}
  }
) => {
  const [activeQuestion, setActiveQuestion] = useState('match')
  const [answers, setAnswers] = useState([])
  const [path, setPath] = useState('')
  const [percent, setPercent] = useState(20)
  const [selectedProduct, setSelectedProduct] = useState('')
  const [selectedVariant, setSelectedVariant] = useState({})
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0)
  const [selectedResultsIndex, setSelectedResultsIndex] = useState(0)
  const [resultOptions, setResultOptions] = useState([])
  const [subResultHeading, setSubResultHeading] = useState('')
  const [subResultSubHeading, setSubResultSubHeading] = useState('')
  const questions = [
    {
      question: `WHAT'S THE FIRST THING YOU DO IN THE MORNING?`,
      options: [
        {
          text: 'Grab your phone to check work notifications and say a little prayer that may today be a chill day',
          value: `a`,
          icon: 'https://cdn.sanity.io/images/bkrzj7um/production/7e8a13def720811936c62ef18b71229b11d69ce3-80x80.png'
        },
        {
          text: 'Snooze the alarm for the seventh time, just this morning',
          value: `b`,
          icon: 'https://cdn.sanity.io/images/bkrzj7um/production/5898077f8558187713366ae92206a12ee15f6ea7-121x121.png'
        },
        {
          text: 'Prepare your workout clothes and go for an early run',
          value: `c`,
          icon: 'https://cdn.sanity.io/images/bkrzj7um/production/bac113d0a4d2899fd9661865e287bb42cc7a2568-120x120.png'
        },
        {
          text: 'Snuggle for an extra 5 minutes with your best fur-iend',
          value: `d`,
          icon: 'https://cdn.sanity.io/images/bkrzj7um/production/bbe5b036f279252249a0ee4f4d9d5cb546633b9f-80x80.png'
        },
        {
          text: 'Scroll through mountain pictures and plan for your next hike',
          value: `e`,
          buttonText: 'POP A GUMMY',
          icon: 'https://cdn.sanity.io/images/bkrzj7um/production/481bd40594345deed5cd4e7f6d55b63dbb99b4e4-250x250.png'
        },
      ]
    },
    {
      question: `WHAT'S THE PERFECT FRIDAY NIGHT FOR YOU?`,
      options: [
        {
          text: 'A good movie and lots of yummy munchies with your work notifications turned off',
          value: `a`,
          icon: 'https://cdn.sanity.io/images/bkrzj7um/production/7e8a13def720811936c62ef18b71229b11d69ce3-80x80.png'
        },
        {
          text: 'An early night in bed, tucked in warm sheets, and listening to soothing jazz music ',
          value: `b`,
          icon: 'https://cdn.sanity.io/images/bkrzj7um/production/5898077f8558187713366ae92206a12ee15f6ea7-121x121.png'
        },
        {
          text: 'A cycling and spinning workout night with your best bud',
          value: `c`,
          icon: 'https://cdn.sanity.io/images/bkrzj7um/production/bac113d0a4d2899fd9661865e287bb42cc7a2568-120x120.png'
        },
        {
          text: 'A well-deserved dinner date with your very cute pet ',
          value: `d`,
          icon: 'https://cdn.sanity.io/images/bkrzj7um/production/bbe5b036f279252249a0ee4f4d9d5cb546633b9f-80x80.png'
        },
        {
          text: 'An after-work backyard camping with bonfire and marshmallows',
          value: `e`,
          icon: 'https://cdn.sanity.io/images/bkrzj7um/production/481bd40594345deed5cd4e7f6d55b63dbb99b4e4-250x250.png'
        },
      ]
    },
    {
      question: `WHAT'S A BAD DAY LIKE FOR YOU?`,
      options: [
        {
          text: 'Monday meetings, countless errands, and dirty dishes piled up in the sink',
          value: `a`,
          icon: 'https://cdn.sanity.io/images/bkrzj7um/production/7e8a13def720811936c62ef18b71229b11d69ce3-80x80.png'
        },
        {
          text: 'Zombie-like feeling after a night of minimal sleep because your neighbor threw a party',
          value: `b`,
          icon: 'https://cdn.sanity.io/images/bkrzj7um/production/5898077f8558187713366ae92206a12ee15f6ea7-121x121.png'
        },
        {
          text: 'Skipped the gym because of sore muscles, backaches, and period cramps',
          value: `c`,
          icon: 'https://cdn.sanity.io/images/bkrzj7um/production/bac113d0a4d2899fd9661865e287bb42cc7a2568-120x120.png'
        },
        {
          text: 'Ruined plans because of anxious, restless and crying furbaby',
          value: `d`,
          icon: 'https://cdn.sanity.io/images/bkrzj7um/production/bbe5b036f279252249a0ee4f4d9d5cb546633b9f-80x80.png'
        },
        {
          text: 'Whole day of scratching itchy and irritated skin from rashes and mosquito bites ',
          value: `e`,
          icon: 'https://cdn.sanity.io/images/bkrzj7um/production/481bd40594345deed5cd4e7f6d55b63dbb99b4e4-250x250.png'
        },
      ]
    },
    {
      question: `WHAT'S YOUR DREAM VACATION LIKE?`,
      options: [
        {
          text: 'A relaxing trip with daily massages, unli margarita, and yoga by the beach',
          value: `a`,
          icon: 'https://cdn.sanity.io/images/bkrzj7um/production/7e8a13def720811936c62ef18b71229b11d69ce3-80x80.png'
        },
        {
          text: 'A cozy stay up in the mountains where you\'re surrounded by chirping birds and fresh waterfalls',
          value: `b`,
          icon: 'https://cdn.sanity.io/images/bkrzj7um/production/5898077f8558187713366ae92206a12ee15f6ea7-121x121.png'
        },
        {
          text: 'An exciting adventure with surfing, kayaking, snorkeling, and other recreational activities',
          value: `c`,
          icon: 'https://cdn.sanity.io/images/bkrzj7um/production/bac113d0a4d2899fd9661865e287bb42cc7a2568-120x120.png'
        },
        {
          text: 'A pawfect vacation with your best fur-iend in a pet-friendly villa',
          value: `d`,
          icon: 'https://cdn.sanity.io/images/bkrzj7um/production/bbe5b036f279252249a0ee4f4d9d5cb546633b9f-80x80.png'
        },
        {
          text: 'An overnight hike in a beautiful campsite with a nice overlooking view',
          value: `e`,
          icon: 'https://cdn.sanity.io/images/bkrzj7um/production/481bd40594345deed5cd4e7f6d55b63dbb99b4e4-250x250.png'
        },
      ]
    }
  ]

  const results = [
    {
      result: 'a',
      title: 'YOUR ANSWERS TELL US that you need to chill out from daily STRESS AND ANXIETY.',
      subtext: 'And we think we know which CBD product is best for you. But for the ultimate tiebreaker, which sounds better?',
      options: [
        {
          text: 'shoo the bad vibes away',
          value: `33245868097595`,
          heading: 'A',
          buttonText: 'SQUISHY GUMMY',
          icon: 'https://cdn.sanity.io/images/bkrzj7um/production/96570ccc1488bc6c1d2276822779eb7c66a69cbe-296x295.png?w=2000&fit=max&auto=format',
          link: 'products/vegan-cbd-gummies/original'
        },
        {
          text: 'mix with your favorite drinks',
          value: `40528601219245`,
          heading: 'B',
          buttonText: 'Calming oil',
          icon: 'https://cdn.sanity.io/images/bkrzj7um/production/43ed2edbc246cd14f01497f0430f2c35bfdf1311-296x295.png?w=2000&fit=max&auto=format',
          link: 'products/cbd-glow-oil'
        },
      ]
    },
    {
      result: 'b',
      title: 'YOUR ANSWERS TELL us that you need AND LOVE GETTING GOOD QUALITY SLEEP.',
      subtext: 'And we think we know which CBD product is best for you. But for the ultimate tiebreaker, which sounds better?',
      options: [
        {
          text: 'fall and stay asleep',
          value: `31068684255291`,
          heading: 'A',
          buttonText: 'SQUISHY GUMMY',
          icon: 'https://cdn.sanity.io/images/bkrzj7um/production/d419b7909ca5b8bf1ad2619fa67a0a39abb0fc76-296x295.png?w=2000&fit=max&auto=format',
          link: 'products/vegan-cbd-gummies/sleep'
        },
        {
          text: 'prepare your mind and body for sleep',
          value: `37885099081901`,
          heading: 'B',
          buttonText: 'SOOTHING SOAK',
          icon: 'https://cdn.sanity.io/images/bkrzj7um/production/6e13020b0fbda378b7eb000b48ab4d4d3f4a3e07-296x295.png?w=2000&fit=max&auto=format',
          link: 'products/cbd-glow-oil'
        },
      ]
    },
    {
      result: 'c',
      title: 'YOUR ANSWERS TELL us that you ENJOY STAYING PHYSICALLY ACTIVE AND STRONG.',
      subtext: 'And we think we know which CBD product is best for you. But for the ultimate tiebreaker, which sounds better?',
      options: [
        {
          text: 'freeze pain fast',
          value: `37694676107437`,
          heading: 'A',
          buttonText: 'COOLING GEL',
          icon: 'https://cdn.sanity.io/images/bkrzj7um/production/61796aa6ae5bdbdd8fcf4b1ea4afbeb110b2530c-295x295.png?w=2000&fit=max&auto=format',
          link: 'products/icy-gel'
        },
        {
          text: 'melt body aches away',
          value: `37844410302637`,
          heading: 'B',
          buttonText: 'REFRESHING SOAK',
          icon: 'https://cdn.sanity.io/images/bkrzj7um/production/7d65b78601ca254233eaee2d4da473c69d081ee4-295x295.png?w=2000&fit=max&auto=format',
          link: 'products/cbd-bath-soak/muscle'
        },
      ]
    }
  ]


  const goNext = (active, percent) => {
    setActiveQuestion(active)
    setPercent(percent || 25)
  }

  const selectProduct = (value) => {
    if (value === 'back') {
      setActiveQuestion('match')
      setPercent(30)
    } else {
      setSelectedProduct(value)
      setActiveQuestion('matchProduct')
      setPercent(70)
    }

  }

  const completeResult = (value) => {
    const {link = '', product = '', id = ''} = value

    if (value === 'back') {
      if (['sleep', 'chill', 'zero', 'soothing', 'selfcare'].includes(selectedProduct)) {
        setActiveQuestion('selectPath')
        setPercent(30)
      }
      if (['relax2', 'recover2'].includes(selectedProduct)) {

        setSelectedProduct('selfcare')
        setActiveQuestion('matchProduct')
        setPercent(70)
      }
      if (['relax', 'recover'].includes(selectedProduct)) {
        setSelectedProduct('soothing')
        setActiveQuestion('matchProduct')
        setPercent(70)

      }

    }

    if (id) {
      setSelectedVariant(variantOptions.find(variant => variant._id === id))
      setPercent(0)
      setActiveQuestion('resultsPage')
    } else if (link) {
      //window.location.href = link;
    } else if (product) {
      setPercent(90)
      setSelectedProduct(product)
    }
  }

  const mode = a =>
    Object.values(
      a.reduce((count, e) => {
        if (!(e in count)) {
          count[e] = [0, e];
        }

        count[e][0]++;
        return count;
      }, {})
    ).reduce((a, v) => v[0] < a[0] ? a : v, [0, null])[1];
  ;


  const setActiveQuestionIndex = (value, percentVal = 25) => {
    const isGrater = selectedQuestionIndex + 1 < questions.length
    setPercent(percent + 20)

    if (isGrater) {
      setSelectedQuestionIndex(selectedQuestionIndex + 1)
      setAnswers([...answers, value])
    } else {
      const resultAnswers = mode(answers)
      let selectPath = 'selectPath'
      let resultsIndex = 0
      switch (resultAnswers) {
        case 'a':
          setSelectedResultsIndex(0)
          break;
        case 'b':
          resultsIndex = 1
          setSelectedResultsIndex(1)
          break;
        case 'c':
          resultsIndex = 2
          setSelectedResultsIndex(2)
          break;
        case 'd':
          selectPath = 'resultsPage'
          setPercent(0)
          setSelectedVariant(variantOptions.find(variant => variant._id === "40528813523117"))
          break;
        case 'e':
          setSelectedVariant(variantOptions.find(variant => variant._id === "42074835321085"))
          setPercent(0)
          selectPath = 'resultsPage'
          break;
      }
      const {options: resultOptionsValue = [], title = '', subtext = ''} = results[resultsIndex] || {}
      setSubResultHeading(title)
      setSubResultSubHeading(subtext)
      setResultOptions(resultOptionsValue)
      setActiveQuestion(selectPath)
    }

  }

  const setResults = (value) => {
    let selectPath = 'resultsPage'
    setPercent(0)
    setSelectedVariant(variantOptions.find(variant => variant._id === value))
    setActiveQuestion(selectPath)
  }

  const {question: currentQuestion = '', options: currentOptions = []} = questions[selectedQuestionIndex] || {}

  return (
    <div className="cbd-quiz">
      <Row m={{t: {lg: '5rem', xs: '3rem'}, b: {lg: '10rem'}}} align="center">
        <Col size={{xs: "12"}}>

          <div className="quiz-wrapper">
            {percent ?

              <header>
                {activeQuestion === 'match' && <h2>{currentQuestion}</h2>}
                {activeQuestion === 'selectPath' && <><h2>{subResultHeading}</h2><p className="sub">{subResultSubHeading}</p></>}
                <Progress percent={percent}/>
              </header> : ''
            }
            {
              {
                'start': <Start setActiveQuestion={goNext}/>,
                'match': <OptionList
                  setActiveQuestion={setActiveQuestionIndex}
                  options={currentOptions}
                  percent={20}
                />,
                'selectPath': <OptionListWithCard
                  options={resultOptions}
                  setActiveQuestion={setResults}
                />,
                'resultsPage': <SelectedResult selectedVariant={selectedVariant}
                                               realSocialMediaPosts={realSocialMediaPosts}/>
              }[activeQuestion]
            }
          </div>
        </Col>
      </Row>
    </div>

  )
}

import ls from "local-storage";

export const discountHelper = {
  getDiscount: () => {
    return ls.get('np_code')
  },
  saveDiscount: (discount_code) => {
    ls.set('np_code', discount_code)
  },
  clearDiscount: () => {
    ls.remove('np_code');
  }

}

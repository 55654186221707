import React, {useContext, useEffect, useState} from "react"
import {graphql} from "gatsby"
import ls from "local-storage"
import {isEmpty} from 'lodash'
import {useMutation, useQuery} from "@apollo/client"
import {Div} from "../lib/atomize"
import {trackProductPage} from '../helpers/freshpaint';

import {getDefaultVariant, getProductModules,} from "../services/productService"
import {SEO} from "../components/common/SEO"
import Layout from "../components/common/Layout"
import GraphQLErrorList from "../components/common/GraphqQLErrorList"
import {saveCheckoutInLocalStorage} from "../services/cartService"
import {CHECKOUT_BY_ID, CHECKOUT_CREATE, TOGGLE_CART,} from "../graphql/queries"
//context
import {AppContext} from "../context";
import {freebieActions} from '../actions'
// modules
import ModulePdpHero from "../modules/ModulePdpHero"


const Product = props => {
  const [state, dispatch] = useContext(AppContext);

  const isNotpotBody = process.env.GATSBY_SITE_MODE === 'notpotbody';

  const [toggleCart] = useMutation(TOGGLE_CART, {
    onCompleted: () => {
      if (window && window.rdt){
        window.rdt('track', 'AddToCart', {
            "currency" : "USD",
            "itemCount": 1,
            "value": 100
        });
      }
    },
  })

  const {data: checkoutData, refetch: refechCheckoutData} = useQuery(
    CHECKOUT_BY_ID,
    {
      // skip: !ls("lastCheckoutID"),
      variables: {id: ls("lastCheckoutID")},
      onCompleted: result => {
        const {node = {}} = result
        const {orderStatusUrl = null} = node || {};
        if (orderStatusUrl) {
          ls.remove("lastCheckoutID");
          if (!ls.get('disable_notSpam')) {
            ls.set('disable_notSpam', true);
          }
          if (ls.get('np_code')) {
            ls.remove('np_code');
          }
        }
      }
    }
  )

  const [checkoutCreate] = useMutation(CHECKOUT_CREATE, {
    onCompleted: result => {
      //window.history.pushState("header", "Not Pot", "/#cart")
      toggleCart({
        variables: {cartDrawerVisible: true},
      })
      saveCheckoutInLocalStorage(result?.checkoutCreate?.checkout)
      refechCheckoutData({
        // skip: !ls("lastCheckoutID"),
        variables: {id: ls("lastCheckoutID")},
      })
    },
    onSuccess: success => {
      console.log('success', success)
    }

  })
  //*************************** */
  const {data, errors} = props
  const PagePath = props?.location?.pathname || props.uri;
  const gorgias = typeof window !== "undefined" && window?.GorgiasChat || '';

  const [selectedVariant, setSelectedVariant] = useState(
    getDefaultVariant({
      variantSlug: props.pathContext.variantSlug,
      data,
    })
  )

  // useEffect(() => {
  //   trackProductPage(product);
  // }, [])

  const paths = PagePath === '/products/banana-og-hemp-body-creme' || PagePath === '/products/banana-og-hemp-body-creme/fresh-banana' || PagePath === '/products/banana-og-hemp-body-creme/fragrance-free';
  useEffect(() => {
    const {site = {}} = data
    const {freebie = {}} = site

    if (!isEmpty(freebie)) {
      dispatch({
        type: freebieActions.SET_FREEBIE,
        payload: freebie,
      })
    }
    trackProductPage(product);

    document.querySelector('body').classList.add("hide-gorgias", "products");
    setTimeout(() => {
      if (gorgias && gorgias.hasOwnProperty("on")) {
        gorgias.setShopifyContext('not-pot.myshopify.com');
        if (gorgias.isBusinessHours() && PagePath && PagePath !== paths) {
          document.querySelector('body').classList.remove("hide-gorgias");
        }
      }
    }, 100);

  }, [PagePath, gorgias, data])

  useEffect (()=> {
    if (window && window.rdt){
      window.rdt('track', 'ViewContent')
    }
  }, [PagePath])

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors}/>
      </Layout>
    )
  }

  const site = (data || {}).site
  const {junipProduct = {}, product = {}} = data
  const {og = {}, id = 0} = product
  const {seo = {}} = selectedVariant || {};
  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }
  const title = selectedVariant?.desktopTitle
  const modules = getProductModules({
    product,
    junipProduct,
    checkoutData,
    checkoutCreate,
  })
  const seoTitle = seo?.seo_title || title;
  const seoDescription = seo?.meta_description || product?.description;
  const seoKeywords = seo?.focus_synonyms || [];
  const seoFocus = seo?.focus_keyword || "";
  const metaKeywords = [seoFocus].concat(seoKeywords);
  const media = selectedVariant?.carousel?.assets[0]?.image?.asset?.url;
  const socialTitle = og?.title || seoTitle;
  const socialDesc = og?.description || seoDescription;
  const productMetaData = {
    price: selectedVariant?.price,
    quantity: 1,
    productId: id,
    productGroupId: selectedVariant?._id
  };

  const pageClass = (PagePath ? PagePath.replace(/\//g, '') : 'home')

  const {freebie = {}} = site
  return (
    <Layout site={site} path={PagePath} pageClass={`page-wrapper page-${pageClass}`}>
      <SEO
        title={seoTitle}
        description={seoDescription}
        keywords={metaKeywords}
        site={site}
        media={media}
        url={PagePath ? PagePath : ''}
        socialTitle={socialTitle}
        socialDesc={socialDesc}
        headComponents={site.headComponents}
        productInfo={productMetaData}
      />
      <Div>
        <Div
          p={{t: {xs: "1.875rem", lg: "2.5rem"}}}
          className="pdf-hero-container"
        >
          <ModulePdpHero
            product={product}
            selectedVariant={selectedVariant}
            setSelectedVariant={setSelectedVariant}
            junipProduct={junipProduct}
            key={props.location.search}
            slug={props.pageContext.slug}
          />
        </Div>
        {modules}
      </Div>
    </Layout>
  )
}

export default Product

export const query = graphql`
  query ProductTemplateQuery($id: String!, $slug: String!) {
    product: sanityProduct(id: { eq: $id }) {
      id
      sanityId
      title
      pdpTitle
      mobileTitle
      desktopTitle
      volume
      supplementFactsFineprint
      latestLabReportsFineprint
      howToUse: _rawHowToUse(resolveReferences: {maxDepth: 10})
      ingredients: _rawIngredients(resolveReferences: {maxDepth: 10})
      seo {
        seo_title
        meta_description
        focus_keyword
        focus_synonyms
      }
      og{
        title
        description
      }
      variants{
        _id
        title
        price
        original_price
        discountedPrice
        klaviyoListId
        sku
        inventory_quantity
        variantTitle
        customVariantSlug
        customVariantCartName
        productDescription: _rawProductDescription
        hasSubscription
        icon {
          asset {
            url
          }
        }
        supplementFacts {
          asset {
            url
          }
        }
        latestCoas {
          asset {
            url
          }
        }
        carousel {
          title
          icon {
            asset {
              url
            }
          }
          assets {
            video {
              asset {
                url
              }
            }
            image {
              asset {
                url
              }
            }
            caption
            alt
          }
        }
        seo {
          seo_title
          meta_description
          focus_keyword
          focus_synonyms
        }
        deleted
        freebie {
          displayTitle
          originalPrice
          slug {
            _key
            current
          }
          variant {
            _id
            _key
            id
            desktopTitle
            discountedPrice
            productHandle
            sanityId
            title
            variantTitle
          }
        }

      }
      defaultVariant {
        _id
        title
        price
        discountedPrice
        original_price
        sku
        inventory_quantity
        variantTitle
        klaviyoListId
        productDescription: _rawProductDescription
        hasSubscription
        icon {
          asset {
            url
          }
        }
        supplementFacts {
          asset {
            url
          }
        }
        latestCoas {
          asset {
            url
          }
        }
        carousel {
          title
          icon {
            asset {
              url
            }
          }
          assets {
            video {
              asset {
                url
              }
            }
            image {
              asset {
                url
              }
            }
            caption
            alt
          }
        }
        seo {
          seo_title
          meta_description
          focus_keyword
          focus_synonyms
        }
        og{
          title
          description
        }
        freebie {
          displayTitle
          originalPrice
          slug {
            _key
            current
          }
          variant {
            _id
            _key
            id
            desktopTitle
            discountedPrice
            productHandle
            sanityId
            title
            variantTitle
          }
        }
      }
      _rawContent(resolveReferences: { maxDepth: 20 })
    }

    junipProduct(remote_handle: { eq: $slug }) {
      id
      rating_average
      rating_count
      remote_id
    }

    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SiteSettings
    }
  }
`

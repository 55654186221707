import _ from "lodash"
import React from "react"
import axios from "axios"
import { useMutation } from "@apollo/client"
import { Div, Anchor, Input, Text, Image, Icon } from "../../../lib/atomize"

import * as yup from "yup"
import { Formik } from "formik"
import BlueButton from "../molecules/BlueButton"
import {
  onLoginFormSubmit,
  onLoginRequestComplete,
} from "../../../services/authService"
import { CREATE_CUSTOMER_TOKEN } from "../../../graphql/queries"

export default function LoginForm({ emailModal, setEmailModal }) {
  let formikProps

  const [
    createCustomerToken,
    { loading: createCustomerTokenLoading, data: createCustomerTokenData },
  ] = useMutation(CREATE_CUSTOMER_TOKEN, {
    onCompleted: result => {
      onLoginRequestComplete({ response: result, formik: formikProps })
    },
  })

  /**
   * on submit form
   *
   * @param {*} values
   */
  const onSubmit = values => {
    onLoginFormSubmit({ inputs: values, createCustomerToken })
  }

  const validator = yup.object().shape({
    email: yup
      .string()
      .required("Please use a valid email address.")
      .min(1)
      .email("Please use a valid email address."),
    password: yup.string().required("Please enter valid a password.").min(6),
  })

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      onSubmit={onSubmit}
      validationSchema={validator}
    >
      {props => {
        const {
          values,
          status,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          submitForm,
        } = props
        formikProps = props

        return (
          <form onSubmit={handleSubmit}>
            <Div flexDir={{ xs: "column", lg: "row" }}>
              {status && status.errorMessage && (
                <Text
                  textColor="danger700"
                  m={{ b: "1rem" }}
                  opacity={status.errorMessage ? "1" : "0"}
                  transition
                >
                  {status.errorMessage}
                </Text>
              )}
              <Div m={{ r: { lg: "0.625rem" } }} flexGrow="1">
                <Input
                  placeholder="Email Address"
                  placeholderTextColor="navy"
                  shadow="2"
                  textColor="blue"
                  h="2.5rem"
                  p="0 2.125rem"
                  name="email"
                  borderColor={
                    touched.email
                      ? values.email
                        ? errors.email
                          ? "red"
                          : "oilGreen"
                        : errors.email
                          ? "red"
                          : "transparent"
                      : "transparent"
                  }
                  prefix={
                    <Image
                      src="/email.svg"
                      w="14px"
                      h="14px"
                      pos="absolute"
                      top="50%"
                      left="0.75rem"
                      transform="translateY(-50%)"
                      alt="Email"
                    />
                  }
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Div>
              <Div
                m={{
                  r: { lg: "0.625rem" },
                  y: { xs: "0.625rem", lg: "0" },
                }}
                flexGrow="1"
              >
                <Text textColor="error" textSize="paragraph" m={{ b: "1rem" }}>
                  {touched.email && _.capitalize(errors.email)}
                </Text>
                <Input
                  name="password"
                  placeholder="Password"
                  placeholderTextColor="navy"
                  shadow="2"
                  type="password"
                  textColor="blue"
                  h="2.5rem"
                  p="0 2.125rem"
                  borderColor={
                    touched.password
                      ? values.password
                        ? errors.password
                          ? "red"
                          : "oilGreen"
                        : errors.password
                          ? "red"
                          : "transparent"
                      : "transparent"
                  }
                  prefix={
                    <Image
                      src="/password.svg"
                      w="14px"
                      h="10px"
                      pos="absolute"
                      top="50%"
                      left="0.75rem"
                      transform="translateY(-50%)"
                      alt="Password"
                    />
                  }
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Text textColor="error" textSize="paragraph" m={{ b: "1rem" }}>
                  {touched.password && _.capitalize(errors.password)}
                </Text>
              </Div>
              <BlueButton
                text={
                  isSubmitting ? (
                    <Icon name="Loading" color="white" size="16px" />
                  ) : (
                    "Login"
                  )
                }
                m={{ t: "1.25rem" }}
                padding="0.5rem 1.25rem"
                textSize="header1"
                h="2.5rem"
                w="100%"
              />
            </Div>
          </form>
        )
      }}
    </Formik>
  )
}

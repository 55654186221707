import React, {useState} from "react"
import {Col, Div, Row} from "../lib/atomize"
import ProductCarousel from "../components/pdp/ProductCarousel"
import ProductDescription from "../components/pdp/ProductDescription"
import WebsiteContainer from "../components/common/atoms/WebsiteContainer"

const ModulePdpHero = (
  {
    product,
    selectedVariant,
    setSelectedVariant,
    junipProduct,
    slug,
    descriptionExtra = "",
    ctaText = '',
    bundles = [],
    originalPrice = '',
    enableAddToBundle = false,
    addBundleItem=()=>{},
    onClose = () => {},
    customVariants = []
  }
) => {

  const [swiper, setSwiper] = useState(null)
  const [selectedSlide, setSelectedSlide] = useState(0)
  /* toggle Swiper slide */
  const toggleSelectedSlide = index => {
    setSelectedSlide(index)
    swiper && swiper.swiper.update()
    if (swiper && swiper.swiper.activeIndex !== index) {
      swiper.swiper.slideTo(index)
    }
  }
  /* render swiper instance */
  const onSwiperInit = swiper => {
    setSwiper({swiper})

    // change selected on slide change
    swiper &&
    swiper.on("slideChange", () => {
      toggleSelectedSlide(swiper.activeIndex)
    })

    // remove event on destroy
    swiper &&
    swiper.on("beforeDestroy", () => {
      swiper.detachEvents()
    })
  }
  return (
    <Div className="bgblocks" data-bg="#FDEEFF">
      <WebsiteContainer>
        <Row m="0" className="pdp-wrapper">
          <Col size={{xs: "12", lg: "7"}} p="0" className="pdp-image">
            <Div m={{l: {xl: "1.25rem"}}}>
              <ProductCarousel
                selectedSlide={selectedSlide}
                toggleSelectedSlide={toggleSelectedSlide}
                onSwiperInit={onSwiperInit}
                selectedVariant={selectedVariant}
              />
            </Div>
          </Col>
          <Col size={{xs: "12", lg: "5"}} p="0" className="pdp-desc">
            <Div
              m={{
                l: {lg: "3rem", xl: "6.25rem"},
                t: {xs: "2.5rem", lg: "0"},
              }}
            >
              <ProductDescription
                product={product}
                selectedVariant={selectedVariant}
                setSelectedVariant={setSelectedVariant}
                setSelectedSlide={setSelectedSlide}
                junipProduct={junipProduct}
                slug={slug}
                subscriptionType={product?.subscriptionType || "one-time"}
                productExtra={descriptionExtra}
                ctaText={ctaText}
                bundles={bundles}
                originalPrice={originalPrice}
                enableAddToBundle={enableAddToBundle}
                addBundleItem={addBundleItem}
                customVariants={customVariants}
                onClose={onClose}
              />
            </Div>
          </Col>
        </Row>
      </WebsiteContainer>
    </Div>
  )
}

export default ModulePdpHero

import React from "react"
import {Button, Div} from "../../lib/atomize"
import {first} from 'lodash'

export const OptionalItem = (
  {
    item = {},
    addItemToCheckout = () => {
    },
    optionalItemPayload = {},
    lineItems = [],
    subTotal = 0

  }
) => {
  const {
    productVariant = {},
    ctaText = '',
    minCartValue = 0,
    isEnabled = false
  } = item
  const {
    title = '',
    variantTitle = '',
    featuredImage = '',
    price = '',
    _id = '',
    carousel = {}
  } = productVariant || {}

  //console.log('productVariant',productVariant)

  if (minCartValue) {
    optionalItemPayload.isFreebie = true
  }

  let isInCart = false;

  lineItems && lineItems.length && lineItems.map(lineItem => {
    const {node = {}} = lineItem
    const {variantTitle = '', title: defaultTitle = ''} = node
    const title = variantTitle === 'Default' || variantTitle === 'BYOB' ? defaultTitle : variantTitle
    const {variant: payloadVariant = {}} = optionalItemPayload || {}
    const {title: payloadTitle = ''} = payloadVariant || {}

    if (defaultTitle === payloadTitle) {
      isInCart = true
    }
  })

  const {assets = []} = carousel || {}

  return (
    <>
      {!isInCart && isEnabled &&
        <section className="gift-bag" key={_id}>
          <Div
            p={{y: "2.5rem"}}
            m={{r: "1rem"}}
            border={{t: "1px solid"}}
            borderColor="yellowPink"
            d="flex"
          >
            <Div
              cursor="pointer"
              bgImg={featuredImage?.asset?.url || assets && first(assets)?.image?.asset?.url}
              bgPos="center"
              bgSize="cover"
              rounded="10px"
              m={{r: "1.25rem"}}
              h="4rem"
              w="4rem"
            />
            <Div
              d="flex"
              align="center"
            >
              <Button
                onClick={() => {
                  addItemToCheckout(optionalItemPayload)
                }}
                disabled={minCartValue && (subTotal >= minCartValue) ? false : true}
                className="solid"
              >
                {minCartValue && (minCartValue >= subTotal) && `Add more to get a freebie` || ctaText ? ctaText : `Add ${title} for ${price}`}
              </Button>
            </Div>
          </Div>
        </section>
      }
    </>

  )
}

import {get} from "lodash"
import {graphql} from "gatsby"
import React, {useEffect} from "react"
import ls from "local-storage"
import {useMutation, useQuery} from "@apollo/client"

import {SEO} from "../components/common/SEO"
// import useEmergence from "../hooks/useEmergence"
import Layout from "../components/common/Layout"
import {getPageModules} from "../services/pageService"
import GraphQLErrorList from "../components/common/GraphqQLErrorList"
import {saveCheckoutInLocalStorage} from "../services/cartService"
import {CHECKOUT_BY_ID, CHECKOUT_CREATE, TOGGLE_CART,} from "../graphql/queries"
import {PageExtra} from "../components/page/PageExtra"
import Social from "../components/blackfriday/Social"
//import NotSpamModal from "../components/common/organisms/NotSpamModal"


const Page = props => {

  //***************This checkoutData has to be in parent of all the instance of moduleContentWithPropductCards */
  const [toggleCart] = useMutation(TOGGLE_CART, {
    onCompleted: () => {
      if (window && window.rdt){
        window.rdt('track', 'AddToCart', {
            "currency" : "USD",
            "itemCount": 1,
            "value": 100
        });
      }
    },
  })

  const {data: checkoutData, refetch: refechCheckoutData} = useQuery(
    CHECKOUT_BY_ID,
    {
      // skip: !ls("lastCheckoutID"),
      variables: {id: ls("lastCheckoutID")},
      onCompleted: result => {
        const {node = {}} = result
        const {orderStatusUrl = null} = node || {};
        if (orderStatusUrl) {
          ls.remove("lastCheckoutID");
          if (!ls.get('disable_notSpam')) {
            ls.set('disable_notSpam', true);
          }
          if (ls.get('np_code')) {
            ls.remove('np_code');
          }
        }
      }
    }
  )

  const [checkoutCreate] = useMutation(CHECKOUT_CREATE, {
    onCompleted: result => {
      //window.history.pushState("header", "Not Pot", "/#cart")
      toggleCart({variables: {cartDrawerVisible: true}})
      saveCheckoutInLocalStorage(result?.checkoutCreate?.checkout)
      refechCheckoutData({
        // skip: !ls("lastCheckoutID"),
        variables: {id: ls("lastCheckoutID")},
      })
    },
    onError: error => console.error(error)
  })
  //********************************** */

  // useEmergence()
  const {data, errors} = props
  const PagePath = props?.location?.pathname || props.uri;

  const isNotpotBody = process.env.GATSBY_SITE_MODE === 'notpotbody';

  const gorgias = typeof window !== "undefined" && window?.GorgiasChat || '';

  useEffect(() => {
    document.querySelector('body').classList.add("hide-gorgias");
    setTimeout(() => {
      if (gorgias && gorgias.hasOwnProperty("on")) {
        gorgias.setShopifyContext('not-pot.myshopify.com');
        if (gorgias.isBusinessHours() && PagePath) {
          document.querySelector('body').classList.remove("hide-gorgias");
        }
      }
    }, 100);

  }, [PagePath, gorgias])

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors}/>
      </Layout>
    )
  }

  const site = (data || {}).site

  const location = props.location

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  const page = data.page || data.route.page || {}
  const {freebie = {}} = site
  const legalRoutes = get(data, "legalRoutes.edges", [])
  const helpdeskRoutes = get(data, "helpdeskRoutes.edges", [])
  const modules = getPageModules({
    legalRoutes,
    helpdeskRoutes,
    page,
    location,
    checkoutData,
    checkoutCreate,
    freebie
  })
  const {seo = {}, og = {}} = page;
  const pageTitle = data.route && !data.route.useSiteTitle && page.title
  const seoTitle = seo?.seo_title || pageTitle;
  const seoDescription = seo?.meta_description || '';
  const seoKeywords = seo?.focus_synonyms || [];
  const seoFocus = seo?.focus_keyword || "";
  const metaKeywords = [seoFocus].concat(seoKeywords);
  const socialTitle = og?.title || seoTitle;
  const socialDesc = og?.description || seoDescription;
  const notSpamForm = get(site, "notSpamForm", [])
  const pageClass = (PagePath ? PagePath.replace(/\//g, '') : 'home')

  return (
    <Layout
      site={site}
      path={PagePath}
      pageClass={`page-wrapper page-${pageClass}`}
    >
      <SEO
        title={seoTitle}
        description={seoDescription}
        keywords={metaKeywords}
        site={site}
        url={PagePath ? PagePath : ''}
        socialTitle={socialTitle}
        socialDesc={socialDesc}
        headComponents={site.headComponents}
        path={PagePath}
      />
      <div className={PagePath === '/bedtime-story' ? 'lp-single' : ''}>
        {modules}
      </div>
      <PageExtra pagePath={PagePath} notSpamForm={notSpamForm} pageContent={page._rawContent}/>
    </Layout>
  )
}
export default Page

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    route: sanityRoute(id: { eq: $id }) {
      slug {
        current
      }
      useSiteTitle
      page {
        ...PageInfo
      }
    }

    legalRoutes: allSanityRoute(filter: { type: { eq: "legal" } }) {
      edges {
        node {
          slug {
            current
          }
          page {
            title
          }
        }
      }
    }

    helpdeskRoutes: allSanityRoute(filter: { type: { eq: "helpdesk" } }) {
      edges {
        node {
          slug {
            current
          }
          page {
            title
          }
        }
      }
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SiteSettings
    }
  }
`

import React from "react"
import { Div, Text } from "../../lib/atomize"
import { useWindowSize } from "react-use"
import CardHeader from "./CardHeader"
import VideoOrImage from "./molecules/VideoOrImage"

const VideoCard = ({ file, title, icon, caption, mobileFile, mobRatio }) => {
  const { width } = useWindowSize()

  return (
    <>
      <CardHeader heading={title} image={icon?.asset?.url} />
      <Text
        textAlign="center"
        bg="#ffffff"
        textSize="paragraph1"
        p={{ y: "0.5rem" }} //0.625rem
        // m={{ b: "-0.625rem" }}
      >
        {caption}
      </Text>
      <Div rounded={{ b: "lg" }} bg="yellow">
        <VideoOrImage
          file={
            mobileFile
              ? width >= 576
                ? file?.asset?.url
                : mobileFile?.asset?.url
              : file?.asset?.url
          }
          // rounded="lg"
          bgSize="cover"
          bgPos="center"
          mobratio={mobRatio}
        />
      </Div>
    </>
  )
}

export default VideoCard

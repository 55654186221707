import React, { Fragment } from "react"

export default function GetInvite({ show = false, setShow = () => { } }) {

  return (
    <Fragment>
      {show &&
        <div className="get-invite" onClick={setShow}>
          <div className="invite-content">
            <div className="bubble">Get invited</div>
            <img src="https://cdn.sanity.io/images/bkrzj7um/notpotbody/73e6da677b4696bf739a1cc37e905906adb96581-200x200.gif" alt="Get invited" />
          </div>
        </div>
      }
    </Fragment>
  )
}


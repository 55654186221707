import React, { Fragment } from "react"
import { Div, Text, Row, Col, Image } from "../lib/atomize"
import WebsiteContainer from "../components/common/atoms/WebsiteContainer"
import BlueButton from "../components/common/molecules/BlueButton"
import CardHeader from "../components/common/CardHeader"
// import ImageLazyLoad from "../components/common/molecules/ImageLazyLoad"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

export default function ModulePostcard() {
  const handleClick = () => {
    window.open(" https://airtable.com/shrDgiE55nAh7RFbl", "_blank")
  }

  return (
    <Div
      p={{ t: { xs: "6.25rem", lg: "9.375rem" } }}
      data-bg="#EAF1F9"
      className="bgblocks"
      id="potpals"
    >
      <WebsiteContainer>
        <Row align="center">
          <Col size={{ xs: "12", lg: "8" }}>
            {/* <Image
              src="/postcard.png"
              transform={{
                lg: "translateX(-25px)",
                xs: "translateX(0px) scale(1.1)",
              }}
              alt="postcard"
            /> */}
            <Fragment>
              {process.env.GATSBY_SITE_MODE && process.env.GATSBY_SITE_MODE === 'notpotbody' ?
                <LazyLoadImage
                  src="/postcard-body.png"
                  width="100%"
                  effect="blur"
                  alt="postcard"
                  wrapperClassName="postcard-lazy-img"
                />
                :
                <LazyLoadImage
                  src="/postcard.png"
                  width="100%"
                  effect="blur"
                  alt="postcard"
                  wrapperClassName="postcard-lazy-img"
                />
              }
            </Fragment>
          </Col>
          <Col size={{ xs: "12", lg: "4" }}>
            <Div
              rounded="lg"
              border="1px solid"
              borderColor="lightPink"
              shadow="1"
              m={{ b: "40px" }}
            >
              <CardHeader
                image="/dealer-profile.svg"
                heading="Pot Pals"
                padding="0.625rem 0.625rem"
              />
              <Div
                bg="yellow"
                rounded={{ b: "lg" }}
                d="flex"
                flexDir="column"
                p="30px"
                color="blue"
                justify="flex-start"
              >
                <Text
                  textWeight="700"
                  textSize={{ xs: "30px", sm: "display1" }}
                  textTransform="uppercase"
                  fontFamily="secondary"
                  m={{ b: "30px" }}
                  tag="h3"
                >
                  Send a free postcard
                </Text>
                <Text m={{ b: "30px" }}>

                  {`To your ${process.env.GATSBY_SITE_MODE && process.env.GATSBY_SITE_MODE === 'production' ? 'Secret Kush, Best Bud, ' : 'Best Bud'} or Chill AF \n Mom. No (heart)strings attached.`}
                </Text>
                <BlueButton text="Send postcard" onClick={handleClick} />
              </Div>
            </Div>
          </Col>
        </Row>
      </WebsiteContainer>
    </Div>
  )
}

import React from "react"
import { Link } from "gatsby"
import { Div, Text, Anchor, Image } from "../../lib/atomize"
import CardHeader from "../common/CardHeader"

const LegalSidebar = ({ links, location, title, icon }) => {
  return (
    <>
      <Div rounded="lg" border="1px solid" borderColor="yellowPink" shadow="1">
        <CardHeader heading="legal" image={icon?.asset?.url} />
        <Div
          bg="yellow"
          p="1.875rem"
          rounded={{ b: "lg" }}
          d="flex"
          flexDir="column"
        >
          {links.map((link, id) => (
            <Link
              to={`/${link.node.slug.current}`}
              style={{ color: "inherit" }}
            >
              <Text
                m={{ t: id === 0 ? "0" : "1rem" }}
                key={`legal-sidebar-${link.node.slug.current}`}
                textWeight={
                  location.pathname === `/${link.node.slug.current}`
                    ? "bold"
                    : "normal"
                }
                cursor="pointer"
              >
                {link.node.page.title}
              </Text>
            </Link>
          ))}
        </Div>
      </Div>
    </>
  )
}

export default LegalSidebar

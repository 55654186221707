import React, { useState, useEffect } from "react"
import axios from "axios"
import { Div, Text, Row, Col, Button } from "../../lib/atomize"

const reason = [
  "This is too expensive",
  "This was created by accident",
  "I already have more than I need",
  "I no longer use this product",
  "I want a different product or variety",
  "Other reason",
]

const CancelSubscription = ({
  activeSubscription,
  setActivePage,
  setErrors,
  setData,
  data,
  setActiveSubscription,
}) => {
  const [message, setMessage] = useState(null)
  const cancelSubscription = async (id, reason) => {
    setErrors({})
    try {
      let response = await axios.post("/api/subscriptions/cancel", {
        subscriptionID: id,
        reason,
      })
      const subscriptions = data
      var foundIndex = subscriptions.findIndex(
        subscription => subscription.id == response.data.subscription.id
      )
      subscriptions[foundIndex] = response.data.subscription
      setData(subscriptions)
      setMessage("Thank you! We appreciate your feedback")
    } catch (error) {
      setErrors({ [id]: error.response.data })
    }
  }

  return (
    <>
      <Div
        p={{
          y: { xs: "1.875rem", lg: "2.5rem" },
          x: { xs: "1.25rem", lg: "2.5rem" },
        }}
        bg="yellow"
        rounded="lg"
        shadow="4"
      >
        <Text
          textWeight="bold"
          textSize="display1"
          fontFamily="secondary"
          p={{ b: { xs: "1.875rem", lg: "2.5rem" } }}
          style={{ lineHeight: "32px" }}
          textAlign={{ xs: "center", lg: "left" }}
        >
          Cancel subscription
        </Text>
        {message && (
          <>
            <Div
              border={{ t: "1px solid" }}
              borderColor="borderColor"
              p={{ y: "2.5rem" }}
            >
              <Text textColor="success700" m={{ t: "1rem" }}>
                {message}
              </Text>
            </Div>

            <Button
              p="13px 1.25rem"
              bg="transparent"
              border="1px solid"
              borderColor="borderColor"
              fontFamily="secondary"
              style={{ lineHeight: "100%" }}
              rounded="20px"
              textColor="blue"
              m={{ t: "2.5rem" }}
              type="button"
              onClick={() => {
                setActivePage("home")
                setActiveSubscription(null)
              }}
            >
              back
            </Button>
          </>
        )}
        {!message && (
          <Div>
            {/* {[1, 2].map(id => {
            return ( */}
            <Div
              border={{ t: "1px solid" }}
              borderColor="borderColor"
              p={{ y: "2.5rem" }}
            >
              <Text>
                We’re so sad to see you go! Would you mind sharing why you’re
                leaving?
              </Text>
              <Div>
                {reason.map(data => {
                  return (
                    <Div
                      p="10px 0"
                      border="1px solid"
                      borderColor="blue"
                      rounded="20px"
                      m={{ t: "10px" }}
                      maxW="18rem"
                      textAlign="center"
                      cursor="pointer"
                      onClick={() => {
                        cancelSubscription(activeSubscription.id, data)
                      }}
                    >
                      <Text>{data}</Text>
                    </Div>
                  )
                })}
              </Div>
              <Button
                p="13px 1.25rem"
                bg="transparent"
                border="1px solid"
                borderColor="borderColor"
                fontFamily="secondary"
                style={{ lineHeight: "100%" }}
                rounded="20px"
                textColor="blue"
                m={{ t: "2.5rem" }}
                type="button"
                onClick={() => {
                  setActivePage("home")
                  setActiveSubscription(null)
                }}
              >
                back
              </Button>
            </Div>
          </Div>
        )}
      </Div>
    </>
  )
}

export default CancelSubscription

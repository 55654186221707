import React from "react";
import Slider from "react-slick";
import StarRatings from 'react-star-ratings';
import { Image } from "../../lib/atomize";

export const Carousel = ({ mode }) => {

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 4000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        }
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const noun = mode === 'notpotgoogle' ? 'Hemp' : 'CBD';
  const reviews = [
    {
      id: '1',
      name: 'Shirley T.',
      // rating: '5',
      // date: 'April 19, 2020',
      product: `${noun} Glow Oil`,
      link: '/products/cbd-glow-oil',
      title: 'You glow girl',
      content: 'My skin LOVES this! Non greasy and leaves my skin feeling nice. I also put a few drops into my coffee and it doesn’t leave a bitter taste! CBD oil from not pot saved me from a hectic day at work!'
    },
    {
      id: '2',
      name: 'Anna S.',
      // rating: '5',
      // date: 'April 8 2021',
      product: 'CBD Bath Soak',
      link: '/products/cbd-bath-soak/muscle',
      title: 'Do the subscription',
      content: 'Used it once and let’s just say I’m happy I subscribed to it. I highly recommend getting some. Maybe 2!'
    },
    {
      id: '3',
      name: 'Paul T.',
      // rating: '5',
      // date: 'May 22, 2020',
      product: 'CBD Glow Oil',
      link: '/products/cbd-glow-oil',
      title: 'Great!',
      content: 'Wow! I don’t know what to say! This is my first ever cbd product, and I am greatly impressed! It does what is advertised and more! It honestly helped relieve me of my anxiety and worry and such. Too bad five stars is the max!'
    },
    {
      id: '4',
      name: 'Emily M.',
      // rating: '5',
      // date: 'April 23, 2021',
      product: 'CBD Gummies',
      link: '/products/vegan-cbd-gummies/original',
      title: 'So helpful!',
      content: 'These are terrific. I honestly can’t believe how much they help with my anxiety & focus. I don’t feel weird at all, but am just able to stay calm. Highly recommend!'
    },
    {
      id: '5',
      name: 'Edyn J.',
      // rating: '5',
      // date: 'January 29, 2021',
      product: 'CBD Icy Gel',
      link: '/products/icy-gel',
      title: 'Smells so good i could eat it!',
      content: 'This product is absolutely amazing!! I am always on the run (literally and figuratively) so having a great cooling solution to put on sore muscles is AMAZING! And it smells to die for.'
    },
    {
      id: '6',
      name: 'Gaby P.',
      // rating: '5',
      // date: 'April 13, 2021',
      product: 'CBD Gummies',
      link: '/products/vegan-cbd-gummies/original',
      title: 'Great gummies',
      content: 'Love these gummies, I use them to get my sleeping schedule back on track. I fall asleep within 30 mins, don’t wake up during the night and wake up great after my 8hrs.'
    },
    {
      id: '7',
      name: 'Olivia W.',
      // rating: '5',
      // date: 'December 29, 2020',
      product: 'CBD Bath Soak',
      link: '/products/cbd-bath-soak/muscle',
      title: 'Game Changer',
      content: 'After my first bath, I felt amazing. I felt super calm and mellow and fell asleep within ten minutes of my head hitting the pillow. This is now my key to a perfect night’s sleep. I love it so much that I subscribed to receiving it every 15 days it’s that good!'
    }
  ].map((review) => {
    if (mode === 'notpotgoogle') {
      review.link = review.link.replace('cbd', 'hemp')
      review.product = review.product.replace('CBD', 'Hemp')
      review.content = review.content.replace('CBD', 'hemp')
      review.content = review.content.replace('cbd', 'hemp')
    }
    return review
  });

  return (
    <section className="carousel">
      <h2>OUR BEST BUDS</h2>
      <Slider {...settings}>
        {reviews.map((review) => (

          <div className="review" key={review.id}>
            <a href={review.link}>
              <header>
                <h3>{review.name}</h3>
                <StarRatings
                  rating={5}
                  numberOfStars={5}
                  starDimension="12"
                  starSpacing="0"
                  starEmptyColor="#FFFFFF"
                  starRatedColor="#283377"
                  svgIconViewBox="0 0 13 12"
                  svgIconPath="M6.40742 0.208252L8.14357 3.7812L12.0257 4.35415L9.21657 7.1353L9.87972 11.0624L6.40742 9.20825L2.93513 11.0624L3.59828 7.1353L0.78913 4.35415L4.67128 3.7812L6.40742 0.208252Z"
                />
              </header>
              <div className="date">
                <Image
                  src="/check2x.png"
                  h="32px"
                  w="32px"
                />
                <p>{review.product}</p>
              </div>
              <div className="review-content">
                <h4>{review.title}</h4>
                <p>{review.content}</p>
              </div>
            </a>
          </div>
        ))}
      </Slider>
    </section>
  )
}

exports.getSiteMode = () => {
  let mode = ''
  switch (process.env.VERCEL_GIT_COMMIT_REF) {
    case 'feature/notpotbody':
    case 'notpotbody':
    case 'staging-notpotbody':
      mode = 'notpotbody'
      break;
    case 'notpotgoogle':
    case 'staging-notpotgoogle':
      mode = 'notpotgoogle'
      break;
    default:
      mode = 'production'
  }
  return mode
}

import React from 'react';
import Slider from "react-slick";
import {SocialPostSliderItem} from './SocialPostSliderItem';
import {Div, Text} from "../../../lib/atomize"

export const SocialPostSlider = (
  {
    title = 'Not Pot Heads',
    subtitle = 'We love our buds. Show off your stash with #notpot.',
    posts = [],
    slidesToShow = 0
  }
) => {

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow || 4,
    slidesToScroll: 1,
    swipeToSlide: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // centerMode: true,
          swipeToSlide: true,
          arrows: true
        }
      }
    ]
  };

  return (
    <Div
      m={{b: "40px"}}
      className="bgblocks"
      data-bg="#EAF1F9"
    >
      <section className="carousel social">
        <Div textAlign="center">
          <Text
            textSize="display1"
            textTransform="uppercase"
            fontFamily="secondary"
          >
            {title}
          </Text>
          <Div
            textSize={{xs: "header", lg: "title1"}}
            m={{t: "1.25rem", b: "1.25rem"}}
            className="description"
          >
            <p>{subtitle}</p>
          </Div>
        </Div>
        <div className="social-wrapper">
          <Slider {...settings}>
            {posts.map((post, index) => (
              <SocialPostSliderItem
                post={post}
                key={index}
              />
            ))}
          </Slider>
        </div>
      </section>
    </Div>
  );
}

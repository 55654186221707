import React, {Fragment, useEffect, useState} from "react"
import {Col, Div, Row, Text} from "../lib/atomize"
// import posthog from "posthog-js"
import {get, isEmpty} from "lodash"
import ls from "local-storage"
import {useMutation, useQuery} from "@apollo/client"

import ProductCard from "../components/home/ProductCard"
import VariantCard from "../components/home/VariantCard"
import PortableText from "../components/common/PortableText"
import WebsiteContainer from "../components/common/atoms/WebsiteContainer"
import {
  CHECKOUT_LINE_ITEMS_ADD,
  FETCH_CUSTOMER,
  GET_RECHARGE_CHECKOUT,
  TOGGLE_CART,
  UPDATE_RECHARGE_CHECKOUT,
} from "../graphql/queries"
import {addItemToCheckout, addItemToRechargeCheckout,} from "../services/cartService"

export default function ModuleContentWithProductCard(
  {
    title,
    body,
    products = [],
    variants = [],
    bundles = [],
    categories = [],
    isHomepage,
    isPcp,
    checkoutData,
    checkoutCreate,
    index,
    isAddToBag = false,
    disableCTA = false,
    memberSaveTitle = false,
    xsItems = '6',
    lgItems = '4',
    freebie = {}
  }
) {

  const [isFeatureAvailable, setIsFeatureAvailable] = useState(isAddToBag)
  const [toggleCart] = useMutation(TOGGLE_CART, {
    onCompleted: () => {
      if (window && window.rdt){
        window.rdt('track', 'AddToCart', {
            "currency" : "USD",
            "itemCount": 1,
            "value": 100
        });
      }
    },
  })

  const {data: fetchCustomerData, loading: fetchCustomerLoading} = useQuery(
    FETCH_CUSTOMER,
    {
      skip: !ls("accessToken"),
      variables: {accessToken: ls("accessToken")},
    }
  )

  // const { data: checkoutData, refetch: refechCheckoutData } = useQuery(
  //   CHECKOUT_BY_ID,
  //   {
  //     // skip: !ls("lastCheckoutID"),
  //     variables: { id: ls("lastCheckoutID") },
  //   }
  // )

  const {data: rechargeCheckoutData, refetch: refetchRechargeCart} = useQuery(
    GET_RECHARGE_CHECKOUT,
    {
      skip: !ls("lastRechargeCheckoutID"),
    }
  )

  const [updateRechargeCheckout] = useMutation(UPDATE_RECHARGE_CHECKOUT)
  // const [checkoutCreate] = useMutation(CHECKOUT_CREATE, {
  //   onCompleted: result => {
  //     //window.history.pushState("header", "Not Pot", "/#cart")
  //     toggleCart({ variables: { cartDrawerVisible: true } })
  //     saveCheckoutInLocalStorage(result?.checkoutCreate?.checkout)
  //     refechCheckoutData({
  //       // skip: !ls("lastCheckoutID"),
  //       variables: { id: ls("lastCheckoutID") },
  //     })
  //   },
  // })

  const [checkoutLineItemsAdd] = useMutation(CHECKOUT_LINE_ITEMS_ADD, {
    onCompleted: result => {
      toggleCart({variables: {cartDrawerVisible: true}})
    },
  })

  const customer = get(fetchCustomerData, "customer")

  const onClickAddToCartButton = (variant, callback) => {
    if (!isEmpty(variant.freebie)) {
      variant.freebie.mode = 'custom'
    }
    if (
      get(rechargeCheckoutData, "rechargeCheckout.completed_at", undefined) ===
      null
    ) {
      if (variant.bundles && variant.item) {
        addItemToRechargeCheckout({
          variant: variant.bundles,
          bundle: variant.item,
          email: get(customer, "email", undefined),
          quantity: 1,
          frequency: undefined,
          checkout: checkoutData?.node,
          rechargeCheckout: rechargeCheckoutData?.rechargeCheckout,
          refetchCart: refetchRechargeCart,
          updateRechargeCheckout,
          toggleRechargeCart: toggleCart,
          setUpdatingRechargeCheckout: callback,

        })
      } else {
        addItemToRechargeCheckout({
          variant,
          email: get(customer, "email", undefined),
          quantity: 1,
          frequency: undefined,
          checkout: checkoutData?.node,
          rechargeCheckout: rechargeCheckoutData?.rechargeCheckout,
          refetchCart: refetchRechargeCart,
          updateRechargeCheckout,
          toggleRechargeCart: toggleCart,
          setUpdatingRechargeCheckout: callback,
        })
      }
    } else {
      if (variant.bundles && variant.item) {
        addItemToCheckout({
          checkout: checkoutData?.node,
          checkoutCreate,
          checkoutLineItemsAdd,
          variant: variant.bundles,
          bundle: variant.item,
          quantity: 1,
          email: get(customer, "email", undefined),
          freebie: variant.freebie || freebie
        })
      } else {
        addItemToCheckout({
          checkout: checkoutData?.node,
          checkoutCreate,
          checkoutLineItemsAdd,
          variant,
          quantity: 1,
          email: get(customer, "email", undefined),
          freebie: variant.freebie || freebie
        })
      }

      setTimeout(() => callback(false), 2000)
    }
  }

  // const getFlag = () => {
  //   const flag = posthog.isFeatureEnabled("add-to-bag")
  //   setIsFeatureAvailable(flag)
  // }

  useEffect(() => {
    // posthog.init("1v5yjATiVqFL71uaXLBd2A6LHkyrt2GaA-1c7liz9k8", {
    //   persistence: "memory",
    //   loaded: function (posthog) {
    //     posthog.reloadFeatureFlags()
    //     posthog.onFeatureFlags(getFlag)
    //   },
    // })

    // posthog.onFeatureFlags(getFlag)
  }, [])

  const isTitleOrBody = title || body

  const renderContent = () => {
    return (
      <Fragment>
        {variants.map((variant, index) => {
          return (
            <Col
              size={{
                xs: xsItems,
                lg: lgItems,
              }}
              key={`product-card-${variant._id}`}
            >
              <Div
                m={{
                  t: {xs: "1.25rem", lg: "3rem"},
                }}
                className="product-variant-mob"
              >
                <VariantCard
                  variant={variant}
                  index={index}
                  isFeatureAvailable={isFeatureAvailable}
                  onClickAddToCartButton={onClickAddToCartButton}
                  disableCTA={disableCTA}
                  isAddToBag={isAddToBag}
                />
              </Div>
            </Col>
          )
        })}
        {products.map((product, index) => {
          return (
            <Col
              size={{
                xs: xsItems,
                lg: lgItems,
              }}
              key={`product-card-${product._id || index}`}
            >
              <Div
                m={{
                  t: {xs: "1.25rem", lg: "3rem"},
                }}
                className="product-variant-mob"
              >
                <ProductCard
                  product={product}
                  index={index}
                  isFeatureAvailable={isFeatureAvailable}
                  onClickAddToCartButton={onClickAddToCartButton}
                  disableCTA={disableCTA}
                />
              </Div>
            </Col>
          )
        })}
        {bundles.map(((bundle, index) => {
          let price = 0;
          const qtylist = [];
          const variants = [];

          const items = bundle.items.map((item) => {
            const {pax = 1} = item
            price += parseFloat((item?.variant?.price * pax) || 0)
            qtylist.push(item.variant && item.variant.inventory_quantity ? item.variant.inventory_quantity : 0)
            if (item.variant) {
              variants.push(
                {
                  ...item.variant,
                  name: item.name || item.variant.name,
                  carousel: bundle.carousel || []
                }
              )
              return {
                id: item.variant._id,
                name: item.variant.variantTitle,
                desc: item.variant.productDescription,
                image: item.variant?.carousel?.assets[0]?.image?.asset?.url || '',
                price: parseFloat(item?.variant?.price || 0),
                pax: item?.pax || 1
              }
            }
          })
          return (
            <Col
              size={{
                xs: xsItems,
                lg: lgItems,
              }}
              key={bundle._id}
            >
              <Div
                m={{
                  t: {xs: "1.25rem", lg: "3rem"},
                }}
                className="bundles product-variant-mob"
              >
                <ProductCard
                  product={{
                    ...bundle,
                    description: bundle.caption || '',
                    defaultVariant: {
                      ...bundle,
                      price: price.toFixed(2)
                    },
                    originalProductPrice: bundle.original_price,
                    desktopTitle: bundle.name,
                    variants: variants,
                    price
                  }}
                  index={index}
                  isFeatureAvailable={isFeatureAvailable}
                  onClickAddToCartButton={onClickAddToCartButton}
                  disableCTA={disableCTA}
                  bundles={items}
                  type="bundles"
                />
              </Div>
            </Col>
          )

        }))}
        {categories.map((category => {
            const {
              _key: _id,
              slug: fakeSlug = {},
              carousel = {},
              title = '',
              featuredImage = {},
              description = {},
              ctaText = '',
              customLink = ''

            } = category
            const {slug = {}} = fakeSlug
            const variant = {
              name: title,
              carousel,
              featuredImage
            }
            const variants = [{...variant}]
            const fakeProduct = {
              _id,
              desktopTitle: title,
              description,
              featuredImage,
              slug,
              variants
            }
            return (
              <Col
                size={{
                  xs: xsItems,
                  lg: lgItems,
                }}
                key={`product-card-${category._id || index}`}
              >
                <Div
                  m={{
                    t: {xs: "1.25rem", lg: "3rem"},
                  }}
                  className="product-variant-mob categories"
                >
                  <ProductCard
                    product={fakeProduct}
                    index={index}
                    isFeatureAvailable={false}
                    disableCTA={true}
                    type={''}
                    enableButtonLink={!!customLink}
                    ctaText={ctaText}
                    customLink={customLink}
                    disableAnchor={true}
                  />

                </Div>
              </Col>
            )
          }

        ))}      </Fragment>
    )
  }

  return (
    <Div
      // p={{
      //   t: {
      //     xs: isPcp ? "1.25rem" : "6.25rem",
      //     lg: isPcp ? "2.5rem" : "9.375rem",
      //   },
      // }}
      className="bgblocks module-product-card"
      // data-emergence="hidden"
      data-bg={!isHomepage ? (index % 2 === 0 ? "#FDEEFF" : "#FEE3E1") : "#FEE3E1"}
      key={title}
    >
      <WebsiteContainer>
        <Row>
          <Col
            size={{xs: "12", lg: isHomepage ? "12" : "12"}}
            offset={{xs: "0", lg: isHomepage ? "0" : "0"}}
          >
            {isTitleOrBody && (
              <Row
                textAlign="center"
                m={{b: {xs: "1.25rem", lg: "2rem"}}}
                p={{
                  t: {
                    xs: "6.25rem",
                    lg: "9.375rem",
                  },
                }}
                className="module-product-card-header"
              >
                <Col
                  size={{xs: "12", lg: isPcp ? "8" : "12"}}
                  offset={{xs: "0", lg: isPcp ? "2" : "0"}}
                >
                  {title && (
                    <Text
                      textSize="display1"
                      textTransform="uppercase"
                      fontFamily="secondary"
                      tag="h2"
                    >
                      {title}
                    </Text>
                  )}
                  {body && (
                    <Div
                      textSize={{xs: "header", lg: "title1"}}
                      m={{t: "1.25rem"}}
                      className="description"
                    >
                      <PortableText blocks={body}/>
                    </Div>
                  )}
                </Col>
              </Row>
            )}
            <Div textAlign="center">
              <Div>
                {isHomepage || isPcp ? (
                  <Row
                    className={
                      isHomepage
                        ? "product-variant-container-home"
                        : "product-variant-container-pcp-pdp"
                    }
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {renderContent()}

                  </Row>
                ) : (
                  <Row
                    className={
                      isHomepage
                        ? "product-variant-container-home"
                        : "product-variant-container-pcp-pdp s"
                    }
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {renderContent()}
                  </Row>
                )}
              </Div>
            </Div>

          </Col>
        </Row>
      </WebsiteContainer>
    </Div>
  )
}

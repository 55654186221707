import React from "react"
import { Formik } from "formik"
import { capitalize } from "lodash"
import queryString from "query-string"
import { useLocation } from "@reach/router"
import { useMutation } from "@apollo/client"
import { Div, Input, Text, Image, Icon } from "../../lib/atomize"

import {
  onResetPasswordRequestComplete,
  onResetPasswordRequestError,
} from "../../services/authService"
import { CUSTOMER_RESET } from "../../graphql/queries"
import BlueButton from "../common/molecules/BlueButton"
import { resetPasswordValidator } from "../../validators"

export default function ForgotPasswordForm() {
  let formikProps
  const location = useLocation()
  const query = queryString.parse(location.search)

  const [
    customerReset,
    { loading: customerResetLoading, data: customerResetData },
  ] = useMutation(CUSTOMER_RESET, {
    onCompleted: result => {
      onResetPasswordRequestComplete({ response: result, formik: formikProps })
    },
    onError: error => {
      onResetPasswordRequestError({ error, formik: formikProps })
    },
  })

  /**
   * on submit form
   *
   * @param {*} values
   */
  const onSubmit = values => {
    customerReset({
      variables: {
        id: window.btoa(`gid://shopify/Customer/${query.userID}`),
        input: {
          resetToken: query.resetToken,
          password: values.password,
        },
      },
    })
  }

  return (
    <Formik
      initialValues={{
        password: "",
        password_confirmation: "",
      }}
      onSubmit={onSubmit}
      validationSchema={resetPasswordValidator}
    >
      {props => {
        const {
          values,
          status,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          submitForm,
        } = props
        formikProps = props

        return (
          <form onSubmit={handleSubmit}>
            {status && status.successMessage && (
              <Text
                textColor="success700"
                m={{ b: "1rem" }}
                opacity={status.successMessage ? "1" : "0"}
                transition
              >
                {status.successMessage}
              </Text>
            )}
            {status && status.errorMessage && (
              <Text
                textColor="danger700"
                m={{ b: "1rem" }}
                opacity={status.errorMessage ? "1" : "0"}
                transition
              >
                {status.errorMessage}
              </Text>
            )}
            <Div flexDir={{ xs: "column", lg: "row" }}>
              <Div m={{ r: { lg: "0.625rem" } }} flexGrow="1">
                <Input
                  type="password"
                  placeholder="New Password"
                  placeholderTextColor="navy"
                  shadow="2"
                  textColor="navy"
                  h="2.5rem"
                  p="0 2.125rem"
                  name="password"
                  borderColor={
                    touched.password
                      ? values.password
                        ? errors.password
                          ? "red"
                          : "oilGreen"
                        : errors.password
                          ? "red"
                          : "transparent"
                      : "transparent"
                  }
                  prefix={
                    <Image
                      src="/password.svg"
                      w="14px"
                      h="14px"
                      pos="absolute"
                      top="50%"
                      left="0.75rem"
                      transform="translateY(-50%)"
                      alt="Password"
                    />
                  }
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Div>
              <Div
                m={{
                  r: { lg: "0.625rem" },
                  y: { xs: "0.625rem", lg: "0" },
                }}
                flexGrow="1"
              >
                <Text textColor="error" textSize="paragraph" m={{ b: "1rem" }}>
                  {touched.password && capitalize(errors.password)}
                </Text>
              </Div>

              {/* password confirmation */}
              <Div m={{ r: { lg: "0.625rem" } }} flexGrow="1">
                <Input
                  placeholder="Confirm Password"
                  placeholderTextColor="navy"
                  shadow="2"
                  type="password"
                  textColor="navy"
                  h="2.5rem"
                  p="0 2.125rem"
                  name="password_confirmation"
                  borderColor={
                    touched.password_confirmation
                      ? values.password_confirmation
                        ? errors.password_confirmation
                          ? "red"
                          : "oilGreen"
                        : errors.password_confirmation
                          ? "red"
                          : "transparent"
                      : "transparent"
                  }
                  prefix={
                    <Image
                      src="/password.svg"
                      w="14px"
                      h="14px"
                      pos="absolute"
                      top="50%"
                      left="0.75rem"
                      transform="translateY(-50%)"
                      alt="Password"
                    />
                  }
                  value={values.password_confirmation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Div>
              <Div
                m={{
                  r: { lg: "0.625rem" },
                  y: { xs: "0.625rem", lg: "0" },
                }}
                flexGrow="1"
              >
                <Text textColor="error" textSize="paragraph" m={{ b: "1rem" }}>
                  {touched.password_confirmation &&
                    capitalize(errors.password_confirmation)}
                </Text>
              </Div>

              <BlueButton
                text={
                  isSubmitting ? (
                    <Icon name="Loading" color="white" size="16px" />
                  ) : (
                    "Submit"
                  )
                }
                m={{ t: "1.25rem" }}
                padding="0.5rem 1.25rem"
                textSize="header1"
                h="2.5rem"
                w="100%"
              />
            </Div>
          </form>
        )
      }}
    </Formik>
  )
}

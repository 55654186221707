import React, { Fragment, useState } from "react"

export const Collapse = ({ title, desc }) => {
  const [isActive, setIsActive] = useState(false);
  const handleClick = () => setIsActive(!isActive);

  return (
    <Fragment>
      <section className={isActive ? '' : 'collapse'}>
        <header onClick={handleClick}>
          <h4>{title}</h4>
          <span className="actions">
            <img src="/arrow-down.svg" alt="Collapse" />
          </span>
        </header>
        <div className="content">
          {desc && desc.map((line) =>
            <span key={line._key} className="line">
              {line.children && line.children.map((elem) =>
                <span key={elem._key} className={elem.marks}>{elem.text}</span>
              )}
            </span>
          )}
        </div>
      </section>
    </Fragment>
  )
}

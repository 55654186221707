import React, { Fragment, useState } from "react"
import Countdown from 'react-countdown'

const renderer = ({ days, hours, minutes, seconds, completed }) => {
    
  return (
    <div className="countdown">
      <div className="block">
        <span className="value">{days}</span>
        <span className="label">Days</span> 
      </div> :
      <div className="block">
        <span className="value">{hours}</span>
        <span className="label">Hours</span> 
      </div> :
      <div className="block">
        <span className="value">{minutes}</span>
        <span className="label">Minutes</span>
      </div> :
      <div className="block">
        <span className="value">{seconds}</span>
        <span className="label">Seconds</span>
      </div>
    </div>
  )
  
}

export const CountdownTimer = ({date = ''}) => {

  return (
    <div className="countdown-wrapper">
      <h3>4th of july sale ends in</h3>
      <Countdown
        date={date}
        renderer={renderer}
      />
    </div>
  )
}

import React from "react"
import { Div, Text, Anchor } from "../../lib/atomize"
import { useWindowSize } from "react-use"

import CardHeader from "../common/CardHeader"

const DealerContact = ({ dealerName, socialLinks }) => {
  const { width } = useWindowSize()
  const links = [
    {
      text: "Instagram",
      cta: 'Follow',
      url: socialLinks.instagram,
      img: "/instaIcon.svg",
    },
    {
      text: "Twitter",
      cta: 'Follow',
      url: socialLinks.twitter,
      img: "/twitterIcon.svg",
    },
    {
      text: "TikTok",
      cta: 'Follow',
      url: socialLinks.tiktok,
      img: "/tiktokIcon.svg",
    },
    {
      text: "Youtube",
      cta: 'Subscribe',
      url: socialLinks.youtube,
      img: "/youtubeIcon.svg",
    },
    {
      text: "Twitch",
      cta: 'Stream',
      url: socialLinks.twitch,
      img: "/twitchIcon.svg",
    },
  ]

  const shouldShowContact = () => {
    const keys = Object.keys(socialLinks)
    return keys.some(key => socialLinks[key] !== null)
  }

  if (!shouldShowContact()) {
    return null
  }

  return (
    <Div
      rounded="lg"
      border="1px solid"
      borderColor="lightPink"
      shadow="1"
      m={{ b: "40px" }}
    >
      <CardHeader
        image="/dealer-contact.svg"
        heading="Let’s be buds"
        padding="0.625rem 0.625rem"
      />
      <Div
        bg="yellow"
        rounded={{ b: "lg" }}
        d="grid"
        flexDir="column"
        p="30px"
        style={{
          gridTemplateColumns: "1fr 1fr",
          gridGap: "20px",
        }}
        className="dealer-contact-container"
      >
        {links.map(
          (link, index) =>
            link.url && (
              <Anchor
                key={index}
                d="flex"
                align="center"
                href={link.url}
                target="_blank"
              >
                <img
                  src={link.img}
                  style={{ display: "inline-block", marginRight: 10 }}
                  alt="Icon"
                  width={30}
                />
                <Text textDecor="underline" style={{ color: "#283377" }}>
                  {link.cta} on
                  {width < 477 && <br />} {link.text}
                </Text>
              </Anchor>
            )
        )}
      </Div>
    </Div>
  )
}

export default DealerContact

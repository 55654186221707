import React, {useEffect} from 'react';
import Layout from "../../components/common/Layout";
import Helmet from "react-helmet";
import WebsiteContainer from "../../components/common/atoms/WebsiteContainer";
import {Col, Div, Row} from "../../lib/atomize";
import VideoCard from "../../components/common/VideoCard";
import {graphql} from "gatsby";

const Referrals = ({data}, props) => {
  const {site} = data
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.conjured.co/shopify/referral/widget.js?shop=not-pot.myshopify.com&cmp=4739";
    script.async = true;
    document.body.appendChild(script);

  }, [])
  const PagePath = props?.location?.pathname || props.uri;
  const pageClass = (PagePath ? PagePath.replace(/\//g, '') : 'home')

  return (
    <Layout site={site} path={PagePath} pageClass={`page-wrapper page-${pageClass}`}>
      <Helmet>
        <title>Refer-A-Bud</title>
      </Helmet>
      <WebsiteContainer>
        <Div>
          <Row m={{t: {lg: '5rem', xs: '3rem'}, b: {lg: '10rem'}}} align="center">
            <Col size={{xs: "12", lg: "4px"}}>
              <Div m={{b: {xs: "2.5rem", lg: "0"}}} maxW={{lg: '88%'}}>
                <div id="conjured_referral"/>
              </Div>
            </Col>
            <Col size={{xs: "12", lg: "8"}}>
              <Div
                shadow="1"
                m={{t: {xs: "2.5rem", lg: "0"}, l: {lg: '6rem'}}}
                border="1px solid"
                borderColor="yellowPink"
                rounded="lg"
              >
                <VideoCard
                  mobileFile={{asset: {url: '/refer_image_mobile.png'}}}
                  file={{asset: {url: '/refer_image.png'}}}
                  icon={{asset: {url: '/disk.svg'}}}
                  title='chilltime'
                  caption='green_team.jpg'
                  mobRatio='1:1'
                />
              </Div>
            </Col>
          </Row>
        </Div>
      </WebsiteContainer>
    </Layout>
  )
}

export default Referrals;

export const query = graphql`
  query Refer {
    page: sanityPage(_id: { regex: "/(drafts.|)frontpage/" }) {
      ...PageInfo
    }

    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SiteSettings
    }
  }
`

import React from "react"
import { Div, Text, Row, Col } from "../../lib/atomize"
import CardHeader from "../common/CardHeader"
import TopPickCard from "./TopPickCard"

const TopPicks = ({ header, affiliatedProducts, discountCoupon = '' }) => (
  <Div
    rounded="lg"
    border="1px solid"
    borderColor="lightPink"
    shadow="1"
    m={{ b: "40px" }}
    className={affiliatedProducts.length === 1 ? "top-picks-single" : ""}
  >
    <CardHeader
      image="/dealer-shop.svg"
      heading="Shop with me"
      padding="0.625rem 0.625rem"
    />
    <Div
      bg="yellow"
      rounded={{ b: "lg" }}
      d="flex"
      flexDir="column"
      p="30px"
      align="center"
    >
      <Row
        textAlign="center"
        m={{ b: "30px" }}
        p={{ b: "30px" }}
        border={{ b: "1px solid" }}
        borderColor="yellowPink"
      >
        <Col size="12">
          <Text
            textSize="header1"
            textTransform="uppercase"
            fontFamily="secondary"
          >
            GET 20% OFF YOUR ORDERS
          </Text>

          <Div textSize="body" m={{ t: "10px" }}>
            {/* <PortableText blocks={body} /> */}
            <Text
              style={{
                lineHeight: "24px",
              }}
            >
              Shop my favorite products below, plus get 20% off all your orders. {discountCoupon &&
                <span className="code-wrapper">Use my code <span className="dealer-code">{discountCoupon}</span> at checkout.</span>
              }
            </Text>
          </Div>
        </Col>
      </Row>
      <Text
        m={{ b: "30px" }}
        textSize="header1"
        fontFamily="secondary"
        textTransform="uppercase"
      >
        {header}
      </Text>
      {!!affiliatedProducts.length && (
        <Div
          className="top-picks-container"
          m={{ b: "30px" }}
          p={{ b: "30px" }}
          border={{ b: "1px solid" }}
          borderColor="yellowPink"
        >
          {affiliatedProducts.map((item, index) => (
            <TopPickCard key={index} affiliatedProduct={item} id={index} />
          ))}
        </Div>
      )}
      <Div d="flex" align="center" className="ps">
        <Div h="20px" minW="20px" m={{ r: "10px" }}>
          <img src="/smiley.svg" alt="smiley" />
        </Div>
        <Text>
          PS, I make a small commission on orders placed through my page.
        </Text>
      </Div>
    </Div>
  </Div>
)

export default TopPicks

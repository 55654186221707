import React, { Fragment, useState } from "react"

export const CollapseItem = ({ faq }) => {
  const [isActive, setIsActive] = useState(false);
  const handleClick = () => setIsActive(!isActive);

  return (
    <Fragment>
      <section className={isActive ? '' : 'collapse'} key={faq.id}>
        <header onClick={handleClick}>
          <h4>{faq.title}</h4>
          <span className="actions">
            <img src="/arrow-down.svg" alt="Collapse" />
          </span>
        </header>
        <div className="content">
          {faq.desc}
        </div>
      </section>
    </Fragment>
  )
}

import React, {createContext, useReducer} from "react";
import combineReducers from 'react-combine-reducers';
import {freebieContext, trackingContext, utmContext} from "../reducers";

const [appReducer, initialStates] = combineReducers({
  tracking: [trackingContext.reducer, trackingContext.initialState],
  utmParams: [utmContext.reducer, utmContext.initialState],
  freebie: [freebieContext.reducer, freebieContext.initialState]
});


export const AppContext = createContext();

export const AppContextProvider = (props, {value}) => {
  const [state, dispatch] = useReducer(appReducer, initialStates);
  return (
    <AppContext.Provider value={value ? [...value] : [state, dispatch]}>
      {props.children}
    </AppContext.Provider>
  )
}

import React, {useEffect, useState} from 'react';
import Layout from "../../components/common/Layout";
import Helmet from "react-helmet";
import WebsiteContainer from "../../components/common/atoms/WebsiteContainer";
import {LazyLoadImage} from "react-lazy-load-image-component"
import {Col, Div, Icon, Input, Row, Text, Image} from "../../lib/atomize";
import CardHeader from "../../components/common/CardHeader"
import BlueButton from "../../components/common/molecules/BlueButton"
import {graphql} from "gatsby";
import {Formik} from "formik"
import * as yup from "yup"
import _ from "lodash";
import Social from '../../components/wholesale/Social';
import SideDrawer from "../../components/common/atoms/SideDrawer"
import FormModal from '../../components/wholesale/FormModal';
import ProductCarousel from "../../components/pdp/ProductCarousel"
import { CountdownTimer } from '../../components/common/CountdownTimer'

const WholesaleApp = ({data}, props) => {
  const {site} = data

  const PagePath = props?.location?.pathname || props.uri;
  const pageClass = (PagePath ? PagePath.replace(/\//g, '') : 'home')

  const [modal, showModal] = useState(false)

  const openFormModal = () => {
    showModal(true)
    document.body.classList.add('noscroll')
  }

  const closeFormModal = () => {
    showModal(false)
    document.body.classList.remove('noscroll')
  }

  const [swiper, setSwiper] = useState(null)
  const [selectedSlide, setSelectedSlide] = useState(0)
  /* toggle Swiper slide */

  const toggleSelectedSlide = index => {
    setSelectedSlide(index)
    swiper && swiper.swiper.update()
    if (swiper && swiper.swiper.activeIndex !== index) {
      swiper.swiper.slideTo(index)
    }
  }
  /* render swiper instance */
  const onSwiperInit = swiper => {
    setSwiper({swiper})

    // change selected on slide change
    swiper &&
    swiper.on("slideChange", () => {
      toggleSelectedSlide(swiper.activeIndex)
    })

    // remove event on destroy
    swiper &&
    swiper.on("beforeDestroy", () => {
      swiper.detachEvents()
    })
  }

  const features = [
    {
      id: 1,
      icon: 'https://cdn.sanity.io/images/bkrzj7um/production/e07bd641c8963042cad97662b055454ca67b4595-151x151.png',
      title: 'High Sell-through Products',
      desc: 'When your business thrives, we thrive. That’s why we offer our partners exclusive discounted prices on our cult-favorite products.'
    },
    {
      id: 2,
      icon: 'https://cdn.sanity.io/images/bkrzj7um/production/d7b2ce5db1e4dc354823d7498bd69c90546113f2-80x80.svg',
      title: 'Exclusive Early Access',
      desc: 'We give our stockists special treatment, of course! You’ll always be up to date about new product launches and get early access, too!'
    },
    {
      id: 3,
      icon: 'https://cdn.sanity.io/images/bkrzj7um/production/9ddb34460609320049834cbc97c9dc12867d47ad-80x80.svg',
      title: 'Free Plants and People',
      desc: 'We’re grateful to work with incredible partners in an effort to destigmatize cannabis and support criminal justice reform on a national scale since 2017.'
    },
  ]

  return (
    <Layout site={site} path={PagePath} pageClass="page-wrapper">
      <Helmet>
        <title>Wholesale Application</title>
      </Helmet>
        <Div 
          className="bgblocks"
          data-bg="#FDEEFF"
        >
          <WebsiteContainer>
            <div className="wholesale-app">
              <Row m={{t: {lg: '5rem', xs: '3rem'}, b: {lg: '10rem'}}} align="center">
                <Col size={{xs: "12", lg: "4"}}>
                  <Div m={{b: {xs: "2.5rem", lg: "0"}, t: {lg: '1rem'}}} maxW={{lg: '88%'}} className="hero-description">
                    <h2>
                      BECOME A WHOLESALE PARTNER
                    </h2>
                    <Text m={{t: "0.625rem"}}>
                    Interested in making a bulk purchase? Retailers and qualified institutions can purchase all our products at special wholesale prices! Just click below to tell us about your business.
                    </Text>
                    <BlueButton
                      m={{xs: "2rem auto 0", md: "2rem 0 0"}}
                      text='Apply'
                      padding="0.5rem 1.25rem"
                      textSize="header1"
                      h="3rem"
                      w="100%"
                      maxW="254px"
                      onClick={openFormModal}
                    />
                    <p className="login">Already a member? <a href="https://not-pot-wholesale.myshopify.com/">Login</a></p>

                  </Div>
                </Col>
                <Col size={{xs: "12", lg: "8"}}>
                  <Div
                    shadow="1"
                    m={{t: {xs: "2.5rem", lg: "0"}, l: {lg: '6rem'}}}
                    border="1px solid"
                    borderColor="yellowPink"
                    rounded="lg"
                  >
                    <>

                      <ProductCarousel
                        selectedSlide={selectedSlide}
                        toggleSelectedSlide={toggleSelectedSlide}
                        onSwiperInit={onSwiperInit}
                        selectedVariant={
                          {
                            "carousel": {
                                "title": "CHILLTIME",
                                "icon": {
                                    "asset": {
                                        "url": "https://cdn.sanity.io/images/bkrzj7um/production/d68b46bc2101dad7d89c4923e2494ae87df398b1-17x16.svg"
                                    }
                                },
                                "assets": [
                                    {
                                      "video": null,
                                      "image": {
                                          "asset": {
                                              "url": "/wholesale/assets/sleep-soak.jpg"
                                          }
                                      },
                                      "caption": "bubbly_aesthetic.jpg",
                                      "alt": "bubbly_aesthetic.jpg"
                                    },
                                    {
                                      "video": null,
                                      "image": {
                                          "asset": {
                                              "url": "/wholesale/assets/banana-creme.png"
                                          }
                                      },
                                      "caption": "this_is_bananas.jpg",
                                      "alt": "this_is_bananas.jpg"
                                    },
                                    {
                                      "video": null,
                                      "image": {
                                          "asset": {
                                              "url": "/wholesale/assets/icy-gel.jpg"
                                          }
                                      },
                                      "caption": "tip_of_the_iceberg.jpg",
                                      "alt": "tip_of_the_iceberg.jpg"
                                    },
                                    {
                                      "video": null,
                                      "image": {
                                          "asset": {
                                              "url": "/wholesale/assets/oat-puff.jpg"
                                          }
                                      },
                                      "caption": "puff_puff_soothe.jpg",
                                      "alt": "puff_puff_soothe.jpg"
                                    },
                                    {
                                      "video": null,
                                      "image": {
                                          "asset": {
                                              "url": "/wholesale/assets/glow-oil.jpg"
                                          }
                                      },
                                      "caption": "we_glow_together.jpg",
                                      "alt": "we_glow_together.jpg"
                                    },
                                    {
                                      "video": null,
                                      "image": {
                                          "asset": {
                                              "url": "/wholesale/assets/pet-oil.jpg"
                                          }
                                      },
                                      "caption": "leader_of_the_pack.jpg",
                                      "alt": "leader_of_the_pack.jpg"
                                    },
                                    {
                                      "video": null,
                                      "image": {
                                          "asset": {
                                              "url": "/wholesale/assets/sleep-gummies.jpg"
                                          }
                                      },
                                      "caption": "sweet_blueberry_dreams.jpg",
                                      "alt": "sweet_blueberry_dreams.jpg"
                                    },
                                ]
                            }
                          }
                        }
                      />
                    </>
                  </Div>
                </Col>
              </Row>

              <Div 
                className="bgblocks"
                data-bg="#EAF1F9"
              >
                <div className="features">
                  <Div textAlign="center">
                    <Text
                        textSize="display1"
                        textTransform="uppercase"
                        fontFamily="secondary"
                        tag="h3"
                      >
                      WHY PARTNER WITH US?
                    </Text>
                    <Div
                      m={{ t: "1.25rem", x: { xs: "0", lg: "2rem" } }}
                      textSize={{ xs: "header", lg: "title1" }}
                      m={{ t: "1.25rem" }}
                      className="description"
                    >
                      We've created a new approach to cannabis—one that's safe, effective, and serious fun. Like it should be.
                    </Div>
                  </Div>

                  <Row m={{t: {lg: '5rem', xs: '3rem'}}}>
                    {
                      features.map((feature, id) =>
                        <Col size={{xs: "12", lg: "4"}}
                          key={`featuresList-${id}`}>
                          <Div d="flex" className="feature" m={{b: "1.5rem"}}>
                            <Div style={{minWidth: "80px", height: "80px"}} m={{r: "1.25rem"}} className="feature-image">
                              <LazyLoadImage src={feature.icon} width="100%" effect="blur" alt="Icon"/>
                            </Div>
                            <Div textAlign="left" className="feature-text">
                              <Text
                                textWeight="bold"
                                textSize="header"
                                style={{lineHeight: "16px"}}
                                tag="h3"
                              >
                                {feature.title}
                              </Text>
                              <Text m={{t: "0.625rem"}}>
                                {feature.desc}
                              </Text>
                            </Div>
                          </Div>
                        </Col>
                      )
                    }
                  </Row>
                </div>
              </Div>

              <Social />

              <Div className="cta">
                <Text
                    textSize="display1"
                    textTransform="uppercase"
                    fontFamily="secondary"
                    tag="h3"
                  >
                  still have questions?
                </Text>
                <a href="mailto:wholesale@notpot.co">
                <BlueButton
                  m={{t: "2rem"}}
                  text='Email Us'
                  padding="0.5rem 1.25rem"
                  textSize="header1"
                  h="3rem"
                  w="100%"
                  maxW="254px"
                />
                </a>
              </Div>
            </div>

            <FormModal
              isOpen={modal}
              onClose={closeFormModal}
            />
          </WebsiteContainer>
        </Div>
    </Layout>
  )
}

export default WholesaleApp;

export const query = graphql`
  query WholesaleApp {
    page: sanityPage(_id: { regex: "/(drafts.|)frontpage/" }) {
      ...PageInfo
    }

    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SiteSettings
    }
  }
`

const bodyBranches = ['feature/notpotbody', 'notpotbody'];
const siteConfiguration = require('./siteConfiguration')

const dataset = (siteConfiguration.getSiteMode(process.env.VERCEL_GIT_COMMIT_REF));
module.exports = {
  sanity: {
    projectId: process.env.GATSBY_SANITY_PROJECT_ID || "bkrzj7um",
    dataset: dataset,
  },
}

import React, { useState } from "react"
import { Col, Div, Row, Text } from "../lib/atomize"
import WebsiteContainer from "../components/common/atoms/WebsiteContainer"
import ProductTestimonials from "../components/pdp/ProductTestimonials"
import Slider from "react-slick"

export default function ModuleContentTestimonials({
  testimonial = {},
  xsItems = '12',
  lgItems = '4'
}) {
  const { title = '', subtitle = '', testimonials = [] } = testimonial

  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    draggable: false,
  };

  return (
    <Div
      className="bgblocks module-product-card"
    >
      <WebsiteContainer>
        <Row>
          <Col
            size={{ xs: "12", lg: "12" }}
            offset={{ xs: "0" }}
          >
            <Row
              textAlign="center"
              m={{ b: { xs: "1.25rem", lg: "2rem" } }}
              p={{
                t: {
                  xs: "6.25rem",
                  lg: "9.375rem",
                },
              }}
              className="module-product-card-header"
            >
              <Col
                size={{ xs: "12" }}
                offset={{ xs: "0" }}
              >

                <Text
                  textSize="display1"
                  textTransform="uppercase"
                  fontFamily="secondary"
                  tag="h2"
                >
                  {title}
                </Text>

                {subtitle &&
                  <Div
                    textSize={{ xs: "header", lg: "title1" }}
                    m={{ t: "1.25rem" }}
                    className="description"
                  >
                    <p>{subtitle}</p>
                  </Div>
                }

              </Col>
            </Row>

            <Div textAlign="center">

              {/* Testimonial Desktop */}
              <Div d={{ xs: "none", md: "flex" }}>
                <Row
                  className="pdp-testimonials"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {testimonials.map(quote =>

                    <Col
                      size={{
                        xs: xsItems,
                        lg: lgItems,
                      }}
                    >

                      <Div
                        m={{
                          t: { xs: "1.25rem", lg: "3rem" },
                        }}
                      >

                        <ProductTestimonials quote={quote} />
                      </Div>
                    </Col>
                  )}
                </Row>
              </Div>

              {/* Testimonial Mobile */}
              <Div d={{ xs: "block", md: "none" }}>
                <Row
                  className="pdp-testimonials"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Slider {...settings}>
                    {testimonials.map(quote =>

                      <Col
                        size={{
                          xs: xsItems,
                          lg: lgItems,
                        }}
                      >

                        <Div
                          m={{
                            t: { xs: "1.25rem", lg: "3rem" },
                          }}
                        >
                          <ProductTestimonials quote={quote} />
                        </Div>
                      </Col>

                    )}
                  </Slider>
                </Row>
              </Div>

            </Div>
          </Col>
        </Row>
      </WebsiteContainer>
    </Div >
  )
}

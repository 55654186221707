import React from 'react';
import Slider from "react-slick";
import {CallOutSliderItem} from './CallOutSliderItem';

export const CallOutSlider = (
  {
    alerts = []
  }
) => {

  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <section className="callout-slider">
      <div className="callout-wrapper">
        <Slider {...settings}>
          {alerts.map((alert, index) => (
            <CallOutSliderItem
              alert={alert}
              key={index}
            />
          ))}
        </Slider>
      </div>
    </section>

  );
}

import React, {useEffect, useState, Fragment, forwardRef, useImperativeHandle} from "react"
import {Transition} from "react-transition-group"
import moment from "moment"
import * as yup from "yup"
import axios from "axios"
import FooterForm from "./FooterForm";
import {Div} from "../../../lib/atomize"
import {add} from 'date-fns'
import ls from "local-storage"
import GetInvite from './GetInvite'

// import {
//   disableBodyScroll,
//   enableBodyScroll,
//   clearAllBodyScrollLocks,
// } from "body-scroll-lock"

export const NotSpamModal = forwardRef((
  {
    notSpamForm,
    setEnableToggle = () => {}
  }, ref) => {
  const isNotpotBody = process.env.GATSBY_SITE_MODE === 'notpotbody';
  const [emailPopup, setEmailPopup] = useState(false)
  const {displayTime = 10, days = 0, hours = 0, minutes = 0, secs = 0} = notSpamForm
  const [endDate, setEndDate] = useState(add(new Date(), {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: secs,
  }))

  useEffect(() => {

    if (
      !ls.get('disable_notSpam') &&
      (!ls.get("emailPopupClosed") ||
        new Date() > new Date(ls.get("emailPopupClosed")))
    ) {
      setTimeout(() => {
        setEmailPopup(true)
      }, (displayTime || 10) * 1000)
    }
    return () => {
    }
  }, [])


  const duration = 300

  const defaultStyle = {
    opacity: "0",
    transition: "opacity 0.2s ease-in-out",
  }

  const transitionStyles = {
    entering: {opacity: "0"},
    entered: {opacity: "1"},
    exiting: {opacity: "0"},
    exited: {opacity: "0"},
  }

  let formikProps

  const initialValues = {
    phone: "",
    email: "",
  }

  const onSubmit = values => {
    const {setSubmitting, setStatus, resetForm} = formikProps
    const klaviyoPromise = axios.post(`/api/klaviyo`, {
      email: values.email,
      phone: "+1" + values.phone,
    })

    const postscriptPromise = axios.post(`/api/postscript`, {
      phone: values.phone,
    })

    Promise.all([klaviyoPromise, postscriptPromise])
      .then(responses => {
        setSubmitting(false)
        setInterval(() => {
          setEmailPopup(false)
        }, 2000)
        resetForm(initialValues)
        setStatus({
          successMessage:
            "Thanks for signing up — check your email!",
          errorMessage: "",
        })
      })
      .catch(error => {
        setSubmitting(false)
        setStatus({
          errorMessage: "Something went wrong. Please try again later.",
          successMessage: "",
        })
      })
  }

  const validator = yup.object().shape({
    email: yup
      .string()
      .required("Please use a valid email address.")
      .min(1)
      .email("Please use a valid email address."),
    phone: yup.string().required("Please use a valid phone number.").min(10),
  })

  useImperativeHandle(
    ref, () => ({
      toggle () {
        setEmailPopup(true)
      }
    })
  )
  return (
    <Fragment>
      <Transition
        in={emailPopup}
        timeout={duration}
        // onExiting={() => {
        //   enableBodyScroll()
        // }}
        // onExited={clearAllBodyScrollLocks}
        unmountOnExit
      >
        {state => (
          <Div
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
            pos="fixed"
            bottom="4rem"
            left="0"
            right="0"
            zIndex="100"
            className={`notspam-modal ${notSpamForm?.layout ? notSpamForm?.layout : ''}`}
          >
            <div
              className="modal-mask"
              onClick={() => {
                setEmailPopup(false)
                ls.set("emailPopupClosed", new Date(endDate))
              }
              }
            />
            <Div d='flex' flexDir='column'>
              {/* <Text d={{ xs: 'none', lg: 'block' }}>{notSpamForm.body}</Text> */}
              <FooterForm
                notSpamForm={
                  {
                    ...notSpamForm,
                    noPhone: true,
                    footer: '',
                    renderTitle: notSpamForm?.title ? true : false,
                    buttonText: notSpamForm?.enableToggle ? 'Get The Invite' : 'Subscribe',
                    extra:
                      notSpamForm?.enableToggle ?
                        <div className="extra">
                          <p><span>For: </span>You ♡</p>
                          <p><span>When: </span>July 3-5</p>
                        </div> : ''
                  }
                }
                close={true}
                closeModal={() => {
                  setEnableToggle(false)
                  setEmailPopup(false)
                  localStorage.setItem("emailPopupClosed", new Date(endDate))
                }}
                sideImage={true}
              />
            </Div>
          </Div>
        )}
      </Transition>

      {notSpamForm?.enableToggle && <GetInvite show={!emailPopup} setShow={() => setEmailPopup(true)} />}
    </Fragment>
  )
})

import React, { Fragment, useState } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"

export const Card = (
  { 
    handleCardClick = () => {},
    customClass = '',
    imgSrc = '/card.png'
  }) => {
  
  return (
    <div className={`card ${customClass}`} onClick={handleCardClick}>
      <LazyLoadImage
        src={imgSrc}
        width="100%"
        effect="blur"
      />
    </div>
  )
}

import React, {Fragment, useEffect} from 'react'
import {Subscription} from './Subscription'
import {SlickCarousel} from "../common/SlickCarousel";
import FooterForm from "../common/organisms/FooterForm";
import {BirthdaySale} from "./BirthdaySale";

export const PageExtra = ({pagePath, notSpamForm, pageContent}) => {
  return (
    <Fragment>
      {
        {
          '/mothers-day': <SlickCarousel/>,
          '/bedtime-story': <FooterForm notSpamForm={{
            ...notSpamForm,
            noPhone: true,
            footer: 'Subscribe to unlock this magical product and get $4.20 off!'
          }} customClass='lp-single-form' customList="WTjt9p"/>,
          '/birthday-sale': <BirthdaySale notSpamForm={{...notSpamForm}} pageContent={pageContent}/>,
          '/membership': <Subscription pageContent={pageContent}/>,
        }[pagePath]
      }
    </Fragment>
  )
}

import React from "react"
import { Text, Div, Modal, Image, Row, Col } from "../../../lib/atomize"
import ImageLazyLoad from '../../common/molecules/ImageLazyLoad'
import CardHeader from "../CardHeader"

const TestimonialsModal = ({ isOpen, onClose, image = {}, testimonial = '', product = '', handle = '' }) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        align="center"
        p="0"
        shadow="4"
        rounded="lg"
        className="testimonial-modal"
      >
        <Div rounded={{ b: "lg" }}>
          <CardHeader heading={product} close={true} closeModal={onClose} />
          <Div bg="yellow" d="flex" flexDir="column" rounded={{ b: "lg" }}>
            <Div
              p="0.5rem 1.25rem"
              bg="#ffffff"
              className="card-item-sub-header"
            >
              <p>{handle}</p>
            </Div>
            <Div
              bg="gray"
              p="1.875rem"
              className="card-item-img-container"
              rounded={{ b: "lg" }}
              pos="relative"
            >
              <Row
                className="pdp-testimonials"
                align="center"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Col
                  size={{
                    xs: 12,
                    lg: 6,
                  }}
                  className="user-img-container"
                >
                  <ImageLazyLoad
                    bgImg={image}
                    bgPos="center"
                    bgSize="cover"
                    p={{ b: "100%" }}
                    transition
                  >
                    <img
                      src={image}
                      alt={handle}
                      className="product-img"
                    />
                  </ImageLazyLoad>
                </Col>
                <Col
                  size={{
                    xs: 12,
                    lg: 6,
                  }}
                >
                  <p className="modal-body-text">
                    {testimonial}
                  </p>
                </Col>
              </Row>
            </Div>
          </Div>
        </Div>
      </Modal>
    </>
  )
}

export default TestimonialsModal

import React, { Fragment, useState } from "react"
import FooterForm from "../common/organisms/FooterForm"


export const SignUpForm = ({
    
}) =>{

	return (
		<>
		<FooterForm
			notSpamForm={
				{
					header: 'Not Spam',
					icon: {
						asset: {
							url: 'https://cdn.sanity.io/images/bkrzj7um/production/f1416742c432429b90e155f2d26af00c6cf4989d-17x16.svg'
						}
					},
					noPhone: true,
					title: 'SIGN UP FOR OUR EXCLUSIVE BLACK FRIDAY LIST',
					footer: '',
					renderTitle: 'Not Spam',
					buttonText: 'Get Half Off!',
				}
			}
			customClass="bf-signup-form"
			customList="WEgGRg"
		/>
		</>
	)
}
import React from "react"

import { SocialPostSlider } from "../common/organisms/SocialPostSlider"

export default function Social() {

  return (
    <SocialPostSlider
      posts={[
        {
          _key: '1',
          image: {asset: {url: '/social-post/christinecooper_08.jpeg'}},
          avatar: {asset: {url: '/social-post/thumb/christinecooper_08.jpg'}},
          name: 'Christine Cooper',
          handle: '@christinecooper_08'
        },
        {
          _key: '2',
          image: {asset: {url: '/social-post/george_the_shihpoo.jpeg'}},
          avatar: {asset: {url: '/social-post/thumb/george_the_shihpoo.jpg'}},
          name: 'George',
          handle: '@george_the_shihpoo'
        },
        {
          _key: '3',
          image: {asset: {url: '/social-post/ashlynnovember.jpg'}},
          avatar: {asset: {url: '/social-post/thumb/ashlynnovember.jpg'}},
          name: 'Ashlyn Knox',
          handle: '@ashlynnovember'
        },
        {
          _key: '4',
          image: {asset: {url: '/social-post/lifeofagoddess_.jpeg'}},
          avatar: {asset: {url: '/social-post/thumb/lifeofagoddess_.jpg'}},
          name: 'Julnequwa Williams',
          handle: '@lifeofagoddess_'
        },
        {
          _key: '5',
          image: {asset: {url: '/social-post/kristine.chao.jpg'}},
          avatar: {asset: {url: '/social-post/thumb/kristine.chao.jpg'}},
          name: 'Kristine Chao',
          handle: '@kristine.chao'
        },
        {
          _key: '6',
          image: {asset: {url: '/social-post/knottycol.jpg'}},
          avatar: {asset: {url: '/social-post/thumb/knottycol.jpg'}},
          name: 'Colleen Baltovski',
          handle: '@knottycol'
        },
        {
          _key: '7',

          image: {asset: {url: '/social-post/kelly.armetta.jpg'}},
          avatar: {asset: {url: '/social-post/thumb/kelly.armetta.jpg'}},

          name: 'Kelly Armetta',
          handle: '@kelly.armetta'
        },
        {
          _key: '8',
          image: {asset: {url: '/social-post/meggle.jpg'}},
          avatar: {asset: {url: '/social-post/thumb/meggle.jpg'}},
          name: 'Meg Bourgeois',
          handle: '@meggle'
        },
        {
          _key: '9',
          image: {asset: {url: '/social-post/mikkibish.jpg'}},
          avatar: {asset: {url: '/social-post/thumb/mikkibish.jpg'}},

          name: 'Mikayla Bishop',
          handle: '@mikkibish'
        },
        {
          _key: '10',
          image: {asset: {url: '/social-post/wildwildwyatt.jpg'}},
          avatar: {asset: {url: '/social-post/thumb/wildwildwyatt.jpg'}},

          name: 'Wyatt',
          handle: '@wildwildwyatt'
        },
        {
          _key: '11',
          image: {asset: {url: '/social-post/paulinasglow.jpg'}},
          avatar: {asset: {url: '/social-post/thumb/paulinasglow.jpg'}},
          name: 'Paulina Garcia',
          handle: '@paulinasglow'
        }
      ]}
    />
  )
}

import React, {Fragment} from 'react'
import {Div, Image, Text} from "../../lib/atomize";

export default function CYOSelectedItem(
  {
    bundleItem = {},
    index = 0,
    removeBundleItem = () => {
    }
  }
) {

  const {featuredImage = {}, title = '', desktopTitle = ''} = bundleItem
  const {asset = {}} = featuredImage

  return (
    <div className="item">
      {typeof bundleItem === 'object' && bundleItem.unset ?
        <div className="block-wrapper">
          <Text
            textWeight="bold"
            textTransform="uppercase"
            textSize="display1"
            fontFamily="secondary"
            tag="h5"
            textAlign="center"
          >
            {index + 1}
          </Text>
        </div>
        :
        <Fragment>
          <div className="block-wrapper">
            <img
              src={`${
                asset && asset.url ?
                  asset.url :
                  'https://cdn.sanity.io/images/bkrzj7um/production/6257f5a0d3f758aa4dd04e20a39ee70b6a67f526-5304x5304.jpg'
              }`
              }
            />
          </div>
          <p className="product-name">{desktopTitle || title}</p>
          <Div
            style={{lineHeight: "0"}}
            bg="lightPink"
            shadow="2"
            hoverShadow="3"
            transition
            cursor="pointer"
            rounded="sm"
            className="item-remove"
          >
            <Image
              src="/close.svg"
              alt="Remove"
              title="Remove"
              onClick={(e) => {
                e.preventDefault();
                removeBundleItem(index)
              }
              }/>
          </Div>
        </Fragment>}
    </div>
  )
}

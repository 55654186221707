import React, {Fragment, useState} from 'react';
import CTALink from "../CTALink"

export const SideDrawerMenuItem = (
  {
    title = '',
    items = []
  }
) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <Fragment>
      {items.length && items.length === 1 ?
        <div className="item">
          <CTALink
            {...items[0]}
            title={title}
            internal={true}
          />
        </div> :
        <div className={isActive ? "item active" : "item"}>
          <div className="toggle" onClick={() => setIsActive(!isActive)}>
            <a>{title}</a>
            <span className="actions">
              <img src="/arrow-up.svg" alt="Collapse"/>
            </span>
          </div>
          {items.map((item,key) =>
            <div className="sub-items" key={key}>
              <div className="item">
                <CTALink
                  {...item}
                  internal={true}
                />
              </div>
            </div>
          )}

        </div>
      }


    </Fragment>

  );
}

import React from "react"
import { Div } from "../../lib/atomize"
import {graphql} from 'gatsby';
import Layout from "../../components/common/Layout"
import ModuleAccountContainer from "../../modules/ModuleAccountContainer"
import EditAddress from "../../components/account/EditAddress"
import EditCardInfo from "../../components/account/EditCardInfo"
import Authenticated from "../../components/common/Authenticated"

const Profile = ({ data }) => {
  const site = (data || {}).site

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Authenticated>
      <Layout site={site}>
        <Div>
          <ModuleAccountContainer />
          {/* <EditAddress /> */}
          {/* <EditCardInfo /> */}
        </Div>
      </Layout>
    </Authenticated>
  )
}

export default Profile

export const query = graphql`
  query AccountQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      navMenu {
        ...NavMenu
      }
      footerNavMenu {
        ...NavMenu
      }
      socialLinks {
        title
        icon {
          asset {
            url
          }
        }
        link
      }
      openGraph {
        title
        description
      }
      notSpamForm {
        header
        body
        icon {
          asset {
            url
          }
        }
      }
    }
  }
`

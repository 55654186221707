import React, { useEffect } from 'react';
import Layout from "../../components/common/Layout";
import Helmet from "react-helmet";
import WebsiteContainer from "../../components/common/atoms/WebsiteContainer";
import { Col, Div, Row, Text } from "../../lib/atomize";
import VideoCard from "../../components/common/VideoCard";
import { graphql } from "gatsby";

const StudentDiscount = ({ data }, props) => {
  const { site } = data
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.studentbeans.com/third-party/all.js";
    script.setAttribute('data-iframe', 'https://connect.studentbeans.com/v4/not-pot/us')
    script.setAttribute('data-load', 'connect')
    script.setAttribute('id', 'stb_root')
    script.async = true;
    document.getElementById('studentDiscount').appendChild(script);

  }, [])
  const PagePath = props?.location?.pathname || props.uri;
  const pageClass = (PagePath ? PagePath.replace(/\//g, '') : 'home')

  return (
    <Layout site={site} path={PagePath} pageClass={`page-wrapper page-${pageClass}`}>
      <Helmet>
        <title>Student Discount</title>
      </Helmet>
      <WebsiteContainer>
        <Div>
          <Row>
            <Col size={{ xs: "12" }}>
              <Div m={{ t: "2rem" }}>
                <div id="studentDiscount" />
              </Div>
            </Col>
          </Row>
        </Div>
      </WebsiteContainer>
    </Layout>
  )
}

export default StudentDiscount;

export const query = graphql`
  query Discount {

    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SiteSettings
    }
  }
`

import React, {useEffect, useState} from "react"
import {Col, Div, Row, Text} from "../lib/atomize"
// import posthog from "posthog-js"
import {get} from "lodash"
import ls from "local-storage"
import {useMutation, useQuery} from "@apollo/client"
import PortableText from "../components/common/PortableText"
import WebsiteContainer from "../components/common/atoms/WebsiteContainer"
import {
  CHECKOUT_LINE_ITEMS_ADD,
  FETCH_CUSTOMER,
  GET_RECHARGE_CHECKOUT,
  TOGGLE_CART,
  UPDATE_RECHARGE_CHECKOUT,
} from "../graphql/queries"
import {addItemToCheckout, addItemToRechargeCheckout,} from "../services/cartService"
import ProductCard from "../components/home/ProductCard";

export default function ModuleCategoriesWithCards(
  {
    title = '',
    body = '',
    products = [],
    variants = [],
    bundles = [],
    categories = [],
    isHomepage,
    isPcp,
    checkoutData,
    checkoutCreate,
    index,
    isAddToBag = false,
    disableCTA = false,
    memberSaveTitle = false,
    xsItems = '6',
    lgItems = '3',
  }
) {
  const [isFeatureAvailable, setIsFeatureAvailable] = useState(isAddToBag)

  const [currentOrigin, setCurrentOrigin] = useState('')


  const [toggleCart] = useMutation(TOGGLE_CART, {
    onCompleted: () => {
    },
  })

  const {data: fetchCustomerData, loading: fetchCustomerLoading} = useQuery(
    FETCH_CUSTOMER,
    {
      skip: !ls("accessToken"),
      variables: {accessToken: ls("accessToken")},
    }
  )

  // const { data: checkoutData, refetch: refechCheckoutData } = useQuery(
  //   CHECKOUT_BY_ID,
  //   {
  //     // skip: !ls("lastCheckoutID"),
  //     variables: { id: ls("lastCheckoutID") },
  //   }
  // )

  const {data: rechargeCheckoutData, refetch: refetchRechargeCart} = useQuery(
    GET_RECHARGE_CHECKOUT,
    {
      skip: !ls("lastRechargeCheckoutID"),
    }
  )

  const [updateRechargeCheckout] = useMutation(UPDATE_RECHARGE_CHECKOUT)
  // const [checkoutCreate] = useMutation(CHECKOUT_CREATE, {
  //   onCompleted: result => {
  //     //window.history.pushState("header", "Not Pot", "/#cart")
  //     toggleCart({ variables: { cartDrawerVisible: true } })
  //     saveCheckoutInLocalStorage(result?.checkoutCreate?.checkout)
  //     refechCheckoutData({
  //       // skip: !ls("lastCheckoutID"),
  //       variables: { id: ls("lastCheckoutID") },
  //     })
  //   },
  // })

  const [checkoutLineItemsAdd] = useMutation(CHECKOUT_LINE_ITEMS_ADD, {
    onCompleted: result => {
      toggleCart({variables: {cartDrawerVisible: true}})
    },
  })

  const customer = get(fetchCustomerData, "customer")

  const onClickAddToCartButton = (variant, callback) => {
    if (
      get(rechargeCheckoutData, "rechargeCheckout.completed_at", undefined) ===
      null
    ) {
      if (variant.bundles && variant.item) {
        addItemToRechargeCheckout({
          variant: variant.bundles,
          bundle: variant.item,
          email: get(customer, "email", undefined),
          quantity: 1,
          frequency: undefined,
          checkout: checkoutData?.node,
          rechargeCheckout: rechargeCheckoutData?.rechargeCheckout,
          refetchCart: refetchRechargeCart,
          updateRechargeCheckout,
          toggleRechargeCart: toggleCart,
          setUpdatingRechargeCheckout: callback,
        })
      } else {
        addItemToRechargeCheckout({
          variant,
          email: get(customer, "email", undefined),
          quantity: 1,
          frequency: undefined,
          checkout: checkoutData?.node,
          rechargeCheckout: rechargeCheckoutData?.rechargeCheckout,
          refetchCart: refetchRechargeCart,
          updateRechargeCheckout,
          toggleRechargeCart: toggleCart,
          setUpdatingRechargeCheckout: callback,
        })
      }


    } else {
      if (variant.bundles && variant.item) {
        addItemToCheckout({
          checkout: checkoutData?.node,
          checkoutCreate,
          checkoutLineItemsAdd,
          variant: variant.bundles,
          bundle: variant.item,
          quantity: 1,
          email: get(customer, "email", undefined),
        })
      } else {
        addItemToCheckout({
          checkout: checkoutData?.node,
          checkoutCreate,
          checkoutLineItemsAdd,
          variant,
          quantity: 1,
          email: get(customer, "email", undefined),
        })
      }

      setTimeout(() => callback(false), 2000)
    }
  }

  // const getFlag = () => {
  //   const flag = posthog.isFeatureEnabled("add-to-bag")
  //   setIsFeatureAvailable(flag)
  // }

  useEffect(() => {
    // posthog.init("1v5yjATiVqFL71uaXLBd2A6LHkyrt2GaA-1c7liz9k8", {
    //   persistence: "memory",
    //   loaded: function (posthog) {
    //     posthog.reloadFeatureFlags()
    //     posthog.onFeatureFlags(getFlag)
    //   },
    // })

    // posthog.onFeatureFlags(getFlag)
    if (window !== undefined) {
      setCurrentOrigin(window.location.origin)
    }
  }, [])

  const isTitleOrBody = title || body

  return (
    <Div
      // p={{
      //   t: {
      //     xs: isPcp ? "1.25rem" : "6.25rem",
      //     lg: isPcp ? "2.5rem" : "9.375rem",
      //   },
      // }}
      className="bgblocks module-product-card"
      // data-emergence="hidden"
      data-bg={!isHomepage ? (index % 2 === 0 ? "#FDEEFF" : "#FEE3E1") : "#FEE3E1"}
      key={title}
    >
      <WebsiteContainer>
        <Row>
          <Col
            size={{xs: "12", lg: isHomepage ? "12" : "12"}}
            offset={{xs: "0", lg: isHomepage ? "0" : "0"}}
          >
            {isTitleOrBody && (
              <Row
                textAlign="center"
                m={{b: {xs: "1.25rem", lg: "2rem"}}}
                p={{
                  t: {
                    xs: "6.25rem",
                    lg: "9.375rem",
                  },
                }}
                className="module-product-card-header"
              >
                <Col
                  size={{xs: "12", lg: isPcp ? "8" : "12"}}
                  offset={{xs: "0", lg: isPcp ? "2" : "0"}}
                >
                  {title && (
                    <Text
                      textSize="display1"
                      textTransform="uppercase"
                      fontFamily="secondary"
                      tag="h2"
                    >
                      {title}
                    </Text>
                  )}
                  {body && (
                    <Div
                      textSize={{xs: "header", lg: "title1"}}
                      m={{t: "1.25rem"}}
                      className="description"
                    >
                      <PortableText blocks={body}/>
                    </Div>
                  )}
                </Col>
              </Row>
            )}
            <Div textAlign="center">
              <Div>
                <Row
                  className={
                    isHomepage
                      ? "product-variant-container-home"
                      : `product-variant-container-pcp-pdp ${!isPcp ? 's' : ''}`
                  }
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {categories.map((category => {
                      const {
                        _key: _id,
                        slug: fakeSlug = {},
                        carousel = {},
                        title = '',
                        featuredImage = {},
                        description = {},
                        ctaText = '',
                        customLink = ''

                      } = category
                      const {slug = {}} = fakeSlug
                      const {current = ''} = slug || {}
                      const variant = {
                        name: title,
                        carousel,
                        featuredImage
                      }
                      const variants = [{...variant}]
                      const fakeProduct = {
                        _id,
                        desktopTitle: title,
                        description,
                        featuredImage,
                        slug,
                        variants
                      }
                      return (
                        <Col
                          size={{
                            xs: xsItems,
                            lg: lgItems,
                          }}
                          key={`product-card-${category._id || index}`}
                        >
                          <Div
                            m={{
                              t: {xs: "1.25rem", lg: "3rem"},
                            }}
                            className="product-variant-mob categories"
                          >
                            <ProductCard
                              product={fakeProduct}
                              index={index}
                              isFeatureAvailable={false}
                              disableCTA={true}
                              type={''}
                              enableButtonLink={!!customLink || !!current}
                              ctaText={ctaText}
                              customLink={customLink || current ? `${currentOrigin}/${current}` : ''}
                              disableAnchor={true}
                            />

                          </Div>
                        </Col>
                      )
                    }

                  ))}
                </Row>
              </Div>
            </Div>

          </Col>
        </Row>
      </WebsiteContainer>
    </Div>
  )
}

import {get} from "lodash"
import React, {useState} from "react"
import {Col, Div, Image, Row, Text} from "../lib/atomize"
import VideoCard from "../components/common/VideoCard"
import PdpModal from "../components/common/molecules/PdpModal"
import WebsiteContainer from "../components/common/atoms/WebsiteContainer"
import {Collapse} from "../components/common/Collapse"
import BlueButton from "../components/common/molecules/BlueButton"

export default function ModuleProductInfo({title, asset, product}) {
  const [modal, showModal] = useState(false)
  const [image, variantImage] = useState(null)
  const {latestLabReportsFineprint, supplementFactsFineprint, howToUse, ingredients} = product

  const openModal = data => {
    showModal(true)
    variantImage(data)
  }

  const shouldShowSupplementSection = () => {
    if (product && product.variants && Array.isArray(product.variants)) {
      const variantImages = product.variants.map(variant => {
          if (!variant.deleted) return get(variant, "supplementFacts.asset.url")
        }
      )
      return variantImages.some(item => item !== undefined)
    }

  }

  const shouldShowCOAsSection = () => {
    if (product && product.variants && Array.isArray(product.variants)) {
      const variantImages = product.variants.map(variant => {
          if (!variant.deleted) return get(variant, "latestCoas.asset.url")
        }
      )
      return variantImages.some(item => item !== undefined)
    }
  }

  const getTitle = (variant = {}) => {
    const {variantTitle = '', title = ''} = variant;
    return variantTitle !== 'Default' ? variantTitle : title
  }

  const isNotpotGoogle = process.env.GATSBY_SITE_MODE === 'notpotgoogle';

  return (
    <Div
      p={{t: {xs: "6.25rem", lg: "9.375rem"}}}
      className="bgblocks productinfo"
      data-bg="#FDEEFF"
    >
      <WebsiteContainer>
        <PdpModal
          isOpen={modal}
          onClose={() => showModal(false)}
          variantImage={image}
        />
        <Row className="fine-print">
          <Col size={{xs: "12", lg: "7"}}>
            <Div
              m={{l: {lg: "1.5rem"}}}
              rounded="lg"
              border="1px solid"
              borderColor="yellowPink"
              shadow="1"
            >
              <VideoCard bold="bold" {...asset} mobRatio="1:1"/>
            </Div>
          </Col>
          <Col size={{xs: "12", lg: "5"}} style={{margin: "auto"}}>
            <Div
              m={{
                l: {xs: "0", lg: "6.25rem"},
                t: {xs: "2.5rem", lg: "0"},
              }}
              maxW={{xs: "100%", lg: "21rem"}}
              className="main"
            >
              <Text
                textTransform="uppercase"
                m={{b: "1.875rem"}}
                textWeight="bold"
                textSize="display1"
                fontFamily="secondary"
                textAlign={{xs: "center", lg: "left"}}
                tag="h3"
              >
                {title}
              </Text>

              {shouldShowSupplementSection() && (
                <section>
                  <header>
                    <h4>
                      {supplementFactsFineprint || 'Supplement Facts'}
                    </h4>
                  </header>
                  <Div
                    d="flex"
                    m={{t: "1.25rem"}}
                    flexWrap="wrap"
                    justify={{lg: "left"}}
                  >
                    {product.variants.map(
                      variant =>
                        !variant.deleted &&
                        get(variant, "supplementFacts.asset.url") && (
                          <Div
                            d="flex"
                            align="center"
                            p="11px 1.25rem"
                            border="1px solid"
                            borderColor="blue"
                            key={variant.id}
                            rounded="20px"
                            m={{r: "12px", b: "10px"}}
                            cursor="pointer"
                            onClick={() =>
                              openModal(
                                get(variant, "supplementFacts.asset.url")
                              )
                            }
                          >
                            {get(variant, "icon.asset.url") ? (
                              <Image
                                src={get(variant, "icon.asset.url")}
                                h="1rem"
                                w="1rem"
                                m={{r: "5px"}}
                              />
                            ) : (
                              ""
                            )}
                            <Text
                              textTransform="uppercase"
                              textWeight="bold"
                              style={{lineHeight: "14px"}}
                              fontFamily="secondary"
                            >
                              {getTitle(variant)}
                            </Text>
                          </Div>
                        )
                    )}
                  </Div>
                </section>
              )}

              {shouldShowCOAsSection() && (
                <section>
                  <header>
                    <h4>
                      {latestLabReportsFineprint || 'Latest COAs'}
                    </h4>
                  </header>
                  <Div
                    d="flex"
                    m={{t: "1.25rem"}}
                    flexWrap="wrap"
                    justify={{lg: "left"}}
                  >
                    {product.variants.map(
                      variant =>
                        !variant.deleted &&
                        get(variant, "latestCoas.asset.url") && (
                          <Div
                            d="flex"
                            align="center"
                            key={variant.id}
                            p="11px 1.25rem"
                            border="1px solid"
                            borderColor="blue"
                            rounded="20px"
                            m={{r: "12px", b: "10px"}}
                            cursor="pointer"
                            onClick={() => {
                              get(variant, "latestCoas.asset.url").endsWith(
                                ".pdf"
                              )
                                ? window.open(
                                  get(variant, "latestCoas.asset.url")
                                )
                                : openModal(
                                  get(variant, "latestCoas.asset.url")
                                )
                            }}
                          >
                            {get(variant, "icon.asset.url") ? (
                              <Image
                                src={get(variant, "icon.asset.url")}
                                h="1rem"
                                w="1rem"
                                m={{r: "5px"}}
                              />
                            ) : (
                              ""
                            )}
                            <Text
                              textTransform="uppercase"
                              textWeight="bold"
                              style={{lineHeight: "14px"}}
                              fontFamily="secondary"
                            >
                              {getTitle(variant)}
                            </Text>
                          </Div>
                        )
                    )}
                  </Div>
                </section>
              )}

              {howToUse &&
              <Collapse
                title="How to Use"
                desc={howToUse}
              />
              }

              {ingredients &&
              <Collapse
                title="Ingredients"
                desc={ingredients}
              />
              }

              <BlueButton
                m={{t: "3rem", b: "2rem"}}
                textSize="header1"
                h="3rem"
                style={{alignSelf: "center"}}
                w="175px"
                padding="0.5rem 2.25rem"
                text="Read Our FAQs"
                onClick={() => {
                  const url = isNotpotGoogle ? 'https://notpot.co/helpdesk/about-hemp' : 'https://notpot.com/helpdesk/about-cbd'
                  window.location.href = url
                }}
              />

            </Div>
          </Col>
        </Row>
      </WebsiteContainer>
    </Div>
  )
}

import React from "react";
import {graphql} from 'gatsby';

import { Div } from "../lib/atomize";
import Layout from "../components/common/Layout";
import ModuleHomeHero from "../modules/ModuleHomeHero";

const pageData = {
  title: [{ children: [{ text: "OH NO!", _type: "span" }], _type: "block" }],
  body: [
    {
      children: [
        {
          text:
            "We could not find the page you were looking for. Feels like 2020 all over again...",
          _type: "span",
        },
      ],
      _type: "block",
    },
  ],
  cta: {
    kind: "button:blue",
    route: "/",
    title: "Return Home",
    _type: "cta",
  },
  video: {
    caption: "introducing np v4(final).gif",
    title: "Chilltime",
    _type: "video",
    file: {
      asset: {
        url: "/404.png",
      },
    },
    icon: {
      asset: {
        url:
          "https://cdn.sanity.io/images/bkrzj7um/production/74818ac7a10fddbf932c9030c9ae2c95855cff0f-64x64.png",
      },
    },
  },
}

const NotFoundPage = (
    {
      data
    }
  ) => {
  const site = (data || {}).site

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  const {
    title,
    body,
    cta,
    video
  } = pageData
  return (
    <Layout site={site}>
      <Div>
        <ModuleHomeHero
          title={title}
          body={body}
          cta={cta}
          video={video}
          is404="true"
        />
      </Div>
    </Layout>
  )
}
export default NotFoundPage

export const query = graphql`
  query NotFoundPage {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SiteSettings
    }
  }
`

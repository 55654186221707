import React from "react"
import {BundleItem} from "./BundleItem"

export const BundlesWrapper = ({bundles = []}) => {
  return (
    <section className="bundles">
      {bundles.map(
        bundle =>
          bundle &&
          <BundleItem
            key={bundle.id}
            {...bundle}
            slug={bundle?.slug && bundle.slug.current || '#'}
          />
      )
      }
    </section>
  )
}

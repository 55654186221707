import React, {useEffect, useState} from "react"
import {graphql, useStaticQuery} from "gatsby"

import {first, isEmpty} from 'lodash';
import {Div, Image, Modal} from "../../../lib/atomize"
import ModulePdpHero from "../../../modules/ModulePdpHero"

const CyoModal = (
  {
    isOpen = false,
    onClose = () => {
    },
    selectedItem = {},
    addBundleItem = () => {
    },
  }) => {

  const data = useStaticQuery(graphql`
    query get_all_junip {
      allJunipProduct {
        nodes {
          title
          id
          rating_average
          rating_count
          remote_handle
        }

      }
    }
  `)

  const {allJunipProduct = {}} = data
  const {nodes = []} = allJunipProduct
  const {variants = [], defaultVariant = {}, parent = {}} = selectedItem
  const {current = ''} = parent
  const {id = '', customVariantSlug = ''} = defaultVariant
  const variant = first(variants) || {}
  const [selectedReview, setSelectedReview] = useState({})
  const {
    carousel = [],
    supplementFacts: supplementFactsFineprint,
    latestCoas: latestLabReportsFineprint,
  } = variant

  const slugs = current && current.split('/') || []

  useEffect(() => {
    if (slugs && slugs.length && slugs[4]) {
      const review = nodes.find(n => n.remote_handle === slugs[4])
      if (!isEmpty(review)) {
        if (current) {
          review.redirectTo = current
        }
        setSelectedReview(review)
      }

    } else if (!isEmpty(setSelectedReview)) {
      setSelectedReview({})
    }
  }, [slugs])
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        align="center"
        rounded="lg"
        p="0"
        shadow="4"
        maxW="80vw"
        className="quick-view-modal"
      >
        <Div
          p={{t: {xs: "1.875rem", lg: "2.5rem"}}}
          className="pdf-hero-container"
        >
          <span 
            className="close-modal"
            onClick={() => {
              onClose(true)
            }}
          >
            <Image
              src="/close.svg"
              alt="Close"
              title="Close"
              width="16px"
              height="16px"
            />
          </span>

          <ModulePdpHero
            product={
              {
                ...selectedItem,
                id,
                variants,
                supplementFactsFineprint,
                latestLabReportsFineprint,
              }
            }
            selectedVariant={{
              ...defaultVariant,
              hasSubscription: false,
            }}
            enableAddToBundle={true}
            addBundleItem={addBundleItem}
            onClose={onClose}
            junipProduct={
              selectedReview
            }
          />
          {current &&
          <p className="view-all">
            <a href={current}>View all details</a>
          </p>
          }
        </Div>
      </Modal>
    </>
  )
}

export default CyoModal

import axios from "axios"
import ls from "local-storage"
import {nanoid} from "nanoid";

const BASE_RECHARGE_URL = "https://api.rechargeapps.com"

const recharge = axios.create({
  baseURL: BASE_RECHARGE_URL,
  headers: {
    "x-recharge-access-token":
      "af836d0bf66bd3bd720f172fe7f8f9ccf909303e932f38f7115b73ac",
    "content-type": "application/json",
  },
})

/**
 * returns list of recharge subscriptions
 *
 * @param {*} param0
 */
export const getCustomerSubscriptions = customerID => {
  return recharge.get(`/subscriptions?customer_id=${customerID}`)
  // return recharge.get(`/customers?email=jeremy@italic.com`)
  // // console.log(cust);
  // return


}

/**
 * reactivate subscriptions
 *
 * @param {*} subscriptionID
 */
export const reactivateCustomerSubscription = subscriptionID => {
  return recharge.post(`/subscriptions/${subscriptionID}/activate`)
}

/* cancel subscriptions
 *
 * @param {*} subscriptionID
 */
export const cancelCustomerSubscription = (subscriptionID, reason) => {
  return recharge.post(`/subscriptions/${subscriptionID}/cancel`, {
    cancellation_reason: reason,
  })
}

/**
 * update subscriptions
 *
 * @param {*} subscriptionID
 */
export const updateCustomerSubscription = (subscriptionID, data) => {
  return recharge.put(`/subscriptions/${subscriptionID}`, data)
}

export const updateNextCharge = (subscriptionID, date) => {
  return recharge.post(
    `/subscriptions/${subscriptionID}/set_next_charge_date`,
    {date}
  )
}

/**
 * create recharge checkout
 *
 * @param {*} lineItems
 */
export const createCheckout = (lineItems, discountCode = null, tracking = null) => {

  const payload = discountCode ? {
    line_items: lineItems, discount_code: discountCode, note_attributes: tracking
  } : {
    line_items: lineItems
  }
  return recharge.post("/checkouts", payload)
}

/**
 * update existing checkout
 *
 * @param {*} checkoutToken
 * @param {*} lineItems
 */

export const createCoupon = () => {
  return recharge.post("/discounts",
    {
      applies_to_product_type: "ALL",
      channel_settings: {
        api: {
          can_apply: true
        },
        checkout_page: {
          can_apply: true
        },
        customer_portal: {
          can_apply: false
        },
        merchant_portal: {
          can_apply: true
        },
      },
      code: nanoid(10),
      discount_type: "percentage",
      duration: "single_use",
      duration_usage_limit: 1,
      status: "enabled",
      usage_limit: 1,
      first_time_customer_restriction: "customer_must_not_exist_in_recharge",
      value: 20
    }
  )
}
export const removeCoupon = (charge_id) => {
  return recharge.post(`/charges/${charge_id}/remove_discount/`)
}

export const updateCheckout = (checkoutToken, lineItems, discount = null, tracking = null) => {
  const email = discount ? 'exampleDiscountEmail@notpot.com' : '';
  const payload = email ? {
    line_items: lineItems, discount_code: discount, note_attributes: tracking, email
  } : {
    line_items: lineItems
  }

  return recharge.put(`/checkouts/${checkoutToken}`, payload)
}

/**
 * retrive existing checkout
 *
 * @param {*} checkoutToken
 */
export const retrieveCheckout = checkoutToken => {
  return recharge.get(`checkouts/${checkoutToken}`)
}
//Upcoming Deliveries
/**
 * retrive Upcoming Delivery
 *
 * @param {*} checkoutToken
 */
  //32156692
  //33348480
  //29654384
export const getUpcomingDeliveries = ({customerID = "29215917"}) => {
    return recharge.get(`/charges?customer_id=${customerID}&status=queued,skipped`)
  }

/**
 * skip charges
 *
 * @param {*} chargeID
 */
export const skipCustomercharge = (chargeID, subscriptionID) => {
  return recharge.post(`/charges/${chargeID}/skip`, {"subscription_id": subscriptionID})
}
/**
 * unskip Charge
 *
 * @param {*} checkoutToken
 */
export const unskipCustomercharge = (chargeID, subscriptionID) => {
  return recharge.post(`/charges/${chargeID}/unskip`, {"subscription_id": subscriptionID})
}
/**
 * retrive Billing Info
 *
 * @param {*} checkoutToken
 */
export const getBilling = ({customerID = "29215917"}) => {
  return recharge.get(`/customers/${customerID}/payment_sources`)
}
/**
 * retrive Billing Info
 *
 * @param {*} checkoutToken
 */
export const UpdateCart = ({customerID = "29215917"}) => {
  return recharge.get(`/customers/${customerID}/payment_sources`)
}
/**
 * retrive Billing Info
 *
 * @param {*} checkoutToken
 */
export const UpdateAddress = (customerID, data) => {
  return recharge.put(`/customers/${customerID}`, data)
}

/**
 * retrive Customer Info
 *
 * @param {*} checkoutToken
 */
export const getLoginCustomers = customerEmail => {
  return recharge.get(`/customers?email=${customerEmail}`)
}

/**
 * retrive Customer Token
 *
 * @param {*} checkoutToken
 */
export const getCustomersToken = data => {
  return axios.post(`https://not-pot.myshopify.com/tools/recurring/get-subscription-access`, data)
}
/**
 * retrive Customer Token
 *
 * @param {*} checkoutToken
 */
export const allremoveCheckout = customerID => {
  ls.remove("lastRechargeCheckoutID")
  ls.remove("lastCheckoutID")
  return ls("lastCheckoutID")
}

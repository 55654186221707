import React, { Component } from "react"
import { Div } from "../../../lib/atomize"
import ImageLazyLoad from "./ImageLazyLoad"
import VideoOnScroll from "./VideoOnScroll"
import isMobile from 'ismobilejs'

export default class VideoOrImage extends Component {
  isVideo = file => {
    const acceptedVideoTypes = ["mp4", "mov"]
    const fileExtensition = file.split(".").pop().split(/\#|\?/)[0]

    return file && acceptedVideoTypes.includes(fileExtensition)
  }

  render() {
    const { 
      file, 
      product, 
      id, 
      hideTracker, 
      disableMobileSize = false, 
      ...rest 
    } = this.props

    const isVideo = file && this.isVideo(file)
    
    let mobileSize = ""
    
    if (disableMobileSize) {
      mobileSize = "mobile-size"
    }

    if (isVideo) {
      return (
        <VideoOnScroll
          file={file}
          id={id}
          pos="relative"
          overflow="hidden"
          hideTracker={hideTracker}
          {...rest}
        />
      )
    }

    return (
      <Div
        p="1.25rem"
        bg="gray"
        borderColor="yellowPink"
        border={{ t: "1px solid" }}
        className={`video-image lazy-img-container ${mobileSize}`}
      >
        <ImageLazyLoad
          bgImg={file}
          bgPos="center"
          bgSize="cover"
          // p={{ b: disableMobileSize ? "100%" : isMobile().any ? "100%" : "75%" }}
          
          {...rest}
          className="lazy-image"
        />
      </Div>
    )
  }
}

import React from "react"
import { Div } from "../../../lib/atomize"

export default function WebsiteContainer(props) {
  return (
    <Div
      maxW="81.5rem"
      m={{ x: "auto" }}
      p={{ x: { xs: "1.25rem", md: "2.5rem", lg: "3rem", xl: "4.5rem" } }}
      {...props}
    />
  )
}

import React, {useEffect} from 'react';
import Layout from "../../components/common/Layout";
import Helmet from "react-helmet";
import WebsiteContainer from "../../components/common/atoms/WebsiteContainer";
import {LazyLoadImage} from "react-lazy-load-image-component"
import {Col, Div, Icon, Input, Row, Text} from "../../lib/atomize";
import CardHeader from "../../components/common/CardHeader"
import BlueButton from "../../components/common/molecules/BlueButton"
import {graphql} from "gatsby";
import {Formik} from "formik"
import * as yup from "yup"
import _ from "lodash";

const Wholesale = ({data}, props) => {
  const {site} = data
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.conjured.co/shopify/referral/widget.js?shop=not-pot.myshopify.com&cmp=4739";
    script.async = true;
    document.body.appendChild(script);

  }, [])
  const PagePath = props?.location?.pathname || props.uri;
  const pageClass = (PagePath ? PagePath.replace(/\//g, '') : 'home')

  const features = [
    {
      id: 1,
      icon: 'https://cdn.sanity.io/images/bkrzj7um/production/c7e374bcd76d599cec0cfc563ccfe7e9dbfddb1f-80x80.svg',
      title: 'Enjoy flexible payment terms',
      desc: 'You can pay straight, Net 30, or in four installments.'
    },
    {
      id: 2,
      icon: 'https://cdn.sanity.io/images/bkrzj7um/production/fb85b578a361142cfdccd35eebd358b221ac8641-80x80.svg',
      title: 'High sell through products',
      desc: 'Sell cult-favorite products with over 4,000 5-star reviews.'
    },
    {
      id: 3,
      icon: 'https://cdn.sanity.io/images/bkrzj7um/production/ab8f69c328b6eb37b57d68e2fbd419fb3366913f-80x80.svg',
      title: 'Get free shipping',
      desc: 'You don\'t have to pay for shipping when you order three or more cases.'
    },
  ]

  let formikProps
  const onSubmit = values => {
    const {setSubmitting, setStatus, resetForm} = formikProps
    const payload = {...values, base: 'appYiWTAcUiDPB9rK', table: 'Wholesale%20Leads'}
    let errorMessage = '';
    let successMessage = '';
    fetch('/api/wholesale', {
      method: 'POST',
      body: JSON.stringify(payload)
    }).then(response => response.json())
      .then(result => {
        if (result.status) {
          errorMessage = "Your message didn't go through. Please try again later."
        } else if (result.records) {
          successMessage = 'Thank you for your interest! You should receive an email or call from us soon!'
        }

      })
      .finally(() => {
        setSubmitting(false)
        if (successMessage) {
          resetForm()
        }
        setStatus({
          errorMessage,
          successMessage
        })

      })
  }
  let validator = yup.object().shape({
    name: yup
      .string()
      .required("Please enter your name."),

    email: yup
      .string()
      .required("Please enter your work email address.")
      .email("Please use a valid work email address."),
    company: yup
      .string()
      .required("Please enter company name."),
    phone: yup
      .string()
      .required("Please enter a phone number.")
  })

  return (
    <Layout site={site} path={PagePath} pageClass={`page-wrapper page-${pageClass}`}>
      <Helmet>
        <title>Wholesale</title>
      </Helmet>
      <WebsiteContainer>
        <div className="wholesale">
          <Row m={{t: {lg: '5rem', xs: '3rem'}, b: {lg: '10rem'}}}>
            <Col size={{xs: "12", lg: "6"}}>
              <Div m={{b: {xs: "2.5rem", lg: "0"}, t: {lg: '1rem'}}} maxW={{lg: '88%'}}>
                <Text
                  textSize="display1"
                  textTransform="uppercase"
                  fontFamily="secondary"
                  tag="h2"
                  textAlign="center"
                  m={{b: "2rem"}}
                >
                  What are the perks of being a not pot stockist?
                </Text>
                <div>
                  {
                    features.map((feature, id) =>
                      <div
                        key={`featuresList-${id}`}>
                        <Div d="flex" className="feature" m={{b: "1.5rem"}}>
                          <Div style={{minWidth: "80px", height: "80px"}} m={{r: "1.25rem"}} className="feature-image">
                            <LazyLoadImage src={feature.icon} width="100%" effect="blur" alt="Icon"/>
                          </Div>
                          <Div textAlign="left" className="feature-text">
                            <Text
                              textWeight="bold"
                              textSize="header"
                              style={{lineHeight: "16px"}}
                              tag="h3"
                            >
                              {feature.title}
                            </Text>
                            <Text m={{t: "0.625rem"}}>
                              {feature.desc}
                            </Text>
                          </Div>
                        </Div>
                      </div>
                    )
                  }
                </div>
              </Div>
            </Col>
            <Col size={{xs: "12", lg: "6"}}>
              <Div
                shadow="1"
                m={{t: {xs: "2.5rem", lg: "0"}, l: {lg: '6rem'}}}
                border="1px solid"
                borderColor="yellowPink"
                rounded="lg"
              >
                <>
                  <CardHeader
                    heading="Be our partner"
                    image="https://cdn.sanity.io/images/bkrzj7um/production/1f55ae222c200b0c8ed8359ea63ad884d80eb994-16x16.png?w=1000&h=1000&fit=max"
                  />
                  <Div p="1.25rem" rounded={{b: "lg"}} bg="yellow">

                    <Text
                      textWeight="bold"
                      textSize="header"
                      style={{lineHeight: "16px"}}
                      tag="h3"
                      className="form-heading"
                    >
                      Explore this exciting opportunity.
                    </Text>


                    <Formik
                      initialValues={{
                        name: "",
                        email: "",
                        company: "",
                        phone: "",
                        website: "",
                      }}
                      onSubmit={onSubmit}
                      validationSchema={validator}
                    >
                      {props => {
                        const {
                          values,
                          status,
                          touched,
                          errors,
                          isSubmitting,
                          handleChange,
                          handleBlur,
                          handleSubmit
                        } = props
                        formikProps = props

                        return (
                          <div className="form">
                            {
                              status ?
                                status.successMessage &&
                                <Text
                                  textColor="success700"
                                  m={{b: "1rem"}}
                                  transition
                                >
                                  {status.successMessage}
                                </Text> ||
                                status.errorMessage &&
                                <Text
                                  textColor="danger700"
                                  m={{b: "1rem"}}
                                  transition
                                >{status.errorMessage}</Text> : ''
                            }
                            <form onSubmit={handleSubmit}>
                              <Input
                                type="text"
                                name="name"
                                placeholder="Name*"
                                placeholderTextColor="navy"
                                shadow="2"
                                textColor="blue"
                                h="2.5rem"
                                border="1px solid"
                                borderColor={
                                  touched.name
                                    ? values.name
                                      ? errors.name
                                        ? "red"
                                        : "oilGreen"
                                      : errors.name
                                        ? "red"
                                        : "transparent"
                                    : "transparent"
                                }
                                m={{t: "0.625rem"}}
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="field-name"
                              />
                              <Text
                                textColor="error"
                                textSize="paragraph"
                                m={{t: ".5rem"}}
                              >
                                {touched.name && _.capitalize(errors.name)}
                              </Text>
                              <Input
                                type="email"
                                name="email"
                                placeholder="Work Email*"
                                placeholderTextColor="navy"
                                shadow="2"
                                textColor="blue"
                                h="2.5rem"
                                border="1px solid"
                                borderColor={
                                  touched.email
                                    ? values.email
                                      ? errors.email
                                        ? "red"
                                        : "oilGreen"
                                      : errors.email
                                        ? "red"
                                        : "transparent"
                                    : "transparent"
                                }
                                m={{t: "1rem"}}
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="field-email"
                              />
                              <Text
                                textColor="error"
                                textSize="paragraph"
                                m={{t: ".5rem"}}
                              >
                                {touched.email && _.capitalize(errors.email)}
                              </Text>
                              <Input
                                type="text"
                                name="company"
                                placeholder="Company*"
                                placeholderTextColor="navy"
                                shadow="2"
                                textColor="blue"
                                h="2.5rem"
                                border="1px solid"
                                borderColor={
                                  touched.company
                                    ? values.company
                                      ? errors.company
                                        ? "red"
                                        : "oilGreen"
                                      : errors.company
                                        ? "red"
                                        : "transparent"
                                    : "transparent"
                                }
                                m={{t: "1rem"}}
                                value={values.company}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="field-company"
                              />
                              <Text
                                textColor="error"
                                textSize="paragraph"
                                m={{t: ".5rem"}}
                              >
                                {touched.company && _.capitalize(errors.company)}
                              </Text>
                              <Input
                                type="text"
                                name="phone"
                                placeholder="Phone Number*"
                                placeholderTextColor="navy"
                                shadow="2"
                                textColor="blue"
                                h="2.5rem"
                                border="1px solid"
                                borderColor={
                                  touched.phone
                                    ? values.phone
                                      ? errors.phone
                                        ? "red"
                                        : "oilGreen"
                                      : errors.phone
                                        ? "red"
                                        : "transparent"
                                    : "transparent"
                                }
                                m={{t: "0.625rem"}}
                                value={values.phone}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="field-phone"
                              />
                              <Text
                                textColor="error"
                                textSize="paragraph"
                                m={{t: ".5rem"}}
                              >
                                {touched.phone && _.capitalize(errors.phone)}
                              </Text>
                              <Input
                                type="text"
                                name="website"
                                placeholder="Website (Optional)"
                                placeholderTextColor="navy"
                                shadow="2"
                                textColor="blue"
                                h="2.5rem"
                                border="1px solid"
                                borderColor={
                                  touched.phone
                                    ? "oilGreen"
                                    : "transparent"
                                }
                                m={{t: "1rem"}}
                                value={values.website}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="field-website"
                              />
                              <BlueButton
                                type="submit"
                                m={{t: "2rem"}}
                                text={
                                  isSubmitting ? (
                                    <Icon name="Loading" color="white" size="16px"/>
                                  ) : (
                                    'Receive a call'
                                  )
                                }
                                padding="0.5rem 1.25rem"
                                textSize="header1"
                                h="3rem"
                                w="100%"
                              />
                            </form>
                          </div>
                        )
                      }}
                    </Formik>
                  </Div>
                </>
              </Div>
            </Col>
          </Row>
        </div>
      </WebsiteContainer>
    </Layout>
  )
}

export default Wholesale;

export const query = graphql`
  query Wholesale {
    page: sanityPage(_id: { regex: "/(drafts.|)frontpage/" }) {
      ...PageInfo
    }

    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SiteSettings
    }
  }
`

import React, { Fragment, useContext, useEffect, useState } from "react"

import { get } from "lodash"

import ls from "local-storage"

import { Link, navigate } from "gatsby"

import { Anchor, Div, Image, Text } from "../../../lib/atomize"

import { useLazyQuery, useMutation, useQuery } from "@apollo/client"

import CTALink from "../CTALink"

import {
  CART_VISIBILITY,
  CHECKOUT_BY_ID,
  FETCH_CUSTOMER,
  FORGOT_PASSWORD_DRAWER_VISIBILITY,
  GET_RECHARGE_CHECKOUT,
  LOGIN_DRAWER_VISIBILITY,
  RECHARGE_CART_VISIBILITY,
  REGISTER_DRAWER_VISIBILITY,
  TOGGLE_CART,
  TOGGLE_FORGOT_PASSWORD_DRAWER,
  TOGGLE_LOGIN_DRAWER,
  TOGGLE_RECHARGE_CART,
  TOGGLE_REGISTER_DRAWER,
  UPDATE_RECHARGE_CHECKOUT,
} from "../../../graphql/queries"
import CartModal from "../../cart/CartModal"
import LoginModal from "../organisms/LoginModal"
import MobileHeaderMenu from "./MobileHeaderMenu"
import SignupModal from "../organisms/SignupModal"
import WebsiteContainer from "../atoms/WebsiteContainer"
import { getTotalCartCount } from "../../../services/cartService"
import ForgotPasswordModal from "../organisms/ForgotPasswordModal"
import { SideDrawerMenu } from "./SideDrawerMenu";
import { AppContext } from "../../../context";
import { CallOutSlider } from "./CallOutSlider"
import { EasterEggItem } from "../../../components/easteregg/EasterEggItem"


const getCustomerEmail = (id) => {
  const myHeaders = new Headers();

  myHeaders.append("Api-key", process.env.GATSBY_TAPFILLIATE_API_KEY);
  myHeaders.append("Content-Type", "application/json");
  const customerOptions = {
    method: 'GET',
    headers: myHeaders,
    mode: 'cors',
    referrerPolicy: 'origin-when-cross-origin'
  };
  return new Promise((resolve, reject) => {
    fetch(`https://api.tapfiliate.com/1.6/customers/${id}/`, customerOptions)
      .then(response => response.json())
      .then(result => {
        const { error } = result;

        if (error) {
          reject(error);
          console.error(error);
        } else {
          resolve(result)
        }

      }).catch(err => console.error(err));
  })


}
export default function Header(
  {
    navMenuItems = [],
    primaryMenu = [],
    secondaryMenu = [],
    redirectToMain = false,
    callout,
    toggleNotSpam = () => {
    },
    alertBar = [],
    waveColor = '',
    optionalCartItems = []

  },
  props) {

  const [showMobileMenu, setShowMobileMenu] = useState(false)

  const [isOpen, setIsOpen] = useState(false)

  const [state, dispatch] = useContext(AppContext);
  const { tracking } = state;
  const { data } = useQuery(CART_VISIBILITY)

  const { data: rechargeCartVisibilityData } = useQuery(
    RECHARGE_CART_VISIBILITY
  )

  const [toggleCart] = useMutation(TOGGLE_CART);
  const [toggleRechargeCart] = useMutation(TOGGLE_RECHARGE_CART)
  const [updateRechargeCheckout] = useMutation(UPDATE_RECHARGE_CHECKOUT)
  const [toggleLoginDrawer] = useMutation(TOGGLE_LOGIN_DRAWER)
  const [toggleForgotPasswordDrawer] = useMutation(
    TOGGLE_FORGOT_PASSWORD_DRAWER
  )

  const [toggleRegisterDrawer] = useMutation(TOGGLE_REGISTER_DRAWER)
  const { data: loginDrawerVisibilityData } = useQuery(LOGIN_DRAWER_VISIBILITY)
  const { data: forgotPasswordDrawerVisibilityData } = useQuery(
    FORGOT_PASSWORD_DRAWER_VISIBILITY
  )
  const { data: registerDrawerVisibilityData } = useQuery(
    REGISTER_DRAWER_VISIBILITY
  )

  const { data: checkoutData, refetch, loading: checkoutLoading } = useQuery(
    CHECKOUT_BY_ID,
    {
      skip: !ls("lastCheckoutID"),
      variables: { id: ls("lastCheckoutID") }
    }
  )

  const {
    data: rechargeCheckoutData,
    refetch: refetchRechargeCart,
    loading: rechargeCheckoutLoading,
  } = useQuery(GET_RECHARGE_CHECKOUT, {
    skip: !ls("lastRechargeCheckoutID"),
  })

  const [fetchCheckout] = useLazyQuery(CHECKOUT_BY_ID)

  const { data: fetchCustomerData, loading: fetchCustomerLoading } = useQuery(
    FETCH_CUSTOMER,
    {
      skip: !ls("accessToken"),
      variables: { accessToken: ls("accessToken") },
      onCompleted: result => {
        if (get(result, "customer.lastIncompleteCheckout.id")) {
          ls(
            "lastCheckoutID",
            get(result, "customer.lastIncompleteCheckout.id")
          )

          fetchCheckout(get(result, "customer.lastIncompleteCheckout.id"))
        }
      },
    }
  )

  const customer = get(fetchCustomerData, "customer")


  useEffect(() => {

    if (
      performance.navigation.type == 2 &&
      performance.navigation.TYPE_RELOAD == 1
    ) {
      // ls("lastCheckoutID", ls('aftercheckoutCartID'))
      // ls("lastRechargeCheckoutID", ls('aftercheckoutRechargeCartID'))
      //  window.history.pushState("home", "Not Pot", "/");
      window.location.reload()
    }
    const url = window.location.href;
    var nav = false;
    var nav2 = false;
    var nav3 = false;
    //var cartUrl = false;
    ls("previousUrl", window.location.pathname);
    {
      url.includes("#Login") || url.includes("#login") ? nav = true : nav = false
    };
    {
      url.includes("#Signup") || url.includes("#signup") ? nav2 = true : nav2 = false
    }
    {
      url.includes("#ForgotPassword") || url.includes("#forgotpassword") ? nav3 = true : nav3 = false
    }
    //{url.includes("#Cart") || url.includes("#cart")? cartUrl=true : cartUrl=false}
    if (ls('ShowCart') == true) {
      setTimeout(function () {
        toggleCart({
          variables: { cartDrawerVisible: true },
        })
      }, 100);
      ls('ShowCart', false)
    }
    if (nav) {
      window.history.pushState("header", "Not Pot", "/#Login");
      setTimeout(function () {
        toggleLoginDrawer({
          variables: { loginDrawerVisible: true },
        })
      }, 100);
    }
    if (nav2) {
      window.history.pushState("header", "Not Pot", "/#Signup");
      setTimeout(function () {
        toggleRegisterDrawer({
          variables: { registerDrawerVisible: true },
        })
      }, 100);
    }
    if (nav3) {
      window.history.pushState("header", "Not Pot", "/#ForgotPassword");
      setTimeout(function () {
        toggleForgotPasswordDrawer({
          variables: { forgotPasswordDrawerVisible: true },
        })
      }, 100);
    }
    // if(cartUrl){
    //   window.history.pushState("header", "Not Pot", "/#Cart");
    //   setTimeout(function(){
    //     toggleCart({
    //       variables: { cartDrawerVisible: true },
    //     })
    //   }, 100);
    // }
  }, [])

  /**
   * on click profile button
   *
   * @param {*} e
   */
  const onClickProfileButton = e => {
    e.preventDefault()
    window.history.pushState("header", "Not Pot", ls("previousUrl"));
    if (customer) {
      navigate("/profile")

      return false
    }

    window.history.pushState("header", "Not Pot", "/#Login");
    toggleLoginDrawer({
      variables: { loginDrawerVisible: true },
    })
  }

  /**
   * on click forgot password btn
   */
  const onClickForgotPasswordBtn = () => {
    window.history.pushState("header", "Not Pot", ls("previousUrl"));
    toggleLoginDrawer({
      variables: { loginDrawerVisible: false },
    })

    toggleRegisterDrawer({
      variables: { registerDrawerVisible: false },
    })
    window.history.pushState("header", "Not Pot", "/#ForgotPassword");
    toggleForgotPasswordDrawer({
      variables: { forgotPasswordDrawerVisible: true },
    })
  }

  /**
   * on click sign up btn
   */
  const onClickSignUpBtn = () => {
    window.history.pushState("header", "Not Pot", ls("previousUrl"));
    toggleLoginDrawer({
      variables: { loginDrawerVisible: false },
    })
    window.history.pushState("header", "Not Pot", "/#Signup");
    toggleRegisterDrawer({
      variables: { registerDrawerVisible: true },
    })
  }

  /**
   * on click login btn
   */
  const onClickLoginBtn = () => {
    window.history.pushState("header", "Not Pot", ls("previousUrl"));
    window.history.pushState("header", "Not Pot", "/#Login");
    toggleLoginDrawer({
      variables: { loginDrawerVisible: true },
    })

    toggleRegisterDrawer({
      variables: { registerDrawerVisible: false },
    })
  }

  return (
    <Fragment>
      <CallOutSlider
        alerts={
          alertBar &&
            alertBar.length ? alertBar :
            [{
              _key: '1',
              icon: 'https://cdn.sanity.io/images/bkrzj7um/production/7e8a13def720811936c62ef18b71229b11d69ce3-80x80.png',
              text: '4.9 Stars from 4000+ Reviews'
            },
            {
              _key: '2',
              icon: 'https://cdn.sanity.io/images/bkrzj7um/production/fb85b578a361142cfdccd35eebd358b221ac8641-80x80.svg',
              text: '100% THC Free and Lab-Tested'
            },
            {
              _key: '3',
              icon: 'https://cdn.sanity.io/images/bkrzj7um/production/57a3f9f1bfffb2a8db006a4d2ad114748c85470d-81x80.svg',
              text: 'Organic USA-Grown Hemp'
            },
            {
              _key: '4',
              icon: 'https://cdn.sanity.io/images/bkrzj7um/production/c7e374bcd76d599cec0cfc563ccfe7e9dbfddb1f-80x80.svg',
              text: 'Free Shipping over $40'
            }
            ]}
      />
      <section className="global-header">
        <Div id="header">
          <WebsiteContainer className="website-container">
            <Div d="flex" align="center" h={{ xs: "4rem", lg: "6.25rem" }}>
              <CartModal
                customer={customer}
                guestCart={checkoutData?.node}
                isOpen={data?.cartDrawerVisible}
                rechargeCart={rechargeCheckoutData?.rechargeCheckout}
                updateRechargeCheckout={updateRechargeCheckout}
                toggleCart={toggleCart}
                onClose={() => {
                  /*window.history.pushState("header", "Not Pot", ls("previousUrl"))*/
                  toggleCart({ variables: { cartDrawerVisible: false } })
                }
                }
                optionalCartItems={optionalCartItems}
              />
              <LoginModal
                isOpen={loginDrawerVisibilityData?.loginDrawerVisible}
                onClose={() => {
                  window.history.pushState("header", "Not Pot", ls("previousUrl"));
                  toggleLoginDrawer({ variables: { loginDrawerVisible: false } })
                }}
                onClickForgotPasswordBtn={onClickForgotPasswordBtn}
                onClickSignUpBtn={onClickSignUpBtn}
              />
              <SignupModal
                isOpen={registerDrawerVisibilityData?.registerDrawerVisible}
                onClickLoginBtn={onClickLoginBtn}
                onClose={() => {
                  window.history.pushState("header", "Not Pot", ls("previousUrl"));
                  toggleRegisterDrawer({
                    variables: { registerDrawerVisible: false },
                  })
                }}
              />
              <ForgotPasswordModal
                isOpen={
                  forgotPasswordDrawerVisibilityData?.forgotPasswordDrawerVisible
                }
                onClose={() => {
                  window.history.pushState("header", "Not Pot", ls("previousUrl"));
                  toggleForgotPasswordDrawer({
                    variables: { forgotPasswordDrawerVisible: false },
                  })
                }}
              />
              <Div
                // flexGrow="0 1 33.33%"
                // maxW="33.33%"
                h={{ xs: "4rem", lg: "6.25rem" }}
                w="100%"
                d="flex"
                transition
                // p="0 1.25rem"
                // m="0 -1.25rem"
                bg={{
                  xs: showMobileMenu ? "pink" : "transparent",
                  lg: "transparent",
                }}
                p={{ x: { xs: "1.25rem", md: "2.5rem", lg: "3rem", xl: "4.5rem" } }}
                className={showMobileMenu ? 'menu-active' : ''}
              >
                <Div flexGrow="1" d="flex" align="center"
                >
                  <Div d={{ xs: "none", lg: "flex" }} className="main-menu">
                    <Div
                      m={{
                        r: "2rem",
                      }}
                      className="menu-toggle"
                    >
                      <a onClick={() => setIsOpen(!isOpen)}><p>Menu</p></a>
                    </Div>
                    {navMenuItems
                      .filter(link => link.type !== "secondary")
                      .map((link, id, index) => {
                        return (
                          <Div
                            m={{
                              r: index === navMenuItems.length - 1 ? "0" : "2rem",
                            }}
                            key={id}
                          >
                            <CTALink {...link} textWeight="bold" redirectToMain={redirectToMain} />
                          </Div>
                        )
                      })}
                  </Div>

                  <SideDrawerMenu
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    primaryMenu={primaryMenu}
                    secondaryMenu={secondaryMenu}
                    toggleNotSpam={toggleNotSpam}
                  />
                  {/* Header Left Mobile */}

                  <Image
                    onClick={() => setShowMobileMenu(!showMobileMenu)}
                    src={
                      showMobileMenu ? "/menu-close.png" : "/mobile-menu-icon.png"
                    }
                    h="36px"
                    transition
                    w="36px"
                    d={{ xs: "block", lg: "none" }}
                    shadow="7"
                    rounded="lg"
                    alt="Close menu"
                  />

                  <MobileHeaderMenu
                    navMenuItems={navMenuItems}
                    showMobileMenu={showMobileMenu}
                    primaryMenu={primaryMenu}
                    secondaryMenu={secondaryMenu}
                    menuV2={true}
                    showLogin={onClickProfileButton}
                    close={() => setShowMobileMenu(false)}
                    toggleNotSpam={toggleNotSpam}
                    waveColor={waveColor}
                  />
                </Div>
                {/* Logo */}
                <Div flexGrow="1" d="flex" align="center" justify="center">
                  {/* Mobile Logo */}
                  <h1>
                    <Link to="/">
                      <Image
                        d={{ xs: "block", lg: "block" }}
                        src="https://cdn.shopify.com/s/files/1/2229/0673/files/notpot-logo.png?v=1555703706"
                        w={{ xs: "100px", lg: "115px" }}
                        alt="Not Pot"
                      />
                      {/* Desktop Logo */}
                    </Link>
                  </h1>
                </Div>
                {/* Header Right */}
                <Div flexGrow="1" d="flex" align="center" justify="flex-end">
                  <Div
                    p='8px 15px 8px 12px'
                    border={{ lg: '1px solid' }}
                    borderColor={{ lg: '#F8D4E4' }}
                    m={{ b: { xs: '50px', lg: '0px' }, r: { lg: '30px' } }}
                    bg={{ xs: '#EAF1F9', lg: '#FFFCF0' }}
                    pos={{ xs: 'absolute', lg: 'relative' }}
                    top={{ xs: '0' }}
                    left={{ xs: '0' }}
                    w={{ xs: '100%', lg: '225px' }}
                    style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
                    d={{ xs: "none", lg: "flex" }}
                    className="callout"
                  >
                    <Image
                      d='inline-block'
                      src="/globe.svg"
                      h="20px"
                      w="20px"
                      right={{ lg: '175px' }}
                      alt="Globe"
                    />
                    <Text
                      d='flex'
                      m={{ l: '5px' }}
                      textWeight={
                        {
                          xs: 'bold',
                          lg: 'normal'
                        }
                      }>
                      {callout || 'Free shipping over $40!'}
                    </Text>
                  </Div>
                  <Div align="center" d={{ xs: "none", lg: "flex" }}>
                    {/* <Image
                      src="/play-icon.svg"
                      h="1.5rem"
                      w="auto"
                      m={{ r: "1.875rem" }}
                      rounded="100%"
                      shadow="6"
                      cursor="pointer"
                    /> */}
                    <Anchor href="" h="1.25rem" onClick={onClickProfileButton}>
                      <Image
                        src="/profile-icon.png"
                        h="1.25rem"
                        w="auto"
                        m={{ r: "1.875rem" }}
                      />
                    </Anchor>
                    <Div m={{ r: "1.875rem" }}>
                      {[1, 2, 3].map((index, id) => {
                        return (
                          <Div
                            key={id}
                            h="5px"
                            w="5px"
                            bg="purple"
                            shadow="3"
                            rounded="circle"
                            m={{ y: index === 2 ? "5px" : "0" }}
                          />
                        )
                      })}
                    </Div>
                  </Div>
                  <Div
                    d="flex"
                    flexGrow={{ xs: "1", lg: "initial" }}
                    justify="flex-end"
                  >
                    <Div
                      bg="pink"
                      rounded="circle"
                      p="10px 20px"
                      d="flex"
                      shadow="2"
                      hoverShadow="3"
                      transition
                      cursor="pointer"
                      m="auto 0"
                      onClick={() => {
                        /*window.history.pushState("header", "Not Pot", "/#Cart")*/
                        setShowMobileMenu(false);
                        toggleCart({
                          variables: { cartDrawerVisible: true },
                        })
                      }}
                    >
                      <Image src="/suites.svg" h="1rem" m={{ r: "5px" }} alt="Suites" />
                      <Text
                        fontFamily="secondary"
                        textWeight="bold"
                        style={{ lineHeight: "14px" }}
                      >
                        {getTotalCartCount(
                          checkoutData?.node,
                          rechargeCheckoutData,
                          checkoutLoading || rechargeCheckoutLoading
                        )}
                      </Text>
                    </Div>
                  </Div>
                </Div>
              </Div>
            </Div>
          </WebsiteContainer>
        </Div>
      </section>
    </Fragment>
  )
}

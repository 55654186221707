import React, {Fragment, useState} from 'react'
import {first} from 'lodash'
import {Col, Div, Icon, Row, Text} from "../../lib/atomize"
import WebsiteContainer from "../../components/common/atoms/WebsiteContainer"
import BlueButton from "../../components/common/molecules/BlueButton"
import CardHeader from "../../components/common/CardHeader"
import VideoOrImage from "../common/molecules/VideoOrImage"
import {Progress} from "../../components/valentines/Progress"
import {Collapse} from "../blackfriday/Collapse"
import {Link} from "gatsby"
import ProductAddToBag from "../pdp/ProductAddToBag"
import Social from '../../components/wholesale/Social'

const Start = (
  {
    setActiveQuestion = () => {}
  }
) => {

  return (
    <>
      <Div
        className="bgblocks"
        data-bg="#FDEEFF"
      >
        <WebsiteContainer>
          <Row>
            <Col
              size={{xs: "12", lg: "5", xl: "4"}}
            >
              <Div h="100%" align="center" d="flex" className="modulehomeHero">
                <Div
                  d="flex"
                  flexDir="column"
                  align={{xs: "center", lg: "flex-start"}}
                  textAlign={{xs: "center", lg: "left"}}
                >
                  <Div className="hero-title">
                    <h2><p>FIND YOUR PERFECT MATCH</p></h2>
                  </Div>
                  <Div className="hero-description">
                    <p>Ready to fall in love with the product of your dreams? Let's play matchmaker.</p>
                  </Div>
                  <BlueButton
                    m={{xs: "2rem auto", md: "2rem 0 0"}}
                    text='Take the quiz'
                    padding="0.5rem 1.25rem"
                    textSize="header1"
                    h="3rem"
                    w="100%"
                    maxW="254px"
                    onClick={() => {
                      setActiveQuestion('match', 10)
                    }}
                  />
                </Div>
              </Div>
            </Col>
            <Col
              size={{xs: "12", lg: "6", xl: "7"}}
              offset={{xs: "0", lg: "1"}}
            >
              <Div
                shadow="1"
                border="1px solid"
                borderColor="yellowPink"
                rounded="lg"
              >
                <>
                  <CardHeader
                    heading="QUIZTIME"
                    image="https://cdn.sanity.io/images/bkrzj7um/production/874afd4d0959e1ba52cd8a62bc0a936ce5a75989-17x16.svg"
                  />
                  <Div p="0.5rem" bg="white" textAlign="center">

                    <Text
                      style={{lineHeight: "12px"}}
                    >
                      youre_the_one.jpg
                    </Text>
                  </Div>
                  <VideoOrImage
                    bgPos="center"
                    bgSize="cover"
                    file="https://cdn.sanity.io/images/bkrzj7um/production/1b4a7cebc5017ad35549b3af6d2278c84d09a9d4-601x477.png"
                  />
                </>
              </Div>
            </Col>
          </Row>
        </WebsiteContainer>
      </Div>
    </>
  )
}

const BackButton = (
  {
    goBack = () => {
    }
  }
) => {
  return (
    <Text className="back" textAlign="center" onClick={() => {
      goBack('back')
    }}>
      <Icon name="LeftArrow" color="#283377" size="20px"/> GO BACK
    </Text>
  )
}

const OptionList = (
  {
    setActiveQuestion = () => {
    },
    question = '',
    options = [],
    percent = 50
  }) => {
  return (
    <WebsiteContainer>
      <Row>
        <Col
          size={{xs: "12"}}
        >
          <h3 className="question">{question}</h3>
          <Div d="flex" justify="center" className="option-list">
            {
              options.map((
                  {
                    text = '',
                    value = '',
                    icon = ''
                  },
                  key
                ) =>
                  <button onClick={() => {
                    setActiveQuestion(value, percent)
                  }} key={`OptionList-${key}`}
                  >
                    <img src={icon}/>
                    <span>{text}</span>
                  </button>
              )
            }
          </Div>
          <BackButton goBack={setActiveQuestion}/>
        </Col>
      </Row>
    </WebsiteContainer>
  )
}

const OptionListWithCard = (
  {
    setActiveQuestion = () => {
    },
    question = '',
    options = [],
    percent = 50
  }) => {
  return (
    <>
      <h3 className="question">{question}</h3>
      <Row
        className="card-items-wrapper"
      >
        {
          options.map(
            ({
               text = '',
               value = '',
               icon = '',
               buttonText = '',
               heading = ''
             }, key) =>
              <Col
                size={{
                  xs: "6",
                  lg: "4",
                }}
                key={`OptionListWithCard-${key}`}
                onClick={() => {
                  setActiveQuestion(value, percent)
                }}
              >
                <Div
                  shadow="1"
                  border="1px solid"
                  borderColor="yellowPink"
                  rounded="lg"
                  className="card-item"
                >
                  <>
                    <CardHeader
                      heading={heading}
                    />
                    <Div p="0.5rem" bg="white" textAlign="center" className="card-item-sub-header">

                      <Text>
                        {text}
                      </Text>
                    </Div>
                    <VideoOrImage
                      bgPos="center"
                      bgSize="cover"
                      file={icon}
                    />
                    <Div p="0.5rem" bg="yellow" textAlign="center" rounded={{b: "lg"}}>
                      <BlueButton
                        m={{xs: "20px auto"}}
                        text={buttonText}
                        padding="0.5rem 1.25rem"
                        textSize="header1"
                        h="3rem"
                        w="100%"
                        maxW="200px"
                        onClick={() => {
                          setActiveQuestion(value, percent)
                        }}
                      />
                    </Div>
                  </>
                </Div>
              </Col>
          )
        }
      </Row>

      <BackButton goBack={setActiveQuestion}/>
    </>
  )
}

const SelectedResult = (
  {
    selectedVariant = {},
    realSocialMediaPosts = {}
  }
) => {
  const [count, setCount] = useState(1)
  const {title = '', carousel = {}, variantTitle = '', _id = '', klaviyoListId = ''} = selectedVariant;
  const {assets = [], title: carouselTitle = '',} = carousel;
  const {image = {}, caption = ''} = first(assets) || {}

  const changeCounterValue = value => {
    if (value === "plus") {
      setCount(prevCount => prevCount + 1)
    } else {
      if (count === 1) {
        return null
      }
      setCount(prevCount => prevCount - 1)
    }
  }

  const productLinks = {
    "37694676107437": {slug: 'icy-gel'},
    "33245868097595": {slug: 'vegan-cbd-gummies/original', title: 'CBD ORIGINAL GUMMIES'},
    "42074835321085": {slug: 'oat-puff-cbd-eczema-creme'},
    "39465651306669": {slug: 'banana-og-hemp-body-creme/fresh-banana', title: 'BANANA OG HEMP BODY CRÈME'},
    "40528601219245": {slug: 'cbd-glow-oil'},
    "31068684255291": {slug: 'vegan-cbd-gummies/sleep', title: 'CBD SLEEP GUMMIES'},
    "37885099081901": {slug: 'cbd-bath-soak/sleep'},
    "37844410302637": {slug: 'cbd-bath-soak/muscle'}
  }

  return (
    <>
      <Div
        className="bgblocks"
        data-bg="#FDEEFF"
      >
        <WebsiteContainer>
          <Row>
            <Col
              size={{xs: "12", lg: "6", xl: "5"}}
            >
              <Div h="100%" align="center" d="flex" className="modulehomeHero">
                <Div
                  d="flex"
                  flexDir="column"
                  align={{xs: "center", lg: "flex-start"}}
                  textAlign={{xs: "center", lg: "left"}}
                >
                  <Div className="hero-description" m={{b: "1rem"}}>
                    <p>Here’s your match made in Not Pot heaven!</p>
                  </Div>
                  <Div className="hero-title">
                    <h2>
                      <p>{productLinks[_id]?.title || `${title} ${variantTitle === 'Default' ? '' : variantTitle}`} </p>
                    </h2>
                  </Div>
                  <Div className="hero-description">
                    <p>You two look so cute together. Use code <strong>VDAYSPECIAL</strong> for 14% off your order until
                      Feb 28. </p>
                  </Div>
                  {klaviyoListId ?

                    <BlueButton
                      m={{xs: "2rem auto", md: "2rem 0 0"}}
                      text='Waitlist'
                      padding="0.5rem 1.25rem"
                      textSize="header1"
                      h="3rem"
                      w="100%"
                      maxW="254px"
                      onClick={() => window.location.href = `/products/${productLinks[_id]?.slug || ''}`}
                    /> :

                    <Div m={{t: "1rem"}} className="valentines-addtobag">
                      <ProductAddToBag
                        count={count}
                        changeCounterValue={value => changeCounterValue(value)}
                        variant={selectedVariant}
                        productType={"one-time"}
                      />
                    </Div>
                  }
                  <Link to={`/products/${productLinks[_id]?.slug || ''}`}>
                    <Text
                      textColor="blue"
                      textWeight="bold"
                      textDecor="underline"
                      hoverTextColor="navy"
                      m="1rem 0"
                    >
                      View this Product
                    </Text>
                  </Link>
                </Div>
              </Div>
            </Col>
            <Col
              size={{xs: "12", lg: "6", xl: "6"}}
              offset={{xs: "0", lg: "1"}}
            >
              <Div
                shadow="1"
                border="1px solid"
                borderColor="yellowPink"
                rounded="lg"
              >
                <>
                  <CardHeader
                    heading={carouselTitle || ''}
                  />
                  <Div p="0.5rem" bg="white" textAlign="center">

                    <Text
                      style={{lineHeight: "12px"}}
                    >
                      {caption}
                    </Text>
                  </Div>
                  <VideoOrImage
                    bgPos="center"
                    bgSize="cover"
                    file={image?.asset?.url || 'https://cdn.sanity.io/files/bkrzj7um/production/a100402f78540792c56d2f1951ba62200544247a.jpg'}
                  />
                </>
              </Div>
            </Col>
          </Row>
          <Row>
            <div className="bf-logo-list">
              <div className="logo">
                <img src="/pr/black-friday/forbes-logo.png"/>
              </div>
              <div className="logo">
                <img src="/pr/black-friday/daily-beast-logo.png"/>
              </div>
              <div className="logo">
                <img src="/pr/black-friday/refinery29-logo.png"/>
              </div>
              <div className="logo">
                <img src="/pr/black-friday/allure-logo.png"/>
              </div>
            </div>
          </Row>
          <Row>
            <Row className="bf-faq">
              <Col
                size={{xs: "12", lg: "6", xl: "7"}}
                p={{xs: "0 8px", lg: "0 0 0 8px", xl: "0"}}
              >
                <Div
                  shadow="1"
                  m={{t: {xs: "2.5rem", lg: "0"}}}
                  border="1px solid"
                  borderColor="yellowPink"
                  rounded="lg"
                >
                  <CardHeader
                    heading="ChillTime"
                    image="https://cdn.sanity.io/images/bkrzj7um/production/874afd4d0959e1ba52cd8a62bc0a936ce5a75989-17x16.svg"
                  />
                  <Div p="0.5rem" bg="white" textAlign="center">

                    <Text
                      style={{lineHeight: "12px"}}
                    >
                      ask_me_anything.jpg
                    </Text>
                  </Div>
                  <VideoOrImage
                    bgPos="center"
                    bgSize="cover"
                    file="https://cdn.sanity.io/files/bkrzj7um/production/a100402f78540792c56d2f1951ba62200544247a.jpg"
                  />
                </Div>
              </Col>
              <Col
                size={{xs: "12", lg: "5", xl: "4"}}
                offset={{xs: "0", lg: "1"}}
              >
                <Text
                  textTransform="uppercase"
                  m={{t: {xs: "2rem"}}}
                  p={{b: "1.25rem"}}
                  textWeight="bold"
                  textSize="display1"
                  fontFamily="secondary"
                  textAlign={{xs: "center", lg: "left"}}
                  tag="h3"
                >FAQ</Text>
                <Collapse
                  faqs={[
                    {
                      id: '1',
                      title: 'What is CBD?',
                      desc: 'Cannabidiol (CBD) is one of more than 100 cannabinoids in the cannabis plant, known to possess anti-inflammatory, neuroprotective, and anti-anxiety properties. Because CBD is non-psychoactive, it will NOT make you high.'
                    },
                    {
                      id: '2',
                      title: 'What are cannabinoids?',
                      desc: 'Cannabinoids are the key chemical compounds of the hemp and marijuana plants (both cannabis, just varietals). Although at least 113 active cannabinoids have been identified to-date, tetrahydrocannabinol (THC) is the only one that’s psychoactive. We extract our CBD from hemp, which makes our products 100% THC-Free.'
                    },
                    {
                      id: '3',
                      title: 'Will CBD make me high?',
                      desc: 'Nope. CBD is completely non-psychoactive. If you’re looking to get high, please do not buy our products! You’ll be thoroughly disappointed (but feeling a little more chill about it).'
                    },
                    {
                      id: '4',
                      title: 'Are Not Pot products all-natural?',
                      desc: 'Our CBD is extracted from organic USA hemp and most of our products are vegan. We are also proud to be 100% cruelty-free, meaning we only test our products on humans—the ones in our HQ to be exact! Our team is extremely passionate about animals and doesn’t condone animal testing in any way.'
                    },
                    {
                      id: '5',
                      title: 'Are Not Pot products third-party tested?',
                      desc: <Fragment>Yes, all our products go through extensive third-party testing to ensure quality
                        and consistency. For lab information on our products, you may go to the fine print section of
                        each product page.</Fragment>
                    }
                  ]}
                />
              </Col>
            </Row>
          </Row>

          <Social
            {
              ...realSocialMediaPosts
            }
          />
        </WebsiteContainer>
      </Div>
    </>
  )
}

export const Quiz = (
  {
    variantOptions = [],
    checkoutData = {},
    realSocialMediaPosts = {}
  }
) => {
  const [activeQuestion, setActiveQuestion] = useState('start')
  const [path, setPath] = useState('')
  const [percent, setPercent] = useState(0)
  const [selectedProduct, setSelectedProduct] = useState('')
  const [selectedVariant, setSelectedVariant] = useState({})
  const questions = {
    myself: {
      question: 'How would you describe yourself?',
      options: [
        {
          text: 'Tired, all the damn time.',
          value: `sleep`,
          buttonText: 'POP A GUMMY',
          icon: 'https://cdn.sanity.io/images/bkrzj7um/production/7e8a13def720811936c62ef18b71229b11d69ce3-80x80.png'

        },
        {
          text: 'I need to chill the f out.',
          value: `chill`,
          buttonText: 'DROP AN OIL',
          icon: 'https://cdn.sanity.io/images/bkrzj7um/production/481bd40594345deed5cd4e7f6d55b63dbb99b4e4-250x250.png'

        },
        {
          text: 'I put self-care before anything else.',
          value: `soothing`,
          buttonText: 'LATHER A CRèME',
          icon: 'https://cdn.sanity.io/images/bkrzj7um/production/250406f47fafc0a632e951a17dad8995c4ee0ccb-81x80.svg'
        }
      ],

      chill: {
        question: 'Ok, stress head. What sounds best?',
        options: [
          {
            text: 'popping a little somethin’ to manage anxiety',
            heading: 'A',
            value: {
              id: '33245868097595'
            },
            buttonText: 'A LITTLE SOMETHIN’',
            icon: 'https://cdn.sanity.io/images/bkrzj7um/production/96570ccc1488bc6c1d2276822779eb7c66a69cbe-296x295.png'
          },
          {
            text: 'a magic potion to scare stress away',
            heading: 'B',
            value: {
              id: '40528601219245'
            },
            buttonText: 'MAGIC POTION',
            icon: 'https://cdn.sanity.io/images/bkrzj7um/production/43ed2edbc246cd14f01497f0430f2c35bfdf1311-296x295.png'

          },
          {
            text: 'a soft and sweet, feel-good treat',
            heading: 'C',
            value: {
              id: '39465651306669'
            },
            buttonText: 'FEEl-GOOD TREAT',
            icon: 'https://cdn.sanity.io/images/bkrzj7um/production/d25638a0fd1cbb572270ed86230ba7b124968fc9-296x295.png'
          }
        ],
      },

      sleep: {
        question: 'Ok, sleepyhead. What sounds best?',
        options: [
          {
            text: 'Popping a little something before bedtime',
            heading: 'A',
            value: {
              id: '31068684255291'
            },
            buttonText: 'A LITTLE SOMETHIN’',
            icon: 'https://cdn.sanity.io/images/bkrzj7um/production/d419b7909ca5b8bf1ad2619fa67a0a39abb0fc76-296x295.png'
          },
          {
            text: 'A soothing ritual that will lull me to sleep',
            heading: 'B',
            value: {
              id: '37885099081901'
            },
            buttonText: 'SOOTHING RITUAL',
            icon: 'https://cdn.sanity.io/images/bkrzj7um/production/6e13020b0fbda378b7eb000b48ab4d4d3f4a3e07-296x295.png'

          },
          {
            text: 'a magic potion that goes with my nightly mug of tea',
            heading: 'C',
            value: {
              id: '40528601219245'
            },
            buttonText: 'MAGIC POTION',
            icon: 'https://cdn.sanity.io/images/bkrzj7um/production/43ed2edbc246cd14f01497f0430f2c35bfdf1311-296x295.png'
          }
        ],
      },

      soothing: {
        question: 'Ok, self-care queen (or king). What sounds best?',
        options: [
          {
            text: 'Something to relax tight and tired muscles',
            value: {
              product: 'relax'
            },
            buttonText: 'POP A GUMMY',
            icon: 'https://cdn.sanity.io/images/bkrzj7um/production/40165de458c08a1801ac83744c0c25d8d8cd62f8-120x120.png'
          },
          {
            text: 'Something to relieve eczema and skin irritation',
            value: {
              id: '42074835321085'
            }, buttonText: 'DROP AN OIL',
            icon: 'https://cdn.sanity.io/images/bkrzj7um/production/b43811cab44184aba3c9d6af15bc30c2cd95cf36-250x250.png'

          },
          {
            text: 'Something to hydrate and soften dry skin',
            value: {
              product: 'recover'
            },
            buttonText: 'LATHER A CRèME',
            icon: 'https://cdn.sanity.io/images/bkrzj7um/production/481bd40594345deed5cd4e7f6d55b63dbb99b4e4-250x250.png'
          }
        ],
        type: 'list'
      },

      relax: {
        question: 'Nice flex. What sounds more relaxing?',
        options: [
          {
            text: 'a warm peppermint-infused bath',
            heading: 'A',
            value: {
              id: '37844410302637'
            }, buttonText: 'WARM BATH',
            icon: 'https://cdn.sanity.io/images/bkrzj7um/production/7d65b78601ca254233eaee2d4da473c69d081ee4-295x295.png'

          },
          {
            text: 'an icy cool treat for your skin',
            heading: 'B',
            value: {
              id: '40895099961517'
            }, buttonText: 'ICY COOL TREAT',
            icon: 'https://cdn.sanity.io/images/bkrzj7um/production/61796aa6ae5bdbdd8fcf4b1ea4afbeb110b2530c-295x295.png'

          },
        ]
      },

      recover: {
        question: 'Bye, dry. Which do you want more?',
        options: [
          {
            text: 'a smooth, glowing face',
            value: {
              id: '40528601219245'
            }, buttonText: 'SMOOTH FACE',
            icon: 'https://cdn.sanity.io/images/bkrzj7um/production/718d06057fae00a2f15ec8cd0ba6a2d87d1dbc2d-295x295.png'

          },
          {
            text: 'a soft, nourished body',
            value: {
              id: '39465651306669'
            }, buttonText: 'SOFT BODY',
            icon: 'https://cdn.sanity.io/images/bkrzj7um/production/150639d8d865afcafd524afe3930de2afb3993ec-295x295.png'

          },
        ]
      },
    },

    someone: {
      question: 'How would you describe them?',
      options: [
        {
          text: `They're literally always tired.`,
          value: `chill`,
          buttonText: 'POP A GUMMY',
          icon: 'https://cdn.sanity.io/images/bkrzj7um/production/7e8a13def720811936c62ef18b71229b11d69ce3-80x80.png'

        },
        {
          text: `They've got zero chill.`,
          value: `zero`,
          buttonText: 'DROP AN OIL',
          icon: 'https://cdn.sanity.io/images/bkrzj7um/production/481bd40594345deed5cd4e7f6d55b63dbb99b4e4-250x250.png'

        },
        {
          text: `They're obsessed with self-care.`,
          value: `selfcare`,
          buttonText: 'LATHER A CRèME',
          icon: 'https://cdn.sanity.io/images/bkrzj7um/production/250406f47fafc0a632e951a17dad8995c4ee0ccb-81x80.svg'
        }
      ],

      chill: {
        question: 'Sleep it is. What would they prefer?',
        options: [
          {
            text: `popping a little somethin' before bedtime`,
            heading: 'A',
            value: {
              id: '31068684255291'
            },
            buttonText: 'A LITTLE SOMETHIN’',
            icon: 'https://cdn.sanity.io/images/bkrzj7um/production/d419b7909ca5b8bf1ad2619fa67a0a39abb0fc76-296x295.png'
          },
          {
            text: 'a soothing, nice-smelling ritual to lull them to sleep',
            heading: 'B',
            value: {
              id: '37885099081901'
            },
            buttonText: 'SOOTHING RITUAL',
            icon: 'https://cdn.sanity.io/images/bkrzj7um/production/6e13020b0fbda378b7eb000b48ab4d4d3f4a3e07-296x295.png'

          },
          {
            text: 'a magic potion that goes with their nightly mug of tea',
            heading: 'C',
            value: {
              id: '40528601219245'
            },
            buttonText: 'MAGIC POTION',
            icon: 'https://cdn.sanity.io/images/bkrzj7um/production/43ed2edbc246cd14f01497f0430f2c35bfdf1311-296x295.png'
          }
        ],
      },

      zero: {
        question: 'We can help. What would they prefer?',
        options: [
          {
            text: 'popping a little somethin’ to manage anxiety',
            heading: 'A',
            value: {
              id: '33245868097595'
            },
            buttonText: 'A LITTLE SOMETHIN’',
            icon: 'https://cdn.sanity.io/images/bkrzj7um/production/96570ccc1488bc6c1d2276822779eb7c66a69cbe-296x295.png'
          },
          {
            text: 'a magic potion to scare stress away',
            heading: 'B',
            value: {
              id: '40528601219245'
            },
            buttonText: 'MAGIC POTION',
            icon: 'https://cdn.sanity.io/images/bkrzj7um/production/43ed2edbc246cd14f01497f0430f2c35bfdf1311-296x295.png'

          },
          {
            text: 'a soft and sweet, feel-good treat',
            heading: 'C',
            value: {
              id: '39465651306669'
            },
            buttonText: 'FEEl-GOOD TREAT',
            icon: 'https://cdn.sanity.io/images/bkrzj7um/production/d25638a0fd1cbb572270ed86230ba7b124968fc9-296x295.png'
          }
        ],
      },

      selfcare: {
        question: 'Honestly, same. What would they prefer? ',
        options: [
          {
            text: 'Something to relax tight and tired muscles',
            value: {
              product: 'relax2'
            },
            buttonText: 'POP A GUMMY',
            icon: 'https://cdn.sanity.io/images/bkrzj7um/production/40165de458c08a1801ac83744c0c25d8d8cd62f8-120x120.png'
          },
          {
            text: 'Something to relieve eczema and skin irritation',
            value: {
              id: '42074835321085'
            },
            buttonText: 'DROP AN OIL',
            icon: 'https://cdn.sanity.io/images/bkrzj7um/production/b43811cab44184aba3c9d6af15bc30c2cd95cf36-250x250.png'

          },
          {
            text: 'Something to hydrate and soften dry skin',
            value: {
              product: 'recover2'
            },
            buttonText: 'LATHER A CRèME',
            icon: 'https://cdn.sanity.io/images/bkrzj7um/production/481bd40594345deed5cd4e7f6d55b63dbb99b4e4-250x250.png'
          }
        ],
        type: 'list'
      },

      relax2: {
        question: 'Nice flex. What sounds more relaxing?',
        options: [
          {
            text: 'a warm peppermint-infused bath',
            heading: 'A',
            value: {
              id: '37844410302637'
            }, buttonText: 'WARM BATH',
            icon: 'https://cdn.sanity.io/images/bkrzj7um/production/7d65b78601ca254233eaee2d4da473c69d081ee4-295x295.png'

          },
          {
            text: 'with an icy cool treat for their skin',
            heading: 'B',
            value: {
              id: '40895099961517'
            }, buttonText: 'ICY COOL TREAT',
            icon: 'https://cdn.sanity.io/images/bkrzj7um/production/61796aa6ae5bdbdd8fcf4b1ea4afbeb110b2530c-295x295.png'

          },
        ]
      },
      recover2: {
        question: 'Bye, dry. Which do you want more?',
        options: [
          {
            text: 'a smooth, glowing face',
            value: {
              id: '40528601219245'
            }, buttonText: 'SMOOTH FACE',
            icon: 'https://cdn.sanity.io/images/bkrzj7um/production/718d06057fae00a2f15ec8cd0ba6a2d87d1dbc2d-295x295.png'

          },
          {
            text: 'a soft, nourished body',
            value: {
              id: '39465651306669'
            }, buttonText: 'SOFT BODY',
            icon: 'https://cdn.sanity.io/images/bkrzj7um/production/150639d8d865afcafd524afe3930de2afb3993ec-295x295.png'

          },
        ]
      },
    }
  }
  const selectPath = (path) => {
    if (path === 'back') {
      setActiveQuestion('start')
      setPercent(0)

    } else {
      setPath(path)
      setActiveQuestion('selectPath')
      setPercent(30)
    }

  }
  const goNext = (active, percent) => {
    setActiveQuestion(active)
    setPercent(percent || 50)
  }
  const selectProduct = (value) => {
    if (value === 'back') {
      setActiveQuestion('match')
      setPercent(30)
    } else {
      setSelectedProduct(value)
      setActiveQuestion('matchProduct')
      setPercent(70)
    }

  }

  const completeResult = (value) => {
    const {link = '', product = '', id = ''} = value

    if (value === 'back') {
      if (['sleep', 'chill', 'zero', 'soothing', 'selfcare'].includes(selectedProduct)) {
        setActiveQuestion('selectPath')
        setPercent(30)
      }
      if (['relax2', 'recover2'].includes(selectedProduct)) {

        setSelectedProduct('selfcare')
        setActiveQuestion('matchProduct')
        setPercent(70)
      }
      if (['relax', 'recover'].includes(selectedProduct)) {
        setSelectedProduct('soothing')
        setActiveQuestion('matchProduct')
        setPercent(70)

      }

    }

    if (id) {
      setSelectedVariant(variantOptions.find(variant => variant._id === id))
      setPercent(0)
      setActiveQuestion('resultsPage')
    } else if (link) {
      //window.location.href = link;
    } else if (product) {
      setPercent(90)
      setSelectedProduct(product)
    }
  }

  return (
    <div className="valentines-app">
      <Row m={{t: {lg: '5rem', xs: '3rem'}, b: {lg: '10rem'}}} align="center">
        <Col size={{xs: "12"}}>
          <div className="quiz-wrapper">
            {percent ?
              <header>
                <h2>Find your perfect match</h2>
                <Progress percent={percent}/>
              </header> : ''
            }
            {
              {
                'start': <Start setActiveQuestion={goNext}/>,
                'match': <OptionListWithCard
                  setActiveQuestion={selectPath}
                  question="I want to give love to..."
                  options={[
                    {
                      text: 'i deserve a treat!',
                      value: `myself`,
                      icon: 'https://cdn.sanity.io/images/bkrzj7um/production/2b31c1d1241c08420db7a68abc73bb05dad8aa0f-295x295.png',
                      buttonText: 'myself',
                      heading: 'A'
                    },
                    {
                      text: 'special gifts for special people',
                      value: `someone`,
                      icon: 'https://cdn.sanity.io/images/bkrzj7um/production/d0f004109dfccfa3e45f4fa47f66937c27cb7e84-295x295.png',
                      buttonText: 'someone else',
                      heading: 'B'
                    }
                  ]}
                />,
                'selectPath': <OptionList
                  setActiveQuestion={selectProduct}
                  question={path && questions[path].question || ''}
                  options={path && questions[path].options || []}
                />,
                'matchProduct': <>
                  {path && selectedProduct && questions[path][selectedProduct]?.type === 'list' &&
                  <OptionList
                    setActiveQuestion={completeResult}
                    question={path && selectedProduct && questions[path][selectedProduct].question || 'match'}
                    options={path && selectedProduct && questions[path][selectedProduct].options || []}
                  />
                  || <OptionListWithCard
                    setActiveQuestion={completeResult}
                    question={path && selectedProduct && questions[path][selectedProduct].question || 'match'}
                    options={path && selectedProduct && questions[path][selectedProduct].options || []}
                  />}
                </>,
                'resultsPage': <SelectedResult selectedVariant={selectedVariant} realSocialMediaPosts={realSocialMediaPosts} />
              }[activeQuestion]
            }
          </div>
        </Col>
      </Row>
    </div>

  )
}

import React from 'react'
import { Col, Div, Row } from "../lib/atomize";
import WebsiteContainer from "../components/common/atoms/WebsiteContainer";
import BlueButton from "../components/common/molecules/BlueButton";
import { Link } from 'react-scroll'

export default function ModuleButtonAnchor({ text, action }) {
  return (
    <Div
      className="button-anchor bgblocks"
      data-bg="#F0F0FF"
    >
      <WebsiteContainer>
        <Row>
          <Col size={{ xs: "12", lg: "8" }} offset={{ xs: "0", lg: "2" }}>
            <Link to={action || '#'} spy={true} smooth={true}>
              <BlueButton
                m="3.75rem auto 2rem"
                textSize="header1"
                text={text || 'Button'}
                w="15.875rem"
                className="small-font"
              />
            </Link>
          </Col>
        </Row>
      </WebsiteContainer>
    </Div>
  )
}

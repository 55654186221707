import React from "react"
import { get } from "lodash"
import { Div, Text, Row, Col } from "../lib/atomize"
import PortableText from "../components/common/PortableText"
import WebsiteContainer from "../components/common/atoms/WebsiteContainer"
import Bubble from "../components/home/Bubble"

export default function ModuleContentWithBubbles({ title, body, products }) {
  return (
    <Div
      className="bgblocks"
      data-bg="#F0F0FF"
      p={{ t: { xs: "6.25rem", lg: "9.375rem" } }}
    >
      <WebsiteContainer>
        <Row>
          <Col size={{ xs: "12", lg: "8" }} offset={{ xs: "0", lg: "2" }}>
            <Div textAlign="center">
              <Text
                textSize="display1"
                textTransform="uppercase"
                fontFamily="secondary"
              >
                {title}
              </Text>
              <Div
                textSize={{ xs: "header", lg: "title1" }}
                m={{ t: "1.25rem" }}
                className="description"
              >
                <PortableText blocks={body} />
              </Div>
            </Div>
          </Col>
        </Row>
        <Div maxW="48rem" m="0 auto">
          <Div
            m={{
              t: "5rem",
              x: { xs: "0", lg: "3.5rem" },
            }}
          >
            {/* Mobile */}
            <Div className="bubble-grid" d={{ xs: "grid", lg: "none" }}>
              {products.slice(0, 6).map((product, id) => {
                return <Bubble product={product} id={id} />
              })}
            </Div>
            {/* Desktop */}
            <Div className="bubble-grid" d={{ xs: "none", lg: "grid" }}>
              {products.map((product, id) => {
                return <Bubble product={product} id={id} />
              })}
            </Div>
          </Div>
        </Div>
      </WebsiteContainer>
    </Div>
  )
}

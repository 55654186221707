import React, {useState} from 'react'
import {Col, Div, Row, Text} from "../../lib/atomize";
import ProductCard from "../home/ProductCard"
import CyoModal from "../common/molecules/CyoModal"

export default function CYOBundleCategory(
  {
    title = '',
    items = [],
    _key = '',
    addBundleItem = () => {
    }
  }
) {

  const [modal, showModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})

  const openCYOModal = (selectedItem) => {
    document.body.classList.add('quick-view')
    setSelectedItem(selectedItem)
    showModal(true)
  }

  const closeCYOModal = () => {
    document.body.classList.remove('quick-view')
    showModal(false)
  }

  return (
    <section className="category" id={`content-${_key}`} key={`content-${_key}`}>
      <Text
        textWeight="bold"
        textTransform="uppercase"
        textSize="heading"
        fontFamily="secondary"
        tag="h3"
      >
        {title}
      </Text>
      <Row>
        {items.map((item, index) =>
          <Col
            size={{xs: "6", lg: "4"}}
            key={`cyo-items-${item._id}-${index}`}
          >
            <Div
              m={{t: {xs: "1.25rem", lg: "3rem"}}}
              onClick={(e) => {
                e.stopPropagation()
                setSelectedItem({
                  title: item.variant.desktopTitle || item.variant.title,
                  pdpTitle: item.variant.title,
                  desktopTitle: item.variant.desktopTitle,
                  variants: [item.variant],
                  defaultVariant: item.variant,
                  parent: item.slug || {},
                  _id:item.variant.id
                })
                document.body.classList.add('quick-view')
                showModal(true)
              }}
            >
              <ProductCard
                product={
                  {
                    title: item.variant.desktopTitle || item.variant.title,
                    variants: [item.variant],
                    defaultVariant: item.variant,
                    pdpTitle: item.variant.title,
                    parent: item.slug || {},
                    _id:item.variant.id
                  }
                }
                disableCTA={true}
                isCYOB={true}
                index={index}
                type="cyo"
                addBundleItem={addBundleItem}
                openCYOModal={openCYOModal}
                disableAnchor={true}
              />
            </Div>
          </Col>
        )}
      </Row>

      <CyoModal
        isOpen={modal}
        onClose={closeCYOModal}
        addBundleItem={addBundleItem}
        selectedItem={selectedItem}
      />
    </section>
  )
}

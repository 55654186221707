import React, { useState, useEffect } from "react"
import { Div, Text, Icon } from "../../lib/atomize"
import axios from "axios"
import { get } from "lodash"
import YourSubscriptions from "./YourSubscriptions"
import EditSubscription from "./EditSubscription"
import CancelSubscription from "./CancelSubscription"
import VerifyLogin from "./VerifyLogin"
import ls from "local-storage"
const SubscriptionsContainer = () => {
  const [page, setActivePage] = useState("home")
  const [activeSubscription, setActiveSubscription] = useState(null)
  const [data, setData] = useState(null)
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(true)
  const [formSubmitting, setFormSubmitting] = useState([])

  const fetchCustomers = async () => {
    setLoading(true)
    try {
      var data = {email: ls('customer_email'), myshopify_domain: 'not-pot.myshopify.com'};
     //let responseTkn = await axios.post("/api/customers/token", data)
     // console.log(responseTkn.data);
       setLoading(false)
     //  console.log(ls('customer_email'))
     //  let response = await axios.post("/api/customers", { customerEmail: 'jeremy@italic.com'})
     //  console.log(response);
     //  const customers = get(response, "data.customers")
     // // setData(customer)
     // console.log(customers[0]);
     // fetchSubscriptions(customers[0].id, customers[0].hash)
      //setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  // const fetchSubscriptions = async (customerID, customerHash) => {
  //   setLoading(true)
  //   try {
  //     console.log(customerID);
  //     console.log(customerHash);
  //     let response = await axios.post("/api/subscriptions", {customerID: customerID})
  //     console.log(response);
  //     const subscriptions = get(response, "data.subscriptions")
  //     const sub = subscriptions.find(x => x.status == "ACTIVE");
  //     console.log(sub);
  //     console.log(`https://notpot.com/tools/recurring/portal/${customerHash}/subscriptions/${sub.id}?token=83377fe7e3f546079444c22cbd20961d&preview_theme=9372`);
  //    // window.location.href =`https://notpot.com/tools/recurring/portal/${customerHash}/subscriptions/${sub.id}?token=83377fe7e3f546079444c22cbd20961d&preview_theme=9372`;
  //    // setData(subscriptions)
  //    // setLoading(false)
  //   } catch (error) {
  //     setLoading(false)
  //   }
  // }

  const reactivateSubscription = async id => {
    setErrors({})
    setFormSubmitting([id])
    try {
      await axios.post("/api/subscriptions/reactivate", { subscriptionID: id })
      //fetchSubscriptions()
      setFormSubmitting([])
    } catch (error) {
      setFormSubmitting([])
      setErrors({ [id]: error.response.data })
    }
  }

  useEffect(() => {
    fetchCustomers()
  }, [])

  if (loading)
    return (
      <Div d="flex" justify="center" align="center" h="50vh">
        <Icon name="Loading" color="blue" size="16px" />
      </Div>
    )

  return (
    <>
      <Div
        p={{
          y: { xs: "1.875rem", lg: "2.5rem" },
          x: { xs: "1.25rem", lg: "2.5rem" },
        }}
        bg="yellow"
        rounded="lg"
        shadow="4"
      >
      <VerifyLogin />
       {/* <Text
          textSize="display1"
          p={{ b: { xs: "1.875rem", lg: "2.5rem" } }}
          style={{ lineHeight: "40px", fontSize: "16px" }}
          textAlign={{ xs: "center"}}
        >
          An email will be sent to you if you're a subscription customer.
        </Text> */ }
       {/* <Div d={page === "home" ? "block" : "none"}>
          <YourSubscriptions
            reactivateSubscription={reactivateSubscription}
            setActivePage={setActivePage}
            data={data}
            setActiveSubscription={setActiveSubscription}
            errors={errors}
            formSubmitting={formSubmitting}
          />
        </Div>

        <Div d={page === "edit" ? "block" : "none"}>
          {activeSubscription && (
            <EditSubscription
              setActivePage={setActivePage}
              activeSubscription={activeSubscription}
              errors={errors}
              setData={setData}
              setErrors={setErrors}
              data={data}
              setActiveSubscription={setActiveSubscription}
            />
          )}
        </Div>
        <Div d={page === "cancel" ? "block" : "none"}>
          {activeSubscription && (
            <CancelSubscription
              activeSubscription={activeSubscription}
              setActivePage={setActivePage}
              setData={setData}
              setErrors={setErrors}
              data={data}
              setActiveSubscription={setActiveSubscription}
            />
          )}
        </Div> */}
      </Div>
    </>
  )
}

export default SubscriptionsContainer

import React from "react"
import { Div } from "../lib/atomize"
import WebsiteContainer from "../components/common/atoms/WebsiteContainer"
import PortableText from "../components/common/PortableText"
import FloatingSticker from "../components/common/molecules/FloatingSticker"

// Stickers
import veganRect from "../components/stickers/veganRect.json"
import selfCare from "../components/stickers/selfCare.json"
import legal from "../components/stickers/legal.json"
import hashNotPotRect from "../components/stickers/hashNotPotRect.json"
import selfLoveSquare from "../components/stickers/selfLoveSquare.json"
import notPot from "../components/stickers/notPot.json"
import hemp2 from "../components/stickers/hemp2.json"
import happyHempCircle from "../components/stickers/happyHempCircle.json"
import chillAF from "../components/stickers/chillAF.json"
import selfLoveOval from "../components/stickers/selfLoveOval.json"
import npOval from "../components/stickers/npOval.json"

const ModuleContentWithImage = ({ body, images }) => {
  // TODO: map images array: image.asset.url

  return (
    <>
      <WebsiteContainer className="bgblocks" data-bg="#FDEEFF">
        <Div>
          <Div
            pos="relative"
            m={{ t: { xs: "3rem", md: "9.75rem" } }}
            textAlign="center"
          >
            <Div
              fontFamily="secondary"
              maxW={{ xs: "80%", md: "35.5rem" }}
              m="auto"
              textSize={{ xs: "display2", md: "display4" }}
              textWeight="bold"
              className="mission-title"
            >
              <PortableText blocks={body} />
            </Div>

            {/* Vegan Rect */}
            <FloatingSticker
              file={veganRect}
              left="19%"
              top="-38%"
              w="100%"
              maxW={{ xs: "2rem", md: "6.3125rem" }}
              transform="matrix(0.99, 0.16, -0.16, 0.99, 0, 0)"
            />

            {/* Self Care Sticker */}
            <FloatingSticker
              file={selfCare}
              left="4%"
              top="-19%"
              w="100%"
              maxW={{ xs: "2.125rem", md: "6.25rem" }}
              transform="matrix(0.99, 0.1, -0.11, 0.99, 0, 0)"
            />
            {/* Legal Sticker */}
            <FloatingSticker
              file={legal}
              left="38%"
              top="-35%"
              w="100%"
              maxW={{ xs: "4.0625rem", md: "12.375rem" }}
              transform="matrix(0.98, 0.21, -0.21, 0.98, 0, 0)"
            />
            {/* NotPot */}
            <FloatingSticker
              file={hashNotPotRect}
              right="10%"
              top="-37%"
              w="100%"
              maxW={{ xs: "5.25rem", md: "15.5rem" }}
              transform="matrix(0.98, -0.19, 0.19, 0.98, 0, 0)"
            />
            {/* SelfLoveSquare */}
            <FloatingSticker
              file={selfLoveSquare}
              right={{ xs: "4%", md: "9%" }}
              top="9%"
              w="100%"
              maxW={{ xs: "2.0625rem", md: "6.125rem" }}
              transform="matrix(0.98, -0.21, 0.21, 0.98, 0, 0)"
            />
            {/* Not Pot */}
            <FloatingSticker
              file={notPot}
              right={{ xs: "-8%", md: "0" }}
              top="42%"
              w="100%"
              maxW={{ xs: "1.875rem", md: "90px" }}
            />
            {/* hemp 2 */}
            <FloatingSticker
              file={hemp2}
              right={{ xs: "8%", md: "12%" }}
              bottom="-30%"
              w="100%"
              maxW={{ xs: "2.05rem", md: "6rem" }}
              transform="matrix(0.97, 0.22, -0.23, 0.97, 0, 0)"
            />
            {/* Happy Hemp */}
            <FloatingSticker
              file={happyHempCircle}
              bottom={{ xs: "-26%", md: "-30%" }}
              left="47%"
              w="100%"
              maxW={{ xs: "2.4375rem", md: "7.25rem" }}
              transform="matrix(0.97, 0.24, -0.24, 0.97, 0, 0)"
            />
            {/* Np3 */}
            <FloatingSticker
              file={npOval}
              bottom="-33%"
              left="25%"
              w="100%"
              maxW={{ xs: "2.625rem", md: "8rem" }}
            />

            {/* Chill AF */}
            <FloatingSticker
              file={chillAF}
              top="56%"
              left={{ xs: "10%", md: "15%" }}
              w="100%"
              maxW={{ xs: "1.9375rem", md: "5.75rem" }}
            />
            {/* Chill AF */}
            <FloatingSticker
              file={chillAF}
              top="56%"
              left={{ xs: "10%", md: "15%" }}
              w="100%"
              maxW={{ xs: "1.9375rem", md: "5.75rem" }}
            />
            {/* Self */}
            <FloatingSticker
              file={selfLoveOval}
              left={{ xs: "-5%", md: "0" }}
              bottom="-11%"
              w="100%"
              maxW={{ xs: "2.0625rem", md: "6.125rem" }}
              transform="matrix(0.98, -0.19, 0.19, 0.98, 0, 0)"
            />
          </Div>
        </Div>
      </WebsiteContainer>
    </>
  )
}

export default ModuleContentWithImage

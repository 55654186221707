import React from "react";
import { Link, navigate } from "gatsby";
import Slider from "react-slick";
import StarRatings from 'react-star-ratings';
import { Text, Image } from "../../lib/atomize";

export const SlickCarousel = () => {

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 4000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        }
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const reviews = [
    {
      id: '1',
      name: 'Mariyesi D.',
      rating: '5',
      date: 'April 19, 2020',
      product: 'CBD Gummies',
      link: 'https://notpot.com/products/vegan-cbd-gummies/sleep',
      title: 'Only thing that works',
      content: 'My mom has severe anxiety, and especially now during COVID-19, she has been experiencing worse anxiety and hasn\'t been able to sleep. I got her the CBD Gummies and she said it was great and that it has been the first time she has been able to FALL and STAY asleep.'
    },
    {
      id: '2',
      name: 'Candance Y.',
      rating: '5',
      date: 'April 8 2021',
      product: 'CBD Icy Gel',
      link: 'https://notpot.com/products/icy-gel',
      title: 'Great for parents',
      content: 'If you have muscle, back, and arthritic aches & pains I can not highly recommend this CBD Icy Gel enough but my best advice is don\'t just buy 👆 buy ✌, especially if your significant other also has muscle aches and pains. Trust me, you\'ll thank me later, cause you are not gonna wanna share. '
    },
    {
      id: '3',
      name: 'Vanessa C.',
      rating: '5',
      date: 'May 22, 2020',
      product: 'CBD Muscle Soak',
      link: 'https://notpot.com/products/vegan-cbd-gummies/sleep',
      title: 'LOVE LOVE LOVE',
      content: 'Loved these CBD Gummies so much I took one in the morning and then at night before bed, and I feel like it really helped with relaxing me, especially being quarantined with two babies 😅 It gets a little hectic and moms need the chill vibes, too.'
    },
    {
      id: '4',
      name: 'Kitty M.',
      rating: '5',
      date: 'April 23, 2021',
      product: 'CBD Icy Gel',
      link: 'https://notpot.com/products/icy-gel',
      title: 'Love the sensation',
      content: 'I love CBD and the benefits, and I love Icy Hot, and this combines them together! I get really painful knees because I’m always carrying the little ones on my hip. I love the sensation and relief this gives me! 10/10'
    },
    {
      id: '5',
      name: 'Lisa K.',
      rating: '5',
      date: 'January 29, 2021',
      product: 'CBD Gummies',
      link: 'https://notpot.com/products/vegan-cbd-gummies/sleep',
      title: 'Finally some relief!',
      content: 'I\'m a mom with a demanding job and an energetic toddler. I have been using Not Pot for about a week, and I can feel a difference with my anxiety and how my body physically manifests my stress. Definitely will repurchase.'
    },
    {
      id: '6',
      name: 'Devan J.',
      rating: '5',
      date: 'April 13, 2021',
      product: 'CBD Glow Oil',
      link: 'https://notpot.com/products/cbd-glow-oil',
      title: 'Glowing mama',
      content: 'A skincare item that is also a tincture? Yes, please. This bottle contains 1000mg of CBD and is great as a skin moisturizing and calming facial oil, or take a dropper under the tongue if you is what needs calming!'
    },
    {
      id: '7',
      name: 'Jordan H.',
      rating: '5',
      date: 'December 29, 2020',
      product: 'CBD Gummies',
      link: 'https://notpot.com/products/vegan-cbd-gummies/sleep',
      title: 'Daughter Purchased, Mother Approved',
      content: 'So good! My mom and I both agree that they help us fall asleep AND stay asleep!'
    },
    {
      id: '8',
      name: 'Raquel G.',
      rating: '5',
      date: 'January 14, 2021',
      product: 'CBD Gummies',
      link: 'https://notpot.com/products/vegan-cbd-gummies/sleep',
      title: 'Mom of young kids...',
      content: 'As a mom of young kids, good sleep is a rarity. These gummies have really helped me get quality rest over the past month!'
    }
  ];

  return (
    <section className="carousel">
      <h2>What cool moms have to say</h2>
      <Slider {...settings}>
        {reviews.map((review) => (

          <div className="review" key={review.id}>
            <a href={review.link}>
              <header>
                <h3>{review.name}</h3>
                <StarRatings
                  rating={5}
                  numberOfStars={5}
                  starDimension="12"
                  starSpacing="0"
                  starEmptyColor="#FFFFFF"
                  starRatedColor="#283377"
                  svgIconViewBox="0 0 13 12"
                  svgIconPath="M6.40742 0.208252L8.14357 3.7812L12.0257 4.35415L9.21657 7.1353L9.87972 11.0624L6.40742 9.20825L2.93513 11.0624L3.59828 7.1353L0.78913 4.35415L4.67128 3.7812L6.40742 0.208252Z"
                />
              </header>
              <div className="date">
                <Image
                  src="/check2x.png"
                  h="32px"
                  w="32px"
                />
                <p>{review.date} <span className="dash">-</span> {review.product}</p>
              </div>
              <div className="review-content">
                <h4>{review.title}</h4>
                <p>{review.content}</p>
              </div>
            </a>
          </div>
        ))}
      </Slider>
    </section>
  )
}

import React, { useEffect, useState } from "react"

import moment from "moment"
import { Div, Text, Row, Col, Button, Icon } from "../../lib/atomize"
import subscriptions from "../../../api/subscriptions"

const YourSubscriptions = ({
  setActivePage,
  reactivateSubscription,
  data,
  setActiveSubscription,
  errors,
  formSubmitting,
}) => {
  // if (!data) {
  //   return <div>Loading</div>
  // }

  return (
    <>
      <Div
        p={{
          y: { xs: "1.875rem", lg: "2.5rem" },
          x: { xs: "1.25rem", lg: "2.5rem" },
        }}
        bg="yellow"
        rounded="lg"
        shadow="4"
      >
        <Text
          textWeight="bold"
          textSize="display1"
          fontFamily="secondary"
          p={{ b: { xs: "1.875rem", lg: "2.5rem" } }}
          style={{ lineHeight: "32px" }}
          textAlign={{ xs: "center", lg: "left" }}
        >
          Your subscriptions
        </Text>
        {data?.map(subscription => {
          return (
            <Div>
              <Div
                border={{ t: "1px solid" }}
                borderColor="borderColor"
                p={{ y: "2.5rem" }}
              >
                <Text
                  textWeight="bold"
                  textSize="header"
                  textTransform="uppercase"
                  m={{ b: { xs: "1.25rem", md: "1.875rem" } }}
                >
                  {subscription.product_title}
                </Text>
                <Row>
                  <Col size={{ xs: "12", lg: "5" }}>
                    <Text m={{ b: "10px" }} textWeight="bold">
                      Product
                    </Text>
                    <Div>
                      {/* {[1, 2, 3].map(id => { */}
                      {/* return */}
                      <Text
                        textColor={
                          subscription.next_charge_scheduled_at
                            ? undefined
                            : "error"
                        }
                      >
                        {subscription.product_title}
                      </Text>
                      {/* })} */}
                    </Div>
                  </Col>
                  <Col size={{ xs: "12", lg: "7" }}>
                    <Div m={{ t: { xs: "10px", md: "0" } }}>
                      <Row d="flex" justify="space-between" m="0">
                        <Col p={{ md: "0" }} size={{ xs: "6", md: "3" }}>
                          <Text textWeight="bold" m={{ b: "10px" }}>
                            Quantity
                          </Text>
                          <Text>{subscription.quantity}</Text>
                        </Col>
                        <Col p={{ md: "0" }} size={{ xs: "6", md: "2" }}>
                          <Text textWeight="bold" m={{ b: "10px" }}>
                            USD
                          </Text>
                          <Text>{subscription.price}</Text>
                        </Col>
                        <Col
                          size={{ xs: "6", md: "4" }}
                          p={{ md: "0", t: { xs: "10px", md: "0" } }}
                        >
                          <Text textWeight="bold" m={{ b: "10px" }}>
                            Frequency
                          </Text>
                          <Text>
                            Every {subscription.charge_interval_frequency} days
                          </Text>
                        </Col>
                        <Col
                          size={{ xs: "6", md: "3" }}
                          p={{ md: "0", t: { xs: "10px", md: "0" } }}
                        >
                          <Text textWeight="bold" m={{ b: "10px" }}>
                            Next Charge
                          </Text>
                          {subscription.next_charge_scheduled_at && (
                            <Text>
                              {moment(
                                subscription.next_charge_scheduled_at
                              ).format("MM/DD/YYYY")}
                            </Text>
                          )}
                          {!subscription.next_charge_scheduled_at && (
                            <Text>Cancelled</Text>
                          )}
                        </Col>
                      </Row>
                    </Div>
                  </Col>
                </Row>

                {subscription.status === "CANCELLED" && (
                  <Div m={{ t: "10px" }}>
                    <Text textWeight="bold">
                      Cancelled:{" "}
                      <Text tag="span" textWeight="normal">
                        {subscription.cancellation_reason}
                      </Text>
                    </Text>
                  </Div>
                )}
                <Text
                  textColor="danger700"
                  m={{ t: "1rem" }}
                  opacity={errors[subscription.id] ? "1" : "0"}
                  transition
                >
                  {errors[subscription.id]}
                </Text>
                {!subscription.next_charge_scheduled_at && (
                  <Div d="flex" m={{ t: "0.875rem" }}>
                    <Button
                      p="13px 1.875rem"
                      bg="pink"
                      fontFamily="secondary"
                      shadow="2"
                      style={{ lineHeight: "100%" }}
                      rounded="20px"
                      textColor="blue"
                      m={{ r: "10px" }}
                      onClick={() => reactivateSubscription(subscription.id)}
                      disabled={formSubmitting.indexOf(subscription.id) !== -1}
                    >
                      Reactivate
                    </Button>
                  </Div>
                )}

                {subscription.status !== "CANCELLED" && (
                  <Div d="flex" m={{ t: "1.875rem" }}>
                    <Button
                      p="13px 1.875rem"
                      bg="pink"
                      fontFamily="secondary"
                      shadow="2"
                      style={{ lineHeight: "100%" }}
                      rounded="20px"
                      textColor="blue"
                      m={{ r: "10px" }}
                      onClick={() => {
                        setActivePage("edit")
                        setActiveSubscription(subscription)
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      p="13px 1.25rem"
                      bg="transparent"
                      border="1px solid"
                      borderColor="borderColor"
                      fontFamily="secondary"
                      style={{ lineHeight: "100%" }}
                      rounded="20px"
                      textColor="blue"
                      m={{ r: "10px" }}
                      onClick={() => {
                        setActivePage("cancel")
                        setActiveSubscription(subscription)
                      }}
                      disabled={formSubmitting.indexOf(subscription.id) !== -1}
                    >
                      cancel
                    </Button>
                  </Div>
                )}
              </Div>
              {/* )
          })} */}
            </Div>
          )
        })}
      </Div>
    </>
  )
}

export default YourSubscriptions

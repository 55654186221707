import _ from "lodash"
import React, {Fragment} from "react"
import {Anchor, Div, Icon, Image, Input, Text} from "../../../lib/atomize"
import axios from "axios"
import * as yup from "yup"
import {Formik} from "formik"
import CardHeader from "../CardHeader"
import BlueButton from "../molecules/BlueButton"

export default function FooterForm(
  {
    notSpamForm, 
    customClass = '', 
    customList = '', 
    closeModal = () => {}, 
    close = false, 
    sideImage = false
  }
) {
  let formikProps
  const listId = notSpamForm.listId ? notSpamForm.listId : 'MQNrva'

  const initialValues = {
    phone: "",
    email: "",
  }

  const onSubmit = values => {
    const {setSubmitting, setStatus, resetForm} = formikProps
    let data = {
      email: values.email,
      listId: customList || listId
    }
    if (values.phone) {
      data.phone = `+1${values.phone}`
    }

    const klaviyoPromise = axios.post(`/api/klaviyo`, data)

    const postscriptPromise = axios.post(`/api/postscript`, {
      phone: values.phone,
    })

    Promise.all([klaviyoPromise, postscriptPromise])
      .then(responses => {
        setSubmitting(false)
        resetForm(initialValues)
        setStatus({
          successMessage:
            "Thanks for signing up — check your email!",
          errorMessage: "",
        })
      })
      .catch((error) => {
        setSubmitting(false)
        setStatus({
          errorMessage: "Something went wrong. Please check your information and try again.",
          successMessage: "",
        })
      })
  }

  let validator = yup.object().shape({
    email: yup
      .string()
      .required("Please use a valid email address.")
      .min(1)
      .email("Please use a valid email address."),
  })

  if (!notSpamForm.noPhone) {
    validator.phone = yup.string().required("Please use a valid phone number.").min(10)
  }

  return (
    <Div rounded="lg" border="1px solid" borderColor="yellowPink" shadow="1" className={customClass}>
      {(notSpamForm && notSpamForm.header && notSpamForm.icon) ?
        <div>
          <CardHeader
            heading={notSpamForm.header}
            image={notSpamForm.icon?.asset?.url}
            padding="0.625rem 0.625rem"
            close={close}
            closeModal={closeModal}
            className="card-header"
          />
          <Div
            bg="yellow"
            p="1.25rem"
            rounded={{b: "lg"}}
            d="flex"
            flexDir="column"
            className="form-wrapper"
          >
            <Div m={{xs: '0 auto'}} d='flex' flexDir='row' className="side-image">
              {
                sideImage &&
                notSpamForm.sideImage?.asset?.url &&
                <Div m={{xs: '0 auto'}} d='flex' align='center' flexDir='row'>
                  <Image
                    src={notSpamForm.sideImage.asset.url}
                    h="80px"
                    w="80px"
                    m={{t: {lg: '0.5rem'}, r: {xs: '0.5rem', lg: '1rem'}}}
                    alt="Not Spam"
                  />
                  <Text d={{lg: 'none', xs: 'block'}}>{notSpamForm.body}</Text>
                </Div>
              }
            </Div>
            {notSpamForm?.renderTitle && <h4>{notSpamForm.title}</h4>}
            {notSpamForm?.extra}
            <Div d='flex' flexDir='column' className="main-form">
              <Text className="body-text">
                {notSpamForm.footer || notSpamForm.body}
              </Text>

              <Formik
                initialValues={{
                  phone: "",
                  email: "",
                }}
                onSubmit={onSubmit}
                validationSchema={validator}
              >
                {props => {
                  const {
                    values,
                    status,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit
                  } = props
                  formikProps = props
                  return (
                    <form onSubmit={handleSubmit}>
                      <Div d="flex" m={{t: "1.25rem"}} flexDir="column">
                        {status && status.successMessage && (
                          <Text
                            textColor="success700"
                            m={{b: "1rem"}}
                            opacity={status.successMessage ? "1" : "0"}
                            transition
                          >
                            {status.successMessage}
                          </Text>
                        )}
                        {status && status.errorMessage && (
                          <Text
                            textColor="danger700"
                            m={{b: "1rem"}}
                            opacity={status.errorMessage ? "1" : "0"}
                            transition
                          >
                            {status.errorMessage}
                          </Text>
                        )}
                        {!notSpamForm.noPhone &&
                        <Fragment>
                          <Input
                            type="text"
                            name="phone"
                            placeholder="Phone Number"
                            placeholderTextColor="navy"
                            shadow="2"
                            textColor="blue"
                            h="2.5rem"
                            border="1px solid"
                            borderColor={
                              touched.phone
                                ? values.phone
                                ? errors.phone
                                  ? "red"
                                  : "oilGreen"
                                : errors.phone
                                  ? "red"
                                  : "transparent"
                                : "transparent"
                            }
                            p="0 2.125rem 0 2.125rem"
                            prefix={
                              <Div
                                d="flex"
                                align="center"
                                transform="translateY(-50%)"
                                pos="absolute"
                                top="50%"
                                left="0.75rem"
                              >
                                <Image src="/phone.svg" w="14px" h="14px" alt="Phone"/>
                              </Div>
                            }
                            value={values.phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <Text
                            textColor="error"
                            textSize="paragraph"
                            m={{t: ".5rem"}}
                          >
                            {touched.phone && _.capitalize(errors.phone)}
                          </Text>
                        </Fragment>
                        }
                        <Input
                          type="email"
                          name="email"
                          placeholder="Email"
                          placeholderTextColor="navy"
                          shadow="2"
                          textColor="blue"
                          h="2.5rem"
                          border="1px solid"
                          borderColor={
                            touched.email
                              ? values.email
                              ? errors.email
                                ? "red"
                                : "oilGreen"
                              : errors.email
                                ? "red"
                                : "transparent"
                              : "transparent"
                          }
                          p="0 2.125rem"
                          prefix={
                            <Image
                              src="/email.svg"
                              w="14px"
                              h="10px"
                              pos="absolute"
                              top="50%"
                              left="0.75rem"
                              transform="translateY(-50%)"
                              alt="Email"

                            />
                          }
                          m={{t: "0.625rem"}}
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="field-email"
                        />
                        <Text
                          textColor="error"
                          textSize="paragraph"
                          m={{t: ".5rem"}}
                        >
                          {touched.email && _.capitalize(errors.email)}
                        </Text>
                        <BlueButton
                          type="submit"
                          m={{t: "0.625rem"}}
                          text={
                            isSubmitting ? (
                              <Icon name="Loading" color="white" size="16px"/>
                            ) : (
                              notSpamForm?.buttonText || 'Subscribe'
                            )
                          }
                          padding="0.5rem 1.25rem"
                          textSize="header1"
                          h="2.5rem"
                          w="100%"
                        />
                      </Div>
                      <Text m={{t: "0.625rem"}} textSize="caption" className="terms">
                        By signing up, you agree to receive recurring automated messages at the email provided. Consent
                        is not a condition to purchase.{" "}
                        <Anchor
                          textColor="blue"
                          hoverTextColor="navy"
                          textDecor="underline"
                          href="/legal/terms"
                        >
                          Terms
                        </Anchor>{" "}
                        &amp;{" "}
                        <Anchor
                          textColor="blue"
                          hoverTextColor="navy"
                          textDecor="underline"
                          href="/legal/privacy-policy"
                        >
                          Privacy{" "}
                        </Anchor>
                        .
                      </Text>
                    </form>
                  )
                }}
              </Formik>
            </Div>
          </Div>
        </div> : ''
      }

    </Div>
  )
}

import {utmActions} from "../actions";

export const initialUtmState = '';
export const utmReducer = (state, action) => {
  switch (action.type) {
    case utmActions.SET_INITIAL_DATA: {
      return ''
    }
    case utmActions.SET_UTM_PARAMS: {
      return action.payload
    }
    default:
      return state;
  }
};

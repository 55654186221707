import React from "react"
import {get} from "lodash"
import {navigate} from "gatsby"
import {Button, Div, Image, Text} from "../../lib/atomize"
import slugify from "slugify"

const ProductVariants = (
  {
    slug,
    variants = [],
    product,
    selectedVariant,
    setSelectedSlide,
    setSelectedVariant,
    type = 'products',
    customSlug = ''
  }
) => {

  return (
    <Div d="flex" flexWrap="wrap">
      {variants.map(variant => {
        if (!variant.deleted) {
          const {customVariantSlug = '', customVariantCartName = '', customName = ''} = variant;
          const computedSlug = slugify(`${variant.variantTitle}`, {
            lower: true,
          })

          const variantLink = variant.custom ? computedSlug : `${customVariantSlug || slug}/${computedSlug}`
          return (
            <Button
              key={variant._id}
              rounded="20px"
              transition=".5s all ease-in-out"
              border="1px solid"
              borderColor={
                variant._id === selectedVariant._id ? "transparent" : "yellowPink"
              }
              p="10px 1.25rem"
              d="flex"
              onClick={() => {
                navigate(`/${type}/${variantLink}`)
                setSelectedVariant(variant)
                setSelectedSlide(0)
              }}
              align="center"
              m={{r: "10px", b: "10px"}}
              className={variant._id === selectedVariant._id ? "variant-btn selected" : "variant-btn"}
            >
              {get(variant, "icon.asset.url") && (
                <Image
                  src={get(variant, "icon.asset.url")}
                  h="1rem"
                  w="1rem"
                  m={{r: "5px"}}
                  alt={customVariantCartName || variant.variantTitle}
                />
              )}
              <Text
                textTransform="uppercase"
                textWeight="bold"
                textColor="blue"
                style={{lineHeight: "14px"}}
                fontFamily="secondary"
                className={
                  variant._id === selectedVariant._id ? "active-variant" : ""
                }
              >
                {customName || variant.variantTitle}
              </Text>
            </Button>
          )
        }
      })}
    </Div>
  )
}

export default ProductVariants

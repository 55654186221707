import React from "react"
import { Div, Row, Col, Text } from "../lib/atomize"
import VideoCard from "../components/common/VideoCard"
import CTALink from "../components/common/CTALink"
import PortableText from "../components/common/PortableText"
import WebsiteContainer from "../components/common/atoms/WebsiteContainer"
import {Collapse} from "../components/blackfriday/Collapse"
import FooterForm from "../components/common/organisms/FooterForm";
import { PageExtra } from "../components/page/PageExtra"
import Social from "../components/blackfriday/Social"
import { SignUpForm } from "../components/blackfriday/SignUpForm"
import { HeroSignUpForm } from "../components/blackfriday/HeroSignUpForm"

export default function ModuleHomeHero({ title, body, cta, video, is404, notSpamForm}) {
  return (
    <Div
      p={{
        t: { xs: "1.875rem", lg: "2.5rem" },
        b: { xs: "0", lg: "2.25rem" },
      }}
      className="bgblocks"
      data-bg="#FDEEFF"
    >
      <WebsiteContainer>
        {cta.title === 'blackfriday' ?
        <>
          <Row align="center">
            <Col
              size={{ xs: "12", lg: "7", xl: "7" }}
              p={{ xs: "0 8px", lg: "0 0 0 8px", xl: "0" }}
              order={{ xs: "2", md: "1"}}
            >
              <Div
                shadow="1"
                m={{ t: { xs: "2.5rem", lg: "0" } }}
                border="1px solid"
                borderColor="yellowPink"
                rounded="lg"
              >
                <VideoCard {...video} mobRatio={is404 ? "1:1" : undefined} />
              </Div>
            </Col>
            <Col
              size={{ xs: "12", lg: "4", xl: "4" }}
              offset={{ xs: "0", lg: "1" }}
              order={{ xs: "1", md: "2"}}
            >
              <HeroSignUpForm />
            </Col>
          </Row>
          <div className="bf-logo-list">
            <div className="logo">
              <img src="/pr/black-friday/forbes-logo.png" />
            </div>
            <div className="logo">
              <img src="/pr/black-friday/daily-beast-logo.png" />
            </div>
            <div className="logo">
              <img src="/pr/black-friday/refinery29-logo.png" />
            </div>
            <div className="logo">
              <img src="/pr/black-friday/allure-logo.png" />
            </div>
          </div>
        </>    
        :
        cta.title === 'faq' ?
          <>
            <Row className="bf-faq">
              <Col
                size={{ xs: "12", lg: "6", xl: "7" }}
                p={{ xs: "0 8px", lg: "0 0 0 8px", xl: "0" }}
              >
                <Div
                  shadow="1"
                  m={{ t: { xs: "2.5rem", lg: "0" } }}
                  border="1px solid"
                  borderColor="yellowPink"
                  rounded="lg"
                >
                  <VideoCard {...video} mobRatio={is404 ? "1:1" : undefined} />
                </Div>
              </Col>
              <Col
                size={{ xs: "12", lg: "5", xl: "4" }}
                offset={{ xs: "0", lg: "1" }}
              >
                <Text
                  textTransform="uppercase"
                  m={{t: {xs: "2rem"}}}
                  p={{b: "1.25rem"}}
                  textWeight="bold"
                  textSize="display1"
                  fontFamily="secondary"
                  textAlign={{xs: "center", lg: "left"}}
                  tag="h3"
                >FAQ</Text>
                  <Collapse />
              </Col>
            </Row>

            <Social />

            <div className="box">
              <SignUpForm />
            </div>
          </>
          :
          <Row>
            <Col
              size={{ xs: "12", lg: "5", xl: "4" }}
              p={{ xs: "0 8px", lg: "0 0 0 8px", xl: "0" }}
            >
              <Div h="100%" align="center" d="flex" className="modulehomeHero">
                <Div
                  d="flex"
                  flexDir="column"
                  align={{ xs: "center", lg: "flex-start" }}
                  textAlign={{ xs: "center", lg: "left" }}
                >
                  <Div className="hero-title">
                    <h2><PortableText blocks={title} /></h2>
                  </Div>
                  <Div className="hero-description">
                    <PortableText blocks={body} />
                  </Div>
                  <Div
                    m={{
                      t: { xs: is404 ? "40px" : "4rem", lg: "4rem" },
                      b: is404 ? "100px" : "0px",
                    }}
                    order="1"
                    d={{ xs: is404 ? "block" : "none", lg: "block" }}
                  >
                    <CTALink minW="9.625rem" p="0 3rem" {...cta} />
                  </Div>
                </Div>
              </Div>
            </Col>
            <Col
              size={{ xs: "12", lg: "6", xl: "7" }}
              offset={{ xs: "0", lg: "1" }}
            >
              <Div
                shadow="1"
                m={{ t: { xs: "2.5rem", lg: "0" } }}
                border="1px solid"
                borderColor="yellowPink"
                rounded="lg"
              >
                <VideoCard {...video} mobRatio={is404 ? "1:1" : undefined} />
              </Div>
              <Div
                m={{ t: "3rem" }}
                d={{ xs: is404 ? "none" : "flex", lg: "none" }}
                justify="center"
              >
                <CTALink {...cta} minW="9.625rem" p="0 3rem" />
              </Div>
            </Col>
          </Row>
      }
      </WebsiteContainer>
    </Div>
  )
}

import React from "react"
import {PRSlider} from "../components/common/organisms/PRSlider"

export default function ModuleSocialMediaPosts(
  {
    title,
    posts = []
  }
) {
  return (
    <PRSlider posts={posts}/>
  )
}

import React, {useEffect, useState} from 'react';
import Layout from "../../components/common/Layout";
import Helmet from "react-helmet";
import WebsiteContainer from "../../components/common/atoms/WebsiteContainer";
import {Col, Div, Row, Input, Image} from "../../lib/atomize";
import VideoCard from "../../components/common/VideoCard";
import {graphql} from "gatsby";
import { Card } from '../../components/common/Card';
import BlueButton from "../../components/common/molecules/BlueButton"
import FormEmail from '../../components/common/organisms/FormEmail';
import { LazyLoadImage } from "react-lazy-load-image-component"

const CardGame = ({data}, props) => {
  const [activeItem, setActiveItem] = useState('initial')
  const [shuffle, setShuffle] = useState(false)

  const {site} = data
  const PagePath = props?.location?.pathname || props.uri;
  const pageClass = (PagePath ? PagePath.replace(/\//g, '') : 'home')

  const cardList = [
    {
      key: '1',
      text: 'Your enemies are en route. Beat these baddies by being bold, brave, and blunt.',
    },
    {
      key: '2',
      text: 'It\'s sink or swim! Stand tall on your tail and speak your truth.',
    },
    {
      key: '3',
      text: 'Don\'t clam up when apologizing. Choose peace over pride.',
    },
    {
      key: '4',
      text: 'Dive in, head (or heart!) first. Your dreams are waiting beneath the surface.',
    },
    {
      key: '5',
      text: 'In your feels? Don\'t worry, hugs are otw.',
    },
    {
      key: '6',
      text: 'Dreaming big? Avoid nightmares by prioritizing your inner peace.',
    },
    {
      key: '7',
      text: 'Forgiveness is showing love for others, but more importantly, for yourself.',
    },
    {
      key: '8',
      text: 'I see you manifesting. Keep vibing, your vision is being actualized!',
    },
    {
      key: '9',
      text: 'Don\'t underestimate yourself. You are creative! You are independent! you are powerful af!',
    },
    {
      key: '10',
      text: 'Rise up! Be ruthless! Rediscover your powers and reclaim what belongs to you!',
    },
    {
      key: '11',
      text: 'Keep flying. You\'re on route to living the high life.',
    },
    {
      key: '12',
      text: 'Doors (and portals!) are opening for you. Spread your wings and soar through them.',
    },
    {
      key: '13',
      text: 'The journey is long, but the view is worth it! Take a breath, then take your next step.',
    },
    {
      key: '14',
      text: 'I sense a blockage. Turn around and try again, your inner strength will serve you well.',
    },
    {
      key: '15',
      text: 'Life is ripe with change. Hang in there, good things are coming.',
    },
    {
      key: '16',
      text: 'Looks like someone in your life is monkeying around. Get ready to split and learn from the stitch.',
    },
    {
      key: '17',
      text: 'New ideas, new projects, and new adventures are baking. Get \'em while they\'re hot!',
    },
    {
      key: '18',
      text: 'You\'re entering your boss era. Trust yourself and more opportunities will start to rise.',
    },
    {
      key: '19',
      text: 'Someone new in your life? They could be your next best bud.',
    },
    {
      key: '20',
      text: 'This situation smells fishy. Time to swim away and start fresh.',
    },
    {
      key: '21',
      text: 'Chill out, you\'re in good company.',
    }
  ]

  const howList = [
    {
      key: '1',
      title: 'Take a deep breath',
      sub: 'In through your nose, out through your mouth. One more time. Everything is going to be ok.'
    },
    {
      key: '2',
      title: 'Ask your question',
      sub: 'If you’re struggling to think of one, simply ask: "what do I need to know most right now?"'
    },
    {
      key: '3',
      title: 'Pick a card',
      sub: 'Trust your intuition and click on a card from the Oracle Deck. The answer will reveal itself.'
    },
    {
      key: '4',
      title: 'Read your fortune',
      sub: 'Read the card, ruminate on the message, then go and make your fortune come true.'
    },
  ]

  const random = cardList[Math.floor(Math.random() * cardList.length)]

  const cards = 10

  const handleCardClick = () => {
    setActiveItem('result')
  }

  const showDeck = () => {
    setActiveItem('deck')
    document.body.classList.remove('show-footer-card')
    setTimeout(function() {
      setShuffle(true)
    }, 500)
  }

  const customAction = () => {
    setTimeout(function() {
      setActiveItem('how')
    }, 2000)
  }
  
  useEffect(() => {
    document.body.classList.add('show-footer-card')
  }, [])

  return (
    <Layout site={site} path={PagePath} pageClass={`page-wrapper oracle page-${pageClass}`}>
      <Helmet>
        <title>Oracle Card Game</title>
      </Helmet>

      <WebsiteContainer style={{position: "relative"}}>
      

      {activeItem === 'initial' &&
        <section className="card-game">
          <Card 
            customClass="initial-tl"
            imgSrc="/oracle/wp-1.png" />
          <Card 
            customClass="initial-tr"
            imgSrc="/oracle/wp-2.png"/>

          <Row m={{t: {lg: '5rem', xs: '3rem'}, b: {lg: '10rem'}}} align="center">
            <Col size={{xs: "12"}}>
              <Div
                d="flex"
                flexDir="column"
                align={{xs: "center"}}
              >
                <Div className="mobile-cards">
                  <LazyLoadImage
                    src="/cards.png"
                    width="100%"
                    effect="blur"
                  />
                </Div>
                <Div className="hero-title">
                  <h2><p>RELEASE ANXIETY, REVEAL YOUR FUTURE</p></h2>
                </Div>
                <Div className="hero-description">
                  <p>It’s like tarot, but cuter. Enter your email below to have your fortune told by our Oracle Deck. (The Universe has hinted that there’s an extra 15% off in your future, too.)</p>
                </Div>
                <Div className="form">
                  <div className="footer-form">
                    <FormEmail
                      notSpamForm={
                        {
                          noPhone: true,
                          title: false,
                          footer: false,
                          renderTitle: false,
                          buttonText: 'SIGN UP & PICK A CARD',
                        }
                      }
                      //customClass="bf-hero-form"
                      customList="Uu4zUz"
                      customAction={customAction}
                    />
                  </div>
                </Div>
              </Div>
            </Col>
          </Row>
        </section>
      }

      {activeItem === 'how' &&
        <section className="how">
          <Card 
            customClass="initial-tl"
            imgSrc="/oracle/wp-1.png" />
          <Card 
            customClass="initial-tr"
            imgSrc="/oracle/wp-2.png"/>

          <Row m={{t: {lg: '5rem', xs: '3rem'}, b: {lg: '10rem'}}} align="center">
            <Col size={{xs: "12"}}>
              <Div
                d="flex"
                flexDir="column"
                align={{xs: "center"}}
                textAlign={{xs: "center"}}
              >
                <Div className="mobile-cards">
                  <LazyLoadImage
                    src="/cards.png"
                    width="100%"
                    effect="blur"
                  />
                </Div>
                <Div className="hero-title">
                  <h2><p>How it works</p></h2>
                </Div>
                <Div className="hero-description">
                  <p>Get soul guidance from the Universe with our Oracle Deck.</p>
                </Div>

                <div className="content">
                  <Row m={{t: {xs: '2rem'}, b: {xs: '3rem'}}} align="center">
                    {howList.map(item =>
                      <Col size={{xs: "12", lg: "6"}} p="0 3rem" key={item.key}>
                        <div className="item">
                          <div className="count">{item.key}</div>
                          <div className="details">
                            <h4>{item.title}</h4>
                            <p>{item.sub}</p>
                          </div>
                        </div>
                      </Col>
                      )}
                  </Row>
                  <BlueButton
                    m={{xs: "0 auto"}}
                    text={`I'M READY!`}
                    padding="0.5rem 1.25rem"
                    textSize="header1"
                    h="3rem"
                    w="100%"
                    maxW="254px"
                    onClick={showDeck}
                  />
                </div>
              </Div>
            </Col>
          </Row>
        </section>
      }

      {activeItem === 'deck' &&
        <section className="deck">
          <Div className="hero-title">
            <h2><p>TIME TO PICK A CARD</p></h2>
          </Div>
          <div 
            className={shuffle? "card-deck" : "card-deck group"}>
            {
              [...Array(cards)].map((e, i) =>
                <Card 
                  key={i}
                  handleCardClick={handleCardClick}
                />
              )
            }
          </div>
        </section>
      }

      {activeItem === 'result' &&
        <section className="card-result-page">

          <Row m={{t: {lg: '5rem', xs: '3rem'}, b: {lg: '10rem'}}} align="center">
            <Col
              size={{xs: "12", lg: "5", xl: "4"}}
            >
              <Div className="image">
                <LazyLoadImage
                  src={`/oracle/${random.key}.png`}
                  width="100%"
                  effect="blur"
                />
              </Div>
            </Col>
            <Col
              size={{xs: "12", lg: "6", xl: "7"}}
              offset={{xs: "0", lg: "1"}}
            >
              <Div className="details">
                <h3>{random.text}</h3>
                <p>This is your message from the Universe. Want another one?</p>
                <BlueButton
                  m={{xs: "2rem auto", md: "2rem 0 0"}}
                  text='Pick a new card'
                  padding="0.5rem 1.25rem"
                  textSize="header1"
                  h="3rem"
                  w="100%"
                  maxW="254px"
                  onClick={() => {
                    setActiveItem('deck')
                  }}
                />
              </Div>
            </Col>
          </Row>

        </section>
      }

      </WebsiteContainer>
    </Layout>
  )
}

export default CardGame;

export const query = graphql`
  query CardGame {
    page: sanityPage(_id: { regex: "/(drafts.|)frontpage/" }) {
      ...PageInfo
    }

    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SiteSettings
    }
  }
`

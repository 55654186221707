import React from "react"

import { SocialPostSlider } from "../../components/common/organisms/SocialPostSlider"

export default function Social(
    {
        title = '',
        subtitle = '',
        posts = []
    }
) {

  return (
    <SocialPostSlider
        title={title || "We love our partners"}
        subtitle={subtitle || "Share your stash with @notpot"}
        posts={posts.length && posts || [
            {
                id: '1',
                image: {
                    asset:{
                        url: '/wholesale/assets/social/bhavawellness.jpeg',
                    }
                },
                avatar: {
                    asset:{
                        url: '/wholesale/assets/social/thumb/bhavawellness.png'
                    }
                },
                name: 'Bhava Shop',
                handle: '@bhavawellness'
            },
            {
                id: '2',
                image: {
                    asset:{
                        url: '/wholesale/assets/social/youresocoup.jpeg',
                    }
                },
                avatar: {
                    asset:{
                        url: '/wholesale/assets/social/thumb/youresocoup.png'
                    }
                },
                name: 'Coup D’état',
                handle: '@youresocoup'
            },
            {
                id: '3',
                image: {
                    asset:{
                        url: '/wholesale/assets/social/heydaybozeman.jpeg',
                    }
                },
                avatar: {
                    asset:{
                        url: '/wholesale/assets/social/thumb/heydaybozeman.png'
                    }
                },
                name: 'Heyday',
                handle: '@heydaybozeman'
            },
            {
                id: '4',
                image: {
                    asset:{
                        url: '/wholesale/assets/social/interwovenshop.jpeg',
                    }
                },
                avatar: {
                    asset:{
                        url: '/wholesale/assets/social/thumb/interwovenshop.png'
                    }
                },
                name: 'Interwoven',
                handle: '@interwovenshop'
            },
            {
                id: '5',
                image: {
                    asset:{
                        url: '/wholesale/assets/social/nolongerwander.jpeg',
                    }
                },
                avatar: {
                    asset:{
                        url: '/wholesale/assets/social/thumb/nolongerwander.png'
                    }
                },
                name: 'No Longer Wander',
                handle: '@nolongerwander'
            },
            {
                id: '6',
                image: {
                    asset:{
                        url: '/wholesale/assets/social/mascotaesthetic.jpeg',
                    }
                },
                avatar: {
                    asset:{
                        url: '/wholesale/assets/social/thumb/mascotaesthetic.png'
                    }
                },
                name: 'Mascot Shop',
                handle: '@mascotaesthetic'
            },
        ]}
    />
  )
}
import React, {Fragment} from "react"
import BlockContent from "@sanity/block-content-to-react"


export const BundleItem = (
  {
    id = '',
    name = '',
    desc = '',
    image = '',
    slug = '#',
    pax=1
  }) => {
  return (
    <Fragment>
      <div className="bundle-item" key={id}>
        <div className="image">
          <a href={slug} className="mobile">
            <img src={image} alt={name} />
          </a>
          <a href={slug} className="desktop" target="_blank">
            <img src={image} alt={name} />
          </a>
        </div>
        <div className="desc">
          <p>
            <a href={slug} className="mobile">
              <span className="item-name">{name}</span>
            </a>
            <a href={slug} className="desktop" target="_blank">
              <span className="item-name">{name}</span>
            </a>
            <BlockContent blocks={desc} />
          </p>
        </div>
      </div>
    </Fragment>

  )
}

import React, { Fragment, useState } from "react"
import FooterForm from "../common/organisms/FooterForm"


export const HeroSignUpForm = ({
    
}) =>{

	return (
		<>
		<FooterForm
			notSpamForm={
				{
					header: 'Black Friday',
					icon: {
						asset: {
							url: 'https://cdn.sanity.io/images/bkrzj7um/production/1f55ae222c200b0c8ed8359ea63ad884d80eb994-16x16.png?w=1000&h=1000&fit=max'
						}
					},
					noPhone: true,
					title: 'GET HALF OFF ON BLACK FRIDAY',
					footer: 'Want up to 50% off all Not Pot products this Black Friday, plus a free item? Sign up now to save while you spend. ',
					renderTitle: 'Not Spam',
					buttonText: 'Sign me up!',
				}
			}
			customClass="bf-hero-form"
			customList="WEgGRg"
		/>
		</>
	)
}
import React, {Fragment, useState} from "react";
import {Link} from "gatsby";
import {isEmpty} from "lodash";
import {Div, Icon, Text} from "../../lib/atomize";

import CardHeader from "../common/CardHeader";
import BlueButton from "../common/molecules/BlueButton";
import PortableText from "../common/PortableText";
import {getProductFeatureImage, getProductHoverImage,} from "../../utilities/helpers";
import ImageLazyLoad from "../common/molecules/ImageLazyLoad";
import DynamicRating from "../../components/reviews/DynamicRating"

export default function ProductCard(
  {
    product,
    index,
    isFeatureAvailable,
    onClickAddToCartButton,
    disableCTA,
    memberSaveTitle = false,
    type = 'products',
    bundles = [],
    enableButtonLink = false,
    ctaText = '',
    isCYOB = false,
    disableAnchor = false,
    customLink = '',
    addBundleItem = () => {
    },
    openCYOModal = () => {
    }
  }
) {
  const [loading, setLoading] = useState(false)
  const itemsWithOutRating = ['']
  const handleMouseEnter = () => {
    if (!getProductHoverImage(product)) return
    const card = document.getElementById(`product-img-${product._id}-${index}`)
    card.style["opacity"] = 0
    const hoverCard = document.getElementById(`product-hover-${product._id}-${index}`)
    hoverCard.style["opacity"] = 1
  }

  const handleMouseLeave = () => {
    if (!getProductHoverImage(product)) return
    const card = document.getElementById(`product-img-${product._id}-${index}`)
    card.style["opacity"] = 1
    const hoverCard = document.getElementById(`product-hover-${product._id}-${index}`)
    hoverCard.style["opacity"] = 0
  }

  const {defaultVariant = {}, items = []} = product;
  const {inventory_quantity = 1, original_price = 0, price: defaultVariantPrice = 0} = defaultVariant;

  const savePrice = defaultVariantPrice && defaultVariantPrice - 4.20 || 0

  return (
    <Div
      rounded="lg"
      border="1px solid"
      borderColor="lightPink"
      shadow="1"
      className="card-item"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Link
        to={`${type ? `/${type === 'cyo' ? 'build-a-bundle' : type}` : ''}/${product?.customProductSlug || product.slug && product.slug.current || ''}`}
        style={{color: 'inherit', pointerEvents: `${type === 'cyo' ? 'none' : 'auto'}`}}
        onClick={(e) => {
          if (disableAnchor) {
            e.preventDefault()
          }
          if (customLink) {
            window.location.href = customLink;
          }
        }}
      >
        {/* Card Header for Desktop */}
        <CardHeader
          heading={product.desktopTitle || product.title}
          padding="0.625rem 0.625rem"
          className="card-item-header"
        />
        {/* Card Header for Mobile */}
        <CardHeader
          heading={product.mobileTitle || product.desktopTitle || product.title}
          padding="0.625rem 0.625rem"
          className="card-item-header-mob"
        />
        <Div bg="yellow" rounded={{b: "lg"}} d="flex" flexDir="column">
          <Div
            p="0.5rem 1.25rem"
            textAlign="center"
            bg="#ffffff"
            className="card-item-sub-header"
          >
            {product.description ? (
              <PortableText blocks={product.description}/>
            ) : (
              <>&nbsp;</>
            )}
          </Div>
          <Div
            bg="gray"
            p="1.25rem"
            className="card-item-img-container a"
            pos="relative"
          >
            {/* <Div
              id={`product-img-${product._id}`}
              bgImg={getProductFeatureImage(product)}
              p={{ b: "100%" }}
              bgSize="cover"
              bgPos="center"
              transition
            /> */}

            <ImageLazyLoad
              id={`product-img-${product._id}-${index}`}
              bgImg={getProductFeatureImage(product)}
              bgPos="center"
              bgSize="cover"
              p={{b: "100%"}}
              transition
            >
              <img src={getProductFeatureImage(product)}
                   alt={product.desktopTitle || product.title}
                   className="product-img"
              />
            </ImageLazyLoad>

            <Div
              bg="gray"
              p="1.25rem"
              className="card-item-img-container b"
              id={`product-hover-${product._id}-${index}`}
              transition
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                opacity: 0,
              }}
            >
              <ImageLazyLoad
                bgImg={getProductHoverImage(product)}
                p={{b: "100%"}}
                bgSize="cover"
                bgPos="center"
                transition
              >
                <img src={getProductHoverImage(product)}
                     alt={product.desktopTitle || product.title}
                     className="product-img"
                />
              </ImageLazyLoad>
            </Div>
          </Div>

          {memberSaveTitle ?
            <Fragment>
              <div className="membership-footer">
                <h4>Members Save <span>$4.20</span></h4>
                <div className="price">
                  <span className="old">
                    ${Number.isInteger(parseFloat(original_price || 0)) ?
                    product && product.defaultVariant && product.defaultVariant.price && parseFloat(product?.defaultVariant?.price).toFixed(2) : product?.defaultVariant?.price}
                  </span>
                  <span className="save">
                    ${parseFloat(savePrice).toFixed(2)}
                  </span>
                </div>
              </div>
            </Fragment> :


            isFeatureAvailable ?
              <Text
                fontFamily="secondary"
                textWeight="700"
                textSize="title1"
                m={{t: "1.25rem"}}
              >
                {product.originalProductPrice || original_price ?
                  <div className="price">
                    <span className="old">${original_price || product.originalProductPrice}</span>
                  </div> : ''
                }

                ${Number.isInteger(
                parseFloat(product?.defaultVariant?.price)) ?
                parseFloat((product?.defaultVariant?.price)) :
                product?.defaultVariant?.price
              }
              </Text> : ''
          }

          {!disableCTA ?
            isFeatureAvailable ? (
              inventory_quantity ? (
                <>
                  {product && product.slug && product.slug.current &&
                  itemsWithOutRating.includes(product.slug.current)
                    ? <div className="spacer"/>
                    : <DynamicRating slug={product.slug.current}/>
                  }
                  <BlueButton
                    m="1.25rem auto 2rem"
                    text="Add to bag"
                    // maxW="9.5rem"
                    className="small-font"
                    w="80%"
                    onClick={e => {
                      e.preventDefault()
                      setLoading(true)
                      onClickAddToCartButton(isEmpty(bundles) ? product.variants[0] : {
                        bundles: bundles,
                        item: product.defaultVariant
                      }, setLoading)
                    }}
                    disabled={loading}
                    prefix={
                      <Icon
                        name="Loading"
                        pos="absolute"
                        top="50%"
                        left="8.5%"
                        transform="translateY(-50%)"
                        size="25px"
                        color="white"
                        className="load-icon"
                        style={{
                          zIndex: 1,
                          opacity: loading ? 1 : 0,
                        }}
                        m={{r: "0.5rem"}}
                      />
                    }
                  />
                </>
              ) : (
                <>
                  {product && product.slug && product.slug.current &&
                  <DynamicRating slug={product.slug.current}/>
                  }
                  <BlueButton
                    m="1.25rem auto 2rem"
                    text="Waitlist"
                    maxW="9.5rem"
                    w="100%"
                  />
                </>
              )
            ) : (
              <BlueButton
                m="1.25rem auto 2rem"
                text="Shop"
                maxW="9.5rem"
                w="100%"
              />
            ) : ''
          }
          {
            enableButtonLink &&
            <BlueButton
              m="1.25rem auto 2rem"
              text={ctaText || "Go!"}
              maxW="12.5rem"
              w="100%"
              h="3.125rem"
              textSize="1.25rem"
              onClick={(e) => {
                if (customLink) {
                  e.preventDefault()
                  window.location.href = customLink
                }
              }}
            />

          }
          {
            isCYOB &&
            <div>
              <div className="card-button">
                <BlueButton
                  text="Add"
                  maxW="12.5rem"
                  h="2rem"
                  w="65px"
                  textSize="1rem"
                  style={{pointerEvents: 'auto'}}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    addBundleItem(defaultVariant)
                  }}
                />

                <BlueButton
                  text="Quick View"
                  maxW="12.5rem"
                  type="button"
                  h="2rem"
                  style={{pointerEvents: 'auto'}}
                  textSize=".625rem"
                  className="solid"
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    openCYOModal(product)
                  }}
                />

              </div>
            </div>
          }
        </Div>

      </Link>
    </Div>
  )
}

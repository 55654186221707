import React from "react"
import { graphql } from "gatsby"

import Page from "../templates/Page"
import Errors from "../components/common/Errors"

/**
 * homepage
 *
 * @param {*} props
 */
const IndexPage = props => {
  const { data, errors } = props

  if (errors) {
    return <Errors errors={errors} />
  }
 // posthog.config._onCapture = (event) => console.log(event)
  return <Page data={data} />
}

export default IndexPage

export const query = graphql`
  query FrontpageQuery {
    page: sanityPage(_id: { regex: "/(drafts.|)frontpage/" }) {
      ...PageInfo
    }

    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SiteSettings
    }
  }
`

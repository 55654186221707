import React from 'react';
import { Row, Col } from "../../../lib/atomize"

export const PRLogoList = ({
  logos = []
}) => {

  return (
    <div className="pr-logo-list">
      <div className="list">
        {logos.map((logo, index) => (
          <div className="item" key={logo._key}>
            <img src={logo.src} alt={logo.alt} />
          </div>
        ))}
      </div>
    </div>
  );
}

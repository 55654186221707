import React, {Fragment, useState} from "react"
import {Link} from "gatsby"
import {Div, Icon, Text} from "../../lib/atomize"

import CardHeader from "../common/CardHeader"
// import Button from "../common/molecules/BlueButton"
import BlueButton from "../common/molecules/BlueButton"
import PortableText from "../common/PortableText"
import {getVariantFeatureImage, getVariantHoverImage,} from "../../utilities/helpers"
import ImageLazyLoad from "../common/molecules/ImageLazyLoad"
import DynamicRating from "../../components/reviews/DynamicRating"

export default function VariantCard(
  {
    variant,
    index,
    isFeatureAvailable,
    onClickAddToCartButton,
    disableCTA,
    memberSaveTitle,
    isAddToBag = false
  }
) {
  const [loading, setLoading] = useState(false)
  const {klaviyoListId = ''} = variant

  const handleMouseEnter = () => {
    if (!getVariantHoverImage(variant)) return
    const card = document.getElementById(`variant-img-${variant._id}`)
    card.style["opacity"] = 0
    const hoverCard = document.getElementById(`variant-hover-${variant._id}`)
    hoverCard.style["opacity"] = 1
  }
  const handleMouseLeave = () => {
    if (!getVariantHoverImage(variant)) return
    const card = document.getElementById(`variant-img-${variant._id}`)
    card.style["opacity"] = 1
    const hoverCard = document.getElementById(`variant-hover-${variant._id}`)
    hoverCard.style["opacity"] = 0
  }

  const {
    title = '',
    inventory_quantity = 0,
    original_price = 0,
    customVariantSlug = '',
    productHandle = '',
    variantTitle = '',
    desktopTitle = '',
    mobileTitle = '',
  } = variant

  return (
    <Div
      rounded="lg"
      border="1px solid"
      borderColor="lightPink"
      shadow="1"
      className="card-item"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Link
        to={`/products/${customVariantSlug || productHandle
        }/${variantTitle.toLowerCase().replaceAll(' ', '-')}`}
        style={{color: "inherit"}}
      >
        {/* Card Header for Desktop */}
        <CardHeader
          heading={desktopTitle || title}
          padding="0.625rem 0.625rem"
          className="card-item-header"
        />
        {/* Card Header for Mobile */}
        <CardHeader
          heading={mobileTitle || desktopTitle || title}
          padding="0.625rem 0.625rem"
          className="card-item-header-mob"
        />
        <Div bg="yellow" rounded={{b: "lg"}} d="flex" flexDir="column">
          <Div
            p="0.5rem 1.25rem"
            textAlign="center"
            bg="#ffffff"
            className="card-item-sub-header"
          >
            {variant.caption ? (
              <PortableText blocks={variant.caption}/>
            ) : (
              <>&nbsp;</>
            )}
          </Div>
          <Div
            bg="gray"
            p="1.25rem"
            className="card-item-img-container a"
            pos="relative"
          >
            {/* <Div
              id={`variant-img-${variant._id}`}
              bgImg={getVariantFeatureImage(variant)}
              p={{ b: "100%" }}
              bgSize="cover"
              bgPos="center"
              transition
            /> */}

            <ImageLazyLoad
              id={`variant-img-${variant._id}`}
              bgImg={getVariantFeatureImage(variant)}
              bgPos="center"
              bgSize="cover"
              p={{b: "100%"}}
              transition
            >
              <img src={getVariantFeatureImage(variant)}
                   alt={desktopTitle || title}
                   className="product-img"
              />
            </ImageLazyLoad>

            <Div
              bg="gray"
              p="1.25rem"
              className="card-item-img-container b"
              id={`variant-hover-${variant._id}`}
              transition
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                opacity: 0,
              }}
            >
              <ImageLazyLoad
                bgImg={getVariantHoverImage(variant)}
                bgSize="cover"
                bgPos="center"
                p={{b: "100%"}}
                transition
              >
                <img src={getVariantHoverImage(variant)}
                     alt={desktopTitle || title}
                     className="product-img"
                />
              </ImageLazyLoad>
            </Div>
          </Div>

          {memberSaveTitle ?
            <Fragment>
              <div className="membership-footer">
                <h4>Members Save <span>$4.20</span></h4>
                <div className="price">
                  <span
                    className="old">${Number.isInteger(parseFloat(variant?.price)) ? parseFloat(variant?.price).toFixed(2) : variant?.price}</span>
                  <span className="save">${parseFloat((variant?.price || 0 - 4.20)).toFixed(2)}</span>
                </div>
              </div>
            </Fragment>
            :
            isFeatureAvailable ?
              <Text
                fontFamily="secondary"
                textWeight="700"
                textSize="title1"
                m={{t: "1.25rem"}}
              >
                {original_price ?
                  <div className="price">
                    <span className="old">${original_price}</span>
                  </div> : ''
                }

                ${Number.isInteger(parseFloat(variant?.price)) ? parseFloat(variant?.price) : variant?.price}

              </Text> : ''
          }
          {(klaviyoListId || inventory_quantity === 0) &&
          <>
            {productHandle && isAddToBag &&
            <DynamicRating slug={productHandle.toLowerCase().replaceAll(' ', '-')}/>
            }
            <BlueButton
              m="1.25rem auto 2rem"
              text="Waitlist"
              maxW="9.5rem"
              w="100%"
            />
          </>
          }
          {(!disableCTA && !klaviyoListId && inventory_quantity !== 0) ?
            isFeatureAvailable ? (
              <>
                {productHandle &&
                <DynamicRating slug={productHandle.toLowerCase().replaceAll(' ', '-')}/>
                }
                <BlueButton
                  m="1.25rem auto 2rem"
                  text="Add to bag"
                  // maxW="9.5rem"
                  className="small-font"
                  w="80%"
                  onClick={e => {
                    e.preventDefault()
                    setLoading(true)
                    onClickAddToCartButton(variant, setLoading)
                  }}
                  disabled={loading}
                  prefix={
                    <Icon
                      name="Loading"
                      pos="absolute"
                      top="50%"
                      left="8.5%"
                      transform="translateY(-50%)"
                      size="25px"
                      color="white"
                      className="load-icon"
                      style={{
                        zIndex: 1,
                        opacity: loading ? 1 : 0,
                      }}
                      m={{r: "0.5rem"}}
                    />
                  }
                />
              </>
            ) : (
              <BlueButton
                m="1.25rem auto 2rem"
                text="Shop"
                maxW="9.5rem"
                w="100%"
              />
            )
            : ''
          }
        </Div>
      </Link>
    </Div>
  )
}

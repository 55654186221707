import React from 'react';
import Layout from "../../components/common/Layout";
import Helmet from "react-helmet";
import {graphql} from "gatsby";
import {Col, Div, Row, Text} from "../../lib/atomize"
import WebsiteContainer from "../../components/common/atoms/WebsiteContainer"
import BlueButton from "../../components/common/molecules/BlueButton"
import CardHeader from "../../components/common/CardHeader"
import VideoOrImage from "../../components/common/molecules/VideoOrImage"

const CBDQuizLanding = ({data}, props) => {
  const {site} = data
  const PagePath = props?.location?.pathname || props.uri;
  return (
    <Layout site={site} path={PagePath} pageClass="page-wrapper">
      <Helmet>
        <title>CBD Quiz</title>
      </Helmet>
      <Div className="wrapper">

        <>
        <Div
          className="bgblocks"
          data-bg="#FDEEFF"
        >
          <WebsiteContainer>
            <Row>
              <Col
                size={{xs: "12", lg: "5", xl: "4"}}
              >
                <Div h="100%" align="center" d="flex" className="modulehomeHero">
                  <Div
                    d="flex"
                    flexDir="column"
                    align={{xs: "center", lg: "flex-start"}}
                    textAlign={{xs: "center", lg: "left"}}
                  >
                    <Div className="hero-title">
                      <h2><p>MEET YOUR NEW BEST bud</p></h2>
                    </Div>
                    <Div className="hero-description">
                      <p>If you need help finding the right CBD product for you, this quiz is at your service! Plus, you
                        get a special code. *wink*</p>
                    </Div>
                    <BlueButton
                      m={{xs: "2rem auto", md: "2rem 0 0"}}
                      text='Take the cbd quiz'
                      padding="0.5rem 1.25rem"
                      textSize="header1"
                      h="3rem"
                      w="100%"
                      maxW="254px"
                      onClick={() => {
                        window.location.href = '/cbd-quiz/start'
                      }}
                    />
                  </Div>
                </Div>
              </Col>
              <Col
                size={{xs: "12", lg: "6", xl: "7"}}
                offset={{xs: "0", lg: "1"}}
              >
                <Div
                  shadow="1"
                  border="1px solid"
                  borderColor="yellowPink"
                  rounded="lg"
                >
                  <>
                    <CardHeader
                      heading="QUIZTIME"
                      image="https://cdn.sanity.io/images/bkrzj7um/production/874afd4d0959e1ba52cd8a62bc0a936ce5a75989-17x16.svg"
                    />
                    <Div p="0.5rem" bg="white" textAlign="center">

                      <Text
                        style={{lineHeight: "12px"}}
                      >
                        best_buds.jpg
                      </Text>
                    </Div>
                    <VideoOrImage
                      bgPos="center"
                      bgSize="cover"
                      file="https://cdn.sanity.io/images/bkrzj7um/production/d83c18690da58aa4b46247eff36c16bdaa93836e-601x477.png"
                    />
                  </>
                </Div>
              </Col>
            </Row>
          </WebsiteContainer>
        </Div>
      </>

      </Div>
    </Layout>
  )
}

export default CBDQuizLanding;

export const query = graphql`
  query CBDQuizLanding {
    page: sanityPage(_id: { regex: "/(drafts.|)frontpage/" }) {
      ...PageInfo
    }

    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SiteSettings
    },
    variants:  allSanityProductVariant(filter: {desktopTitle: {}, _id: {in: [
      "37694676107437",
      "33245868097595",
      "42074835321085",
      "39465651306669",
      "40528601219245",
      "31068684255291",
      "37885099081901",
      "37844410302637",
      "40895099961517",
      "40528813523117"
    ]}}) {
      nodes {
        _id
        title
        price
        original_price
        discountedPrice
        klaviyoListId
        sku
        inventory_quantity
        variantTitle
        customVariantSlug
        customVariantCartName
        productDescription: _rawProductDescription
        hasSubscription
        icon {
          asset {
            url
          }
        }
        supplementFacts {
          asset {
            url
          }
        }
        latestCoas {
          asset {
            url
          }
        }
        carousel {
          title
          icon {
            asset {
              url
            }
          }
          assets {
            video {
              asset {
                url
              }
            }
            image {
              asset {
                url
              }
            }
            caption
            alt
          }
        }
      }
    }
    frontPage: sanityPage(_id: { regex: "/(drafts.|)frontpage/" }) {
      ...PageInfo
    }
  }
`

import React from 'react';
import {Div} from "../../../lib/atomize"
import PortableText from "../PortableText";

export const PRSliderItem = ({post}) => {

  return (
    <div className="item" key={post._key}>
      <div className="content">
        <div className="details">
          <Div d="flex" align="center">
            <Div>
              {post.text &&
              <PortableText blocks={post.text}/>
              }
              <p>{post.name}</p>
            </Div>
          </Div>
        </div>
      </div>
    </div>
  );
}

import React from "react"
import {Div, Text} from "../../lib/atomize"
import {scroller} from "react-scroll"

import RatingStar from "../reviews/RatingStar"

const ProductHeroRating = ({junipProduct}) => {
  let computedJunipProduct = ""

  const {redirectTo = false} = junipProduct

  if (junipProduct) {
    computedJunipProduct = junipProduct.aliased_product || junipProduct
  }
  //const computedJunipProduct = junipProduct
  return (
    <Div d="flex" align="center" className="rating-wrapper">
      <Div d="flex" align="center">
        <Text textSize="paragraph1" textWeight="bold">
          {computedJunipProduct?.rating_average?.toFixed(2)}
        </Text>
        <Div m={{x: ".5rem"}}>
          <RatingStar
            m="0"
            rating={
              computedJunipProduct?.rating_average === null
                ? 0
                : computedJunipProduct?.rating_average?.toFixed(2)
            }
            width="13px"
            height="12px"
          />
        </Div>
        <Text
          onClick={() =>
            redirectTo ? window.location.href = `${redirectTo}#review-element` :
              scroller.scrollTo("review-element", {
                smooth: true,
                duration: 600,
              })
          }
          textSize="paragraph1"
          cursor="pointer"
          textDecor="underline"
        >
          (Read {computedJunipProduct?.rating_count} reviews)
        </Text>
      </Div>
    </Div>
  )
}

export default ProductHeroRating

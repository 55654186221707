import {initialTrackingState, trackingReducer} from "./trackingReducer";
import {initialUtmState, utmReducer} from "./utmReducer"
import {freebieReducer, initialFreebieState} from "./freebieReducer";

export const trackingContext = {
    reducer: trackingReducer,
    initialState: initialTrackingState
}

export const utmContext = {
    reducer: utmReducer,
    initialState: initialUtmState
}
export const freebieContext = {
    reducer: freebieReducer,
    initialState: initialFreebieState
}

import React from "react"
import {graphql} from 'gatsby';

import {Div, Text} from "../../lib/atomize"

import Layout from "../../components/common/Layout"
import CardHeader from "../../components/common/CardHeader"
import InvalidTokenForm from "../../components/auth/InvalidTokenForm"
import WebsiteContainer from "../../components/common/atoms/WebsiteContainer"

const InvalidToken = (
  {
    data
  }
) => {
  const site = (data || {}).site

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <>
      <Layout site={site}>
        <WebsiteContainer>
          <Div m="2.5rem auto" maxW="30rem">
            <CardHeader heading="profile" image="/disk.svg"></CardHeader>
            <Div bg="yellow" p="1.875rem">
              <Div border={{b: "1px solid"}} borderColor="borderColor">
                <Text
                  textTransform="uppercase"
                  textWeight="bold"
                  textSize="display1"
                  style={{lineHeight: "100%"}}
                  fontFamily="secondary"
                >
                  Verify your identity
                </Text>
                <Text style={{lineHeight: "150%"}} m={{y: "1.25rem"}}>
                  Create your password to activate your account.
                </Text>
              </Div>

              <InvalidTokenForm/>
            </Div>
          </Div>
        </WebsiteContainer>
      </Layout>
    </>
  )
}
export default InvalidToken

export const query = graphql`
  query InvalidTokenQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SiteSettings
    }
  }
`

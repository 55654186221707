import React, {Fragment, useEffect, useState} from 'react';
import _, {find, flatten} from 'lodash';
import {graphql, StaticQuery} from "gatsby"
import {Div, Image, Text} from "../../lib/atomize"


export const BundleCartItems = (
  {
    id = '',
    name = '',
    customCartName = '',
    image =
      {
        asset: {
          url: 'https://cdn.sanity.io/images/bkrzj7um/production/b1e2a6251c7f2c0999dc1e038dab23ec2fe31e07-500x500.jpg'
        }
      },
    onClose = () => {
    },
    removeCheckoutLineItems = () => {
    },
    replaceCheckoutLineItems = () => {
    },
    updateItemsInVirtualCart = () => {
    },
    cart = {},
    loading = false,
    setLoading = () => {
    },
    onIncreaseQuantity = () => {
    },
    groupedItems = {},
    onRemoveItem = () => {

    },
    onDecreaseQuantity = () => {
    },
    checkoutType = 'shopify',
    productTitle = '',
    bundleType = '',
    totalPrice = 0,
    totalOriginalPrice = 0,
    customItems = []
  }
) => {
  const groupedItemList = groupedItems[name] || []

  const lineItemIds = flatten(_.map(groupedItemList, 'id')) || [];

  const price = _.sumBy(groupedItemList, o => {
    const {customAttributes = []} = o
    let multiplier = 1
    customAttributes.map(attribute => {
      if (attribute.key === '_pax') {
        multiplier = parseInt(attribute.value)
      }
    })
    return Number(o.variant?.price * multiplier || (o?.price * multiplier) || 0)
  }).toFixed(2);

  const quantity = _.sumBy(groupedItemList, (o) => {
    return Number(o.variant?.quantity || o?.quantity || 0)
  })

  const [count, setCount] = useState(quantity)

  const [itemPax, setItemPax] = useState(1)


  useEffect(() => {
    setCount(quantity)
  }, [quantity])

  const changeCounterValue = value => {

    let itemQuantity = Math.ceil(count / itemPax);
    if (value === "plus") {
      itemQuantity += 1
      if (checkoutType === "recharge") {
        if (itemPax) {
          return ''
        }
        onIncreaseQuantity(groupedItemList)
      }

      setCount(prevCount => prevCount + 1)
    } else {
      itemQuantity -= 1
      if (count === 1) {
        checkoutType === "recharge" ?
          onRemoveItem(groupedItemList)
          :
          removeCheckoutLineItems({
            variables: {lineItemIds: lineItemIds, checkoutId: cart.id},
          })
        if (checkoutType === "recharge") {
          onDecreaseQuantity(groupedItemList)
        }
        return null
      }
      setCount(prevCount => prevCount - 1)
    }
    if (checkoutType !== "recharge") {
      updateItemsInVirtualCart(
        replaceCheckoutLineItems,
        cart,
        itemQuantity,
        lineItemIds
      )
    }
  }

  return (
    <section className="bundle-cart">
      <StaticQuery
        query={graphql`
    query GetBundles{
      allSanityBundles {
        nodes {
          _id
          name
          original_price
          featuredImage {
            asset {
              id
              url
            }
          }
          items {
            displayTitle
            pax
            variant {
            _id
            id
            title
            price
          }
          }
        }
      }

    }
`}

        render={({allSanityBundles = {}}) => {
          const {
            nodes = []
          } = allSanityBundles;

          const item = find(nodes, (node) => node.name === name) || {};
          const {
            featuredImage: image = {},
            original_price = '',
            items = []
          } = item
          const {asset = {}} = image
          const {url: assetUrl = ''} = bundleType === 'cyo' ? {url: '/squishyinbox.jpg'} : asset

          let {pax: totalPax} = items
            .filter(item => item.pax)
            .reduce((prevValue, item) => ({pax: prevValue.pax + item.pax}), {pax: 0})
          if (totalPax === 0 && bundleType === 'cyo') {
            totalPax = customItems.length;
          }

          if (totalPax) {
            setItemPax(totalPax)
          }

          return (
            <Fragment key={name}>
              <div className="image">
                {
                  assetUrl &&
                  <img src={assetUrl} width="64px"/>
                }

              </div>
              <div className="desc">
                <header>
                  <h3>{customCartName || productTitle || name}</h3>
                  <Div
                    style={{lineHeight: "0"}}
                    bg="lightPink"
                    shadow="2"
                    hoverShadow="3"
                    transition
                    cursor="pointer"
                    rounded="sm"
                  >
                    <Image
                      src="/close.svg"
                      h="1rem"
                      w="1rem"
                      p="10px"
                      alt="Close"
                      onClick={() => onRemoveItem(groupedItemList)}
                    />
                  </Div>
                </header>
                {customCartName ? <div className='product-name'><p>{name}</p></div> : ''}
                <div className="price">
                  <span className="old">
                    ${totalOriginalPrice || itemPax && (original_price * Math.ceil(count / totalPax) || original_price * count).toFixed(2)}
                  </span>
                  <span
                    className="new">${totalPrice || itemPax && (price * Math.ceil(count / totalPax) || price * count).toFixed(2)}
                  </span>
                </div>
                <div className="items">
                  {
                    items.map((node, index) => {
                        const {
                          variant = {},
                          price = '0.00',
                          displayTitle = '',
                          pax = 1
                        } = node

                        return (
                          <div className="item" key={index}>
                            <span>${variant?.price || price}</span> -
                            <span>
                              {displayTitle}
                              {pax && pax !== 1 ? ` x${pax} ` : ''}
                            </span>
                          </div>
                        )
                      }
                    )}
                  {
                    customItems.map((node, index) => {
                      const {

                        p: price = '0.00',
                        t: displayTitle = '',
                        op: original_price = '',

                      } = node

                      return (
                        <div className="item" key={index}>
                          <div className="price">
                            {original_price && <span className="old">${original_price}</span>}
                            {price && <span className="new">${price}</span>} -
                            <span>
                            {displayTitle}
                            </span>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
                {
                  bundleType !== 'cyo' &&
                  <div className="quantity">
                    <Div
                      bg="lightPink"
                      d="flex"
                      align="center"
                      h="1.625rem"
                      maxW="3.75rem"
                      justify="space-around"
                      p="6px 8px 8px"
                      shadow="2"
                      rounded="lg"
                    >
                      <Div
                        d="flex"
                        align="center"
                        textWeight="bold"
                        onClick={() => changeCounterValue("minus")}
                        cursor="pointer"
                        p="0 8px"
                        h="100%"
                        disabled={checkoutType === 'recharge'}
                      >
                        -
                      </Div>
                      <Text textWeight="bold" m="0">
                        {!totalPax ? count : Math.ceil(count / totalPax)}
                      </Text>
                      <Div
                        d="flex"
                        align="center"
                        textWeight="bold"
                        onClick={() => changeCounterValue("plus")}
                        cursor="pointer"
                        disabled={checkoutType === 'recharge'}
                        p="0 8px"
                        h="100%"
                      >
                        +
                      </Div>
                    </Div>
                  </div>
                }
              </div>
            </Fragment>
          )
        }
        }
      >

      </StaticQuery>
      {/* <span>quantity:{quantity}</span> */}
    </section>
  )
}

import React, {useState} from "react";
import {Checkbox, Label} from "lib/atomize";

export default function OptionalCYOItems(
  {
    item = {},
    key = '',
    onSelectOptionalItem = () => {
    },
    index = 0
  }
) {

  const {variantTitle = '', title: defaultTitle = ''} = item
  const title = variantTitle === 'Default'||  variantTitle==='BYOB' ? defaultTitle : variantTitle
  const [isOptionalItemChecked, setOptionalItemChecked] = useState(false)
  const toggleState = (e) => {
    onSelectOptionalItem(index, e.target.checked)
    setOptionalItemChecked(e.target.checked)
  }
  return (
    <Label align="center" textWeight="600" m={{b: "0.5rem"}}>
      <Checkbox
        onChange={toggleState}
        checked={isOptionalItemChecked}
      /> Include a Free {title} with my bundle.
    </Label>

  );
}

import React from "react"
import {Div} from "../lib/atomize"

import ModuleHomeHero from "../modules/ModuleHomeHero"
import ModuleHelpdesk from "../modules/ModuleHelpdesk"
import ModuleLegal from "../modules/ModuleLegalContainer"
import ModuleLogoAnimation from "../modules/ModuleLogoAnimation"
import ModuleContentWithBubbles from "../modules/ModuleContentWithBubbles"
import ModuleContentWithFeatures from "../modules/ModuleContentWithFeatures"
import ModuleContentWithCtaAsset from "../modules/ModuleContentWithCtaAsset"
import ModuleContentWithProductCard from "../modules/ModuleContentWithProductCard"
import ModuleCategoriesWithCards from "../modules/ModuleCategoriesWithCards"
import ModuleContentWithImage from "../modules/ModuleContentWithImage"
import PortableText from "../components/common/PortableText"
import ModulePostcard from "../modules/ModulePostcard"
import ModuleButtonAnchor from "../modules/ModuleButtonAnchor"
import ModuleSocialMediaPosts from "../modules/ModuleSocialMediaPosts"
import ModuleRealSocialMediaPosts from "../modules/ModuleRealSocialMediaPosts"

import ModuleCYO from "../modules/ModuleCYO";
import { EasterEggItem } from "../components/easteregg/EasterEggItem"

/**
 * renders all page modules
 *
 * @param {*} param0
 */
export const getPageModules = (
  {
    page,
    location,
    legalRoutes,
    helpdeskRoutes,
    checkoutData,
    checkoutCreate,
    freebie={}
  }
) => {
  const modules = (page._rawContent || [])
    .filter(c => !c.disabled)
    .map((c, i) => {
      let el = null
      switch (c._type) {
        case "homeHero":
          el = <ModuleHomeHero key={c._key} {...c} />
          break
        case "features":
          el = <ModuleContentWithFeatures key={c._key} {...c} />
          break
        case "logoAnimation":
          el = <ModuleLogoAnimation key={c._key} {...c} />
          break
        case "contentWithCTAAsset":
          el = <ModuleContentWithCtaAsset key={c._key} {...c} />
          break
        case "bubbles":
          el = <ModuleContentWithBubbles key={c._key} {...c} />
          break
        case "contentWithProductCards":
          el = (
            <ModuleContentWithProductCard
              key={c._key}
              {...c}
              isHomepage={page.title === "Home"}
              isPcp={page.title === "Products"}
              checkoutData={checkoutData}
              checkoutCreate={checkoutCreate}
              freebie={freebie}
              index={i}
            />
          )
          break

        case "cyob":
          el = (
            <ModuleCYO
              key={c._key}
              isHomepage={false}
              isPcp={false}
              checkoutData={checkoutData}
              freebie={freebie}
              checkoutCreate={checkoutCreate}
              {...c}
            />
          )
          break

        case "legal":
          el = (
            <ModuleLegal
              key={c._key}
              {...c}
              links={legalRoutes}
              location={location}
            />
          )
          break
        case "helpdesk":
          el = (
            <ModuleHelpdesk
              key={c._key}
              {...c}
              links={helpdeskRoutes}
              location={location}
            />
          )
          break
        case "richTextEditor":
          el = (
            <Div
              className="bgblocks"
              data-bg="#F0F0FF"
              m={{
                t: {xs: "6.125rem", md: "16.75rem"},
                b: "9.375rem",
              }}
            >
              <Div className="mission-content" style={{position: "relative"}}>
                <PortableText key={c._key} blocks={c.content}/>
              </Div>
            </Div>
          )
          break
        case "contentWithImage":
          el = <ModuleContentWithImage key={c._key} {...c} />
          break
        case "postcard":
          el = <ModulePostcard key={c._key} {...c} />
          break
        case "buttonWithAction":
          el = <ModuleButtonAnchor key={c._key} {...c} />
          break
        case "categoriesWithCards":
          el = (
            <ModuleCategoriesWithCards
              key={c._key}
              {...c}
              isHomepage={page.title === "Home"}
              isPcp={page.title === "Products"}
              checkoutData={checkoutData}
              checkoutCreate={checkoutCreate}
              index={i}
            />
          )
          break
        case "socialMediaPosts":
          el = <ModuleSocialMediaPosts key={c._key} {...c} />
          break
        case "realSocialMediaPosts":
          el = <ModuleRealSocialMediaPosts key={c._key} {...c} />
          break
        default:
          el = null
      }
      return el
    })

  return modules
}

import React from "react"
import {ApolloProvider} from "@apollo/client"

import {client} from "./apolloClient"
import {AppContextProvider} from "../context";

export const wrapRootElement = ({element}) => (
  <ApolloProvider client={client}>
    <AppContextProvider>
      {element}
    </AppContextProvider>
  </ApolloProvider>
)

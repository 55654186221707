import React, { Fragment, useEffect, useState } from "react";
import FooterForm from "../common/organisms/FooterForm";


export const BirthdaySale = ({ pageContent = [], notSpamForm }) => {
  const [title, setTitle] = useState('');
  const [body, setBody] = useState([]);

  useEffect(() => pageContent.map((content) => {
    if (content?._type === 'contentWithImageExtra') {
      setBody(content?.body || []);
      setTitle(content?.carousel.title || '');
    }
  }), [pageContent], () => false)

  return (
    <div className="birthday">
      <div className="header">
        <h2 className="hero-title">{title}</h2>
        <p className="sub">30% off sitewide</p>
      </div>
      <div className="form">
        <FooterForm
          notSpamForm={
            {
              ...notSpamForm,
              noPhone: true,
              footer: '',
              renderTitle: notSpamForm?.title ? true : false,
              buttonText: 'Subscribe',
              customClass: 'lp-single-form',
              extra:
                notSpamForm?.enableToggle ?
                  <div className="extra">
                    <p><span>For: </span>You ♡</p>
                    <p><span>When: </span>July 3-5</p>
                  </div> : ''
            }
          }
          sideImage={true}
        />
      </div>
      <div className="content">
        {body && body.map((line) =>
          <span key={line._key} className="line">
            {line.children && line.children.map((elem) =>
              <span key={elem._key} className={elem.marks}>{elem.text}</span>
            )}
          </span>
        )}
      </div>
    </div>
  )
}

import React, { useState } from "react"

import isMobile from 'ismobilejs';

export const EasterEggItem = (props) => {
  const { posClass = "lt", image = "/home-easter-egg1.png", link = '' } = props;
  const [counter, setCounter] = useState(0);
  const handleClick = () => {
    if (isMobile().any) {
      if (counter >= 1) {
        window.location.href = link;
      } else {
        setCounter(counter + 1);
      }
    } else {
      window.location.href = link
    }
  }
  return (
    <img src={image}
      onClick={handleClick}
      className={`easter-egg ${posClass}`}
      alt="There's a hidden egg here, would you like to click it?"
    />
  )
}
import _ from "lodash"
import React, { Component } from "react"
import { Div } from "../../../lib/atomize"
import ReactPlayer from "react-player"
import {
  Player,
  ControlBar,
  CurrentTimeDisplay,
  TimeDivider,
  VolumeMenuButton,
} from "video-react"

const videoParams = {
  playsInline: true,
  autoPlay: true,
  isFullscreen: false,
  muted: true,
}

export default class VideoOnScroll extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isVideoPlaying: false,
      paused: false,
    }

    this.cover = React.createRef()
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll = () => {
    window.requestAnimationFrame(this.animate)
  }

  animate = () => {
    const { isVideoPlaying } = this.state

    if (_.get(this.cover, "current")) {
      const isInView =
        this.cover.current.getBoundingClientRect().bottom > 100 &&
        window.innerHeight - 100 >
        this.cover.current.getBoundingClientRect().top

      if (isInView) {
        if (!isVideoPlaying) {
          this.setState({ isVideoPlaying: true })
        }
      } else {
        if (isVideoPlaying) {
          this.setState({ isVideoPlaying: false })
        }
      }
    }
  }

  render() {
    const { isVideoPlaying, paused } = this.state
    const { file, playing, children, id, hideTracker, ...rest } = this.props

    let config = {
      file: {
        forceHLS: false,
        forceDash: true,
        hlsOptions: {
          overrideNative: true,
        },
      },
    }

    return (
      <Div {...rest} ref={this.cover} className="vid">
        <span
          className={`replaybtn${id}`}
          style={{ position: "absolute", height: 0 }}
          onClick={() => {
            this.player.video.play()
            this.player.video.replay(this.player.video.video.duration)
          }}
        ></span>
        {paused && (
          <img
            alt="Play"
            src="/play-icon.svg"
            className={`gif-play-icon big`}
            onClick={() => this.player.video.play()}
          />
        )}
        <Div
          className="video-wrapper"
          bg="gray"
          p="1.25rem"
          borderColor="yellowPink"
          border={{ y: "1px solid" }}
        >
          <Player
            ref={(player) => { this.player = player }}
            onPause={() => this.setState({ paused: true })}
            onPlay={() => this.setState({ paused: false })}
            ref={player => {
              this.player = player
            }}
            {...videoParams}
            //className="video-modal"
            playsInline={true}
          >
            <source src={file} />
            <ControlBar
              autoHide={false}
              className={hideTracker ? "hide-video-tracker" : ""}
            >
              <CurrentTimeDisplay order={4.1} />
              <TimeDivider order={4.2} />
              <VolumeMenuButton order={8} />
            </ControlBar>
          </Player>
          {/* <ReactPlayer
            width="100%"
            config={config}
            loop={true}
            height="100%"
            className="video-cover"
            playsinline
            url={file}
            controls={true}
            muted={true}
            autoplay
            playing={playing || isVideoPlaying}
          /> */}
        </Div>
      </Div>
    )
  }
}

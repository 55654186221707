import React, { useState, useEffect } from "react"
import ls from "local-storage"

export default function Autheticated({ children }) {
  const accessToken = ls("accessToken")

  useEffect(() => {
    if (!accessToken) {
      window.location.href = "/"
    } else {
    }
  }, [])

  return <>{children}</>
}

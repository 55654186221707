// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-access-subscribe-page-index-js": () => import("./../../../src/pages/access-subscribe-page/index.js" /* webpackChunkName: "component---src-pages-access-subscribe-page-index-js" */),
  "component---src-pages-account-activate-js": () => import("./../../../src/pages/account/activate.js" /* webpackChunkName: "component---src-pages-account-activate-js" */),
  "component---src-pages-account-remove-checkout-data-js": () => import("./../../../src/pages/account/removeCheckoutData.js" /* webpackChunkName: "component---src-pages-account-remove-checkout-data-js" */),
  "component---src-pages-account-reset-js": () => import("./../../../src/pages/account/reset.js" /* webpackChunkName: "component---src-pages-account-reset-js" */),
  "component---src-pages-cbd-quiz-index-js": () => import("./../../../src/pages/cbd-quiz/index.js" /* webpackChunkName: "component---src-pages-cbd-quiz-index-js" */),
  "component---src-pages-cbd-quiz-start-index-js": () => import("./../../../src/pages/cbd-quiz/start/index.js" /* webpackChunkName: "component---src-pages-cbd-quiz-start-index-js" */),
  "component---src-pages-cyo-bak-index-js": () => import("./../../../src/pages/cyo_bak/index.js" /* webpackChunkName: "component---src-pages-cyo-bak-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-oracle-index-js": () => import("./../../../src/pages/oracle/index.js" /* webpackChunkName: "component---src-pages-oracle-index-js" */),
  "component---src-pages-profile-index-js": () => import("./../../../src/pages/profile/index.js" /* webpackChunkName: "component---src-pages-profile-index-js" */),
  "component---src-pages-refer-index-js": () => import("./../../../src/pages/refer/index.js" /* webpackChunkName: "component---src-pages-refer-index-js" */),
  "component---src-pages-refer-oat-puff-index-js": () => import("./../../../src/pages/refer-oat-puff/index.js" /* webpackChunkName: "component---src-pages-refer-oat-puff-index-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-sms-index-js": () => import("./../../../src/pages/sms/index.js" /* webpackChunkName: "component---src-pages-sms-index-js" */),
  "component---src-pages-student-discount-index-js": () => import("./../../../src/pages/student-discount/index.js" /* webpackChunkName: "component---src-pages-student-discount-index-js" */),
  "component---src-pages-valentines-index-js": () => import("./../../../src/pages/valentines/index.js" /* webpackChunkName: "component---src-pages-valentines-index-js" */),
  "component---src-pages-wholesale-contact-form-index-js": () => import("./../../../src/pages/wholesale-contact-form/index.js" /* webpackChunkName: "component---src-pages-wholesale-contact-form-index-js" */),
  "component---src-pages-wholesale-index-js": () => import("./../../../src/pages/wholesale/index.js" /* webpackChunkName: "component---src-pages-wholesale-index-js" */),
  "component---src-templates-bundle-js": () => import("./../../../src/templates/Bundle.js" /* webpackChunkName: "component---src-templates-bundle-js" */),
  "component---src-templates-dealer-js": () => import("./../../../src/templates/Dealer.js" /* webpackChunkName: "component---src-templates-dealer-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/Product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}


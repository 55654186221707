import React, {Fragment, useEffect, useState} from 'react';
import {nanoid} from 'nanoid'
import {Col, Div, Icon, Row, Text} from "../lib/atomize";
import BlueButton from "../components/common/molecules/BlueButton";
import CYOBundleCategory from "../components/bundles/CYOBundleCategory";
import CYOSelectedItem from "../components/bundles/CYOSelectedItem";
import WebsiteContainer from "../components/common/atoms/WebsiteContainer";
import OptionalCYOItems from "components/bundles/OptionalCYOItems"
import {get} from "lodash";
import {addItemToCheckout, addItemToRechargeCheckout} from "../services/cartService";
import {useMutation, useQuery} from "@apollo/client";
import { Link } from "gatsby"

import {
  CHECKOUT_LINE_ITEMS_ADD,
  FETCH_CUSTOMER,
  GET_RECHARGE_CHECKOUT,
  TOGGLE_CART,
  UPDATE_RECHARGE_CHECKOUT
} from "../graphql/queries";
import ls from "local-storage";

import scrollTo from 'gatsby-plugin-smoothscroll';
import { EasterEggItem } from '../components/easteregg/EasterEggItem';


export default function ModuleCYO(
  {
    title = '',
    _key = '',
    bundleCategories = [],
    checkoutData,
    checkoutCreate,
    freebie = {},
    optionalCartItems = []
  }
) {
  const defaultCYO = [{unset: true}, {unset: true}, {unset: true}]
  const [createdBundle, setCreatedBundle] = useState(defaultCYO)
  const [loading, setLoading] = useState(false)
  const selectedOptionalItems = new Set()
  const [totalOriginalPrice, setOriginalPrice] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)
  const [isCyoCompleted, setCompleted] = useState(false)
  const updateItems = (item, index) => createdBundle.map((bundle, currentIndex) =>
    currentIndex === index ? item : bundle
  )
  const [toggleCart] = useMutation(TOGGLE_CART, {
    onCompleted: () => {
      setCreatedBundle(defaultCYO)
      setCompleted(false)
      if (window && window.rdt){
        window.rdt('track', 'AddToCart', {
            "currency" : "USD",
            "itemCount": 1,
            "value": 100
        });
      }
    },
  })

  const {data: fetchCustomerData, loading: fetchCustomerLoading} = useQuery(
    FETCH_CUSTOMER,
    {
      skip: !ls("accessToken"),
      variables: {accessToken: ls("accessToken")},
    }
  )

  const addBundleItem = (item) => {
    const index = createdBundle.findIndex(x => x.unset);
    if (index !== -1) {
      const variantItem = {...item, id: item._id}
      setCreatedBundle(updateItems(variantItem, index))
    }
  }

  const customer = get(fetchCustomerData, "customer")

  const [checkoutLineItemsAdd] = useMutation(CHECKOUT_LINE_ITEMS_ADD, {
    onCompleted: result => {
      toggleCart({variables: {cartDrawerVisible: true}})
      setCreatedBundle(defaultCYO)
    },
  })

  const removeBundleItem = (index) => {
    if (index !== -1) {
      setCreatedBundle(updateItems({unset: true}, index))
    }
  }

  const {data: rechargeCheckoutData, refetch: refetchRechargeCart} = useQuery(
    GET_RECHARGE_CHECKOUT,
    {
      skip: !ls("lastRechargeCheckoutID"),
    }
  )

  const [updateRechargeCheckout] = useMutation(UPDATE_RECHARGE_CHECKOUT)

  const onClickAddToCartButton = (variant, callback) => {
    console.log('onClickAddToCartButton')
    if (
      get(rechargeCheckoutData, "rechargeCheckout.completed_at", undefined) ===
      null
    ) {
      if (variant.bundles && variant.item) {
        addItemToRechargeCheckout({
          variant: variant.bundles,
          bundle: variant.item,
          email: get(customer, "email", undefined),
          quantity: 1,
          frequency: undefined,
          checkout: checkoutData?.node,
          rechargeCheckout: rechargeCheckoutData?.rechargeCheckout,
          refetchCart: refetchRechargeCart,
          updateRechargeCheckout,
          toggleRechargeCart: toggleCart,
          setUpdatingRechargeCheckout: callback,
        })
      } else {
        addItemToRechargeCheckout({
          variant,
          email: get(customer, "email", undefined),
          quantity: 1,
          frequency: undefined,
          checkout: checkoutData?.node,
          rechargeCheckout: rechargeCheckoutData?.rechargeCheckout,
          refetchCart: refetchRechargeCart,
          updateRechargeCheckout,
          toggleRechargeCart: toggleCart,
          setUpdatingRechargeCheckout: callback,
        })
      }
    } else {
      if (variant.bundles && variant.item) {
        addItemToCheckout({
          checkout: checkoutData?.node,
          checkoutCreate,
          checkoutLineItemsAdd,
          variant: variant.bundles,
          bundle: variant.item,
          quantity: 1,
          email: get(customer, "email", undefined),
          freebie: selectedOptionalItems.size ? Array.from(selectedOptionalItems) : freebie,

        })
      } else {
        addItemToCheckout({
          checkout: checkoutData?.node,
          checkoutCreate,
          checkoutLineItemsAdd,
          variant,
          quantity: 1,
          email: get(customer, "email", undefined),
        })
      }

      setTimeout(() => callback(false), 2000)
    }
  }

  const completeCYO = () => {
    if (createdBundle.some(bundle => !bundle.unset)) {
      console.log("selectedOptionalItems", selectedOptionalItems, createdBundle,selectedOptionalItems.size)
      if(selectedOptionalItems.size){
        Array.from(selectedOptionalItems).map(optionalItem=>{
          const selectedOptionalItem = {
            ...optionalItem,
            id:optionalItem._id
          }
          createdBundle.push(selectedOptionalItem)
        })

      }
      const cyoPayload = {
        bundles: createdBundle,
        item: {
          type: 'cyo',
          name: nanoid(),
          totalPrice: Number.isInteger(totalPrice) ? totalPrice : totalPrice.toFixed(2),
          totalOriginalPrice: Number.isInteger(totalOriginalPrice) ? totalOriginalPrice : totalOriginalPrice.toFixed(2),
        }
      }
      setLoading(true)
      onClickAddToCartButton(cyoPayload, setLoading)
    }
  }
  useEffect(() => {
    if (createdBundle.every(bundle => !bundle.unset) && !isCyoCompleted) {
      let total_OriginalPrice = 0;
      let total_Price = 0;
      createdBundle.map((item) => {
        const {original_price = 0, price = 0} = item
        total_OriginalPrice += parseFloat(original_price)
        total_Price += parseFloat(price)
      })
      setOriginalPrice(total_OriginalPrice)
      setTotalPrice(total_Price)
      setCompleted(true)
    } else if (!createdBundle.every(bundle => !bundle.unset) && isCyoCompleted) {
      setOriginalPrice(0)
      setTotalPrice(0)
      setCompleted(false)
    }
  }, [createdBundle])

  const onSelectOptionalItem = (key, toAdd) => {
    if (optionalCartItems[key]) {
      if (toAdd) {
        selectedOptionalItems.add(optionalCartItems[key])
      } else {
        selectedOptionalItems.delete(optionalCartItems[key])
      }
      console.log('selectedOptionalItemsSize', selectedOptionalItems.size)
    }

  }

  return (
    <WebsiteContainer>
      <Div className="cyo">
        <Div
          d={{xs: "block", md: "none"}}
        >
          <header>
            <Text
              textWeight="bold"
              textTransform="uppercase"
              textSize="display1"
              style={{lineHeight: "32px"}}
              fontFamily="secondary"
              tag="h2"
              m={{b: "1rem"}}
            >
              {title || 'Build Your Bundle'}
            </Text>
            <p>Get 10% off when you build your own bundle.</p>
          </header>
        </Div>
        <Row
          m={{
            t: '2rem',
            b: {lg: '10rem'}
          }}
        >
          <Col
            size={{xs: "12", lg: "7"}}
            offset="0"
            p={{
              r: {xs: "0", lg: "2rem"}
            }}
            order={{xs: 2, md: 1}}
          >
            <Div m={{t: {xs: "20px", md: "0"}}}>
              <Div
                d={{xs: "none", md: "block"}}
              >
                <header>
                  <Text
                    textWeight="bold"
                    textTransform="uppercase"
                    textSize="display1"
                    style={{lineHeight: "32px"}}
                    fontFamily="secondary"
                    tag="h2"
                    m={{b: "1rem"}}
                  >
                    {title || 'Build Your Bundle'}
                  </Text>
                  <p>Get 10% off when you build your own bundle.</p>
                  <div className="filter">
                    {
                      bundleCategories.map((bundleItem, index) =>
                        <button onClick={() => scrollTo(`#content-${bundleItem._key}`)}>{bundleItem.title}</button>
                      )
                    }
                  </div>
                </header>
              </Div>
              {
                bundleCategories.map(
                  bundleCategory =>
                    <CYOBundleCategory
                      addBundleItem={addBundleItem}
                      {...bundleCategory}
                      key={`category-item-key-${bundleCategory._key}`}
                    />
                )
              }
            </Div>
          </Col>
          <Col
            size={{xs: "12", lg: "5"}}
            order={{xs: 1, md: 2}}
            className="sticky"
          >
            <Div rounded="lg">
              <section className="bundle-summary">

                <Text
                  textWeight="bold"
                  textTransform="uppercase"
                  textSize="display1"
                  style={{lineHeight: "32px"}}
                  fontFamily="secondary"
                  tag="h2"
                  m={{b: "1rem"}}
                  textAlign="center"
                >
                  Your Bundle
                </Text>
                <Text
                  textSize="paragrapgh"
                  m={{b: "1rem"}}
                  textAlign="center"
                >
                  Save big when you buy a bundle
                </Text>


                <Div
                  d={{xs: "block", md: "none"}}
                >
                  <div className="filter">
                    {
                      bundleCategories.map((bundleItem, index) =>
                        <button key={`filter-list-mobile-${bundleItem._key}`}
                                onClick={() => scrollTo(`#content-${bundleItem._key}`)}>{bundleItem.desktopTitle || bundleItem.title}</button>
                      )
                    }
                  </div>
                </Div>

                <section className="cyo-items">


                  {
                    Array.isArray(createdBundle) && createdBundle.map((bundleItem, index) =>
                      <CYOSelectedItem
                        bundleItem={bundleItem}
                        index={index}
                        removeBundleItem={removeBundleItem}
                      />
                    )
                  }
                </section>
                {
                  isCyoCompleted ?
                    <Fragment>
                      <div className="price">
                        <Div
                          d="flex"
                          align="center"
                          justify="center"
                          m={{t: "20px", b: "10px"}}
                        >
                          <Text
                            textSize="button"
                            textWeight="bold"
                            fontFamily="secondary"
                            m={{r: "10px"}}
                            textDecor="line-through"
                            opacity="0.3"
                          >
                            ${Number.isInteger(totalOriginalPrice) ? totalOriginalPrice : totalOriginalPrice.toFixed(2)}
                          </Text>
                          <Text
                            textSize="button"
                            textWeight="bold"
                            fontFamily="secondary"
                          >
                            ${Number.isInteger(totalPrice) ? totalPrice : totalPrice.toFixed(2)}
                          </Text>
                        </Div>
                      </div>

                      <div className="action">
                        <div className="optional-items">
                          {optionalCartItems.map((item, idx) =>
                            <OptionalCYOItems
                              item={item}
                              key={idx}
                              index={idx}
                              onSelectOptionalItem={onSelectOptionalItem}
                            />)
                          }
                        </div>
                        <BlueButton
                          m="0 auto"
                          textSize="header1"
                          text={
                            loading ? (
                              <Icon name="Loading" color="white" size="16px"/>
                            ) : (
                              "Complete Bundle"
                            )
                          }
                          w="15.875rem"
                          h="44px"
                          onClick={(e) => {
                            completeCYO()
                          }}
                        />
                      </div>

                    </Fragment>
                    :
                    <div className="add-discount">
                      Add <span> {createdBundle.filter(item => item.unset).length}</span> {createdBundle.filter(item => item.unset).length !== 3 && 'more'} to
                      see your price
                    </div>
                }

              </section>
            </Div>
          </Col>
        </Row>
      </Div>
    </WebsiteContainer>
  );

}

import {trackingActions} from "../actions";

export const initialTrackingState = {
  referCode: '',
  customerId: '',
  email: '',
  discountCode: '',
  trackingCode: '',
  params: {
    utm_source: ''
  }
};
export const trackingReducer = (state, action) => {
  switch (action.type) {
    case trackingActions.SET_INITIAL_DATA: {
      return {
        referCode: action.payload.referCode,
        customerId: action.payload.customerId,
        email: action.payload.email,
        discountCode: action.payload.discountCode,
        expired: action.payload.expired,
        expiresAt: action.payload.expiresAt
      }
    }
    default:
      return state;
  }
};

import _ from "lodash"
import React, { useState, useEffect } from "react"
import { Div, Input, Text, Icon, Image, Button } from "../../lib/atomize"

import axios from "axios"
import * as yup from "yup"
import { Formik } from "formik"
import BlueButton from "../common/molecules/BlueButton"

const EditAddress = ({
  setActivePage,
  activeBilling,
  setActiveBilling,
  errors,
  setErrors,
  setData,
  data,
}) => {
  let formikProps

  const [message, setMessage] = useState(null)
  const [formSubmitting, setFormSubmitting] = useState(false)
  const initialValues = {
    firstName: "",
    lastName: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    phoneNumber: "",
    cartNote: "",
    company: "",
  }

  const onSubmit = async (values) => {
    setFormSubmitting(true)
    const datas = {
      first_name: values.firstName,
      last_name: values.lastName,
      billing_address1: values.address,
      billing_address2: values.address2,
      billing_zip: values.zipCode,
      billing_city: values.city,
      billing_company: values.company,
      billing_province: values.state,
      billing_country: values.country,
      billing_phone: values.phoneNumber,
    }
    await updateAddress(data.customer_id, datas)
    setFormSubmitting(false)
  }

  const updateAddress = async (id, datas) => {
    setErrors({})
    try {
      let response = await axios.post("/api/billing/addressupdate", {
        CustomerID: id,
        data: datas,
      })
      // const payment_sources = data[0]
      // console.log(payment_sources);
      // var foundIndex = payment_sources.findIndex(
      //   payment_sources => payment_sources.id == response.data.payment_sources.id
      // )
      // payment_sources[foundIndex] = response.data.payment_sources
      //setData(payment_sources[0])
      setMessage("Your changes have been saved.")
    } catch (error) {
      setErrors({ [id]: error.response.data })
    }
  }

  const validator = yup.object().shape({
    firstName: yup
      .string()
      .required("Please use a valid name.")
      .min(1)
      .email("Please use a valid name."),
    lastName: yup
      .string()
      .required("Please use a valid name.")
      .min(1)
      .email("Please use a valid name."),
  })
  return (
    <>
      <Div
        p={{
          y: { xs: "1.875rem", lg: "2.5rem" },
          x: { xs: "1.25rem", lg: "2.5rem" },
        }}
        bg="yellow"
        rounded="lg"
        shadow="4"
        maxW="765px"
      >
        <Text
          textWeight="bold"
          textSize="display1"
          fontFamily="secondary"
          p={{ b: { xs: "1.875rem", lg: "2.5rem" } }}
          m={{ b: "2.5rem" }}
          style={{ lineHeight: "32px" }}
          border={{ b: "1px solid" }}
          borderColor="borderColor"
          textAlign={{ xs: "center", lg: "left" }}
        >
          Edit Address
        </Text>
        {message && (
          <>
            <Div
              border={{ t: "1px solid" }}
              borderColor="borderColor"
              p={{ y: "2.5rem" }}
              d={{ xs: "block", md: "flex" }}
            >
              <Text textColor="success700" m={{ t: "1rem" }}>
                {message}
              </Text>
            </Div>
          </>
        )}
        {!message && (
          <Formik
            initialValues={{
              address: data.billing_address?.address1 || "",
              address2: data.billing_address?.address2 || "",
              firstName: data.billing_address?.first_name || "",
              lastName: data.billing_address?.last_name || "",
              city: data.billing_address?.city || "",
              state: data.billing_address?.province || "",
              zipCode: data.billing_address?.zip || "",
              country: data.billing_address?.country || "",
              phoneNumber: data.billing_address?.phone || "",
              cartNote: data.billing_address?.cartNote || "",
              company: data.billing_address?.company || "",
            }}
            onSubmit={onSubmit}
          //validationSchema={validator}
          >
            {props => {
              const {
                values,
                status,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
              } = props
              formikProps = props
              return (
                <form onSubmit={handleSubmit}>
                  <Div d="flex" m={{ t: "1.25rem" }} flexDir="column">
                    {/* {status && status.successMessage && (
                    <Text
                      textColor="success700"
                      m={{ b: "1rem" }}
                      opacity={status.successMessage ? "1" : "0"}
                      transition
                    >
                      {status.successMessage}
                    </Text>
                  )}
                  {status && status.errorMessage && (
                    <Text
                      textColor="danger700"
                      m={{ b: "1rem" }}
                      opacity={status.errorMessage ? "1" : "0"}
                      transition
                    >
                      {status.errorMessage}
                    </Text>
                  )} */}
                    <Div d="flex" m={{ b: "1rem" }}>
                      <Div flexGrow="1">
                        <Text textWeight="bold" m={{ b: "10px" }}>
                          First Name
                      </Text>
                        <Input
                          type="text"
                          name="firstName"
                          placeholder="First Name"
                          placeholderTextColor="navy"
                          shadow="2"
                          textColor="blue"
                          h="2.5rem"
                          border="1px solid"
                          borderColor={
                            touched.firstName
                              ? values.firstName
                                ? errors.firstName
                                  ? "red"
                                  : "oilGreen"
                                : errors.firstName
                                  ? "red"
                                  : "transparent"
                              : "transparent"
                          }
                          p="0 2.125rem 0 2.125rem"
                          prefix={
                            <Div
                              d="flex"
                              align="center"
                              transform="translateY(-50%)"
                              pos="absolute"
                              top="50%"
                              left="0.75rem"
                            >
                              {/* <Image src="/phone.svg" w="14px" h="14px" /> */}
                            </Div>
                          }
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        <Text
                          textColor="error"
                          textSize="paragraph"
                          m={{ t: ".5rem" }}
                        >
                          {touched.firstName && _.capitalize(errors.firstName)}
                        </Text>
                      </Div>
                      <Div flexGrow="1" m={{ l: "1rem" }}>
                        <Text textWeight="bold" m={{ b: "10px" }}>
                          Last Name
                      </Text>
                        <Input
                          type="text"
                          name="lastName"
                          placeholder="Last Name"
                          placeholderTextColor="navy"
                          shadow="2"
                          textColor="blue"
                          h="2.5rem"
                          border="1px solid"
                          borderColor={
                            touched.lastName
                              ? values.lastName
                                ? errors.lastName
                                  ? "red"
                                  : "oilGreen"
                                : errors.lastName
                                  ? "red"
                                  : "transparent"
                              : "transparent"
                          }
                          p="0 2.125rem"
                          prefix={
                            <Image
                              src="/email.svg"
                              w="14px"
                              h="10px"
                              pos="absolute"
                              top="50%"
                              left="0.75rem"
                              transform="translateY(-50%)"
                              alt="Email"
                            />
                          }
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <Text
                          textColor="error"
                          textSize="paragraph"
                          m={{ t: ".5rem" }}
                        >
                          {touched.lastName && _.capitalize(errors.lastName)}
                        </Text>
                      </Div>
                    </Div>

                    <Div m={{ b: "1rem" }}>
                      <Text textWeight="bold" m={{ b: "10px" }}>
                        Address 1
                    </Text>
                      <Input
                        type="text"
                        name="address"
                        placeholder="Address"
                        placeholderTextColor="navy"
                        shadow="2"
                        textColor="blue"
                        h="2.5rem"
                        border="1px solid"
                        borderColor={
                          touched.address
                            ? values.address
                              ? errors.address
                                ? "red"
                                : "oilGreen"
                              : errors.address
                                ? "red"
                                : "transparent"
                            : "transparent"
                        }
                        p="0 2.125rem"
                        prefix={
                          <Image
                            //   src="/address.svg"
                            w="14px"
                            h="10px"
                            pos="absolute"
                            top="50%"
                            left="0.75rem"
                            transform="translateY(-50%)"
                          />
                        }
                        value={values.address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Text
                        textColor="error"
                        textSize="paragraph"
                        m={{ t: ".5rem" }}
                      >
                        {touched.address && _.capitalize(errors.address)}
                      </Text>
                    </Div>

                    <Div m={{ b: "1rem" }}>
                      <Text textWeight="bold" m={{ b: "10px" }}>
                        Address 2
                    </Text>
                      <Input
                        type="text"
                        name="address2"
                        placeholder="Address 2"
                        placeholderTextColor="navy"
                        shadow="2"
                        textColor="blue"
                        h="2.5rem"
                        border="1px solid"
                        borderColor={
                          touched.address2
                            ? values.address2
                              ? errors.address2
                                ? "red"
                                : "oilGreen"
                              : errors.address2
                                ? "red"
                                : "transparent"
                            : "transparent"
                        }
                        p="0 2.125rem"
                        prefix={
                          <Image
                            //   src="/address2.svg"
                            w="14px"
                            h="10px"
                            pos="absolute"
                            top="50%"
                            left="0.75rem"
                            transform="translateY(-50%)"
                          />
                        }
                        value={values.address2}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Text
                        textColor="error"
                        textSize="paragraph"
                        m={{ t: ".5rem" }}
                      >
                        {touched.address2 && _.capitalize(errors.address2)}
                      </Text>
                    </Div>
                    <Div m={{ b: "1rem" }}>
                      <Text textWeight="bold" m={{ b: "10px" }}>
                        Company
                    </Text>
                      <Input
                        type="text"
                        name="company"
                        placeholder="Company"
                        placeholderTextColor="navy"
                        shadow="2"
                        textColor="blue"
                        h="2.5rem"
                        border="1px solid"
                        borderColor={
                          touched.company
                            ? values.company
                              ? errors.company
                                ? "red"
                                : "oilGreen"
                              : errors.company
                                ? "red"
                                : "transparent"
                            : "transparent"
                        }
                        p="0 2.125rem"
                        prefix={
                          <Image
                            //   src="/company.svg"
                            w="14px"
                            h="10px"
                            pos="absolute"
                            top="50%"
                            left="0.75rem"
                            transform="translateY(-50%)"
                          />
                        }
                        value={values.company}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Text
                        textColor="error"
                        textSize="paragraph"
                        m={{ t: ".5rem" }}
                      >
                        {touched.company && _.capitalize(errors.company)}
                      </Text>
                    </Div>

                    <Div d="flex" m={{ b: "1rem" }}>
                      <Div flexGrow="1">
                        <Text textWeight="bold" m={{ b: "10px" }}>
                          City
                      </Text>
                        <Input
                          type="text"
                          name="city"
                          placeholder="City"
                          placeholderTextColor="navy"
                          shadow="2"
                          textColor="blue"
                          h="2.5rem"
                          border="1px solid"
                          borderColor={
                            touched.city
                              ? values.city
                                ? errors.city
                                  ? "red"
                                  : "oilGreen"
                                : errors.city
                                  ? "red"
                                  : "transparent"
                              : "transparent"
                          }
                          p="0 2.125rem 0 2.125rem"
                          prefix={
                            <Div
                              d="flex"
                              align="center"
                              transform="translateY(-50%)"
                              pos="absolute"
                              top="50%"
                              left="0.75rem"
                            >
                              {/* <Image src="/phone.svg" w="14px" h="14px" /> */}
                            </Div>
                          }
                          value={values.city}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        <Text
                          textColor="error"
                          textSize="paragraph"
                          m={{ t: ".5rem" }}
                        >
                          {touched.city && _.capitalize(errors.city)}
                        </Text>
                      </Div>
                      <Div flexGrow="1" m={{ l: "1rem" }}>
                        <Text textWeight="bold" m={{ b: "10px" }}>
                          Country
                      </Text>
                        <Input
                          type="text"
                          name="country"
                          placeholder="Country"
                          placeholderTextColor="navy"
                          shadow="2"
                          textColor="blue"
                          h="2.5rem"
                          border="1px solid"
                          borderColor={
                            touched.country
                              ? values.country
                                ? errors.country
                                  ? "red"
                                  : "oilGreen"
                                : errors.country
                                  ? "red"
                                  : "transparent"
                              : "transparent"
                          }
                          p="0 2.125rem"
                          prefix={
                            <Image
                              src="/email.svg"
                              w="14px"
                              h="10px"
                              pos="absolute"
                              top="50%"
                              left="0.75rem"
                              transform="translateY(-50%)"
                              alt="Email"
                            />
                          }
                          value={values.country}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <Text
                          textColor="error"
                          textSize="paragraph"
                          m={{ t: ".5rem" }}
                        >
                          {touched.country && _.capitalize(errors.country)}
                        </Text>
                      </Div>
                    </Div>

                    {/*
                    ZIP CODE
                        */}

                    <Div d="flex" m={{ b: "1rem" }}>
                      <Div flexGrow="1">
                        <Text textWeight="bold" m={{ b: "10px" }}>
                          Zip/Postal Code
                      </Text>
                        <Input
                          type="text"
                          name="zipCode"
                          placeholder="Zip/Postal Code"
                          placeholderTextColor="navy"
                          shadow="2"
                          textColor="blue"
                          h="2.5rem"
                          border="1px solid"
                          borderColor={
                            touched.zipCode
                              ? values.zipCode
                                ? errors.zipCode
                                  ? "red"
                                  : "oilGreen"
                                : errors.zipCode
                                  ? "red"
                                  : "transparent"
                              : "transparent"
                          }
                          p="0 2.125rem 0 2.125rem"
                          prefix={
                            <Div
                              d="flex"
                              align="center"
                              transform="translateY(-50%)"
                              pos="absolute"
                              top="50%"
                              left="0.75rem"
                            >
                              {/* <Image src="/phone.svg" w="14px" h="14px" /> */}
                            </Div>
                          }
                          value={values.zipCode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        <Text
                          textColor="error"
                          textSize="paragraph"
                          m={{ t: ".5rem" }}
                        >
                          {touched.zipCode && _.capitalize(errors.zipCode)}
                        </Text>
                      </Div>
                      <Div flexGrow="1" m={{ l: "1rem" }}>
                        <Text textWeight="bold" m={{ b: "10px" }}>
                          State
                      </Text>
                        <Input
                          type="text"
                          name="state"
                          placeholder="State"
                          placeholderTextColor="navy"
                          shadow="2"
                          textColor="blue"
                          h="2.5rem"
                          border="1px solid"
                          borderColor={
                            touched.state
                              ? values.state
                                ? errors.state
                                  ? "red"
                                  : "oilGreen"
                                : errors.state
                                  ? "red"
                                  : "transparent"
                              : "transparent"
                          }
                          p="0 2.125rem"
                          prefix={
                            <Image
                              src="/email.svg"
                              w="14px"
                              h="10px"
                              pos="absolute"
                              top="50%"
                              left="0.75rem"
                              transform="translateY(-50%)"
                              alt="Email"
                            />
                          }
                          value={values.state}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <Text
                          textColor="error"
                          textSize="paragraph"
                          m={{ t: ".5rem" }}
                        >
                          {touched.state && _.capitalize(errors.state)}
                        </Text>
                      </Div>
                    </Div>

                    <Div m={{ b: "1rem" }} maxW="49%">
                      <Text textWeight="bold" m={{ b: "10px" }}>
                        Phone Number
                    </Text>
                      <Input
                        type="text"
                        name="phoneNumber"
                        placeholder="Phone Number"
                        placeholderTextColor="navy"
                        shadow="2"
                        textColor="blue"
                        h="2.5rem"
                        border="1px solid"
                        borderColor={
                          touched.phoneNumber
                            ? values.phoneNumber
                              ? errors.phoneNumber
                                ? "red"
                                : "oilGreen"
                              : errors.phoneNumber
                                ? "red"
                                : "transparent"
                            : "transparent"
                        }
                        p="0 2.125rem"
                        prefix={
                          <Div
                            d="flex"
                            align="center"
                            transform="translateY(-50%)"
                            pos="absolute"
                            top="50%"
                            left="0.75rem"
                          ></Div>
                        }
                        value={values.phoneNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Text
                        textColor="error"
                        textSize="paragraph"
                        m={{ t: ".5rem" }}
                      >
                        {touched.phoneNumber && _.capitalize(errors.phoneNumber)}
                      </Text>
                    </Div>

                    <Div m={{ b: "1rem" }} maxW="49%">
                      <Text textWeight="bold" m={{ b: "10px" }}>
                        Carry Note
                    </Text>
                      <Input
                        type="text"
                        name="cartNote"
                        placeholder="Carty Note"
                        placeholderTextColor="navy"
                        shadow="2"
                        textColor="blue"
                        h="2.5rem"
                        border="1px solid"
                        borderColor={
                          touched.cartNote
                            ? values.cartNote
                              ? errors.cartNote
                                ? "red"
                                : "oilGreen"
                              : errors.cartNote
                                ? "red"
                                : "transparent"
                            : "transparent"
                        }
                        p="0 2.125rem"
                        prefix={
                          <Image
                            //   src="/cartNote.svg"
                            w="14px"
                            h="10px"
                            pos="absolute"
                            top="50%"
                            left="0.75rem"
                            transform="translateY(-50%)"
                          />
                        }
                        value={values.cartNote}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Text
                        textColor="error"
                        textSize="paragraph"
                        m={{ t: ".5rem" }}
                      >
                        {touched.cartNote && _.capitalize(errors.cartNote)}
                      </Text>
                    </Div>
                  </Div>
                  <BlueButton
                    type="submit"
                    text="Update Address"
                    disabled={formSubmitting}
                    //   text={
                    //     isSubmitting ? (
                    //       <Icon name="Loading" color="white" size="16px" />
                    //     ) : (
                    //       "Sign Up"
                    //     )
                    //   }
                    padding="0.5rem 1.25rem"
                    textSize="header1"
                    h="2.5rem"
                  />
                </form>
              )
            }}
          </Formik>
        )}
        <Text m={{ t: "1rem" }} textWeight="bold" textDecor="underline" cursor="pointer" onClick={() => {
          setActivePage("home")
          setActiveBilling(null)
        }}>
          Return to billing information
        </Text>
      </Div>
    </>
  )
}

export default EditAddress

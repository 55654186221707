import React from "react"
import { Div, Image, Text } from "../../lib/atomize"

export default function CardHeader({
  heading,
  image,
  close,
  closeModal,
  padding = "0.625rem 1.25rem",
  className,
}) {
  return (
    <Div
      w="100%"
      p={padding}
      d="flex"
      bg="lightPink"
      justify="center"
      align="center"
      rounded={{ t: "lg" }}
      className={className}
    >
      {close && (
        <Div
          style={{ lineHeight: "0" }}
          bg="lightPink"
          shadow="2"
          cursor="pointer"
          rounded="sm"
          m={{ r: "10px" }}
          onClick={closeModal}
          className="close-btn"
        >
          <Image src="/close.svg" h="8.5px" w="8.5px" p="5.76px" alt="Close" alt="Close" />
        </Div>
      )}
      <Div w="100%" flexGrow={close ? "1 1 86%" : "1 1 100%"}>
        <Div h="2px" bg="purple" shadow="3" rounded="xs"></Div>
        <Div h="2px" bg="purple" shadow="3" rounded="xs" m={{ y: "4px" }}></Div>
        <Div h="2px" bg="purple" shadow="3" rounded="xs"></Div>
      </Div>

      <Div d="flex" p="0 10px" align="center">
        {image && <Image src={image} h="1rem" w="1rem" m={{ r: "5px" }} alt="Chill Time" />}
        <Text
          style={{ whiteSpace: "nowrap", lineHeight: "16px" }}
          textSize="header"
          textTransform="uppercase"
          textWeight="bold"
          fontFamily="secondary"
          tag="h3"
        >
          {heading && heading}
        </Text>
      </Div>

      <Div w="100%">
        <Div h="2px" bg="purple" shadow="3" rounded="xs"></Div>
        <Div h="2px" bg="purple" shadow="3" rounded="xs" m={{ y: "4px" }}></Div>
        <Div h="2px" bg="purple" shadow="3" rounded="xs"></Div>
      </Div>
    </Div>
  )
}

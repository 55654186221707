import React, {useState} from 'react'
import {Collapse, Div, Icon, Image, Input, Label, Radiobox, Text, Textarea} from "../../lib/atomize"
import BlueButton from "../../components/common/molecules/BlueButton"
import {Formik} from "formik"
import * as yup from "yup"
import _ from "lodash";

export default function ApplyWholesaleForm() {

  let formikProps
  const [selectedBuisnessType, setSelectedBuisnessType] = useState(new Set())
  const [selectedWhereFind, setSelectedWhereFind] = useState(new Set())
  const [selectedCity, setSelectedCity] = useState('')
  const [selectedState, setSelectedState] = useState('')
  const addBuisnessType = (businessType) => {
    !selectedBuisnessType.has(businessType) ?
      setSelectedBuisnessType(prev => new Set(prev.add(businessType))) :
      selectedBuisnessType.delete(businessType) && setSelectedBuisnessType(() => new Set(selectedBuisnessType))
  }

  const addSelectedSource = (source) => {
    !selectedWhereFind.has(source) ?
      setSelectedWhereFind(prev => new Set(prev.add(source))) :
      selectedWhereFind.delete(source) && setSelectedWhereFind(() => new Set(selectedWhereFind))
  }

  let validator = yup.object().shape({
    business: yup
      .string()
      .required("Please enter your business name."),

    taxid: yup
      .string()
      .required("Please enter your work Federal Tax ID."),
    name: yup
      .string()
      .required("Please enter your name."),

    lname: yup
    .string()
    .required("Please enter your last name."),

    email: yup
      .string()
      .required("Please enter your work email address.")
      .email("Please use a valid work email address."),
    location: yup
      .string()
      .required("Please enter your address."),
    phone: yup
      .string()
      .required("Please enter a phone number."),
  })

  const businessOptions = [
    {
      id: 1,
      value: 'Boutique Retailer'
    },
    {
      id: 2,
      value: 'Gym'
    },
    {
      id: 3,
      value: 'Large Retailer'
    },
    {
      id: 4,
      value: 'Distributor'
    },
    {
      id: 5,
      value: 'Restaurant'
    },
    {
      id: 6,
      value: 'E-commerce'
    },
    {
      id: 7,
      value: 'Hotel'
    },
    {
      id: 8,
      value: 'Corporate Office'
    },
    {
      id: 9,
      value: 'Spa'
    }
  ]

  const whereFind = [
    {
      id: 1,
      value: 'Instagram'
    },
    {
      id: 2,
      value: 'Google'
    },
    {
      id: 3,
      value: 'Press'
    },
    {
      id: 4,
      value: 'Event'
    },
    {
      id: 5,
      value: 'Facebook'
    },
    {
      id: 6,
      value: 'Heard from a friend'
    },
  ]

  const onSubmit = values => {
    const payload = {}
    const fieldsToMap = [
      {
        name: 'business',
        label: 'Business Name',
      },
      {
        name: 'taxid',
        label: 'Federal Tax ID'
      },
      {
        name: 'name',
        label: 'Contact First Name',
      },
      {
        name: 'lname',
        label: 'Contact Last Name',
      },
      {
        name: 'email',
        label: 'Email',
      },
      {
        name: 'phone',
        label: 'Phone Number',
      },
      {
        name: 'location',
        label: 'Location',
      },
      {
        name: 'business',
        label: 'Business Name',
      },
      {
        name: 'website',
        label: 'Website',
      },
      {
        name: 'notes',
        label: 'Notes',
      }
    ]

    const {setSubmitting, setStatus, resetForm} = formikProps

    let errorMessage = '';

    let successMessage = '';

    fieldsToMap.map(({name, label, type = ''}) => {
      if (values[`${name}`]) {
        if (type === 'number') {
          payload[`${label}`] = parseInt(values[`${name}`]);
        } else {
          payload[`${label}`] = values[`${name}`];
        }
      }
    })

    if (selectedWhereFind.size) {
      payload['Source'] = [...selectedWhereFind]
    }

    // else {
    //   errorMessage = 'Tell us where you found us'
    // }

    if (selectedBuisnessType.size) {
      payload['Type of Business'] = [...selectedBuisnessType]
    }

    else {
      errorMessage = 'Please select atleast one business type'
    }

    if (errorMessage) {
      setSubmitting(false)
      setStatus({
        errorMessage
      })
    }

    if (!errorMessage) {
      payload.base = 'appYiWTAcUiDPB9rK'
      payload.table = 'Stockists'

      fetch('/api/wholesale', {
        method: 'POST',
        body: JSON.stringify(payload)
      })
        .then(response => response.json())
        .then(result => {
          if (result.status) {
            errorMessage = "Your message didn't go through. Please try again later."
          } else if (result.records) {
            successMessage = 'Thank you for your interest! You should receive an email or call from us soon!'
          }
        })
        .finally(() => {
          setSubmitting(false)
          if (successMessage) {
            setSelectedCity('')
            setSelectedState('')
            setSelectedBuisnessType(new Set())
            setSelectedWhereFind(new Set())
            resetForm()
          }
          setStatus({
            errorMessage,
            successMessage
          })

        })
    }
  }

  return (
    <div className="wholesale-form">
      <Formik
        initialValues={{
          business: '',
          taxid: '',
          name: '',
          lname: '',
          email: '',
          phone: '',
          location: '',
          notes: ''
        }}
        onSubmit={onSubmit}
        validationSchema={validator}
      >
        {props => {
          const {
            values,
            status,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit
          } = props
          formikProps = props
          return (
            <div className="form">
              {
                status ?
                  status.successMessage &&
                  <Text
                    textColor="success700"
                    m={{b: "1rem"}}
                    transition
                  >
                    {status.successMessage}
                  </Text> ||
                  status.errorMessage &&
                  <Text
                    textColor="danger700"
                    m={{b: "1rem"}}
                    transition
                  >{status.errorMessage}</Text> : ''
              }
              <form onSubmit={handleSubmit}>
                <div className="main-form">
                  <div className="header-sub">
                    <p>If you've already applied in the past, please do not submit another application and instead contact wholesale@notpot.com</p>
                    <p>We normally approve applications in 3-4 hours.</p>
                  </div>

                  <div className="form-item">
                    <Label>Business Name *</Label>
                    <p className="sub">Please only use your legal entity (i.e. Not Pot Inc.)</p>
                    <Input
                      type="text"
                      tabindex="1"
                      name="business"
                      placeholder="Business Name*"
                      placeholderTextColor="navy"
                      shadow="2"
                      textColor="blue"
                      h="2.5rem"
                      border="1px solid"
                      borderColor={
                        touched.business
                          ? values.business
                            ? errors.business
                              ? "red"
                              : "oilGreen"
                            : errors.business
                              ? "red"
                              : "transparent"
                          : "transparent"
                      }
                      m={{t: "0.625rem"}}
                      value={values.business}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="field-business"
                    />
                    <Text
                      textColor="error"
                      textSize="paragraph"
                      m={{t: ".5rem"}}
                    >
                      {touched.business && _.capitalize(errors.business)}
                    </Text>
                  </div>

                  <div className="form-item">
                    <Label>Federal Tax ID *</Label>
                    <p className="sub">9 digits. No dash necessary.</p>
                    <Input
                      type="text"
                      tabindex="2"
                      name="taxid"
                      placeholder="Federal Tax ID*"
                      placeholderTextColor="navy"
                      shadow="2"
                      textColor="blue"
                      h="2.5rem"
                      border="1px solid"
                      borderColor={
                        touched.taxid
                          ? values.taxid
                            ? errors.taxid
                              ? "red"
                              : "oilGreen"
                            : errors.taxid
                              ? "red"
                              : "transparent"
                          : "transparent"
                      }
                      m={{t: "0.625rem"}}
                      value={values.taxid}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="field-business"
                    />
                    <Text
                      textColor="error"
                      textSize="paragraph"
                      m={{t: ".5rem"}}
                    >
                      {touched.taxid && _.capitalize(errors.taxid)}
                    </Text>
                  </div>
                  <div className="form-item radio">
                    <Label>Type of Business *</Label>
                    <div className="radio-list">
                      {businessOptions.map(({id, value}, index) =>
                        <Label
                          align="center"
                          textWeight="600"
                          m={{b: "0.5rem"}}
                          key={`business-options-${id}-${index}`}
                        >
                          <Radiobox
                            name="business"
                            onClick={() => addBuisnessType(value)}
                            checked={selectedBuisnessType.has(value)}
                          />
                          {value}
                        </Label>
                      )}
                    </div>
                  </div>

                  {/*
                  <div className="form-item">
                    <Label>Seller Permit</Label>
                    <p className="sub">
                    All stockists required to provide seller permits. If you do not have one right now, you can skip this step for now and provide it later.</p>
                    <label className="upload" for="permit">Attach File</label>
                    <input type="file" id="permit"/>
                  </div>
                  */}

                  <div className="personal">
                    <div className="form-item">
                      <Label>Your First Name *</Label>
                      <Input
                        tabindex="4"
                        type="text"
                        name="name"
                        placeholderTextColor="navy"
                        shadow="2"
                        textColor="blue"
                        h="2.5rem"
                        border="1px solid"
                        borderColor={
                          touched.name
                            ? values.name
                              ? errors.name
                                ? "red"
                                : "oilGreen"
                              : errors.name
                                ? "red"
                                : "transparent"
                            : "transparent"
                        }
                        m={{t: "0.625rem"}}
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="field-fname"
                      />
                      <Text
                        textColor="error"
                        textSize="paragraph"
                        m={{t: ".5rem"}}
                      >
                        {touched.name && _.capitalize(errors.name)}
                      </Text>
                    </div>

                    <div className="form-item">
                      <Label>Your Last Name *</Label>
                      <Input
                        tabindex="5"
                        type="text"
                        name="lname"
                        placeholderTextColor="navy"
                        shadow="2"
                        textColor="blue"
                        h="2.5rem"
                        border="1px solid"
                        borderColor={
                          touched.lname
                            ? values.lname
                              ? errors.lname
                                ? "red"
                                : "oilGreen"
                              : errors.lname
                                ? "red"
                                : "transparent"
                            : "transparent"
                        }
                        m={{t: "0.625rem"}}
                        value={values.lname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="field-lname"
                      />
                      <Text
                        textColor="error"
                        textSize="paragraph"
                        m={{t: ".5rem"}}
                      >
                        {touched.lname && _.capitalize(errors.lname)}
                      </Text>
                    </div>
                    <div className="form-item">
                      <Label>Buisness email *</Label>
                      <Input
                        type="text"
                        tabindex="6"
                        name="email"
                        placeholderTextColor="navy"
                        shadow="2"
                        textColor="blue"
                        h="2.5rem"
                        border="1px solid"
                        borderColor={
                          touched.email
                            ? values.email
                              ? errors.email
                                ? "red"
                                : "oilGreen"
                              : errors.email
                                ? "red"
                                : "transparent"
                            : "transparent"
                        }
                        m={{t: "0.625rem"}}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="field-email"
                      />
                      <Text
                        textColor="error"
                        textSize="paragraph"
                        m={{t: ".5rem"}}
                      >
                        {touched.email && _.capitalize(errors.email)}
                      </Text>
                    </div>
                    <div className="form-item">
                      <Label>Phone Number *</Label>
                      <Input
                        type="text"
                        tabindex="7"
                        name="phone"
                        placeholderTextColor="navy"
                        shadow="2"
                        textColor="blue"
                        h="2.5rem"
                        border="1px solid"
                        borderColor={
                          touched.phone
                            ? values.phone
                              ? errors.phone
                                ? "red"
                                : "oilGreen"
                              : errors.phone
                                ? "red"
                                : "transparent"
                            : "transparent"
                        }
                        m={{t: "0.625rem"}}
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="field-phone"
                      />
                      <Text
                        textColor="error"
                        textSize="paragraph"
                        m={{t: ".5rem"}}
                      >
                        {touched.phone && _.capitalize(errors.phone)}
                      </Text>
                    </div>

                    <div className="form-item">
                      <Label>Website</Label>
                      <Input
                        type="text"
                        tabindex="8"
                        name="website"
                        placeholder="Website (Optional)"
                        placeholderTextColor="navy"
                        shadow="2"
                        textColor="blue"
                        h="2.5rem"
                        border="1px solid"
                        borderColor={
                          touched.phone
                            ? "oilGreen"
                            : "transparent"
                        }
                        m={{t: "1rem"}}
                        value={values.website}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="field-website"
                      />
                    </div>
                  </div>

                  <div className="form-item">
                    <Label>Which city are you in? *</Label>
                    <p className="sub">If you have multiple locations, just enter one and we can fill out the rest on
                      our end. Please format as City, State (i.e. Chicago, Illinois)</p>
                     <Input
                      type="text"
                      tabindex="9"
                      name="location"
                      placeholder="City *"
                      placeholderTextColor="navy"
                      shadow="2"
                      textColor="blue"

                      h="2.5rem"
                      border="1px solid"
                      borderColor={
                        touched.location
                          ? values.location
                            ? errors.location
                              ? "red"
                              : "oilGreen"
                            : errors.location
                              ? "red"
                              : "transparent"
                          : "transparent"
                      }
                      m={{t: "0.625rem"}}
                      value={values.location}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="field-city"
                    />
                    <Text
                      textColor="error"
                      textSize="paragraph"
                      m={{t: ".5rem"}}
                    >
                      {touched.location && _.capitalize(errors.location)}
                    </Text>
                  </div>

                  <div className="form-item radio">
                    <Label>Where did you find Not Pot?</Label>
                    <div className="radio-list">
                      {whereFind.map(({id, value}, index) =>
                        <Label
                          align="center"
                          textWeight="600"
                          m={{b: "0.5rem"}}
                          key={`where-find-${id}-${index}`}
                        >
                          <Radiobox
                            name="wherefind"
                            onChange={() => addSelectedSource(value)}
                            checked={selectedWhereFind.has(value)}
                          />
                          {value}
                        </Label>
                      )}
                    </div>
                  </div>

                  <div className="form-item">
                    <Label>Anything we should know?</Label>
                    <Textarea
                      tabindex="11"
                      m={{t: "0.625rem"}}
                      name="notes"
                      textColor="blue"
                      value={values.notes}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <BlueButton
                  type="submit"
                  m={{t: "2rem"}}
                  text={
                    isSubmitting ? (
                      <Icon name="Loading" color="white" size="16px"/>
                    ) : (
                      'Submit'
                    )
                  }
                  padding="0.5rem 1.25rem"
                  textSize="header1"
                  h="3rem"
                  w="100%"
                />
              </form>
            </div>
          )
        }}
      </Formik>
    </div>
  )
}

import ls from "local-storage"

import {
  transformMutationErrors,
  transformErrorsForFormik,
} from "../utilities/helpers"
import {
  getLoginCustomers,
} from "./subscriptionService"
/**
 * on login form submit
 *
 * @param {*} param0
 */
export const onLoginFormSubmit = ({ inputs, createCustomerToken }) => {
  ls(
    "customer_email",
    inputs?.email
  )
  createCustomerToken({ variables: { input: inputs } })
}

/**
 * fire post login events
 *
 * @param {*} result
 */
export const onLoginRequestComplete = ({ response, formik }) => {
  // error occurred
  if (response?.customerAccessTokenCreate.userErrors.length > 0) {
    const { setSubmitting, setStatus, setErrors } = formik

    const errors = transformMutationErrors(
      response.customerAccessTokenCreate.userErrors
    )

    setSubmitting(false)
    setStatus({
      errorMessage: "Invalid email or password. Please try again.",
    })
    setErrors(transformErrorsForFormik(errors))

    return false
  }

  ls(
    "accessToken",
    response?.customerAccessTokenCreate?.customerAccessToken?.accessToken
  )

  ls(
    "accessTokenexpiresAt",
    response?.customerAccessTokenCreate?.customerAccessToken?.expiresAt
  )

  window.location.href = "/profile?action=login"
}

/**
 * fire post register events
 *
 * @param {*} result
 */
export const onRegisterRequestComplete = ({ response, formik }) => {
  if (response?.customerAccessTokenCreate.userErrors.length > 0) {
    const { setSubmitting, setStatus, setErrors } = formik

    const errors = transformMutationErrors(
      response.customerAccessTokenCreate.userErrors
    )

    setSubmitting(false)
    setStatus({
      errorMessage: "Invalid email or password. Please try again.",
    })
    setErrors(transformErrorsForFormik(errors))

    return false
  }

  ls(
    "accessToken",
    response?.customerAccessTokenCreate?.customerAccessToken?.accessToken
  )

  ls(
    "accessTokenexpiresAt",
    response?.customerAccessTokenCreate?.customerAccessToken?.expiresAt
  )

  window.location.href = "/profile?action=register" //"/?action=register"
}

/**
 * fire post login events
 *
 * @param {*} result
 */
export const firePostUpdatePassowrdEvents = result => {
  if (result?.customerUpdate.customerUserErrors.length > 0) {
    return
  }

  ls("accessToken", result?.customerUpdate?.customerAccessToken?.accessToken)

  ls(
    "accessTokenexpiresAt",
    result?.customerUpdate?.customerAccessToken?.expiresAt
  )
}

/**
 * fire post login events
 *
 * @param {*} result
 */
export const firePostLogout = () => {
  ls.remove("customer_email")
  ls.remove("accessToken")
  ls.remove("accessTokenexpiresAt")
  ls.remove("lastCheckoutID")

  window.location.href = "/?action=logout"
}

/**
 * fire post profile update
 *
 * @param {*} result
 */
export const onProfileUpdateRequestComplete = ({
  response,
  formik,
  refetchCustomer,
}) => {
  const { setSubmitting, setStatus, setErrors, resetForm } = formik

  // error occurred
  if (response?.customerUpdate.customerUserErrors.length > 0) {
    const errors = transformMutationErrors(
      response.customerUpdate.customerUserErrors
    )

    setSubmitting(false)
    setStatus({
      errorMessage: errors.message || `Something went wrong!`,
    })
    setErrors(transformErrorsForFormik(errors))

    return false
  }

  refetchCustomer()
  setSubmitting(false)
  setStatus({
    successMessage: "Profile updated successfully.",
  })
}

/**
 on profile update error
 *
 * @param {*} result
 */
export const onProfileUpdateRequestError = ({ error, formik }) => {
  const { setStatus, setSubmitting } = formik

  setStatus({ errorMessage: error.message })
  setSubmitting(false)
}

/**
 * fire post login events
 *
 * @param {*} result
 */
export const onResetPasswordRequestComplete = ({ response, formik }) => {
  const { setSubmitting, setStatus, setErrors, resetForm } = formik

  // error occurred
  if (response?.customerReset.userErrors.length > 0) {
    const errors = transformMutationErrors(response.customerReset.userErrors)

    setSubmitting(false)
    setStatus({
      errorMessage: `Your password was changed recently. Please try again later!`,
    })
    setErrors(transformErrorsForFormik(errors))

    return false
  }

  // everything is fine :)
  setSubmitting(false)
  resetForm({ password: "", password_confirmation: "" })
  setStatus({
    successMessage: "You can now login with new password.",
  })
}

/**
 on reset password error
 *
 * @param {*} result
 */
export const onResetPasswordRequestError = ({ error, formik }) => {
  const { setStatus, setSubmitting } = formik

  setStatus({ errorMessage: error.message })
  setSubmitting(false)
}

/**
 * fire post subscription events
 *
 * @param {*} result
 */
export const fireSubscriptionPage = async (url) => {
 // const accessToken = ls("accessToken")
 // const accessTokenexpiresAt = ls("accessTokenexpiresAt")
 // const lastCheckoutID = ls("lastCheckoutID")
 // console.log(accessToken);
 // console.log(accessTokenexpiresAt);
 // console.log(lastCheckoutID);
  window.location.href =url;
}
/**
 * fire post Order events
 *
 * @param {*} result
 */
export const fireOrdersPage = async (url) => {
 //  const CustomerEmail = ls("customer_email")
 //  //jeremy@italic.com
 //  const customer = await getLoginCustomers(CustomerEmail)
 //  console.log(customer)
 //  const accessToken = ls("accessToken")
 // const accessTokenexpiresAt = ls("accessTokenexpiresAt")
 // const lastCheckoutID = ls("lastCheckoutID")
 // console.log(CustomerEmail);
 // console.log(accessTokenexpiresAt);
 // console.log(lastCheckoutID);
  window.location.href =url;
}

/**
 * fire post Order events
 *
 * @param {*} result
 */
export const fireLoginEvents = async (result, formik) => {
 // error occurred
  if (result?.customerActivate?.customerUserErrors.length > 0) {
    const { setSubmitting, setStatus, setErrors } = formik

    const errors = transformMutationErrors(
      result?.customerActivate?.customerUserErrors
    )

    setSubmitting(false)
    setStatus({
      errorMessage: "Invalid access tocken. Please try again.",
    })
    setErrors(transformErrorsForFormik(errors))

    return false
  }

  ls(
    "accessToken",
    result?.customerActivate?.customerAccessToken?.accessToken
  )

  ls(
    "accessTokenexpiresAt",
    result?.customerActivate?.customerAccessToken?.expiresAt
  )

  window.location.href = "/profile?action=login"
}

import React from 'react';
import {SideDrawerMenuItem} from "./SideDrawerMenuItem";

export const SideDrawerMenuSection = (
  {
    type = 'primary',
    items = []
  }
) => {
  return (
    <section className={type}>
      <div>
        {items.map((item, index) => <SideDrawerMenuItem {...item} key={index} />)}
      </div>
    </section>

  );
}

import React from "react"
import {SocialPostSlider} from "../components/common/organisms/SocialPostSlider"

export default function ModuleRealSocialMediaPosts(
  {
    title = '',
    subtitle = '',
    posts = []
  }
) {
  return (
    <SocialPostSlider
      title={title || ''}
      subtitle={subtitle || ''}
      posts={posts || []}
    />
  )
}

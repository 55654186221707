import React, {useState} from "react"
import {find} from "lodash";
import RatingStar from "./RatingStar";
import {graphql, StaticQuery} from "gatsby"

export default function DynamicRating({slug = ''}) {
  const [addSpacer, setAddSpacer] = useState(slug === 'sticker-sheet')

  return (
    <StaticQuery
      query={graphql`
          query JunipQuery {
          allJunipProduct {
            nodes {
              id
              rating_average
              rating_count
              remote_id
              title
              remote_handle
            }
          }
        }`
      }
      render={data => {
        const {allJunipProduct = {}} = data
        const {nodes:junipProducts = []} = allJunipProduct
        const rating = find(junipProducts, {remote_handle: slug})
        const {rating_average = 0, rating_count = ''} = rating || {}
        return (
          <>
            {rating && rating_average &&
              <RatingStar
                m={{t: ".3125rem", b: "-.3125rem"}}
                rating={rating_average.toFixed(2)}
                width="14px"
                height="14px"
                starColor="#9A95BF"
              />
            }
            {rating && rating_count ?
              <p className="reviews-text">{rating_count} reviews</p>
              :
              addSpacer ? <div className="spacer" /> : ''
            }
          </>
        )
      }}
    />
  )
}

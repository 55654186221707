import React, { useState, Fragment } from "react"
import { EasterEggItem } from "./EasterEggItem";

const easterEggs = [
  {
    posClass: 'lt',
    image: '/home-easter-egg1.png'
  },
  {
    posClass: 'rt',
    image: '/home-easter-egg2.png'
  },
];

export const EasterEgg = () => {
  return (
    <Fragment>
      {
        easterEggs.map((item) => <EasterEggItem {...item} />)
      }
    </Fragment>
  )
}
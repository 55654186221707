import React, { useEffect, useState } from "react"

import moment from "moment"
import { Div, Text, Row, Col, Button, Icon } from "../../lib/atomize"
import BlueButton from "../common/molecules/BlueButton"
//import subscriptions from "../../../api/subscriptions"
const VerifyLogin = () => {
	const [isSubmitting, setIsSubmitting] = useState(false)
  	const [loading, setLoading] = useState(false)
  	//fireSubscriptionPage(`https://not-pot.myshopify.com/account/login?checkout_url=/tools/recurring/login`)
  	const redirectOnThemeLogin = () => {
  		setLoading(true)
  		window.location.href=`https://hello.notpot.com/account/login?checkout_url=/tools/recurring/login`;
  	}
    const redirectOngetsubscription = () =>{
      setLoading(true)
      window.location.href=`https://hello.notpot.com/tools/recurring/get-subscription-access`;
    }
  	if (loading)
    return (
      <Div d="flex" justify="center" align="center" h="50vh">
        <Icon name="Loading" color="blue" size="16px" />
      </Div>
    )
	return (
    <>
		<Text
          textWeight="bold"
          textSize="display1"
          fontFamily="secondary"
          p={{ b: { xs: "1.875rem", lg: "2.5rem" } }}
          style={{ lineHeight: "32px" }}
          textAlign={{ xs: "center", lg: "left" }}
        >
          Verify Identity
        </Text>
        <Div>
          <Div
            border={{ t: "1px solid" }}
            borderColor="borderColor"
            p={{ y: "2.5rem" }}
          >
            <Text
              textSize="header"
              m={{ b: { xs: "1.25rem", md: "1.875rem" } }}
              style={{ lineHeight: "22px", fontSize: "14px" }}
            >
              Login with your Not Pot account below to verify your identity.
            </Text>
           	<BlueButton
                text={
                  isSubmitting ? (
                    <Icon name="Loading" color="white" size="16px" />
                  ) : (
                    "Go To Login"
                  )
                }
                onClick={() => redirectOnThemeLogin()}
                m={{ t: "1.25rem" }}
                padding="0.5rem 2.25rem"
                textSize="header1"
                h="2.9rem"
              />
        	</Div>
          <Div
            border={{ t: "1px solid" }}
            borderColor="borderColor"
            p={{ y: "2.5rem" }}
          >
            <Text
              textWeight="bold"
              textSize="header"
              m={{ b: { xs: "1.00rem", md: "1.00rem" } }}
              style={{ lineHeight: "20px", fontSize: "16px" }}
            >
              Login with magic link
            </Text>
            <Text
              textSize="header"
              m={{ b: { xs: "1.25rem", md: "1.875rem" } }}
              style={{ lineHeight: "22px", fontSize: "14px" }}
            >
              We'll email you a magic link to confirm your account and access your subscriptions.
            </Text>
            <BlueButton
                text={
                  isSubmitting ? (
                    <Icon name="Loading" color="white" size="16px" />
                  ) : (
                    "Get Login Link"
                  )
                }
                onClick={() => redirectOngetsubscription()}
                m={{ t: "1.25rem" }}
                padding="0.5rem 2.25rem"
                textSize="header1"
                h="2.9rem"
              />
          </Div>
        </Div>
    </>
    )
}
export default VerifyLogin

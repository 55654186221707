const {
  getCustomerSubscriptions,
} = require("../../src/services/subscriptionService")

export default async (req, res) => {
  const { customerID } = req.body;
  if (!customerID) {
    return res.status(422).send('Please provide a ID');
  }
  const response = await getCustomerSubscriptions(customerID)
  res.send(response.data)
}

import React from 'react';
import Layout from "../../components/common/Layout";
import Helmet from "react-helmet";
import {Div} from "../../lib/atomize";
import {graphql} from "gatsby";
import {Quiz} from "../../components/valentines/Quiz"
import {useQuery} from "@apollo/client";
import {CHECKOUT_BY_ID} from "../../graphql/queries";
import ls from "local-storage";
import {find} from 'lodash'

const Valentines = ({data}, props) => {
  const {site, variants = {}, frontPage = {}} = data
  const {nodes: variantOptions = []} = variants
  const PagePath = props?.location?.pathname || props.uri;
  const pageClass = (PagePath ? PagePath.replace(/\//g, '') : 'home')
  const {data: checkoutData, refetch: refechCheckoutData} = useQuery(
    CHECKOUT_BY_ID,
    {
      // skip: !ls("lastCheckoutID"),
      variables: {id: ls("lastCheckoutID")},
      onCompleted: result => {
        const {node = {}} = result
        const {orderStatusUrl = null} = node || {};
        if (orderStatusUrl) {
          ls.remove("lastCheckoutID");
          if (!ls.get('disable_notSpam')) {
            ls.set('disable_notSpam', true);
          }
          if (ls.get('np_code')) {
            ls.remove('np_code');
          }
        }
      }
    }
  )

  const {_rawContent = []} = frontPage
  const realSocialMediaPosts = find(_rawContent, (content)=>content._type === 'realSocialMediaPosts') || {}

  return (
    <Layout site={site} path={PagePath} pageClass="page-wrapper">
      <Helmet>
        <title>Valentines</title>
      </Helmet>
      <Div className="wrapper">
        <Quiz
          variantOptions={variantOptions}
          checkoutData={checkoutData}
          realSocialMediaPosts={realSocialMediaPosts}
        />
      </Div>
    </Layout>
  )
}

export default Valentines;

export const query = graphql`
  query Valentines {
    page: sanityPage(_id: { regex: "/(drafts.|)frontpage/" }) {
      ...PageInfo
    }

    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SiteSettings
    },
    variants:  allSanityProductVariant(filter: {desktopTitle: {}, _id: {in: [
      "37694676107437",
      "33245868097595",
      "42074835321085",
      "39465651306669",
      "40528601219245",
      "31068684255291",
      "37885099081901",
      "37844410302637",
      "40895099961517"
    ]}}) {
      nodes {
        _id
        title
        price
        original_price
        discountedPrice
        klaviyoListId
        sku
        inventory_quantity
        variantTitle
        customVariantSlug
        customVariantCartName
        productDescription: _rawProductDescription
        hasSubscription
        icon {
          asset {
            url
          }
        }
        supplementFacts {
          asset {
            url
          }
        }
        latestCoas {
          asset {
            url
          }
        }
        carousel {
          title
          icon {
            asset {
              url
            }
          }
          assets {
            video {
              asset {
                url
              }
            }
            image {
              asset {
                url
              }
            }
            caption
            alt
          }
        }
      }
    }
    frontPage: sanityPage(_id: { regex: "/(drafts.|)frontpage/" }) {
      ...PageInfo
    }
  }
`

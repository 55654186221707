import _, {flatten, get, includes, isEmpty, pick, reject, uniq, uniqBy} from "lodash"
import ls from "local-storage"
import {isBefore} from "date-fns";

/**
 * adds item to existing checkout or create a new checkout
 *
 * @param freebie : object
 * @param checkout : object
 * @param type : string
 * @param items : [Array]
 */

const applyFreebie = (freebie = {}, checkout, type = 'shopify', items = []) => {
  const {variant: freeBieVariant = {}, displayTitle = '', mode = ''} = freebie || {}
  let hasCustomFreeBie = false

  if (!isEmpty(freeBieVariant) && !ls.get('customFreebie')
    && (mode || !checkout?.lineItems?.edges.length)) {
    const {_id: freebieId = ''} = freeBieVariant
    const freebieAttributes = []
    if (freebieId) {
      const newItem = {}
      if (mode === 'custom') {
        hasCustomFreeBie = true
        if (checkout?.lineItems?.edges.length) {
          checkout.lineItems.edges.map(item => {
          })
        }
        ls.set('customFreebie', window.btoa(`gid://shopify/ProductVariant/${freebieId}`))
      }
      if (type === 'shopify') {
        if (displayTitle) {
          freebieAttributes.push({key: '_or', value: displayTitle})
        }
        freebieAttributes.push({key: '_isFreebie', value: 'yes'})
        newItem.variantId = window.btoa(`gid://shopify/ProductVariant/${freebieId}`)
        newItem.quantity = 1
        newItem.customAttributes = freebieAttributes

      }

      if (type === 'recharge') {
        if (displayTitle) {
          freebieAttributes.push({name: '_or', value: displayTitle})
        }
        freebieAttributes.push({name: '_isFreebie', value: 'yes'})
        newItem.variant_id = freebieId
        newItem.quantity = 1
        newItem.properties = freebieAttributes
      }

      items.push(newItem)
    }
  }
  return {items, hasCustomFreeBie}
}


export const addItemToCheckout = (props = {}) => {
  const {
    checkout,
    email,
    checkoutCreate,
    checkoutLineItemsAdd,
    variant,
    quantity = 1,
    bundle = {},
    freebie = {},
    isFreebie = false
  } = props
  const {items, hasCustomFreeBie} = applyFreebie(freebie, checkout)
  if (Array.isArray(freebie)) {

    freebie.map(freeItem => {
      const freebieObject = {
        variant: {...freeItem},
        displayTitle: freeItem.title
      }
      const {freeItems, cfb} = applyFreebie(freebieObject, checkout, items)
      items.push(freeItems)
    })
  }
  if (!Array.isArray(variant)) {
    let attributes = []
    const {_id: id, customVariantCartName = '', original_price = '', id: variantId = ''} = variant || {}

    if (customVariantCartName) {
      attributes.push({key: '_or', value: customVariantCartName})
    }

    if (original_price) {
      attributes.push({key: '_original_price', value: original_price.toString()})
    }
    if (hasCustomFreeBie) {
      attributes.push({key: '_cfb', value: 'yes'})
    }

    if (isFreebie) {
      const {minCartValue = 0} = variant || {}
      attributes.push({key: '_isFreebie', value: 'yes'})
      if (minCartValue) {
        attributes.push({key: '_minCartValue', value: `${minCartValue}`})
      }
    }

    items.push({
      variantId: window.btoa(`gid://shopify/ProductVariant/${id}`),
      quantity: quantity,
      customAttributes: attributes
    })
  }

  if (Array.isArray(variant)) {
    const bundleItems = []
    variant.map(item => {
      let attributes = []

      const {_id: id, customVariantCartName = '', pax = 1} = item

      const itemQuantity = quantity * pax;

      const {name: bundleName = '', product_name = ''} = bundle

      if (bundleName) {
        attributes.push({key: '_type', value: bundleName});
      }

      if (bundle.type) {
        attributes.push({key: '_bundleType', value: bundle.type})
        if (bundle.type === 'cyo') {

          //attributes.push({key: '_cyoItems', value: variant})
          if (bundle.totalPrice) {
            attributes.push({key: '_totalPrice', value: bundle.totalPrice.toString()})
          }


          if (bundle.totalOriginalPrice) {
            attributes.push({key: '_original_price', value: bundle.totalOriginalPrice.toString()})
          }
          const cyoItems = variant.map((variantItem => ({
            p: variantItem.price || '',
            op: variantItem.original_price || '',
            t: variantItem.desktopTitle || variantItem.title || ''
          })))

          attributes.push({key: '_cyoItems', value: JSON.stringify(cyoItems)})

        }
      }

      if (customVariantCartName) {
        attributes.push({key: '_or', value: customVariantCartName})
      }

      if (product_name) {
        attributes.push({key: '_or', value: product_name})
      }

      if (pax) {
        attributes.push({key: '_pax', value: pax.toString()})
      }

      if (item) {
        items.push({
          variantId: window.btoa(`gid://shopify/ProductVariant/${item.id}`),
          quantity: itemQuantity,
          customAttributes: attributes
        });

        bundleItems.push({
          ...item,
          variantId: window.btoa(`gid://shopify/ProductVariant/${item.id}`),
          quantity: itemQuantity,
        });

      }

    })
    //createOrUpdateVirtualCart(bundleItems, bundle, quantity)

  }

  // if there is no existing checkout || or current checkout is completed
  if (!checkout || checkout?.orderStatusUrl) {
    checkoutCreate({
      variables: {
        input: {
          lineItems: items,
          email,
        },
      },
    }).catch(e => console.error(e))
    return
  }
  // if there is a existing checkout, then add item to that checkout
  checkoutLineItemsAdd({
    variables: {
      items: items,
      checkoutId: checkout.id,
    },
  }).catch(e => console.error(e))
}

/**
 * adds items to existing checkout or create a new checkout
 *
 * @param {*} param0
 */
export const createCheckoutWithItems = (
  {
    email,
    checkoutCreate,
    items
  }) => {
  checkoutCreate({
    variables: {
      input: {
        lineItems: items,
        email
      },
    },
  })
}

/**
 * add item to recharge checkout
 *
 * @param props
 */
export const addItemToRechargeCheckout = (props) => {
  const {
    checkout,
    rechargeCheckout,
    updateRechargeCheckout,
    email,
    variant = {},
    bundle = {},
    freebie = {}
  } = props

  let lineItems = [];
  const {items: freebieItems,} = applyFreebie(freebie, checkout, 'recharge')
  if (Array.isArray(variant)) {
    variant.map(item => {
      const {id, customVariantCartName = '', pax = 1} = item

      const attributes = []
      const qty = props.quantity * pax || 1

      if (customVariantCartName) {
        attributes.push({name: '_or', value: customVariantCartName})
      }

      if (pax) {
        attributes.push({name: '_pax', value: pax.toString()})
      }


      const newItem = {
        variant_id: id,
        quantity: qty,
        charge_interval_frequency: props.frequency,
        order_interval_unit: props.frequency ? "day" : undefined,
        order_interval_frequency: props.frequency,
        properties: attributes
      }

      lineItems.push(
        newItem
      )
    })

  } else {
    const {_id: id, customVariantCartName = ''} = variant
    const newRechargeItem = {
      variant_id: id,
      quantity: props.quantity,
      charge_interval_frequency: props.frequency,
      order_interval_unit: props.frequency ? "day" : undefined,
      order_interval_frequency: props.frequency,
      properties: customVariantCartName ? [{name: "_or", value: customVariantCartName}] : null
    }
    lineItems.push(newRechargeItem)
  }

  // if there is no recharge checkout or current checkout is completed create a new
  if (!rechargeCheckout || rechargeCheckout?.completed_at) {
    // if there is normal checkout and it's not completed, then create new recharge
    if (checkout && !checkout?.orderStatusUrl || freebieItems.length) {
      lineItems = lineItems.concat(copyRegularItems(checkout), freebieItems)
    }

    if (lineItems.length) {
      createRechargeCheckout({lineItems, ...props})
    }
    return true
  }
  replaceItemsFromRechargeCheckout({...props, updateRechargeCheckout})
}

/**
 * remove item from checkout
 *
 * @param props
 */
export const removeItemsFromCheckout = (props = {}) => {
  const {
    removeCheckoutLineItems,
    checkout,
    cartItem,
  } = props

  const {lineItems = {}} = checkout
  const itemsToRemove = Array.isArray(cartItem) ? [] : [cartItem.id]
  if (Array.isArray(cartItem)) {
    cartItem.map(item => {
      itemsToRemove.push(item.id)
    })
  }
  const itemCountAfterRemove = checkout?.lineItems?.edges.length - itemsToRemove.length

  if (ls.get('customFreebie')) {
    const {customAttributes = []} = cartItem
    customAttributes.map(attribute => {
      if (attribute.key === '_cfb') {
        lineItems.edges.map(({node}) => {
          const {customAttributes: attributes = []} = node
          attributes.map(property => {
            if (property.key === '_isFreebie') {
              itemsToRemove.push(node.id);
              if (ls.get('customFreebie')) {
                ls.remove('customFreebie')
              }
            }
          })
        })
      }
    })

  }

  if (itemCountAfterRemove === 1) {
    let idToRemove = ''
    lineItems.edges.map(({node}) => {
      const {customAttributes = []} = node
      customAttributes.map(attribute => {
        if (attribute.key === '_isFreebie') {
          idToRemove = node.id
          if (ls.get('customFreebie')) {
            ls.remove('customFreebie')
          }
        }
      })
    })
    if (idToRemove) {
      itemsToRemove.push(idToRemove)
    }
  }

  removeCheckoutLineItems({
    variables: {lineItemIds: itemsToRemove, checkoutId: checkout.id},
  })
}

/**
 * remove item from recharge checkout
 *
 * @param {*} param0
 */
export const removeItemsFromRechargeCheckout = (props) => {
  const {
    cart,
    rechargeCart,
    cartItem,
    email,
    checkoutCreate,
    replaceCheckoutLineItems,
    updateRechargeCheckout,
    removeCheckoutLineItems,
    setLoading,
  } = props
  const items = get(rechargeCart, "line_items", [])
  const variantIds = Array.isArray(cartItem) ? _.map(cartItem, 'variant_id') : [cartItem.variant_id]

  const itemsAfterRemoving = reject(items, item => {
    return (
      includes(variantIds, item.variant_id) &&
      item.charge_interval_frequency == cartItem.charge_interval_frequency
    )
  })
  const hasOnlyRegularItems = checkIfCartHasOnlyRegularItems(itemsAfterRemoving)
  if (itemsAfterRemoving.length <= 0) {
    ls.remove("lastRechargeCheckoutID")
  }
  let isFreebie = false
  if (hasOnlyRegularItems && itemsAfterRemoving.length > 0) {
    if (itemsAfterRemoving.length > 0) {
      if (itemsAfterRemoving.length === 1) {
        if (itemsAfterRemoving[0].type === "ONETIME") {
          setLoading(true)
          let items = [
            {
              variantId: window.btoa(`gid://shopify/ProductVariant/${itemsAfterRemoving[0].variant_id}`),
              quantity: itemsAfterRemoving[0].quantity,
            },
          ];

          if (itemsAfterRemoving[0] && itemsAfterRemoving[0].properties && itemsAfterRemoving[0].properties.length) {
            const customAttributes = []
            itemsAfterRemoving[0].properties.map(attrb => {
              if (attrb.name === '_isFreebie') {
                isFreebie = true
              }
              customAttributes.push({key: attrb.name, value: attrb.value})
            })
            items[0].customAttributes = customAttributes
          }

          const email = undefined;
          checkoutCreate({
            variables: {
              input: {
                lineItems: isFreebie ? [] : items,
                email,
              },
            },
          })
        } else {
          replaceCheckoutLineItems({
            variables: {
              checkoutId: cart?.id,
              items: itemsAfterRemoving.map(item => {
                let checkoutItem = {
                  variantId: window.btoa(
                    `gid://shopify/ProductVariant/${item?.variant_id}`
                  ),
                  quantity: item?.quantity,
                }

                if (item.properties.length) {
                  const customAttributes = []
                  item.properties.map(attrb => {
                    customAttributes.push({key: attrb.name, value: attrb.value})
                  })
                  checkoutItem.customAttributes = customAttributes
                }
                return checkoutItem
              }),
            },
          })
        }
      } else {
        const items = []
        itemsAfterRemoving.map(item => {
          let checkoutItem = {
            variantId: window.btoa(`gid://shopify/ProductVariant/${item?.variant_id}`),
            quantity: item?.quantity,
          }
          items.push(checkoutItem)
        })
        replaceCheckoutLineItems({
          variables: {
            checkoutId: cart?.id,
            items
          },
        })
      }
      updateRechargeCheckout({
        variables: {checkout: null},
      })
      ls.remove("lastRechargeCheckoutID")
    }
  } else {

    const checkoutUpdateOptions = {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({
        lineItems: itemsAfterRemoving,
        checkoutToken: rechargeCart.token,
      }),
      referrerPolicy: 'origin-when-cross-origin'
    }
    fetch(`${process.env.GATSBY_RECHARGE_API_ENDPOINT ? process.env.GATSBY_RECHARGE_API_ENDPOINT : ''}/api/checkout/update`, checkoutUpdateOptions)
      .then(response =>
        response.json()
      ).then(result => {
      const {checkout = {}} = result;
      updateRechargeCheckout({
        variables: {checkout: checkout},
      })
    })
    //
    if (!hasOnlyRegularItems) {
      ls.remove('lastCheckoutID')
    }
  }
}

/**
 * replaces items from checkout
 * using it for increasing or decreasing quanity
 *
 * @param {*} param0
 */
export const replaceItemsFromCheckout = (props) => {
  const {
    replaceCheckoutLineItems,
    cartItem,
    checkout,
    quantity,
    removeItem
  } = props
  const cartItems = get(checkout, "lineItems.edges", [])

  const items = []
  let pax = 1
  const itemsToRemove = []
  let freebieToRemove = ''
  console.log('replaceItemsFromCheckout', checkout)
  let minThresholdPrice = 0
  cartItems.map(item => {
    let checkoutItem = {
      variantId: window.btoa(item.node.variant.id),
      quantity: item.node.quantity
    }
    if (item.node.customAttributes.length) {
      const customAttributes = []
      flatten(item.node.customAttributes).map(attrb => {
        customAttributes.push(pick(attrb, ['key', 'value']))
        if (attrb.key === '_pax') {
          pax = parseInt(attrb.value)
        }
        if (attrb.key === '_minCartValue') {
          console.log(item, parseInt(attrb.value))
          minThresholdPrice = parseInt(attrb.value)
          if (minThresholdPrice) {
            freebieToRemove = {
              variantId: window.btoa(item.node.variant.id),
              quantity: item.node.quantity
            }
          }
        }
      })
      checkoutItem.customAttributes = customAttributes
    }
    let totalQuantity = quantity * pax

    if (cartItem && cartItem.variant
      && cartItem.variant.id
      && window.btoa(cartItem.variant.id) === checkoutItem.variantId) {
      checkoutItem.quantity = totalQuantity;
    }

    items.push(checkoutItem)
  })
  replaceCheckoutLineItems({
    variables: {items: items, checkoutId: checkout.id},
  }).then(checkoutResult => {
    if (minThresholdPrice && !isEmpty(freebieToRemove)) {
      const {data: checkoutData = {}} = checkoutResult || {}
      const {checkoutLineItemsReplace = {}} = checkoutData || {}
      const {checkout: updatedCheckout = {}} = checkoutLineItemsReplace
      const {totalPriceV2: totalCurrentPrice = {}} = updatedCheckout || {}
      const {amount: currentAmount = ''} = totalCurrentPrice || {}
      console.log('currentAmount', currentAmount, parseInt(currentAmount), minThresholdPrice, minThresholdPrice > parseInt(currentAmount))
      if (minThresholdPrice > parseInt(currentAmount)) {
        removeItemsFromCheckout({
          removeCheckoutLineItems: removeItem,
          checkout: updatedCheckout,
          cartItem: freebieToRemove,
        })
      }
    }

  })
}

/**
 * replaces items from checkout
 * using it for increasing or decreasing quanity
 *
 * @param {*} param0
 */
export const replaceItemsFromRechargeCheckout = props => {
  const {rechargeCheckout: checkout, variant, frequency, bundle = {}} = props
  const cartItems = get(checkout, "line_items", [])
  const items = JSON.parse(JSON.stringify(cartItems))
  if (!Array.isArray(variant)) {
    const alreadyExistingItem = items.find(
      item =>
        item.variant_id == variant._id &&
        item.charge_interval_frequency == frequency
    )

    switch (true) {
      case alreadyExistingItem &&
      alreadyExistingItem.charge_interval_frequency === frequency:
        const index = items.findIndex(
          item =>
            item.variant_id == variant._id &&
            item.charge_interval_frequency == frequency
        )
        items[index].quantity = items[index].quantity + props.quantity
        break

      default:
        items.push({
          variant_id: variant._id,
          quantity: props.quantity,
          charge_interval_frequency: frequency,
          order_interval_unit: frequency ? "day" : undefined,
          order_interval_frequency: frequency,
        })
    }
  }

  if (Array.isArray(variant)) {
    variant.map(item => {
      const index = items.findIndex(
        prevItems =>
          prevItems.variant_id === item.id &&
          prevItems.charge_interval_frequency === frequency
      )

      if (Math.sign(index) !== -1) {
        items[index].quantity = items[index].quantity + props.quantity
      } else {
        const attributes = []
        const {customVariantCartName = '', pax = 1} = item
        const qty = props.quantity * pax || 1

        const {name: bundleName = ''} = bundle


        if (bundleName) {
          attributes.push({name: '_type', value: bundleName});
        }

        if (bundle.type) {
          attributes.push({name: '_bundleType', value: bundle.type})
          if (bundle.type === 'cyo') {

            //attributes.push({key: '_cyoItems', value: variant})
            if (bundle.totalPrice) {
              attributes.push({name: '_totalPrice', value: bundle.totalPrice.toString()})
            }
            if (bundle.totalOriginalPrice) {
              attributes.push({name: '_original_price', value: bundle.totalOriginalPrice.toString()})
            }
            const cyoItems = variant.map((variantItem => ({
              p: variantItem.price || '',
              op: variantItem.original_price || '',
              t: variantItem.desktopTitle || variantItem.title || ''
            })))

            attributes.push({name: '_cyoItems', value: JSON.stringify(cyoItems)})

          }

        }
        if (customVariantCartName) {
          attributes.push({name: "_or", value: customVariantCartName})
        }
        items.push({
          variant_id: item.id,
          quantity: qty,
          charge_interval_frequency: frequency,
          order_interval_unit: frequency ? "day" : undefined,
          order_interval_frequency: frequency,
          properties: attributes
        })
      }
    })
  }


  updateRechargeCheckout({
    checkout,
    items,
    toggleRechargeCart: props.toggleRechargeCart,
    updateRechargeCheckout: props.updateRechargeCheckout,
    setUpdatingRechargeCheckout: props.setUpdatingRechargeCheckout,
  })
}

/**
 * replaces items from checkout
 * using it for increasing or decreasing quanity
 *
 * @param {*} param0
 */
export const updateItemsInRechargeCheckout = (
  {
    rechargeCheckout: checkout = [],
    item: currentItem,
    quantity = 1,
    toggleRechargeCart: toggleCart = () => {

    },
    updateRechargeCheckout: updateCheckout = () => {

    }
  },
  props
) => {
  const variantIds = Array.isArray(currentItem) ? _.map(currentItem, 'variant_id') : [currentItem.variant_id]
  const cartItems = get(checkout, "line_items", [])
  const frequency = Array.isArray(currentItem) ? _.first(currentItem).charge_interval_frequency : currentItem.charge_interval_frequency
  const items = []
  cartItems.map((item) => {
    const checkoutItem = {
      ...item
    }
    if (includes(variantIds, item.variant_id) && item.charge_interval_frequency == frequency) {
      checkoutItem.quantity = item.quantity + quantity
    }
    items.push(checkoutItem)
  })

  updateRechargeCheckout({
    checkout,
    items,
    toggleRechargeCart: toggleCart,
    updateRechargeCheckout: updateCheckout,
  })
}

/**
 * saves checkout id in localstorage
 *
 * @param {*} checkout
 */
export const saveCheckoutInLocalStorage = checkout => {
  ls("lastCheckoutID", checkout?.id)
}

/**
 * saves checkout id in localstorage
 *
 * @param {*} checkout
 */
export const saveRechargeCheckoutInLocalStorage = checkout => {
  ls("lastRechargeCheckoutID", checkout.token)
}

/**
 * counts the total number of items in a cart
 *
 * @param {*} cart
 */
export const getTotalCartCount = (cart, rechargeCheckoutData, loading) => {
  if (loading) return 0

  if (get(rechargeCheckoutData, "rechargeCheckout.completed_at") === null) {
    return get(rechargeCheckoutData.rechargeCheckout, "line_items", []).reduce(
      (accumulator, currentValue) => {
        return accumulator + currentValue.quantity
      },
      0
    )
  }

  return get(cart, "lineItems.edges", []).reduce(
    (accumulator, currentValue, currentIndex, array) => {
      return accumulator + currentValue.node.quantity
    },
    0
  )
}

export const syncGuestCartWithCustomerCart = (
  {
    guestCheckout,
    customer,
    checkoutLineItemsAdd,
    checkoutCreate,
  }
) => {
  const userCheckout = get(customer, "lastIncompleteCheckout")
  const guestCartItems = get(guestCheckout, "lineItems.edges", [])
  const userCartItems = get(userCheckout, "lineItems.edges", [])
  const items = []

  if (guestCartItems.length > 0) {
    guestCartItems.forEach(guestCartItem => {
      if (
        userCartItems.find(
          userCartItem =>
            userCartItem.node.variant.id === guestCartItem.node.variant.id
        )
      ) {
        return false
      }

      items.push({
        variantId: guestCartItem.node.variant.id,
        quantity: guestCartItem.node.quantity,
      })
    })

    // if there is no existing checkout || or current checkout is completed
    if (!userCheckout || userCheckout?.orderStatusUrl) {
      checkoutCreate({
        variables: {
          input: {
            lineItems: items,
            email: customer.email,
          },
        },
      })

      return
    }

    // if there is a existing checkout, then add item to that checkout
    checkoutLineItemsAdd({
      variables: {
        items: items,
        checkoutId: userCheckout.id,
      },
    })
  }
}

/**
 * copies regular items
 */
export const copyRegularItems = checkout => {
  const lineItems = []
  if (get(checkout, "lineItems.edges", []).length > 0) {
    const regularCartItems = get(checkout, "lineItems.edges", [])

    regularCartItems.map(item => {
      const customAttributes = []
      if (item.node.customAttributes.length) {
        flatten(item.node.customAttributes).map(attrb => {
          const attribute = pick(attrb, ['key', 'value'])
          customAttributes.push({name: attribute.key, value: attribute.value})
        })
      }

      //  console.log('variant To add', item.node.variant, item.node.variant.id,atob(item.node.variant.id).slice(-1)[0])
      console.log('item.node.variant.id', item.node.variant.id)
      lineItems.push({
        quantity: item.node.quantity,
        //variant_id: atob(item.node.variant.id).split("/").slice(-1)[0],
        properties: customAttributes ? customAttributes : null
      })
    })
  }

  return lineItems
}

/**
 * creates a new recharge checkout, save the token in localStorage
 * and update local cache to update view
 *
 * @param {*} param0
 */
export const createRechargeCheckout = (
  {
    lineItems,
    email,
    updateRechargeCheckout,
    toggleRechargeCart,
    setUpdatingRechargeCheckout,
  }
) => {
  setUpdatingRechargeCheckout(true)
  const notpotTracker = ls('notpotTracker');
  const discountCode = ls.get('np_code');

  let tracking = null;
  const trackingParams = {...notpotTracker};
  if (trackingParams && !trackingParams.expired && trackingParams.expiresAt && isBefore(new Date(), new Date(trackingParams.expiresAt)) && !trackingParams.email && trackingParams.customerId && trackingParams.referCode) {
    tracking = {...trackingParams}
  } else {
    tracking = null;
  }
  const checkoutOptions = {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify({
      lineItems,
      email,
      tracking,
      discountCode: discountCode || ''
    }),
    referrerPolicy: 'origin-when-cross-origin'
  }
  fetch(`${process.env.GATSBY_RECHARGE_API_ENDPOINT ? process.env.GATSBY_RECHARGE_API_ENDPOINT : ''}/api/checkout/create`, checkoutOptions)
    .then(response => response.json()).then(result => {
    const {checkout = {}} = result;
    saveRechargeCheckoutInLocalStorage(checkout)
    updateRechargeCheckout({
      variables: {
        checkout: checkout,

      },
    })
    setUpdatingRechargeCheckout(false)
    toggleRechargeCart({
      variables: {cartDrawerVisible: true},
    })
  })
}

/**
 * update recharge checkout and update cache
 *
 * @param {*} param0
 */
export const updateRechargeCheckout = (
  {
    checkout,
    items,
    updateRechargeCheckout,
    toggleRechargeCart,
    setUpdatingRechargeCheckout = () => {
    },
  }
) => {
  setUpdatingRechargeCheckout(true)

  const notpotTracker = ls('notpotTracker');
  let tracking = null;
  const trackingParams = {...notpotTracker};
  if (trackingParams && !trackingParams.expired && trackingParams.expiresAt && isBefore(new Date(), new Date(trackingParams.expiresAt)) && !trackingParams.email && trackingParams.customerId && trackingParams.referCode) {
    tracking = {...trackingParams}
  } else {
    tracking = null;
  }
  const checkoutOptions = {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify({
      lineItems: items,
      checkoutToken: checkout.token,
      tracking
    }),
    referrerPolicy: 'origin-when-cross-origin'
  }

  fetch(`${process.env.GATSBY_RECHARGE_API_ENDPOINT ? process.env.GATSBY_RECHARGE_API_ENDPOINT : ''}/api/checkout/update`, checkoutOptions)
    .then(response =>
      response.json()
    ).then((result) => {
    const {checkout = {}} = result
    updateRechargeCheckout({
      variables: {checkout},
    })
    setUpdatingRechargeCheckout(false)
    toggleRechargeCart({
      variables: {cartDrawerVisible: true},
    })
  }).catch(e => {
    console.error(e)
  })
}

/**
 * checks if cart has regular items only
 *
 * @param {*} items
 */
export const checkIfCartHasOnlyRegularItems = items => {
  return items.filter(item => item.charge_interval_frequency).length == 0
}

// export const allremoveCheckout = () => {
//  ls.remove("lastRechargeCheckoutID")
//  ls.remove("lastCheckoutID")
//  return true;
// }

export const getVirtualCart = () => {
  return ls('virtualCart') || {}
}

export const createOrUpdateVirtualCart = (
  bundleItems = [],
  bundle = {},
  quantity = 1
) => {

  const itemIds = flatten(_.map(bundleItems, 'variantId'));
  const variantIds = flatten(_.map(bundleItems, 'id'));
  let {
    price = 0,
    name = '',
    variantTitle = '',
    featuredImage = {},
    id
  } = bundle;
  name = variantTitle || name;

  const {
    items: prevItems = [],
    itemIds: prevItemIds = [],
    variantIds: prevVariantIds = []
  } = getVirtualCart();

  const items = []
  const bundleCartItem = {
    id,
    name,
    quantity,
    image: featuredImage,
    price,
    items: bundleItems
  }

  if (!isEmpty(prevItems) && (flatten(_.map(prevItems, 'id'))).includes(id)) {
    const objIndex = prevItems.findIndex((item => item.id === id));
    prevItems[objIndex].quantity += quantity;
  } else {
    items.push(bundleCartItem);
  }
  const virtualCart = {
    itemIds: uniq(prevItemIds.concat(itemIds)),
    items: uniqBy(prevItems.concat(items), 'id'),
    variantIds: uniq(prevVariantIds.concat(variantIds)),
  }
}

export const removeItemFromVirtualCart = (
  removeCheckoutLineItems,
  checkout,
  id = ''
) => {
  const {
    items: prevItems = [],
    itemIds: prevItemIds = []
  } = getVirtualCart();

  const objIndex = prevItems.findIndex((item => item.id === id));

  const itemsToRemove = []
  if (objIndex !== undefined) {
    if (prevItems[objIndex] && prevItems[objIndex].items && Array.isArray(prevItems[objIndex].items)) {
      prevItems[objIndex].items.map(item => {
          prevItemIds.splice(prevItemIds.indexOf(item.variantId), 1)
          checkout.lineItems.edges.map(edge => {
            if (edge.node.variant.id === item.variantId) {
              itemsToRemove.push(edge.node.id)
            }
          })
        }
      )
    }

    prevItems.splice(prevItems.indexOf(objIndex), 1)
  }

  removeCheckoutLineItems({
    variables: {lineItemIds: itemsToRemove, checkoutId: checkout.id},
  })

  const virtualCart = {
    itemIds: uniq(prevItemIds),
    items: uniqBy(prevItems, 'id')
  }

  ls('virtualCart', virtualCart);
}

export const updateItemsInVirtualCart = (
  replaceCheckoutLineItems,
  checkout,
  quantity = 1,
  ids = [],
) => {
  const items = [];

  let pax = 1

  checkout.lineItems.edges.map(edge => {
    let checkoutItem = {
      variantId: window.btoa(edge.node.variant.id),
      quantity: edge.node.quantity,
    }

    if (edge.node.customAttributes.length) {
      const customAttributes = []
      flatten(edge.node.customAttributes).map(attrb => {
        customAttributes.push(pick(attrb, ['key', 'value']))
        if (attrb.key === '_pax') {
          pax = parseInt(attrb.value)
        }
      })
      checkoutItem.customAttributes = customAttributes
    }
    if (includes(ids, edge.node.id)) {
      checkoutItem.quantity = quantity * pax
    }
    items.push(checkoutItem)
  })

  replaceCheckoutLineItems({
    variables: {items: items, checkoutId: checkout.id},
  })
}

import React, { useEffect } from 'react';
import Layout from "../../components/common/Layout";
import Helmet from "react-helmet";
import WebsiteContainer from "../../components/common/atoms/WebsiteContainer";
import { Col, Div, Row, Text, Image } from "../../lib/atomize";
import ImageLazyLoad from "../../components/common/molecules/ImageLazyLoad";
import BlueButton from "../../components/common/molecules/BlueButton";
import { graphql } from "gatsby";

const CYO = ({ data }, props) => {
  const { site } = data
  const PagePath = props?.location?.pathname || props.uri;
  const pageClass = (PagePath ? PagePath.replace(/\//g, '') : 'home')

  return (
    <Layout site={site} path={PagePath} pageClass={`page-wrapper page-${pageClass}`}>
      <Helmet>
        <title>Build Your Bundle</title>
      </Helmet>
      <WebsiteContainer>
      </WebsiteContainer>
    </Layout>
  )
}

export default CYO;

export const query = graphql`
  query Cyo {
    page: sanityPage(_id: { regex: "/(drafts.|)frontpage/" }) {
      ...PageInfo
    }

    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SiteSettings
    }
  }
`

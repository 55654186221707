import React from 'react'

export const Progress = ({percent = ''}) => {
  return (
    <div className="progress">
      <div className="track" />
        <img 
          src="https://cdn.sanity.io/images/bkrzj7um/production/ab8f69c328b6eb37b57d68e2fbd419fb3366913f-80x80.svg?h=200&fit=max" 
          width="24px"
          className="position-icon" 
          style={{left: `${percent}%`}}
        />
    </div>
  )
}
import React from 'react';
import Layout from "components/common/Layout";
import Helmet from "react-helmet";
import WebsiteContainer from "components/common/atoms/WebsiteContainer";
import {Anchor, Div, Icon, Input, Tag, Text} from "lib/atomize";
import CardHeader from "components/common/CardHeader"
import BlueButton from "components/common/molecules/BlueButton"
import {graphql} from "gatsby";
import {Formik} from "formik"
import * as yup from "yup"

import _ from "lodash";

const accessSubscribe = ({data}, props) => {
  const {site} = data
  const PagePath = props?.location?.pathname || props.uri;
  const pageClass = (PagePath ? PagePath.replace(/\//g, '') : 'home')

  let formikProps
  const onSubmit = values => {
    const {setSubmitting, setStatus, resetForm} = formikProps
    const payload = {...values, phone_number: `+1${values.phone_number}`}
    let errorMessage = '';
    let successMessage = '';
    fetch('https://hooks.zapier.com/hooks/catch/5150528/b1zchi0/', {
      method: 'POST',
      body: JSON.stringify(payload)
    }).then(response => response.json())
      .then(result => {
        if (result.status && result.status === 'success') {
          successMessage = 'Thanks! Please be on the lookout for texts from the Dealer ;)'
        } else {
          errorMessage = "Your message didn't go through. Please try again later."
        }
      })
      .finally(() => {
        setSubmitting(false)
        if (successMessage) {
          resetForm()
        }
        setStatus({
          errorMessage,
          successMessage
        })

      })
  }
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  let validator = yup.object().shape({
    first_name: yup
      .string()
      .required("Please enter your first name."),

    last_name: yup
      .string()
      .required("Please enter your last name."),

    phone_number: yup
      .string()
      .matches(new RegExp("^[0-9]{10}$"), {
        message: "Please use a valid 10-digit phone number.",
        excludeEmptyString: false
      })
  })

  return (
    <Layout site={site} path={PagePath} pageClass={`page-wrapper page-${pageClass}`}>
      <Helmet>
        <title>ACCESS Subscribe Page</title>
      </Helmet>
      <WebsiteContainer>
        <div className="subscribe-page">
          <Div
            shadow="1"
            m={{t: {xs: "2.5rem", lg: "0"}}}
            border="1px solid"
            borderColor="yellowPink"
            rounded="lg"
          >
            <>
              <CardHeader
                heading="Not Spam"
                image="https://cdn.shopify.com/s/files/1/2229/0673/files/image_55_c5f98b3f-9658-4967-979f-8398615d41a9.png?v=1605010909"
              />
              <Div p="1.25rem" rounded={{b: "lg"}} bg="yellow">

                <Text
                  style={{lineHeight: "16px"}}
                  m={{b: "1rem"}}
                >
                  Drop your number below to subscribe!
                </Text>


                <Formik
                  initialValues={{
                    first_name: "",
                    last_name: "",
                    phone_number: "",
                  }}
                  onSubmit={onSubmit}
                  validationSchema={validator}
                >
                  {props => {
                    const {
                      values,
                      status,
                      touched,
                      errors,
                      isSubmitting,
                      handleChange,
                      handleBlur,
                      handleSubmit
                    } = props
                    formikProps = props

                    return (
                      <div className="form">
                        {
                          status ?
                            status.successMessage &&
                            <Text
                              textColor="success700"
                              m={{b: "1rem"}}
                              transition
                            >
                              {status.successMessage}
                            </Text> ||
                            status.errorMessage &&
                            <Text
                              textColor="danger700"
                              m={{b: "1rem"}}
                              transition
                            >{status.errorMessage}</Text> : ''
                        }
                        <form onSubmit={handleSubmit}>
                          <Input
                            type="text"
                            name="phone_number"
                            placeholder="Phone Number*"
                            placeholderTextColor="navy"
                            shadow="2"
                            textColor="blue"
                            h="2.5rem"
                            border="1px solid"
                            borderColor={
                              touched.phone_number
                                ? values.phone_number
                                  ? errors.phone_number
                                    ? "red"
                                    : "oilGreen"
                                  : errors.phone_number
                                    ? "red"
                                    : "transparent"
                                : "transparent"
                            }
                            m={{t: "0.625rem"}}
                            value={values.phone_number}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={values.phone_number ? "field-phone value" : "field-phone"}
                            suffix={
                              <Tag>+1</Tag>
                            }
                          />
                          <Text
                            textColor="error"
                            textSize="paragraph"
                            m={{t: ".5rem"}}
                          >
                            {touched.phone_number && _.capitalize(errors.phone_number)}
                          </Text>

                          <Input
                            type="text"
                            name="first_name"
                            placeholder="First Name*"
                            placeholderTextColor="navy"
                            shadow="2"
                            textColor="blue"
                            h="2.5rem"
                            border="1px solid"
                            borderColor={
                              touched.first_name
                                ? values.first_name
                                  ? errors.first_name
                                    ? "red"
                                    : "oilGreen"
                                  : errors.first_name
                                    ? "red"
                                    : "transparent"
                                : "transparent"
                            }
                            m={{t: "0.625rem"}}
                            value={values.first_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="field-fname"
                          />
                          <Text
                            textColor="error"
                            textSize="paragraph"
                            m={{t: ".5rem"}}
                          >
                            {touched.first_name && _.capitalize(errors.first_name)}
                          </Text>
                          <Input
                            type="text"
                            name="last_name"
                            placeholder="Last Name*"
                            placeholderTextColor="navy"
                            shadow="2"
                            textColor="blue"
                            h="2.5rem"
                            border="1px solid"
                            borderColor={
                              touched.last_name
                                ? values.last_name
                                  ? errors.last_name
                                    ? "red"
                                    : "oilGreen"
                                  : errors.last_name
                                    ? "red"
                                    : "transparent"
                                : "transparent"
                            }
                            m={{t: "0.625rem"}}
                            value={values.last_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="field-lname"
                          />
                          <Text
                            textColor="error"
                            textSize="paragraph"
                            m={{t: ".5rem"}}
                          >
                            {touched.last_name && _.capitalize(errors.last_name)}
                          </Text>

                          <BlueButton
                            type="submit"
                            m={{t: "2rem"}}
                            text={
                              isSubmitting ? (
                                <Icon name="Loading" color="white" size="16px"/>
                              ) : (
                                'Submit'
                              )
                            }
                            padding="0.5rem 1.25rem"
                            textSize="header1"
                            h="3rem"
                            w="100%"
                          />
                        </form>
                        <Text m={{t: "0.625rem"}} textSize="caption" className="terms">
                          By signing up, you agree to receive recurring automated messages at the email provided.
                          Consent
                          is not a condition to purchase.{" "}
                          <Anchor
                            textColor="blue"
                            hoverTextColor="navy"
                            textDecor="underline"
                            href="/legal/terms"
                          >
                            Terms
                          </Anchor>{" "}
                          &amp;{" "}
                          <Anchor
                            textColor="blue"
                            hoverTextColor="navy"
                            textDecor="underline"
                            href="/legal/privacy-policy"
                          >
                            Privacy{" "}
                          </Anchor>
                          .
                        </Text>
                      </div>
                    )
                  }}
                </Formik>
              </Div>
            </>
          </Div>
        </div>
      </WebsiteContainer>
    </Layout>
  )
}

export default accessSubscribe;

export const query = graphql`
  query accessSubscribe {
    page: sanityPage(_id: { regex: "/(drafts.|)frontpage/" }) {
      ...PageInfo
    }

    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SiteSettings
    }
  }
`

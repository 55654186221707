import _ from "lodash"
import React from "react"
import axios from "axios"
import { get } from "lodash"
import { useMutation } from "@apollo/client"
import { Div, Anchor, Input, Text, Image, Icon } from "../../lib/atomize"

import * as yup from "yup"
import { Formik } from "formik"
import { transformMutationErrors } from "../../utilities/helpers"
import BlueButton from "../common/molecules/BlueButton"
import { CUSTOMER_RECOVER } from "../../graphql/queries"

export default function ForgotPasswordForm() {
  let formikProps

  const [
    customerRecover,
    { loading: customerRecoverLoading, data: customerRecoverData },
  ] = useMutation(CUSTOMER_RECOVER, {
    onCompleted: result => {
      const { setStatus, setSubmitting } = formikProps

      setStatus({
        successMessage: "Thanks! Please check your email for next steps.",
      })

      if (result.customerRecover.userErrors.length > 0) {
        const errors = result.customerRecover.userErrors
        const error = transformMutationErrors(errors)

        if (
          error.message ===
          "Your password was changed recently. Please try again later!"
        ) {
          setStatus({
            errorMessage:
              "Your password was changed recently. Please try again later!",
          })
        } else {
          setStatus({
            errorMessage: `Sorry, we couldn't find an account associated with the email address.`,
          })
        }
      }

      setSubmitting(false)
    },
    onError: error => {
      const { setStatus, setSubmitting } = formikProps

      setStatus({ errorMessage: error.message })
      setSubmitting(false)
    },
  })

  /**
   * on submit form
   *
   * @param {*} values
   */
  const onSubmit = values => {
    customerRecover({ variables: { email: values.email } })
  }

  const validator = yup.object().shape({
    email: yup
      .string()
      .required("Please use a valid email address.")
      .min(1)
      .email("Please use a valid email address."),
  })

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      onSubmit={onSubmit}
      validationSchema={validator}
    >
      {props => {
        const {
          values,
          status,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          submitForm,
        } = props
        formikProps = props

        return (
          <form onSubmit={handleSubmit}>
            {status && status.successMessage && (
              <Text
                textColor="success700"
                m={{ b: "1rem" }}
                opacity={status.successMessage ? "1" : "0"}
                transition
              >
                {status.successMessage}
              </Text>
            )}
            {status && status.errorMessage && (
              <Text
                textColor="danger700"
                m={{ b: "1rem" }}
                opacity={status.errorMessage ? "1" : "0"}
                transition
              >
                {status.errorMessage}
              </Text>
            )}
            <Div flexDir={{ xs: "column", lg: "row" }}>
              <Div m={{ r: { lg: "0.625rem" } }} flexGrow="1">
                <Input
                  placeholder="Email Address"
                  placeholderTextColor="navy"
                  shadow="2"
                  textColor="blue"
                  h="2.5rem"
                  p="0 2.125rem"
                  name="email"
                  borderColor={
                    touched.email
                      ? values.email
                        ? errors.email
                          ? "red"
                          : "oilGreen"
                        : errors.email
                          ? "red"
                          : "transparent"
                      : "transparent"
                  }
                  prefix={
                    <Image
                      src="/email.svg"
                      w="14px"
                      h="14px"
                      pos="absolute"
                      top="50%"
                      left="0.75rem"
                      transform="translateY(-50%)"
                      alt="Email"
                    />
                  }
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Div>
              <Div
                m={{
                  r: { lg: "0.625rem" },
                  y: { xs: "0.625rem", lg: "0" },
                }}
                flexGrow="1"
              >
                <Text textColor="error" textSize="paragraph" m={{ b: "1rem" }}>
                  {touched.email && _.capitalize(errors.email)}
                </Text>
              </Div>
              <BlueButton
                text={
                  isSubmitting ? (
                    <Icon name="Loading" color="white" size="16px" />
                  ) : (
                    "Submit"
                  )
                }
                m={{ t: "1.25rem" }}
                padding="0.5rem 1.25rem"
                textSize="header1"
                h="2.5rem"
                w="100%"
              />
            </Div>
          </form>
        )
      }}
    </Formik>
  )
}
